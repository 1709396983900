import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  DeleteMachineTicketTask,
  deleteMachineTicketTask,
} from '../../../../../../actions/machines';
import { IAppState } from '../../../../../../reducers/index';
import DeleteModal from './DeleteModal';

const mapStateToProps = ({ machines }: IAppState) => ({
  loading: machines.tickets.deletePopup.loading, 
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteMachineTicketTask: (properties: DeleteMachineTicketTask) =>
      dispatch(deleteMachineTicketTask.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);