import { combineReducers } from 'redux';

import application, { IApplicationState } from './application';
import authentication, { IAuthenticationState } from './authentication';
import customers, { ICustomersState } from './customers';
import dashboard, { IDashboardState } from './dashboard';
import suppliers, { ISuppliersState } from './suppliers';
import machines, { IMachinesState } from './machines';
import users, { IUsersState } from './users';
import tables, { ITablesState } from './tables';

export interface IAppState {
  application: IApplicationState;
  authentication: IAuthenticationState;
  customers: ICustomersState;
  dashboard: IDashboardState;
  suppliers: ISuppliersState;
  machines: IMachinesState;
  users: IUsersState;
  tables: ITablesState;
}

export default combineReducers({
  application,
  authentication,
  customers,
  dashboard,
  suppliers,
  machines,
  users,
  tables,
});
