import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollyFlatbed, faPlug, faTachometerAlt, faUserTag, faUsers, faCog } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import className from 'classname';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import User from '../../models/User';

const StyledDrawer = withStyles({
  root: {
    zIndex: 900,
  },
  paper: {
    width: '250px',
    backgroundColor: '#323538',
    color: '#72808c',
  },
})(Drawer);

const StyledListItemIcon = withStyles({
  root: {
    color: '#72808c',
  },
})(ListItemIcon);

const StyledList = withStyles({
  root: {
    marginLeft: '24px',
    borderLeft: '1px solid #72808c',
  },
})(List);

const StyledListItemText = withStyles({
  root: {
    textAlign: 'left',
    paddingLeft: '12px',
    marginLeft: '12px',
  },
})(ListItemText);

interface IProps {
  history: any;
  location: any;
  match: any;
  user: User;
}

interface IState {
  openCustomers?: boolean;
  openMachines?: boolean;
}

class SideBar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.expandCustomers = this.expandCustomers.bind(this);
    this.expandMachines = this.expandMachines.bind(this);
  }

  public expandCustomers() {
    this.setState((prevState) => ({ openCustomers: !prevState.openCustomers }));
  }

  public expandMachines() {
    this.setState((prevState) => ({ openMachines: !prevState.openMachines }));
  }

  public render() {
    const { openCustomers, openMachines } = this.state;
    const { location, user } = this.props;

    const isDashboardActive = location.pathname === '/dashboard';
    const isCustomersActive = location.pathname.indexOf('/customers') !== -1;
    const isSuppliersActive = location.pathname.indexOf('/suppliers') !== -1;
    const isMachinesActive = location.pathname.indexOf('/machines') !== -1;
    const isUsersActive = location.pathname === '/dashboard/users';
    const isTablesActive = location.pathname.indexOf('/dashboard/definitions') !== -1;
    const isCustomerListActive = className('drawer-list', {
      'drawer-list-active': location.pathname === '/dashboard/customers/list' ||
        location.pathname === '/dashboard/customers/new' ||
        location.pathname.indexOf('/dashboard/customers/customerid') !== -1,
    });
    const isCustomerProposalsActive = className('drawer-list', {
      'drawer-list-active': location.pathname === '/dashboard/customers/proposals/list' ||
        location.pathname === '/dashboard/customers/proposals/new' ||
        location.pathname.indexOf('/dashboard/customers/proposals/proposalid') !== -1
    });
    const isMachineListActive = className('drawer-list', {
      'drawer-list-active': location.pathname === '/dashboard/machines/list' ||
        location.pathname === '/dashboard/machines/new' ||
        location.pathname.indexOf('dashboard/machines/machineid') !== -1,
    });
    const isMachineTicketsActive = className('drawer-list', {
      'drawer-list-active': location.pathname === '/dashboard/machines/tickets/list' ||
        location.pathname === '/dashboard/machines/tickets/new' ||
        location.pathname.indexOf('/dashboard/machines/tickets/ticketid') !== -1,
    });
    
    return (
      <StyledDrawer variant="permanent">
        <Toolbar />
        <div>
          <List>
            {user.role !== 'externaltech' ? (
              <ListItem className="drawer-link" button component={Link} to="/dashboard">
                <StyledListItemIcon style={{ color: isDashboardActive ? '#cb0000' : '#72808c' }}>
                  <FontAwesomeIcon icon={faTachometerAlt} />
                </StyledListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            ) : null}
            {user.role !== 'tech' && user.role !== 'externaltech' ? (
              <>
                <ListItem className="drawer-link" button onClick={this.expandCustomers}>
                  <StyledListItemIcon style={{ color: isCustomersActive ? '#cb0000' : '#72808c' }}>
                    <FontAwesomeIcon icon={faUserTag} />
                  </StyledListItemIcon>
                  <ListItemText primary="Clientes" />
                  {(openCustomers === undefined ? isCustomersActive : openCustomers) ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCustomers === undefined ? isCustomersActive : openCustomers} timeout="auto" unmountOnExit>
                  <StyledList disablePadding>
                    <ListItem className={isCustomerListActive} button component={Link} to="/dashboard/customers/list">
                      <StyledListItemText inset primary="Lista de Clientes" />
                    </ListItem>
                    {user.role !== 'admintech' ? (
                    <ListItem className={isCustomerProposalsActive} button component={Link} to="/dashboard/customers/proposals/list">
                      <StyledListItemText inset primary="Propostas" />
                    </ListItem>
                    ) : null}
                  </StyledList>
                </Collapse>
              </>
            ) : null}
            {user.role !== 'tech' && user.role !== 'externaltech' ? (
              <ListItem className="drawer-link" button component={Link} to="/dashboard/suppliers">
                <StyledListItemIcon style={{ color: isSuppliersActive ? '#cb0000' : '#72808c' }}>
                  <FontAwesomeIcon icon={faDollyFlatbed} />
                </StyledListItemIcon>
                <ListItemText primary="Fornecedores" />
              </ListItem>
            ) : null}
            <ListItem className="drawer-link" button onClick={this.expandMachines}>
              <StyledListItemIcon style={{ color: isMachinesActive ? '#cb0000' : '#72808c' }}>
                <FontAwesomeIcon icon={faPlug} />
              </StyledListItemIcon>
              <ListItemText primary="Maquinas" />
              {(openMachines === undefined ? isMachinesActive : openMachines) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openMachines === undefined ? isMachinesActive : openMachines} timeout="auto" unmountOnExit>
              <StyledList disablePadding>
                {user.role !== 'externaltech' ? (
                  <ListItem className={isMachineListActive} button component={Link} to="/dashboard/machines/list">
                    <StyledListItemText inset primary="Lista de Máquinas" />
                  </ListItem>
                ) : null}
                <ListItem className={isMachineTicketsActive} button component={Link} to="/dashboard/machines/tickets/list">
                  <StyledListItemText inset primary="SATs" />
                </ListItem>
              </StyledList>
            </Collapse>
            <ListItem className="drawer-link" button component={Link} to="/dashboard/users">
              <StyledListItemIcon style={{ color: isUsersActive ? '#cb0000' : '#72808c' }}>
                <FontAwesomeIcon icon={faUsers} />
              </StyledListItemIcon>
              <ListItemText primary="Utilizadores" />
            </ListItem>
            {user.role === 'admin' && (
              <ListItem className="drawer-link" button component={Link} to="/dashboard/definitions">
                <StyledListItemIcon style={{ color: isTablesActive ? '#cb0000' : '#72808c' }}>
                  <FontAwesomeIcon icon={faCog} />
                </StyledListItemIcon>
                <ListItemText primary="Definições" />
              </ListItem>
            )}
          </List>
        </div>
      </StyledDrawer>
    );
  }
}

export default withRouter(SideBar);