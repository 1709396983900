import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchCustomers,
  fetchMachines,
  toggleProposalView,
  toggleMachineView,
} from '../../../../../actions/index';
import { IAppState } from '../../../../../reducers/index';
import List from './List';

const mapStateToProps = ({ customers, machines }: IAppState) => ({
  loading: customers.loading || customers.proposals.loading || machines.loading,
  customers: customers.customers,
  proposals: Object.values(customers.proposals.proposals),
  view: customers.proposals.view.proposalId,
  viewMachine: machines.view.machineId,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleProposalView: (proposalId?: number) =>
      dispatch(toggleProposalView(proposalId)),
    toggleMachineView: (machineId?: number) =>
      dispatch(toggleMachineView(machineId)),
    fetchCustomers: () => dispatch(fetchCustomers.request()),
    fetchMachines: () => dispatch(fetchMachines.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
