import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Machine from '../../../../models/Machine';

interface IProps {
  machine: Machine;
}

const BarSupplier: FunctionComponent <IProps> = (properties) => {
  const {
    barSupplierBrand,
    barSupplierModel,
    barSupplierMaxDiameter,
    barSupplierMaxHeight,
    barSupplierDimentionC,
    barSupplierDimentionL,
    barSupplierDimentionA,
    barSupplierWeight,
  } = properties.machine;

  return (
    <Box pt={2}>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography className="subtitle" component="b">Alimentador de Barra</Typography>
          </Box>

          <Grid container spacing={2}>
            {barSupplierBrand !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Marca</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierBrand}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierModel !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Modelo</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierModel}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierMaxDiameter !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Diâmetro Máximo</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierMaxDiameter}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierMaxHeight !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Comprimento</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierMaxHeight}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierDimentionC !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Dimensão Alimentador C</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierDimentionC}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierDimentionL !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Dimensão Alimentador L</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierDimentionL}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierDimentionA !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Dimensão Alimentador A</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierDimentionA}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {barSupplierWeight !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Peso</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{barSupplierWeight}Kg</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default BarSupplier;
