import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { storageType, MachineTypes } from '../sources';
import Machine from '../../../../models/Machine';

interface IProps {
  machine: Machine;
}

const Optionals: FunctionComponent <IProps> = (properties) => {
  const {
    type,

    tornTurret,
    toolQuantity,
    warehouseType,
    tollExchangeTime,
    supportType,
    staticSupportNumber,
    motorTool,
    motorToolRPM,
    motorToolPotence,
    motorToolSupports,
    motorToolNew,
    motorToolNewSupportName,
    motorToolNewSupportDescription,
    motorToolNewSupportBuyPrice,
    motorToolNewSupportSellPrice,
    motorToolRadQuantity,
    motorToolAxiQuantity,
    electrodeWarehouse,
    electrodeWarehouseQuantity,
    electrodeMaxHeight,
    electrodeMaxErosionHead,
  } = properties.machine;

  return (
    <Box pt={2}>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography className="subtitle" component="b">Gerais</Typography>
          </Box>

          <Grid container spacing={2}>
            {tornTurret !== null && (type === MachineTypes.TORNO || type === MachineTypes.TORNOMULTITASKS || type === MachineTypes.SWISS_LATHE) && (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Turreta de Ferramentas</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{tornTurret ? 'Sim' : 'Não'}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            )}
            {(
              type === MachineTypes.T3X ||
              type === MachineTypes.T5X ||
              type === MachineTypes.HORIZONTAL ||
              type === MachineTypes.TORNO ||
              type === MachineTypes.BORING ||
              type === MachineTypes.SWISS_LATHE ||
              tornTurret
            ) && (
              <>
                {toolQuantity ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Quantidade de Ferramentas</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{toolQuantity}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {type !== MachineTypes.TORNO && (
                  <>
                    {warehouseType ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Armazém</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{(storageType.find((c) => c.id === warehouseType) || {}).name}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tollExchangeTime ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tempo de Troca Tool to Tool</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tollExchangeTime}s</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
            {(type === MachineTypes.TORNO || type === MachineTypes.SWISS_LATHE) && tornTurret && (
              <>
                {supportType ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de Suporte</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{supportType}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {staticSupportNumber ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Nº de Suportes Estáticos</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{staticSupportNumber}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {motorTool !== null ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Ferramenta Motorizada</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{motorTool ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {motorTool && (
                  <>
                    {motorToolRPM ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>RPM</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{motorToolRPM}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {motorToolPotence ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Potência Spindle Fresagem</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{motorToolPotence}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {motorToolSupports ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Suportes Ferramenta Motorizada</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{motorToolSupports}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {motorToolNew !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Novo</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{motorToolNew ? ' Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {motorToolNew ? (
                      <>
                        {motorToolNewSupportName ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Nome do Suporte</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{motorToolNewSupportName}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {motorToolNewSupportDescription ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Descrição do Suporte</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{motorToolNewSupportDescription}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {motorToolNewSupportBuyPrice ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Preço Compra</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{motorToolNewSupportBuyPrice}€</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {motorToolNewSupportSellPrice ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Preço Venda</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{motorToolNewSupportSellPrice}€</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {motorToolRadQuantity ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Qts. Radiais</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{motorToolRadQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {motorToolAxiQuantity ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Qts. Axiais</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{motorToolAxiQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {type === MachineTypes.EDM && electrodeWarehouse !== null ? (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Tem armazém de eletrodos?</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{electrodeWarehouse ? 'Sim' : 'Não'}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            {electrodeWarehouse && (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Quantidade de eletrodos</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{electrodeWarehouseQuantity}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            )}
            {type === MachineTypes.EDM && (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Altura máxima dos eletrodos</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{electrodeMaxHeight}mm</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            )}
            {type === MachineTypes.EDM && (
              <>
                <Grid item sm={6}>
                  <Typography variant="body2">
                    <b>Peso máximo do eletrodo na cabeça de erosão</b>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">{electrodeMaxErosionHead}Kg</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Optionals;