import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchUser } from '../../actions';
import { IAppState } from '../../reducers/index';
import Dashboard from './Dashboard';

const mapStateToProps = ({ authentication }: IAppState) => ({
  user: authentication.user,
  loading: authentication.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchUser: () =>
      dispatch(fetchUser.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
