import LinearProgress from '@material-ui/core/LinearProgress';
import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import AppBar from './AppBar';
import Drawer from './Drawer';
import DashboardContent from './DashboardContent';
import Customers from './Customers';
import Suppliers from './Suppliers';
import Machines from './Machines';
import Users from './Users';
import Tables from './Tables';
import User from '../../models/User';

import './Dashboard.css';

interface IProps {
  fetchUser: () => void;
  loading: boolean;
  user?: User;
  history: any;
}

interface IState {}

class Dashboard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { fetchUser } = this.props;

    fetchUser();
  }

  public render() {
    const { history, loading, user } = this.props;

    return (
      loading ? (
        <LinearProgress />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <AppBar />
          <Drawer user={user || ({} as User)} />
          <main className="container">
            <Router history={history}>
              <Switch>
                <Route path="/dashboard" exact component={DashboardContent} />
                <Route path="/dashboard/customers" render={() => <Customers history={history} />} />
                <Route path="/dashboard/suppliers" render={() => <Suppliers history={history} />} />
                <Route path="/dashboard/machines" render={() => <Machines history={history} />} />
                <Route path="/dashboard/users" render={() => <Users history={history} />} />
                <Route path="/dashboard/definitions" render={() => <Tables history={history} />} />
                <Redirect to="/authenticate" />
              </Switch>
            </Router>
          </main>
        </DndProvider>
      )
    );
  }
}

export default Dashboard;
