import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faTimes } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';

import { EditDynamicField } from '../../../../../actions/index';
import Button from '../../../../common/Button';
import TableButton from '../../../../common/TableButton';
import { DynamicValue } from '../../../../../models/DynamicField';

interface IProps {
  closeDynamicFieldForm: () => void;
  fetchDynamicFieldMeta: (id: string) => void;
  values?: DynamicValue[];
  editDynamicField: (properties: EditDynamicField) => void;
  meta: { id: string, name: string };
  loading: boolean;
}
interface IState {
  values: {
    id?: number;
    value: string;
  }[];
  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { values } = props;

    this.state = {
      values: values || [],
      submitted: false,
      dirty: false,
    };

    this.addValue = this.addValue.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { meta, fetchDynamicFieldMeta } = this.props;

    fetchDynamicFieldMeta(meta.id);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { values } = this.props;

    if (JSON.stringify(prevProps.values) !== JSON.stringify(values)) {
      this.setState({
        values: values || [],
        submitted: false,
      });
    }
  }

  public hasErrors() {
    return false;
  }

  public handleSubmit() {
    const { meta, editDynamicField } = this.props;
    const { values } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    editDynamicField({ id: meta.id, values });
  }

  public handleValueChange(index: number, event: any) {
    const { values } = this.state;

    values[index].value = event.target.value;
  
    this.setState({ values, dirty: true });
  }

  public addValue() {
    const { values } = this.state;

    this.setState({ values: values.concat([{ value: '' }]), dirty: true });
  }

  public handleRemove(index: number) {
    const { values } = this.state;
    
    const v = JSON.parse(JSON.stringify(values));
    v.splice(index, 1);

    this.setState({ values: v, dirty: true });
  }

  public render() {
    const { closeDynamicFieldForm, loading, meta } = this.props;
    const { values, submitted, dirty } = this.state;

    return (
      <Dialog fullWidth maxWidth="sm" open onClose={() => closeDynamicFieldForm()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faGlobe} />
          <span style={{ marginLeft: '8px' }}>Editar "{meta.name}"</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => closeDynamicFieldForm()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: 0 }}>{meta.name}</TableCell>
                <TableCell style={{ width: '32px' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((value, index) =>
                <TableRow key={index}>
                  <TableCell style={{ padding: 0 }}>
                    <FormControl margin="normal" fullWidth style={{ top: '-10px' }}>
                      <TextField
                        error={submitted && !value.value}
                        label="Valor"
                        value={value.value || ''}
                        variant="standard"
                        required
                        onChange={(event: any) => this.handleValueChange(index, event)}
                        helperText={submitted && !value.value ? 'Deve inserir um valor' : ''}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell style={{ padding: 0}}>
                    <TableButton onClick={() => this.handleRemove(index)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </TableButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell style={{ padding: 0 }}></TableCell>
                <TableCell style={{ padding: 0 }}>
                  <IconButton onClick={() => this.addValue()}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            <Box mr={1}>
              <Button color="default" variant="contained" onClick={() => closeDynamicFieldForm()}>Cancelar</Button>
            </Box>
            <Button
              disabled={loading || !dirty}
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={this.handleSubmit}
            >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
};

export default Form;
