import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Form from './Form';

interface IProps {
  fetchUsers: () => void;
  history: any;
  loading: boolean;
}
interface IState {}

class Users extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers();
  }

  public render() {
    const { history } = this.props;

    return (
      <div className="dashboard-container">
        <Router history={history}>
          <Switch>
            <Route path="/dashboard/users" exact component={List} />
            <Route path="/dashboard/users/list" exact component={List} />
            <Route path="/dashboard/users/new" exact render={() => <Form history={history} />} />
            <Route path="/dashboard/users/userid/:userid/edit" exact render={(props) => <Form history={history} userid={props.match.params.userid} />} />
            <Redirect to="/authenticate" />
          </Switch>
        </Router>
      </div>
    );
  }
};

export default Users;