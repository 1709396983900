import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';

import { CreateMachineTicketTaskExpense } from '../../../../../../actions';
import Task, { TaskExpenseType } from '../../../../../../models/Task';
import Button from '../../../../../common/Button';
import User from '../../../../../../models/User';
import Zone from '../../../../../../models/Zone';

interface IProps {
  anchorEl: Element;
  auth: User;
  task: Task;
  users: User[];
  zones: Zone[];
  zoneId?: number;
  createMachineTicketTaskExpense: (properties: CreateMachineTicketTaskExpense) => void;
  handleClose: () => void;
  redirectReady: boolean;
  loading: boolean;
}

interface IState {
  type: TaskExpenseType | '';
  description: string;
  quantity: number;
  price?: number;
  date?: Date;
  regUserId?: number;
  submitted: boolean;
}

class ExpenseModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      type: '',
      description: '',
      quantity: 1,
      regUserId: props.auth.id,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { handleClose, redirectReady } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      handleClose();
    }
  }

  public handleChange(name: keyof IState, event: any) {
    const { zoneId, zones } = this.props;

    const change = { [name]: event.target.value };
    if (name === 'type' && event.target.value === 'travels') {
      change.price = (zones.find((z) => z.id === zoneId) || {}).price;
    } else if (name === 'type') {
      change.price = undefined;
    }

    this.setState(change as Pick<IState, 'type'>);
  }

  public handleDateChange(name: keyof IState, date: Date) {
    this.setState({ [name]: date } as Pick<IState, 'date'>);
  }

  public handleUserSelect(value: any) {
    const { users } = this.props;
    const price = (users.find((u) => u.id === value) || {}).price || 0;

    this.setState({
      regUserId: value,
      price
    } as Pick<IState, 'regUserId'>);
  }

  public hasErrors() {
    const { type, description, quantity, price, regUserId } = this.state;

    return !type.length ||
      (type !== 'manHours' && !description.length) ||
      (type === 'manHours' && !regUserId) ||
      !price ||
      !quantity;
  }

  public handleSubmit() {
    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    const { type, description, quantity, price, date, regUserId } = this.state;
    const { createMachineTicketTaskExpense, task } = this.props;

    createMachineTicketTaskExpense({
      ticketid: task.ticketid,
      taskid: task.id,
      type: type || TaskExpenseType.MANHOURS,
      description: type !== 'manHours' ? description : undefined,
      quantity,
      price: price || 0,
      date,
      regUserId: type === 'manHours' ? regUserId : undefined,
    });
  }

  public render() {
    const { anchorEl, handleClose, loading, users } = this.props;
    const { type, description, quantity, price, date, regUserId, submitted } = this.state;

    return (
      <Popover
        open={true}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card style={{ width: '320px' }}>
          <CardHeader title="Registo de Serviço" action={
            <IconButton edge="end" size="small" className="dialog-close-button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }></CardHeader>
          <CardContent>
            <FormControl
              error={submitted && !type.length}
              margin="dense"
              fullWidth
            >
              <InputLabel id="expensemodal-type">Tipo</InputLabel>
              <Select
                labelId="expensemodal-type"
                value={type || ''}
                onChange={(event: any) => this.handleChange('type', event)}
              >
                <MenuItem value="" disabled>Nenhum</MenuItem>
                <MenuItem value="material">Material</MenuItem>
                <MenuItem value="travels">Deslocações</MenuItem>
                <MenuItem value="manHours">Mão de obra</MenuItem>
                <MenuItem value="others">Outros</MenuItem>
              </Select>
              {submitted && !type.length ? <FormHelperText>Deve seleccionar o tipo da despesa</FormHelperText> : null}
            </FormControl>
            {type === 'manHours' && (
              <FormControl fullWidth>
                <Autocomplete
                  autoComplete
                  clearOnEscape
                  openOnFocus
                  options={users.filter((u) => u.role === 'admin' || u.role === 'admintech' || u.role === 'tech' || u.role === 'externaltech')}
                  value={users.find((u) => u.id === regUserId) || null}
                  getOptionLabel={(option: User) => option.name}
                  onChange={(event, value: User | null) => this.handleUserSelect((value || {}).id)}
                  renderInput={(params: any) =>
                    <TextField
                      {...params}
                      error={submitted && !regUserId}
                      label="Seleccione técnico(s)"
                      helperText={submitted && !regUserId ? 'Deve seleccionar um técnico' : ''}
                    />
                  }
                />
              </FormControl>
            )}
            {type !== 'manHours' && (
              <FormControl margin="dense" fullWidth>
                <TextField
                  error={submitted && !description.length}
                  label="Descrição"
                  value={description || ''}
                  variant="standard"
                  onChange={(event: any) => this.handleChange('description', event)}
                  helperText={submitted && !description.length ? 'Deve inserir uma descrição' : ''}
                />
              </FormControl>
            )}
            <FormControl margin="dense" fullWidth>
              <TextField
                error={submitted && !quantity}
                label="Quantidade"
                value={quantity || ''}
                variant="standard"
                type="number"
                onChange={(event: any) => this.handleChange('quantity', event)}
                helperText={submitted && !quantity ? 'Deve indicar a quantidade' : ''}
              />
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <TextField
                error={submitted && !price}
                label="Preço unitário"
                value={price || ''}
                variant="standard"
                type="number"
                inputProps={{ step: 0.01 }}
                InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
                onChange={(event: any) => this.handleChange('price', event)}
                helperText={submitted && !price ? 'Deve inserir o total' : ''}
              />
            </FormControl>
            <FormControl fullWidth>
              <DateTimePicker
                error={submitted && !date}
                disableToolbar
                ampm={false}
                variant="inline"
                format="yyyy/MM/dd HH:mm"
                label="Data"
                value={date || null}
                onChange={(date: any) => this.handleDateChange('date', date)}
                helperText={submitted && !date ? 'Deve indicar a data' : ''}
              />
            </FormControl>
          </CardContent>
          <Box p={2} pr={1}>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Box mr={1}>
                <Button color="default" variant="contained" onClick={handleClose}>Cancelar</Button>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </CardActions>
          </Box>
        </Card>
      </Popover>
    );
  }
}

export default ExpenseModal;
