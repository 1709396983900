import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronRight, faPencilAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';

import Supplier from '../../../../models/Supplier';
import User from '../../../../models/User';
import { TableFilter } from '../../../../models/TableFilter';
import Button from '../../../common/Button';
import TableButton from '../../../common/TableButton';
import { Column } from '../../../common/types';
import { tableTranslations } from '../../../common/utils';
import ViewModal from '../ViewModal';
import ViewRepsModal from '../ViewRepsModal';

interface IProps {
  suppliers: Supplier[];
  loading: boolean;
  toggleView: (supplierId?: number) => void;
  toggleRepsView: (customerId: number | undefined) => void;
  toggleSupplier: (supplierId: number, active: boolean) => void;
  user: User;
  view?: number;
  repsView?: number;
}
interface IState {
  columns: Column[];
  filter: TableFilter;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const filter = this.getTableState();
    const columns = this.apendFilterListToColumns([
      { name: 'id', label: 'ID', options: { display: (localStorage.getItem('suppliers_list_columns_id') || 'true') as Display } },
      { name: 'name', label: 'Nome', options: { display: (localStorage.getItem('suppliers_list_columns_name') || 'true') as Display } },
      { name: 'repsText', label: 'Pessoas de contacto', options: { display: (localStorage.getItem('suppliers_list_columns_repsText') || 'true') as Display } },
      { name: 'country', label: 'Pais', options: { display: (localStorage.getItem('suppliers_list_columns_country') || 'true') as Display } },
      { name: 'email', label: 'Email', options: { display: (localStorage.getItem('suppliers_list_columns_email') || 'true') as Display } },
      { name: 'telephone', label: 'Telefone', options: { display: (localStorage.getItem('suppliers_list_columns_telephone') || 'true') as Display } },
      { name: 'active', label: 'Estado', options: { display: (localStorage.getItem('suppliers_list_columns_active') || 'true') as Display } },
      {
        name: 'actions',
        label: 'Acções',
        options: {
          display: (localStorage.getItem('suppliers_list_columns_actions') || 'true') as Display,
          filter: false,
          customHeadRender: (columnMeta) => (
            <TableCell key="-1">
              <div style={{ width: '90px'}}>{columnMeta.label}</div>
            </TableCell>
          ),
        }
      },
    ], filter.filterList);
  
    this.state = {
      columns,
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`suppliers_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }
  
  public handleSwitchChange(supplierId: number, checked: boolean) {
    const { toggleSupplier } = this.props;

    toggleSupplier(supplierId, checked);
  }

  public customRowRender(rowData: any[], dataIndex: number, rowIndex: number) {
    const { suppliers, toggleView, toggleRepsView, user } = this.props;
    const { columns } = this.state;

    const supplier = suppliers.find((s) => s.id === rowData[0]) || ({} as Supplier);

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && <TableCell>{rowData[1]}</TableCell>}
        {columns[2].options.display === 'true' && (
          <TableCell>
            <span style={{ cursor: 'pointer' }} className="machine-length" onClick={
              () => user.role !== 'tech' ? toggleRepsView(rowData[0]) : null
            }>
              <FontAwesomeIcon icon={faUser} />
              <span>{(supplier.reps || []).length}</span>
            </span>
          </TableCell>
        )}
        {columns[3].options.display === 'true' && <TableCell>{rowData[3]}</TableCell>}
        {columns[4].options.display === 'true' && <TableCell>{rowData[4]}</TableCell>}
        {columns[5].options.display === 'true' && <TableCell>{rowData[5]}</TableCell>}
        {columns[6].options.display === 'true' && <TableCell>
          <Switch
            checked={rowData[6] === 'Activo'}
            disabled={user.role === 'commercial' || user.role === 'tech'}
            onChange={(checked: boolean) => this.handleSwitchChange(rowData[0], checked)}
            offColor="#eef1f5"
            onColor="#28a745"
            uncheckedIcon={
              <div
                style={{
                  position: 'absolute',
                  left: '-15px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  color: '#b6c3ce',
                }}
              >
                Inactivo
              </div>
            }
            checkedIcon={
              <div
                style={{
                  position: 'absolute',
                  left: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  color: '#ffffff',
                }}
              >
                Activo
              </div>
            }
            width={90}
          />
        </TableCell>}
        {columns[7].options.display === 'true' &&  <TableCell>
          <TableButton onClick={() => toggleView(rowData[0])}>
            <FontAwesomeIcon icon={faEye} />
          </TableButton>
          {user.role !== 'commercial' && user.role !== 'tech' ? (
            <Link to={`/dashboard/suppliers/supplierid/${rowData[0]}/edit`}>
              <TableButton>
                <FontAwesomeIcon icon={faPencilAlt} />
              </TableButton>
            </Link>
          ) : null}
        </TableCell>}
      </TableRow>
    );
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('suppliers_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('suppliers_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][]): any[] {
    filterList.forEach((filter, index) => {
      (columns[index] || {}).options = {
        ...((columns[index] || {}).options || {}),
        filterList: filter,
      };
    });

    return columns;
  }

  public render() {
    const { columns, filter } = this.state;
    const { suppliers, user, view, repsView } = this.props;

    return (
      <>
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">Fornecedores</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">Lista de Fornecedores</Typography>
              </Breadcrumbs>
            </Grid>

            {user.role !== 'commercial' && user.role !== 'tech' ? (
              <Grid item sm={6} style={{ textAlign: 'right' }}>
                <Link to="/dashboard/suppliers/new">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                  >Adicionar</Button>
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <MUIDataTable
                title={''}
                data={suppliers.map((s) => ({ ...s, active: s.active ? 'Activo' : 'Inactivo' }))}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {view ? <ViewModal /> : null}
        {repsView ? <ViewRepsModal /> : null}
      </>
    );
  }
};

export default List;