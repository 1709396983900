import { ActionType, getType } from 'typesafe-actions';

import * as suppliers from '../actions/suppliers';
import Supplier from '../models/Supplier';

type SupplierAction = ActionType<typeof suppliers>;

export interface IFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectReady: boolean;
}

export interface IViewState {
  supplierId?: number;
}
interface IRepsState {
  supplierId?: number;
  loading: boolean;
  error?: object;
  redirectReady: boolean;
}

export interface ISuppliersState {
  readonly suppliers: Record<Supplier['id'], Supplier>;
  readonly form: IFormState;
  readonly error?: object;
  readonly loading: boolean;
  readonly view: IViewState;
  readonly repsView: IRepsState;
}

export const initialState: ISuppliersState = {
  loading: false,
  suppliers:{},
  form: { loading: false, redirectReady: false },
  view: {},
  repsView: {
    loading: false,
    redirectReady: false,
  },
};

const reducer = (
  state: ISuppliersState = initialState,
  action: SupplierAction
): ISuppliersState => {
  switch (action.type) {
    case getType(suppliers.fetchSuppliers.request):
      return {
        ...state,
        loading: true,
      };

    case getType(suppliers.fetchSuppliers.success): {
      const { suppliers } = action.payload;

      const suppliersObj: Record<Supplier['id'], Supplier> = {};
      suppliers.forEach((supplier) => {
        suppliersObj[supplier.id] = supplier;
      });

      return {
        ...state,
        suppliers: suppliersObj,
        error: undefined,
        loading: false,
      };
    }

    case getType(suppliers.fetchSuppliers.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(suppliers.createSupplier.request):
      return {
        ...state,
        form: {
          loading: true,
          redirectReady: false,
        },
      };

    case getType(suppliers.createSupplier.success): {
      const { supplier } = action.payload;

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [supplier.id]: supplier,
        },
        form: {
          ...state.form,
          error: undefined,
          redirectReady: true,
          loading: false,
        },
      };
    }

    case getType(suppliers.createSupplier.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(suppliers.editSupplier.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(suppliers.editSupplier.success): {
      const { supplier } = action.payload;

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [supplier.id]: {
            ...(state.suppliers[supplier.id] || {}),
            ...supplier,
          },
        },
        form: {
          ...state.form,
          error: undefined,
          redirectReady: true,
          loading: false,
        },
      };
    }

    case getType(suppliers.editSupplier.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(suppliers.toggleSupplier.request):
      return {
        ...state,
        loading: true,
      };

    case getType(suppliers.toggleSupplier.success): {
      const { supplierId, active } = action.payload;

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [supplierId]: {
            ...(state.suppliers[supplierId] || {}),
            active,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(suppliers.toggleSupplier.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(suppliers.toggleSupplierView): {
      return {
        ...state,
        view: {
          supplierId: action.payload,
        },
      };
    }

    case getType(suppliers.toggleSupplierRepsView):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          supplierId: action.payload,
        },
      };

    case getType(suppliers.createSupplierRep.request):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          loading: true,
          redirectReady: false,
        }
      };

    case getType(suppliers.createSupplierRep.success): {
      const { rep } = action.payload;
      const supplier = state.suppliers[rep.supplierId] || {};
      const { reps = [] } = supplier;

      reps.push(rep);

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [rep.supplierId]: {
            ...supplier,
            reps,
          }
        },
        repsView: {
          ...state.repsView,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(suppliers.createSupplierRep.failure): {
      const { error } = action.payload;

      return {
        ...state,
        repsView: {
          ...state.repsView,
          error,
          loading: false,
        },
      };
    }
  
    case getType(suppliers.editSupplierRep.request):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          loading: true,
          redirectReady: false,
        }
      };

    case getType(suppliers.editSupplierRep.success): {
      const { rep } = action.payload;
      const supplier = state.suppliers[rep.supplierId] || {};
      const { reps = [] } = supplier;

      const index = reps.findIndex((r) => r.id === rep.id);
      reps[index] = rep;

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [rep.supplierId]: {
            ...supplier,
            reps,
          }
        },
        repsView: {
          ...state.repsView,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(suppliers.editSupplierRep.failure): {
      const { error } = action.payload;

      return {
        ...state,
        repsView: {
          ...state.repsView,
          error,
          loading: false,
        },
      };
    }
  
    case getType(suppliers.deleteSupplierRep.request):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          loading: true,
        }
      };

    case getType(suppliers.deleteSupplierRep.success): {
      const { id, supplierId } = action.payload;
      const supplier = state.suppliers[supplierId] || {};
      const { reps = [] } = supplier;

      const index = reps.findIndex((r) => r.id === id);
      reps.splice(index, 1);

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [supplierId]: {
            ...supplier,
            reps,
          }
        },
        repsView: {
          ...state.repsView,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(suppliers.deleteSupplierRep.failure): {
      const { error } = action.payload;

      return {
        ...state,
        repsView: {
          ...state.repsView,
          error,
          loading: false,
        },
      };
    }
  
    default:
      return state;
  }
};

export default reducer;