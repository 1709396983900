import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { resetPassword } from '../../actions/index';
import { IAppState } from '../../reducers/index';
import PasswordReset from './PasswordReset';

const mapStateToProps = ({ authentication }: IAppState) => ({
  error: authentication.passwordResetForm.error,
  loading: authentication.passwordResetForm.loading,
  redirectReady: authentication.passwordResetForm.redirectReady,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    resetPassword: (password: string, hash: string) =>
      dispatch(resetPassword.request({ password, hash })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
