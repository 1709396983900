import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchMachinesForMarketplace } from '../../actions/index';
import { IAppState } from '../../reducers';
import Marketplace from './Marketplace';

const mapStateToProps = ({ machines }: IAppState) => ({
  showForm: machines.marketplace.contactForm.open,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMachinesForMarketplace: () =>
      dispatch(fetchMachinesForMarketplace.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);