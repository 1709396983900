import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { DynamicValue } from '../../../../models/DynamicField';
import Supplier from '../../../../models/Supplier';
import User from '../../../../models/User';
import { countries } from '../../../../data';
import Button from '../../../common/Button';
import { CircularProgress } from '@material-ui/core';

interface IProps {
  loading: boolean;
  supplier: Supplier;
  supplierTypes: DynamicValue[];
  toggleSupplierView: (supplierId?: number) => void;
  fetchSupplierTypes: () => void;
  user: User;
}

class ViewModal extends Component<IProps> {
  public componentDidMount() {
    const { fetchSupplierTypes } = this.props;

    fetchSupplierTypes();
  }

  public render() {
    const { loading, supplier, supplierTypes, toggleSupplierView, user } = this.props;
    const {
      id,
      address,
      city,
      companyName,
      country,
      email,
      name,
      supplierTaxNumber,
      supplierType,
      telephone,
    } = supplier;

    return (
      <Dialog open onClose={() => toggleSupplierView()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faUserTag} />
          <span style={{ marginLeft: '8px' }}>Detalhes de fornecedor - {name}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleSupplierView()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Grid container spacing={1} alignItems="center">
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Nome</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{name}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Telefone</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{telephone}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Empresa</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{companyName}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Email</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{email}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Número de contribuinte</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{supplierTaxNumber}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Tipo de Fornecedor</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(supplierTypes.find((st) => st.id === supplierType) || {}).value}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Morada</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{address}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Cidade</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{city}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>País</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(countries.find((c) => c.value === country) || {}).label}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            {user.role !== 'commercial' && user.role !== 'tech' ? (
              <Box mr={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
                  component={Link}
                  to={`/dashboard/suppliers/supplierid/${id}/edit`}
                >Editar</Button>
              </Box>
            ) : null}
            <Button color="default" variant="contained" onClick={() => toggleSupplierView()}>Fechar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default ViewModal;