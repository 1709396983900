import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  toggleCustomerRepsView,
  createCustomerRep,
  editCustomerRep,
  deleteCustomerRep,
  CreateCustomerRep,
  EditCustomerRep,
  DeleteCustomerRep,
  fetchDynamicFieldValuesByForm
} from '../../../../actions';
import { IAppState } from '../../../../reducers/index';
import ViewRepsModal from './ViewRepsModal';

const mapStateToProps = ({ customers, tables }: IAppState) => ({
  functions: (tables.dynamicFields.fetch.functions || {}).values || [],
  customerId: customers.repsView.customerId || 0,
  customer: customers.customers[customers.repsView.customerId || 0] || {},
  loading: customers.repsView.loading || (tables.dynamicFields.fetch.functions || {}).loading,
  redirectReady: customers.repsView.redirectReady,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchFunctions: () =>
      dispatch(fetchDynamicFieldValuesByForm.request({ id: 'functions' })),
    toggleCustomerRepsView: (customerId?: number) =>
      dispatch(toggleCustomerRepsView(customerId)),
    createCustomerRep: (properties: CreateCustomerRep) =>
      dispatch(createCustomerRep.request(properties)),
    editCustomerRep: (properties: EditCustomerRep) =>
      dispatch(editCustomerRep.request(properties)),
    deleteCustomerRep: (properties: DeleteCustomerRep) =>
      dispatch(deleteCustomerRep.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRepsModal);