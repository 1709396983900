import { ActionType, getType } from 'typesafe-actions';

import * as users from '../actions/users';
import User from '../models/User';

type UserAction = ActionType<typeof users>;

export interface IFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectReady: boolean;
}

interface IViewState {
  userId?: number;
}
export interface IUsersState {
  readonly users: Record<User['id'], User>;
  readonly form: IFormState;
  readonly error?: object;
  readonly loading: boolean;
  readonly view: IViewState;
}

export const initialState: IUsersState = {
  loading: false,
  users:{},
  form: { loading: false, redirectReady: false },
  view: {},
};

const reducer = (
  state: IUsersState = initialState,
  action: UserAction
): IUsersState => {
  switch (action.type) {
    case getType(users.fetchUsers.request):
      return {
        ...state,
        loading: true,
      };

    case getType(users.fetchUsers.success): {
      const { users } = action.payload;

      const usersObj: Record<User['id'], User> = {};
      users.forEach((user) => {
        usersObj[user.id] = user;
      });

      return {
        ...state,
        users: usersObj,
        error: undefined,
        loading: false,
      };
    }

    case getType(users.fetchUsers.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(users.createUser.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(users.createUser.success): {
      const { user } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [user.id]: user,
        },
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(users.createUser.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(users.editUser.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(users.editUser.success): {
      const { user } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [user.id]: {
            ...(state.users[user.id] || {}),
            ...user,
          },
        },
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(users.editUser.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(users.toggleUser.request):
      return {
        ...state,
        loading: true,
      };

    case getType(users.toggleUser.success): {
      const { id, active } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          [id]: {
            ...(state.users[id] || {}),
            active,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(users.toggleUser.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(users.toggleUserView):
      return {
        ...state,
        view: {
          userId: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;