import { createAction, createAsyncAction } from 'typesafe-actions';

import User from '../models/User';
import { IFailed } from './types';

enum Types {
  FETCH_USER = '@@authentication/FETCH_USER',
  FETCH_USER_FAILED = '@@authentication/FETCH_USER_FAILED',
  FETCH_USER_SUCCEEDED = '@@authentication/FETCH_USER_SUCCEEDED',

  AUTHENTICATE = '@@authentication/AUTHENTICATE',
  AUTHENTICATE_FAILED = '@@authentication/AUTHENTICATE_FAILED',
  AUTHENTICATE_SUCCEEDED = '@@authentication/AUTHENTICATE_SUCCEEDED',

  RECOVER_PASSWORD = '@@authentication/RECOVER_PASSWORD',
  RECOVER_PASSWORD_FAILED = '@@authentication/RECOVER_PASSWORD_FAILED',
  RECOVER_PASSWORD_SUCCEEDED = '@@authentication/RECOVER_PASSWORD_SUCCEEDED',

  RESET_PASSWORD = '@@authentication/RESET_PASSWORD',
  RESET_PASSWORD_FAILED = '@@authentication/RESET_PASSWORD_FAILED',
  RESET_PASSWORD_SUCCEEDED = '@@authentication/RESET_PASSWORD_SUCCEEDED',

  LOGOUT = '@@authentication/LOGOUT',
  LOGOUT_FAILED = '@@authentication/LOGOUT_FAILED',
  LOGOUT_SUCCEEDED = '@@authentication/LOGOUT_SUCCEEDED',

  CLEAR = '@@authentication/CLEAR',
}

interface IFetchUserSuccess {
  user: User;
}

export const fetchUser = createAsyncAction(
  Types.FETCH_USER,
  Types.FETCH_USER_SUCCEEDED,
  Types.FETCH_USER_FAILED
)<void, IFetchUserSuccess, IFailed>();

interface IAuthenticateRequest {
  email: string;
  password: string;
}

export const authenticate = createAsyncAction(
  Types.AUTHENTICATE,
  Types.AUTHENTICATE_SUCCEEDED,
  Types.AUTHENTICATE_FAILED
)<IAuthenticateRequest, void, IFailed>();

interface IRecoverRequest {
  email: string,
}

export const recoverPassword = createAsyncAction(
  Types.RECOVER_PASSWORD,
  Types.RECOVER_PASSWORD_SUCCEEDED,
  Types.RECOVER_PASSWORD_FAILED
)<IRecoverRequest, void, IFailed>();

export interface IResetRequest {
  password: string,
  hash: string,
}

export const resetPassword = createAsyncAction(
  Types.RESET_PASSWORD,
  Types.RESET_PASSWORD_SUCCEEDED,
  Types.RESET_PASSWORD_FAILED
)<IResetRequest, void, IFailed>();

export const logout = createAsyncAction(
  Types.LOGOUT,
  Types.LOGOUT_SUCCEEDED,
  Types.LOGOUT_FAILED
)<void, void, IFailed>();

export const clear = createAction(
  Types.CLEAR,
)<void>();