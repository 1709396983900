import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { recoverPassword } from '../../actions/index';
import { IAppState } from '../../reducers/index';
import PasswordRecovery from './PasswordRecovery';

const mapStateToProps = ({ authentication }: IAppState) => ({
  error: authentication.passwordRecoveryForm.error,
  loading: authentication.passwordRecoveryForm.loading,
  redirectReady: authentication.passwordRecoveryForm.redirectReady,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    recoverPassword: (email: string) =>
      dispatch(recoverPassword.request({ email })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
