import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEuroSign, faPlug, faPlus, faUserTag, faUserCog, faSignature } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import { DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateMachineTicket, EditMachineTicket, EditMachineTicketTask } from '../../../../../actions';
import Machine from '../../../../../models/Machine';
import Customer from '../../../../../models/Customer';
import Task, { TaskExpenseType, TaskStatus } from '../../../../../models/Task';
import Ticket, { TicketStatus } from '../../../../../models/Ticket';
import User from '../../../../../models/User';
import Zone from '../../../../../models/Zone';
import Button from '../../../../common/Button';
import TaskContainer from './TaskContainer';
import TaskForm from './TaskForm';
import TaskModal from './TaskModal';
import SignModal from './SignModal';

import './Form.css';

interface IProps {
  customers: Customer[];
  createMachineTicket: (properties: CreateMachineTicket) => void;
  editMachineTicket: (properties: EditMachineTicket) => void;
  editMachineTicketTask: (properties: EditMachineTicketTask) => void;
  fetchCustomers: () => void;
  fetchMachines: () => void;
  fetchMachinesTickets: () => void;
  fetchUsers: () => void;
  fetchZonesTable: () => void;
  history: any;
  machines: Machine[];
  openSignModal: boolean;
  openMachineTicketTask: (task: Task) => void;
  openSign: (ticketId: number) => void;
  ticket: Ticket;
  users: User[];
  loading: boolean;
  loadingZones: boolean;
  redirectReady: boolean;
  redirectReadyId?: number;
  taskModal?: Task;
  user: User;
  zones: Zone[];
}
interface IState {
  id?: number;
  observations: string;
  title: string;
  machineId?: number;
  customerId?: number;
  limitDate?: Date;
  responsibleUserId?: number;
  zoneId?: number;
  costCenter?: string;
  invoiceno?: string;
  tasks: Task[];
  status: TicketStatus,
  created: Date,

  formTasks: Record<string, boolean>;

  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { ticket, user, users } = props;

    const {
      id,
      title,
      machineId,
      customerId,
      observations,
      limitDate,
      responsibleUserId,
      zoneId,
      costCenter,
      invoiceno,
      tasks,
      status,
      created,
    } = ticket;

    const defaultUserId = users.filter(
      (u) => u.role === 'admin' || u.role === 'admintech' || u.role === 'tech' || u.role === 'externaltech'
    ).map((u) => u.id).indexOf(user.id) !== -1 ? user.id : undefined;
    this.state = {
      id,
      title: title || '',
      machineId,
      customerId,
      observations: observations || '',
      limitDate,
      responsibleUserId: responsibleUserId || defaultUserId,
      zoneId,
      costCenter: costCenter || '',
      invoiceno,
      tasks: Object.values(tasks || {}),
      status: status || TicketStatus.PENDING,
      created: created || new Date(),

      formTasks: {},

      submitted: false,
      dirty: false,
    };
    
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.openMultipleSign = this.openMultipleSign.bind(this);
    this.onTaskDrop = this.onTaskDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.getExpensesTotal = this.getExpensesTotal.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { history, ticket, user, users, redirectReady, redirectReadyId } = this.props;
    
    if (JSON.stringify(prevProps.ticket) !== JSON.stringify(ticket)) {
      const {
        id,
        title,
        machineId,
        customerId,
        observations,
        limitDate,
        responsibleUserId,
        zoneId,
        costCenter,
        invoiceno,
        tasks,
        status,
        created,
      } = ticket;

      const defaultUserId = users.filter(
        (u) => u.role === 'admin' || u.role === 'admintech' || u.role === 'tech' || u.role === 'externaltech'
      ).map((u) => u.id).indexOf(user.id) !== -1 ? user.id : undefined;
      this.setState({
        id,
        title: title || '',
        machineId,
        customerId,
        observations: observations || '',
        limitDate,
        responsibleUserId: responsibleUserId || defaultUserId,
        zoneId,
        costCenter,
        invoiceno,
        tasks: Object.values(tasks || {}),
        status: status || TicketStatus.PENDING,
        created: created || new Date(),
      });
    }

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        if (redirectReadyId) {
          history.push(`/dashboard/machines/tickets/ticketid/${redirectReadyId}/edit`);
        } else {
        history.push('/dashboard/machines/tickets/list');
        }
      });
    }
  }

  public componentDidMount() {
    const {
      fetchCustomers,
      fetchMachines,
      fetchMachinesTickets,
      fetchUsers,
      fetchZonesTable
    } = this.props;
    
    fetchCustomers();
    fetchMachines();
    fetchMachinesTickets();
    fetchUsers();
    fetchZonesTable();
  }

  public handleChange(field: string, event: any) {
    this.setState({
      [field]: event.target.value,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public handleSelect(field: string, value: any) {
    const { customers } = this.props;
    const updatedState = {
      [field]: value,
      dirty: true,
    } as Pick<IState, 'machineId' | 'zoneId'>;

    if (field === 'customerId') {
      updatedState.zoneId = (customers.find((c) => c.id === value) || {}).zoneId;
      updatedState.machineId = undefined;
    }

    this.setState(updatedState);
  }

  public handleDateChange(field: string, date: Date) {
    this.setState({
      [field]: date,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public onTaskDrop(status: TaskStatus, index: number, newStatus: TaskStatus) {
    if (status !== newStatus) {
      const { tasks } = this.state;
      const { editMachineTicketTask } = this.props;

      const task = tasks.filter((t) => t.status === status)[index];

      const { ticketid, id, title, technicians, files } = task;
      
      editMachineTicketTask({ ticketid, id, title, technicians, status: newStatus, files });
    }
  }

  public openModal(task: Task) {
    const { openMachineTicketTask } = this.props;

    openMachineTicketTask(task);
  }

  public changeStatus(index: number, status: TaskStatus) {
    const { tasks } = this.state;
    tasks[index].status = status;
    this.setState({ tasks });
  }

  public showTaskForm(status: string) {
    const { formTasks } = this.state;
    formTasks[status] = !formTasks[status];
    this.setState({ formTasks });
  }

  public getExpensesQuantity(expenseType: TaskExpenseType) {
    const { tasks } = this.state;
    let total = 0;

    tasks.forEach((task) => {
      Object.values(task.expenses || {})
        .filter((e) => e.type === expenseType)
        .forEach((e) => {
          total += e.quantity;
        });
    });

    return total;
  }

  public getExpensesTotal(expenseType: TaskExpenseType) {
    const { tasks } = this.state;
  
    let total = 0;

    tasks.forEach((task) => {
      Object.values(task.expenses || {})
        .filter((e) => e.type === expenseType)
        .forEach((e) => {
          total += e.quantity * e.price;
        });
    });

    return total;
  }

  public openMultipleSign() {
    const { openSign } = this.props;
    const { id } = this.state;

    openSign(id || 0);
  }

  public hasErrors() {
    const {
      title,
      customerId,
      responsibleUserId,
      zoneId,
    } = this.state;

    if (
      !title.length ||
      !customerId ||
      !responsibleUserId ||
      !zoneId
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createMachineTicket, editMachineTicket } = this.props;
    const {
      id,
      title,
      machineId,
      customerId,
      observations,
      limitDate,
      responsibleUserId,
      zoneId,
      costCenter,
      invoiceno,
      created
    } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editMachineTicket({
        id,
        title,
        machineId: machineId || undefined,
        customerId: customerId || 0,
        observations: observations || undefined,
        limitDate: limitDate || undefined,
        responsibleUserId: responsibleUserId || 0,
        zoneId: zoneId || 0,
        costCenter: costCenter || undefined,
        invoiceno: costCenter === 'invoice' ? invoiceno || undefined : undefined,
        created
      });
    } else {
      createMachineTicket({
        title,
        machineId: machineId || undefined,
        customerId: customerId || 0,
        observations: observations || undefined,
        limitDate: limitDate || undefined,
        responsibleUserId: responsibleUserId || 0,
        zoneId: zoneId || 0,
        costCenter: costCenter || undefined,
        invoiceno: costCenter === 'invoice' ? invoiceno || undefined : undefined,
      });
    }
  }

  public render() {
    const { customers, machines, users, loading, loadingZones, openSignModal, taskModal, user, zones } = this.props;
    const {
      id,
      title,
      machineId,
      customerId,
      observations,
      limitDate,
      responsibleUserId,
      zoneId,
      costCenter,
      invoiceno,
      tasks,
      status,
      created,
      formTasks,
      submitted,
      dirty
    } = this.state;

    const viewOnly = user.role !== 'admin' && user.role !== 'admintech';

    return (
      <>
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />

        <Box>
          <Grid container>
            <Grid item>
              <Typography variant="h6" component="h2">Máquinas</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Link color="inherit" to="/dashboard/machines/tickets/list">
                  Lista de SATs
                </Link>
                <Typography color="textPrimary">Adicionar</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          {!viewOnly ? (
            <Grid style={{ justifyContent: 'flex-end' }} container>
              <Grid style={{ display: 'flex' }} item>
                <Box mr={1}>
                  <Link to="/dashboard/machines/tickets/list">
                    <Button color="default" variant="contained">Cancelar</Button>
                  </Link>
                </Box>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  startIcon={<SaveIcon />}
                  onClick={this.handleSubmit}
                >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
              </Grid>
            </Grid>
          ) : null}
        </Box>

        <Box mt={2} pb={2}>
          <Grid container spacing={2}>
            <Grid item md={user.role !== 'externaltech' ? 8 : 12}>
              <Card>
                <CardContent>
                  <Box p={2}>
                    <Typography className="subtitle" component="b">Dados gerais</Typography>
                  </Box>

                  <Box m={2}>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !title.length}
                            label="Título"
                            required
                            value={title}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('title', event)}
                            helperText={submitted && !title.length ? 'Deve introduzir o título' : ''}
                            inputProps={{ maxLength: 255 }}
                            disabled={viewOnly}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={6} item>
                        <FormControl fullWidth>
                          <Autocomplete
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            options={customers}
                            value={customers.find((c) => c.id === customerId) || null}
                            getOptionLabel={(option: Customer) => option.name}
                            onChange={(event, value: any) => this.handleSelect('customerId', value ? value.id : undefined)}
                            renderOption={(option) => {
                              const customer = customers.find((c) => c.id === option.id) || {} as Customer;

                              return (
                                <Typography variant="body1">
                                  {option.name}
                                  {!customer.active ? <Typography variant="caption" style={{ color: '#ccc', marginLeft: '7px' }}>(Cliente inactivo)</Typography> : null}
                                </Typography>
                              );
                            }}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                error={submitted && !customerId}
                                label="Cliente"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon className="form-icon" icon={faUserTag} />
                                    </InputAdornment>
                                  )
                                }}
                                helperText={submitted && !customerId ? 'Deve seleccionar um cliente' : ''}
                              />
                            }
                            disabled={viewOnly}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={6} item>
                        <FormControl fullWidth>
                          <Autocomplete
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            disabled={viewOnly || !customerId}
                            options={machines.filter((m) => m.customerId === customerId)}
                            value={machines.find((m) => m.id === machineId) || null}
                            getOptionLabel={(option: Machine) => option.description || ''}
                            onChange={(event, value: any) => this.handleSelect('machineId', value ? value.id : undefined)}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                label="Máquina"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon className="form-icon" icon={faPlug} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            label="Descrição do problema"
                            value={observations}
                            variant="standard"
                            multiline={true}
                            minRows={3}
                            inputProps={{ maxLength: 3000 }}
                            onChange={(event: any) => this.handleChange('observations', event)}
                            disabled={viewOnly}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {user.role !== 'externaltech' && (
              <Grid item md={4}>
                <Card style={{ minHeight: '100%' }}>
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <InputLabel>Estado</InputLabel>
                        <Box mt={2}>
                          <Chip
                            style={{
                              height: 'auto',
                              padding: '3px',
                              backgroundColor:
                                status === TicketStatus.PENDING
                                ? '#7a7a7a'
                                : status === TicketStatus.EXECUTION
                                ? '#007bff'
                                : status === TicketStatus.DONE
                                ? '#28a745'
                                : '',
                              color: '#ffffff',
                            }}
                            label={
                              status === TicketStatus.PENDING
                              ? 'Em espera'
                              : status === TicketStatus.EXECUTION
                              ? 'Em execução'
                              : status === TicketStatus.DONE
                              ? 'Concluído'
                              : ''
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid sm={12} item>
                        <InputLabel>Criado em</InputLabel>
                        <Typography variant="body1">{format(new Date(created || new Date()), 'yyyy-MM-dd')}</Typography>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            label="Data limite"
                            value={limitDate || null}
                            onChange={(date: any) => this.handleDateChange('limitDate', date)}
                            disabled={viewOnly}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <Autocomplete
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            options={users.filter((u) => u.role === 'admin' || u.role === 'admintech' || u.role === 'tech' || u.role === 'externaltech')}
                            value={users.find((u) => u.id === responsibleUserId) || null}
                            getOptionLabel={(option: User) => option.name}
                            onChange={(event, value: any) => this.handleSelect('responsibleUserId', value ? value.id : undefined)}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                error={submitted && !responsibleUserId}
                                label="SAT criada por"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon className="form-icon" icon={faUserCog} />
                                    </InputAdornment>
                                  )
                                }}
                                helperText={submitted && !responsibleUserId ? 'Deve seleccionar um utilizador' : ''}
                              />
                            }
                            disabled={viewOnly}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
            
          {user.role !== 'externaltech' ? (
            <>
              <Box mt={2} pb={2}>
                <Card>
                  <CardContent>
                    <Box p={2}>
                      <Typography className="subtitle" component="b">Dados de facturação</Typography>
                    </Box>

                    <Box pl={2} pr={2}>
                      <Grid container spacing={2}>
                        <Grid sm={6} item>
                          <FormControl disabled={viewOnly} fullWidth>
                            <InputLabel id="form-costCenter">Tipo de Custos</InputLabel>
                            <Select
                              id="form-costCenter"
                              value={costCenter || ''}
                              onChange={(event: any) => this.handleChange('costCenter', event)}
                              name="costCenter"
                            >
                              <MenuItem value="">
                                <i>Nenhum</i>
                              </MenuItem>
                              <MenuItem value="warranty">Garantia</MenuItem>
                              <MenuItem value="sell">Ao abrigo do valor de venda</MenuItem>
                              <MenuItem value="invoice">Facturação</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid sm={6} item>
                          <FormControl fullWidth>
                            <TextField
                              label="Número de factura"
                              value={invoiceno || ''}
                              variant="standard"
                              onChange={(event: any) => this.handleChange('invoiceno', event)}
                              inputProps={{ maxLength: 255 }}
                              disabled={viewOnly}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>

              <Box mt={2} mb={2}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Card>
                      <CardContent>
                        <Box p={2}>
                          <Typography className="subtitle" component="b">Custos da intervenção</Typography>
                        </Box>
                        
                        <Box pl={2} pr={2}>
                          <Grid container spacing={2}>
                            <Grid item sm={6}>
                              {loadingZones ? <CircularProgress /> : (
                                <FormControl fullWidth>
                                  <TextField
                                    error={submitted && !zoneId}
                                    fullWidth
                                    label="Zona"
                                    select
                                    value={zoneId || ''}
                                    variant="standard"
                                    onChange={(event: any) => this.handleChange('zoneId', event)}
                                    helperText={submitted && !zoneId ? 'Deve seleccionar uma zona' : ''}
                                    disabled={viewOnly}
                                  >
                                    {zones.map((option) => (
                                      <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </FormControl>
                              )}
                            </Grid>
                          </Grid>
                        </Box>

                        <Table style={{ opacity: !id ? 0.3 : 1 }}>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <FormControl fullWidth>
                                  <TextField
                                    label="Material"
                                    disabled
                                    value={this.getExpensesTotal(TaskExpenseType.MATERIAL)}
                                    variant="standard"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl fullWidth>
                                  <TextField
                                    label="Deslocações"
                                    disabled
                                    value={this.getExpensesTotal(TaskExpenseType.TRAVELS)}
                                    variant="standard"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl fullWidth>
                                  <TextField
                                    label="Horas Trabalhadas"
                                    disabled
                                    value={this.getExpensesQuantity(TaskExpenseType.MANHOURS)}
                                    variant="standard"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">(h)</InputAdornment>
                                      )
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl fullWidth>
                                  <TextField
                                    label="Custo Técnico"
                                    disabled
                                    value={this.getExpensesTotal(TaskExpenseType.MANHOURS)}
                                    variant="standard"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl fullWidth>
                                  <TextField
                                    label="Outros"
                                    disabled
                                    value={this.getExpensesTotal(TaskExpenseType.OTHERS)}
                                    variant="standard"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : null}
          <Box mt={2} pb={2}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Card>
                  <CardContent style={{ opacity: !id ? 0.3 : 1 }}>
                    <Box p={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography className="subtitle" component="b">Tarefas</Typography>

                      {id && tasks.find((t) => t.status === TaskStatus.DONE) && (
                        <Button
                          variant="contained"
                          color="default"
                          style={{ justifyContent: 'flex-start' }}
                          startIcon={<FontAwesomeIcon icon={faSignature} />}
                          onClick={this.openMultipleSign}
                        >
                          Assinar Várias
                        </Button>
                      )}
                    </Box>

                    <Grid container spacing={4}>
                      <Grid item sm={4}>
                        <Paper className="task-header pending">
                          <Box pl={2}>
                            <Typography variant="body1" component="span">Em espera</Typography>
                          </Box>
                        </Paper>
                        <Box p={2} className="task-container">
                          <TaskContainer
                            onTaskDrop={this.onTaskDrop}
                            openModal={this.openModal}
                            status={TaskStatus.PENDING}
                            tasks={tasks}
                            users={users}
                            viewOnly={viewOnly && user.role !== 'tech'}
                          />
                          {!viewOnly || user.role === 'tech' ? (
                            <>
                              {formTasks.pending ? (
                                <TaskForm
                                  ticketId={id || 0}
                                  users={users}
                                  status={TaskStatus.PENDING}
                                  onCancel={() => this.showTaskForm('pending')}
                                />
                              ) : (
                                <Box p={2}>
                                  <Button
                                    color="inherit"
                                    disabled={!id}
                                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                                    onClick={() => this.showTaskForm('pending')}
                                  >Adicionar</Button>
                                </Box>
                              )}
                            </>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item sm={4}>
                        <Paper className="task-header execution">
                          <Box pl={2}>
                            <Typography variant="body1" component="span">Em execução</Typography>
                          </Box>
                        </Paper>
                        <Box p={2} className="task-container">
                          <TaskContainer
                            onTaskDrop={this.onTaskDrop}
                            openModal={this.openModal}
                            status={TaskStatus.EXECUTION}
                            tasks={tasks}
                            users={users}
                            viewOnly={viewOnly && user.role !== 'tech'}
                          />
                          {!viewOnly || user.role === 'tech' ? (
                            <>
                              {formTasks.execution ? (
                                <TaskForm
                                  ticketId={id || 0}
                                  users={users}
                                  status={TaskStatus.EXECUTION}
                                  onCancel={() => this.showTaskForm('execution')}
                                />
                              ) : (
                                <Box p={2}>
                                  <Button
                                    color="inherit"
                                    disabled={!id}
                                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                                    onClick={() => this.showTaskForm('execution')}
                                  >Adicionar</Button>
                                </Box>
                              )}
                            </>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item sm={4}>
                        <Paper className="task-header done">
                          <Box pl={2}>
                            <Typography variant="body1" component="span">Concluídas</Typography>
                          </Box>
                        </Paper>
                        <Box p={2} className="task-container">
                          <TaskContainer
                            onTaskDrop={this.onTaskDrop}
                            openModal={this.openModal}
                            status={TaskStatus.DONE}
                            tasks={tasks}
                            users={users}
                            viewOnly={viewOnly && user.role !== 'tech'}
                          />
                          {!viewOnly || user.role === 'tech' ? (
                            <>
                              {formTasks.done ? (
                                <TaskForm
                                  ticketId={id || 0}
                                  users={users}
                                  status={TaskStatus.DONE}
                                  onCancel={() => this.showTaskForm('done')}
                                />
                              ) : (
                                <Box p={2}>
                                  <Button
                                    color="inherit"
                                    disabled={!id}
                                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                                    onClick={() => this.showTaskForm('done')}
                                  >Adicionar</Button>
                                </Box>
                              )}
                            </>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          
          {!viewOnly ? (
            <Grid style={{ justifyContent: 'flex-end' }} container>
              <Grid style={{ display: 'flex' }} item>
                <Box mr={1}>
                  <Link to="/dashboard/machines/tickets/list">
                    <Button color="default" variant="contained">Cancelar</Button>
                  </Link>
                </Box>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  startIcon={<SaveIcon />}
                  onClick={this.handleSubmit}
                >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
              </Grid>
            </Grid>
          ) : null}
        </Box>
        {!openSignModal && taskModal ? (
          <TaskModal
            taskid={taskModal.id}
            ticketid={taskModal.ticketid}
            zoneId={zoneId}
            zones={zones}
            users={users}
            viewOnly={viewOnly && user.role !== 'tech'}
          />
        ): null}
        {openSignModal ? <SignModal /> : null}
      </>
    );
  }
};

export default Form;