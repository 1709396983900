import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { openMarketplaceForm } from '../../../actions/index';
import { IAppState } from '../../../reducers/index';
import List from './List';

const mapStateToProps = ({ machines }: IAppState) => ({
  error: machines.marketplace.error,
  machines: machines.marketplace.machines || [],
  loading: machines.marketplace.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    openForm: (machineId: number) => dispatch(openMarketplaceForm({ machineId })),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(List);