import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';

interface IProps {
  costsWarrantyMaterial?: number;
  costsWarrantyTravels?: number;
  costsWarrantyManHours?: number;
  costsWarrantyOthers?: number;
  costsSellMaterial?: number;
  costsSellTravels?: number;
  costsSellManHours?: number;
  costsSellOthers?: number;
  costsInvoicingMaterial?: number;
  costsInvoicingTravels?: number;
  costsInvoicingManHours?: number;
  costsInvoicingOthers?: number;
  costsOthersMaterial?: number;
  costsOthersTravels?: number;
  costsOthersManHours?: number;
  costsOthersOthers?: number;

  handleChange: (name: string, value: any) => void;
}

class FormInfoCosts extends PureComponent<IProps> {
  public render() {
    const {
      costsWarrantyMaterial,
      costsWarrantyTravels,
      costsWarrantyManHours,
      costsWarrantyOthers,
      costsSellMaterial,
      costsSellTravels,
      costsSellManHours,
      costsSellOthers,
      costsInvoicingMaterial,
      costsInvoicingTravels,
      costsInvoicingManHours,
      costsInvoicingOthers,
      costsOthersMaterial,
      costsOthersTravels,
      costsOthersManHours,
      costsOthersOthers,

      handleChange,
    } = this.props;

    return (
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Custos de SAT's</Typography>
            </Box>
            
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ color: 'grey' }}>Material</TableCell>
                  <TableCell style={{ color: 'grey' }}>Deslocações</TableCell>
                  <TableCell style={{ color: 'grey' }}>Custo Técnico</TableCell>
                  <TableCell style={{ color: 'grey' }}>Outros</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Garantia</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsWarrantyMaterial || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsWarrantyTravels || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsWarrantyManHours || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsWarrantyOthers || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={
                          (costsWarrantyMaterial || 0) +
                          (costsWarrantyTravels || 0) +
                          (costsWarrantyManHours || 0) +
                          (costsWarrantyOthers || 0)
                        }
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ao abrigo do valor de venda</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsSellMaterial || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsSellTravels || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsSellManHours || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsSellOthers || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={
                          (costsSellMaterial || 0) +
                          (costsSellTravels || 0) +
                          (costsSellManHours || 0) +
                          (costsSellOthers || 0)
                        }
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Facturação</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsInvoicingMaterial || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsInvoicingTravels || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsInvoicingManHours || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={costsInvoicingOthers || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={
                          (costsInvoicingMaterial || 0) +
                          (costsInvoicingTravels || 0) +
                          (costsInvoicingManHours || 0) +
                          (costsInvoicingOthers || 0)
                        }
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Outro</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        value={costsOthersMaterial || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                        onChange={(event: any) => handleChange('costsOthersMaterial', event.target.value)}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        value={costsOthersTravels || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                        onChange={(event: any) => handleChange('costsOthersTravels', event.target.value)}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        value={costsOthersManHours || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                        onChange={(event: any) => handleChange('costsOthersManHours', event.target.value)}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        value={costsOthersOthers || 0}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                        onChange={(event: any) => handleChange('costsOthersOthers', event.target.value)}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        value={
                          Number(costsOthersMaterial || 0) +
                          Number(costsOthersTravels || 0) +
                          Number(costsOthersManHours || 0) +
                          Number(costsOthersOthers || 0)
                        }
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEuroSign} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    );
  }
};

export default FormInfoCosts;
