import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { coneTypes, MachineTypes, motorTransmissionTypes } from '../sources';

interface IProps {
  type: string;

  courseX?: number;
  courseY?: number;
  courseZ?: number;
  course4Axis?: number;
  course4AxisMM?: number;
  course5Axis?: number;
  axisType?: string;
  positioning4Axis?: string;
  positioning5Axis?: string;
  autoPalletExchange?: boolean;
  autoPalletExchangeQuantity?: number;
  autoPalletExchangeDimension?: number;
  tableMeasurement: string;
  tableDimensionX?: number;
  tableDimensionY?: number;
  tableDimensionDiameter?: number;
  spindleTableDistance?: number;
  spindleTableDistanceMin?: number;
  spindleTableDistanceMax?: number;
  spindleTableWeight?: number;
  rotativeTable?: boolean;
  rotativeTableAngleMinPositioning?: number;
  robot?: boolean;
  robotNumberOfEletrods?: number;
  robotNumberOfPallets?: number;
  robotNumberOfAxis?: number;
  robotReach?: number;
  tankDimensionLength?: number;
  tankDimensionWidth?: number;
  tankDimensionHeight?: number;
  tankSubmersible?: boolean;
  tankSubmersibleAuto?: boolean;
  portableSteeringWheel?: boolean;
  partMaxHeight?: number;
  dielectricQuantity?: number;
  hasCorePullers?: boolean;
  corePullersSideMobile?: boolean;
  corePullersSideFixed?: boolean;
  corePullersQuantity?: string;
  hasPneumaticValves?: boolean;
  pneumaticValvesSideMobile?: boolean;
  pneumaticValvesSideFixed?: boolean;
  pneumaticValvesQuantity?: string;
  hasControlValveGate?: boolean;
  controlValveGateQuantity?: number;
  hasMoldTemperatureControl?: boolean;
  moldTemperatureControlZones?: number;
  maxHydricPression?: number;
  heatingPower?: number;
  oilTankCapacity?: number;
  motorPump?: number;
  motorPower?: number;
  tempControlZones?: number;
  magneticTableDimensionLength?: number;
  magneticTableDimensionWidth?: number;
  magneticTableWeight?: number;
  rapidAxisVelocityX?: number;
  rapidAxisVelocityY?: number;
  rapidAxisVelocityZ?: number;
  programmableVelocityAxisX?: number;
  programmableVelocityAxisY?: number;
  programmableVelocityAxisZ?: number;
  rapidAxisVelocityC?: number;
  programmableVelocityAxisC?: number;
  rapidAxisVelocityB?: number;
  programmableVelocityAxisB?: number;
  transmissionMotor4Axis?: string;
  transmissionMotor5Axis?: string;
  cutMaxVelocity?: number;
  cutMaxAngle?: number;
  cutMaxAngleByLength?: number;
  turningDiameter?: number;
  turningLength?: number;
  detailsRPM?: number;
  detailsPotence?: number;
  detailsTorque?: number;
  detailsConeType?: number;
  headtype?: string;
  guidesWidth?: number;
  RPMAxisC?: number;
  maximumFeedrate?: number;
  connectionPower?: number;

  handleChange: (name: string, value: any) => void;
}

const FormDetails: FunctionComponent <IProps> = (properties) => {
  const {
    type,
    
    courseX,
    courseY,
    courseZ,
    course4Axis,
    course4AxisMM,
    course5Axis,
    axisType,
    positioning4Axis,
    positioning5Axis,
    autoPalletExchange,
    autoPalletExchangeQuantity,
    autoPalletExchangeDimension,
    tableMeasurement,
    tableDimensionX,
    tableDimensionY,
    tableDimensionDiameter,
    spindleTableDistance,
    spindleTableDistanceMin,
    spindleTableDistanceMax,
    spindleTableWeight,
    rotativeTable,
    rotativeTableAngleMinPositioning,
    robot,
    robotNumberOfEletrods,
    robotNumberOfPallets,
    robotNumberOfAxis,
    robotReach,
    tankDimensionLength,
    tankDimensionWidth,
    tankDimensionHeight,
    tankSubmersible,
    tankSubmersibleAuto,
    portableSteeringWheel,
    partMaxHeight,
    dielectricQuantity,
    hasCorePullers,
    corePullersQuantity,
    corePullersSideMobile,
    corePullersSideFixed,
    hasPneumaticValves,
    pneumaticValvesQuantity,
    pneumaticValvesSideMobile,
    pneumaticValvesSideFixed,
    hasControlValveGate,
    controlValveGateQuantity,
    hasMoldTemperatureControl,
    moldTemperatureControlZones,
    maxHydricPression,
    heatingPower,
    oilTankCapacity,
    motorPump,
    motorPower,
    tempControlZones,
    magneticTableDimensionLength,
    magneticTableDimensionWidth,
    magneticTableWeight,
    rapidAxisVelocityX,
    rapidAxisVelocityY,
    rapidAxisVelocityZ,
    programmableVelocityAxisX,
    programmableVelocityAxisY,
    programmableVelocityAxisZ,
    rapidAxisVelocityC,
    programmableVelocityAxisC,
    rapidAxisVelocityB,
    programmableVelocityAxisB,
    transmissionMotor4Axis,
    transmissionMotor5Axis,
    cutMaxVelocity,
    cutMaxAngle,
    cutMaxAngleByLength,
    turningDiameter,
    turningLength,
    detailsRPM,
    detailsPotence,
    detailsTorque,
    detailsConeType,
    headtype,
    guidesWidth,
    RPMAxisC,
    maximumFeedrate,
    connectionPower,

    handleChange,
  } = properties;

  return (
    <>
      {(type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.TORNO ||
        type === MachineTypes.TORNOMULTITASKS ||
        type === MachineTypes.BORING ||
        type === MachineTypes.EDM ||
        type === MachineTypes.EDM_WIRE ||
        type === MachineTypes.EDM_HOLE ||
        type === MachineTypes.SWISS_LATHE ||
        type === MachineTypes.FLAT_GRINDING) && (
        <>
          <Box pt={2}>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Typography className="subtitle" component="b">Curso</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="X"
                        value={courseX || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('courseX', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Y"
                        value={courseY || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('courseY', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Z"
                        value={courseZ || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('courseZ', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  {(
                    type === MachineTypes.T5X ||
                    type === MachineTypes.HORIZONTAL ||
                    type === MachineTypes.EDM_WIRE ||
                    type === MachineTypes.EDM_HOLE
                  ) && (
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="4º Eixo"
                          value={course4Axis || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">graus</InputAdornment> }}
                          onChange={(event: any) => handleChange('course4Axis', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {(type === MachineTypes.BORING) && (
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="4º Eixo"
                          value={course4AxisMM || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('course4AxisMM', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {(type === MachineTypes.T5X || type === MachineTypes.BORING || type === MachineTypes.EDM_WIRE) && (
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="5º Eixo"
                          value={course5Axis || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">graus</InputAdornment> }}
                          onChange={(event: any) => handleChange('course5Axis', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {(type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL) && (
                    <Grid item sm={12}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="marchine-axistype">Tipo de Eixo</InputLabel>
                        <Select
                          labelId="marchine-axistype"
                          value={axisType || ''}
                          onChange={(event: any) => handleChange('axisType', event.target.value)}
                        >
                          <MenuItem value="">Nenhum</MenuItem>
                          <MenuItem value="positioned">Posicionado</MenuItem>
                          <MenuItem value="continuous">Contínuo</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {(type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL) && axisType === 'positioned' && (
                    <>
                      <Grid item sm={6}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="4º Eixo"
                            value={positioning4Axis || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('positioning4Axis', event.target.value)}
                            helperText="Resolução Posicionamento"
                          />
                        </FormControl>
                      </Grid>
                      {type === MachineTypes.T5X && (
                        <Grid item sm={6}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="5º Eixo"
                              value={positioning5Axis || ''}
                              variant="standard"
                              inputProps={{ maxLength: 255 }}
                              onChange={(event: any) => handleChange('positioning5Axis', event.target.value)}
                              helperText="Resolução Posicionamento"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {type !== MachineTypes.EDM_HOLE && type !== MachineTypes.SWISS_LATHE && type !== MachineTypes.FLAT_GRINDING && (
            <Box pt={2}>
              <Card>
                <CardContent>
                  <Box mb={2}>
                    <Typography className="subtitle" component="b">Velocidade Rápido</Typography>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="X"
                          value={rapidAxisVelocityX || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                          onChange={(event: any) => handleChange('rapidAxisVelocityX', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    {(
                      type === MachineTypes.T3X ||
                      type === MachineTypes.T5X ||
                      type === MachineTypes.HORIZONTAL ||
                      type === MachineTypes.BORING ||
                      type === MachineTypes.EDM ||
                      type === MachineTypes.EDM_WIRE
                    ) && (
                      <Grid item sm={4}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Y"
                            value={rapidAxisVelocityY || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('rapidAxisVelocityY', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Z"
                          value={rapidAxisVelocityZ || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                          onChange={(event: any) => handleChange('rapidAxisVelocityZ', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}
        </>
      )}
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.BORING ||
        type === MachineTypes.EDM ||
        type === MachineTypes.EDM_WIRE
      ) && (
        <>
          <Box pt={2}>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Typography className="subtitle" component="b">Velocidade Programável</Typography>
                </Box>

                <Grid container spacing={2}>
                  {type !== MachineTypes.EDM_WIRE && (
                    <>
                      <Grid item sm={4}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="X"
                            value={programmableVelocityAxisX || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                            onChange={(event: any) => handleChange('programmableVelocityAxisX', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={4}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Y"
                            value={programmableVelocityAxisY || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                            onChange={(event: any) => handleChange('programmableVelocityAxisY', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={4}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Z"
                            value={programmableVelocityAxisZ || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                            onChange={(event: any) => handleChange('programmableVelocityAxisZ', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {(type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL || type === MachineTypes.BORING || type === MachineTypes.EDM_WIRE) && (
                    <>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Velocidade Rápido"
                            value={rapidAxisVelocityC || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">graus/s</InputAdornment> }}
                            onChange={(event: any) => handleChange('rapidAxisVelocityC', event.target.value)}
                            helperText="Eixo C"
                          />
                        </FormControl>
                      </Grid>
                      {type !== MachineTypes.EDM_WIRE && (
                        <Grid item sm={3}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Velocidade Programável"
                              value={programmableVelocityAxisC || ''}
                              variant="standard"
                              type="number"
                              inputProps={{ step: 0.01 }}
                              InputProps={{ endAdornment: <InputAdornment position="end">graus/s</InputAdornment> }}
                              onChange={(event: any) => handleChange('programmableVelocityAxisC', event.target.value)}
                              helperText="Eixo C"
                            />
                          </FormControl>
                        </Grid>
                      )}
                      {(type === MachineTypes.T5X || type === MachineTypes.BORING || type === MachineTypes.EDM_WIRE) && (
                        <>
                          <Grid item sm={3}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Velocidade Rápido"
                                value={rapidAxisVelocityB || ''}
                                variant="standard"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                InputProps={{ endAdornment: <InputAdornment position="end">graus/s</InputAdornment> }}
                                onChange={(event: any) => handleChange('rapidAxisVelocityB', event.target.value)}
                                helperText="Eixo B"
                              />
                            </FormControl>
                          </Grid>
                          {type !== MachineTypes.EDM_WIRE && (
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <TextField
                                  label="Velocidade Programável"
                                  value={programmableVelocityAxisB || ''}
                                  variant="standard"
                                  type="number"
                                  inputProps={{ step: 0.01 }}
                                  InputProps={{ endAdornment: <InputAdornment position="end">graus/s</InputAdornment> }}
                                  onChange={(event: any) => handleChange('programmableVelocityAxisB', event.target.value)}
                                  helperText="Eixo B"
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {type === MachineTypes.T5X && (
                            <>
                              <Grid item sm={6}>
                                <FormControl margin="dense" fullWidth>
                                  <InputLabel id="marchine-transmissionmotor4axis">4º eixo</InputLabel>
                                  <Select
                                    labelId="marchine-transmissionmotor4axis"
                                    value={transmissionMotor4Axis || ''}
                                    onChange={(event: any) => handleChange('transmissionMotor4Axis', event.target.value)}
                                  >
                                    <MenuItem value="">Nenhum</MenuItem>
                                    {motorTransmissionTypes.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item sm={6}>
                                <FormControl margin="dense" fullWidth>
                                  <InputLabel id="marchine-transmissionmotor5axis">5º eixo</InputLabel>
                                  <Select
                                    labelId="marchine-transmissionmotor5axis"
                                    value={transmissionMotor5Axis || ''}
                                    onChange={(event: any) => handleChange('transmissionMotor5Axis', event.target.value)}
                                  >
                                    <MenuItem value="">Nenhum</MenuItem>
                                    {motorTransmissionTypes.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {type === MachineTypes.EDM_WIRE && (
                    <>
                      <Grid container />
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Velocidade Corte Máx."
                            value={cutMaxVelocity || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm/Min</InputAdornment> }}
                            onChange={(event: any) => handleChange('cutMaxVelocity', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Angulo Máx. Corte"
                            value={cutMaxAngle || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                            onChange={(event: any) => handleChange('cutMaxAngle', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Angulo Máx. Corte em mm"
                            value={cutMaxAngleByLength || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('cutMaxAngleByLength', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {type !== MachineTypes.FLAT_GRINDING && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Máquina</Typography>
              </Box>

              <Grid container spacing={2}>
                {type === MachineTypes.SWISS_LATHE && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Diâmetro de Giro"
                          value={turningDiameter || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('turningDiameter', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Comprimento de Giro"
                          value={turningLength || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('turningLength', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {type === MachineTypes.TORNOMULTITASKS && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="RPM"
                          value={detailsRPM || ''}
                          variant="standard"
                          type="number"
                          onChange={(event: any) => handleChange('detailsRPM', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Pontência"
                          value={detailsPotence || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          onChange={(event: any) => handleChange('detailsPotence', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Torque"
                          value={detailsTorque || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">Nm</InputAdornment> }}
                          onChange={(event: any) => handleChange('detailsTorque', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="marchine-conetype">Tipo de Cone</InputLabel>
                        <Select
                          labelId="marchine-conetype"
                          value={detailsConeType || ''}
                          onChange={(event: any) => handleChange('detailsConeType', event.target.value)}
                        >
                          <MenuItem value="">Nenhum</MenuItem>
                          {coneTypes.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {(type === MachineTypes.T3X ||
                type === MachineTypes.T5X ||
                type === MachineTypes.HORIZONTAL ||
                type === MachineTypes.TORNO ||
                type === MachineTypes.TORNOMULTITASKS) && (
                  <>
                    <Grid item sm={4}>
                      <FormControl component="fieldset" fullWidth margin="dense">
                        <FormLabel component="legend">Tipo de Cabeça</FormLabel>
                        <RadioGroup
                          value={headtype || ''}
                          onChange={(event: any) => handleChange('headtype', event.target.value)}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="bars" control={<Radio />} label="Barramentos" />
                          <FormControlLabel value="guides" control={<Radio />} label="Guias Lineares" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      {headtype === 'guides' && (
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Largura das Guias"
                            value={guidesWidth || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('guidesWidth', event.target.value)}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </>
                )}
                {type === MachineTypes.EDM_HOLE && (
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="RPM Eixo C"
                        value={RPMAxisC || ''}
                        variant="standard"
                        type="number"
                        onChange={(event: any) => handleChange('RPMAxisC', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {type === MachineTypes.SWISS_LATHE && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Velocidade de Alimentação"
                          value={maximumFeedrate || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">mm/Min</InputAdornment> }}
                          onChange={(event: any) => handleChange('maximumFeedrate', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Potência da Conexão"
                          value={connectionPower || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">Kw</InputAdornment> }}
                          onChange={(event: any) => handleChange('connectionPower', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid container></Grid>
                {(type === MachineTypes.T3X || type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL || type === MachineTypes.BORING) && (
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={autoPalletExchange || false} />}
                          label="Troca Automática de Palete"
                          onChange={(event: any) => handleChange('autoPalletExchange', event.target.checked)}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
                {autoPalletExchange && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Quantidade"
                          value={autoPalletExchangeQuantity || ''}
                          variant="standard"
                          type="number"
                          onChange={(event: any) => handleChange('autoPalletExchangeQuantity', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Dimensão"
                          value={autoPalletExchangeDimension || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          onChange={(event: any) => handleChange('autoPalletExchangeDimension', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {(type === MachineTypes.EDM || type === MachineTypes.EDM_WIRE || type === MachineTypes.PLASTIC_INJECTION) && (
                  <>
                    <Grid item sm={4}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={robot || false} />}
                            label="Tem robot"
                            onChange={(event: any) => handleChange('robot', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {robot && type !== MachineTypes.PLASTIC_INJECTION && (
                      <>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantos eletrodos"
                              value={robotNumberOfEletrods || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('robotNumberOfEletrods', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantas paletes"
                              value={robotNumberOfPallets || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('robotNumberOfPallets', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {robot && type === MachineTypes.PLASTIC_INJECTION && (
                      <>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantos eixos"
                              value={robotNumberOfAxis || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('robotNumberOfAxis', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Alcance"
                              value={robotReach || ''}
                              variant="standard"
                              type="number"
                              InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                              onChange={(event: any) => handleChange('robotReach', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid container />
                {(type === MachineTypes.EDM || type === MachineTypes.EDM_WIRE || type === MachineTypes.EDM_HOLE) && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Comprimento"
                          value={tankDimensionLength || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tankDimensionLength', event.target.value)}
                          helperText="Dimensão do tanque"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Altura"
                          value={tankDimensionHeight || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tankDimensionHeight', event.target.value)}
                          helperText="Dimensão do tanque"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Largura"
                          value={tankDimensionWidth || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tankDimensionWidth', event.target.value)}
                          helperText="Dimensão do tanque"
                        />
                      </FormControl>
                    </Grid>
                    {type === MachineTypes.EDM_WIRE && (
                      <>
                        <Grid item sm={12}>
                          <FormControl fullWidth margin="dense">
                            <FormGroup row>
                              <FormControlLabel
                                control={<Checkbox checked={tankSubmersible || false} />}
                                label="Tanque submersível"
                                onChange={(event: any) => handleChange('tankSubmersible', event.target.checked)}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        {tankSubmersible === true && type === MachineTypes.EDM_WIRE && (
                          <Grid item sm={12}>
                            <FormControl fullWidth margin="dense">
                              <FormGroup row>
                                <FormControlLabel
                                  control={<Checkbox checked={tankSubmersibleAuto || false} />}
                                  label="Tanque submersível automático"
                                  onChange={(event: any) => handleChange('tankSubmersibleAuto', event.target.checked)}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid item sm={12}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={portableSteeringWheel || false} />}
                            label="Tem volante portátil"
                            onChange={(event: any) => handleChange('portableSteeringWheel', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Altura máxima da peça"
                          value={partMaxHeight || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('partMaxHeight', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    {type === MachineTypes.EDM && (
                      <Grid item sm={4}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Quantidade de dieletrico"
                            value={dielectricQuantity || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">l</InputAdornment> }}
                            onChange={(event: any) => handleChange('dielectricQuantity', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}
                {type === MachineTypes.PLASTIC_INJECTION && (
                  <>
                    <Grid item sm={4}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={hasCorePullers || false} />}
                            label="Tem core pullers"
                            onChange={(event: any) => handleChange('hasCorePullers', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {hasCorePullers && (
                      <>
                        <Grid item sm={4}>
                          <FormControl component="fieldset" fullWidth margin="dense">
                            <FormLabel component="legend">Lado</FormLabel>
                            <FormGroup style={{ flexDirection: 'row' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={corePullersSideMobile || false}
                                    onChange={(event: any) => handleChange('corePullersSideMobile', event.target.checked)}
                                  />
                                }
                                label="Mobile"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={corePullersSideFixed || false}
                                    onChange={(event: any) => handleChange('corePullersSideFixed', event.target.checked)}
                                  />
                                }
                                label="Fixo"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantos"
                              value={corePullersQuantity || ''}
                              variant="standard"
                              onChange={(event: any) => handleChange('corePullersQuantity', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid container />
                    <Grid item sm={4}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={hasPneumaticValves || false} />}
                            label="Tem valvulas pneumáticas"
                            onChange={(event: any) => handleChange('hasPneumaticValves', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {hasPneumaticValves && (
                      <>
                        <Grid item sm={4}>
                          <FormControl component="fieldset" fullWidth margin="dense">
                            <FormLabel component="legend">Lado</FormLabel>
                            <FormGroup style={{ flexDirection: 'row' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={pneumaticValvesSideMobile || false}
                                    onChange={(event: any) => handleChange('pneumaticValvesSideMobile', event.target.checked)}
                                  />
                                }
                                label="Mobile"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={pneumaticValvesSideFixed || false}
                                    onChange={(event: any) => handleChange('pneumaticValvesSideFixed', event.target.checked)}
                                  />
                                }
                                label="Fixo"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantos"
                              value={pneumaticValvesQuantity || ''}
                              variant="standard"
                              onChange={(event: any) => handleChange('pneumaticValvesQuantity', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid container />
                    <Grid item sm={4}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={hasControlValveGate || false} />}
                            label="Tem control valve gate"
                            onChange={(event: any) => handleChange('hasControlValveGate', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {hasControlValveGate && (
                      <>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantos"
                              value={controlValveGateQuantity || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('controlValveGateQuantity', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid container />
                    <Grid item sm={4}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={hasMoldTemperatureControl || false} />}
                            label="Tem controlo de temperatura do molde"
                            onChange={(event: any) => handleChange('hasMoldTemperatureControl', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {hasMoldTemperatureControl && (
                      <>
                        <Grid item sm={4}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantas Zonas"
                              value={moldTemperatureControlZones || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('moldTemperatureControlZones', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Pressão Máxima do Grupo Hidráulico"
                          value={maxHydricPression || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">bar</InputAdornment> }}
                          onChange={(event: any) => handleChange('maxHydricPression', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Potência de Aquecimento"
                          value={heatingPower || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">Kw</InputAdornment> }}
                          onChange={(event: any) => handleChange('heatingPower', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Capacidade do tanque de óleo"
                          value={oilTankCapacity || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">l</InputAdornment> }}
                          onChange={(event: any) => handleChange('oilTankCapacity', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Motor Pump"
                          value={motorPump || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">kw</InputAdornment> }}
                          onChange={(event: any) => handleChange('motorPump', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Potência motor"
                          value={motorPower || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">Kw</InputAdornment> }}
                          onChange={(event: any) => handleChange('motorPower', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Quantidade zonas controlo temperatura"
                          value={tempControlZones || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 1 }}
                          onChange={(event: any) => handleChange('tempControlZones', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        (type === MachineTypes.HORIZONTAL && !autoPalletExchange) ||
        type === MachineTypes.BORING ||
        type === MachineTypes.EDM ||
        type === MachineTypes.EDM_WIRE ||
        type === MachineTypes.EDM_HOLE
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Dimensão da mesa</Typography>
              </Box>

              <Grid container spacing={2}>
                {type !== MachineTypes.HORIZONTAL && (
                  <Grid item sm={4}>
                    <FormControl component="fieldset" fullWidth margin="dense">
                      <RadioGroup
                        value={tableMeasurement || ''}
                        onChange={(event: any) => handleChange('tableMeasurement', event.target.value)}
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel value="dimension" control={
                          <Radio
                            disabled={!!tableDimensionDiameter || !!tableDimensionX || !!tableDimensionY}
                          />
                        } label="Dimensão" />
                        <FormControlLabel value="diameter" control={
                          <Radio
                            disabled={!!tableDimensionDiameter || !!tableDimensionX || !!tableDimensionY}
                          />
                        } label="Diâmetro" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}

                {(type === MachineTypes.HORIZONTAL || tableMeasurement === 'dimension') && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="X"
                          value={tableDimensionX || ''}
                          variant="standard"
                          disabled={!!tableDimensionDiameter}
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tableDimensionX', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Y"
                          value={tableDimensionY || ''}
                          variant="standard"
                          disabled={!!tableDimensionDiameter}
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tableDimensionY', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {(type !== MachineTypes.HORIZONTAL && tableMeasurement === 'diameter') && (
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Diâmetro"
                        value={tableDimensionDiameter || ''}
                        variant="standard"
                        disabled={!!tableDimensionX || !!tableDimensionY}
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('tableDimensionDiameter', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2}>
                {(type === MachineTypes.T3X || type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL) && (
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Distância Spindle à Mesa"
                        value={spindleTableDistance || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('spindleTableDistance', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {(type === MachineTypes.EDM_WIRE || type === MachineTypes.EDM_HOLE) && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Distância min. Spindle à Mesa"
                          value={spindleTableDistanceMin || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('spindleTableDistanceMin', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Distância máx. Spindle à Mesa"
                          value={spindleTableDistanceMax || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('spindleTableDistanceMax', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {(
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM ||
                  type === MachineTypes.EDM_WIRE ||
                  type === MachineTypes.EDM_HOLE
                ) && (
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Peso Máx Sobre a Mesa"
                        value={spindleTableWeight || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                        onChange={(event: any) => handleChange('spindleTableWeight', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {type === MachineTypes.BORING && (
                  <>
                    <Grid item sm={4}>
                      <FormControl fullWidth margin="dense">
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={rotativeTable || false} />}
                            label="Mesa rotativa 360º"
                            onChange={(event: any) => handleChange('rotativeTable', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {rotativeTable && (
                      <Grid item sm={4}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Ângulo mínimo de posicionamento"
                            value={rotativeTableAngleMinPositioning || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                            onChange={(event: any) => handleChange('rotativeTableAngleMinPositioning', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      
      {(type === MachineTypes.FLAT_GRINDING) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Dimensão da mesa magnética</Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="C"
                      value={magneticTableDimensionLength || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('magneticTableDimensionLength', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="L"
                      value={magneticTableDimensionWidth || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('magneticTableDimensionWidth', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Peso Máx Sobre a Mesa"
                      value={magneticTableWeight || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                      onChange={(event: any) => handleChange('magneticTableWeight', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default FormDetails;
