import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Form from './Form';

interface IProps {
  fetchCustomerProposals: () => void;
  history: any;
  location: any;
  loading: boolean;
}
interface IState {}

class Proposals extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchCustomerProposals } = this.props;

    fetchCustomerProposals();
  }

  public render() {
    const { history, location } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route path="/dashboard/customers/proposals/list" exact component={List} />
          <Route path="/dashboard/customers/proposals/new" exact render={(props) => <Form history={history} location={location} />} />
          <Route path="/dashboard/customers/proposals/proposalid/:proposalid/edit" exact render={(props) => <Form history={history} location={location} proposalid={props.match.params.proposalid} />} />
          <Redirect to="/authenticate" />
        </Switch>
      </Router>
    );
  }
};

export default Proposals;