import { CreateUser, EditUser, ToggleUser } from '../actions/users';
import User from '../models/User';
import axios from './axios';

export const fetchUsers = async (): Promise<User[]> => {
  const { data } = await axios.get('/users');

  return data;
};

export const createUser = async (properties: CreateUser) => {
  const { data } = await axios.post('/users', properties);

  return data;
}

export const editUser = async (properties: EditUser) => {
  const { data } = await axios.put('/users', properties);

  return data;
}

export const toggleUser = async (properties: ToggleUser) => {
  const { data } = await axios.put('/users/active', properties);

  return data;
}