import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React, { Component } from 'react';

import { Message } from '../../../reducers/application';

interface IProps {
  dismissError: (index: number) => void;
  messages: Message[];
  count: number;
}

class Errors extends Component<IProps> {
  public render() {
    const { dismissError, messages } = this.props;

    return (
      <>
        {
          messages.map((message: any, index) => {
            const messageText = (((message.message || {}).response || {}).data || {}).message;
            let finalMessage = messageText;
            switch (messageText) {
              case 'Email not found':
                finalMessage = 'Email não encontrado';
                break;
              case 'User not found':
                finalMessage = 'Utilizador não encontrado';
                break;
              case 'Password does not match':
                finalMessage = 'Password incorrecta';
                break;
              case 'User not active':
                finalMessage = 'User inactivo';
                break;
              case 'An user with that email already exists':
                finalMessage = 'Já existe um utilizador com esse email';
                break;
              case '"email" must be a valid email':
                finalMessage = 'O email indicado é inválido';
                break;
              case 'A machine with that reference already exists':
                finalMessage = 'Já existe uma máquina com essa referência';
                break;
              case 'Machine is in use':
                finalMessage = 'A máquina está a ser usada numa proposta';
                break;
              case 'Tax number already exists':
                finalMessage = 'Já existe um registo com esse número de contribuinte';
                break;
              case 'Already exists an open ticket for that machine':
                finalMessage = 'Já existe um SAT aberto para a máquina seleccionada';
                break;
            }

            return (
              <Snackbar
                open={true}
                onClose={() => dismissError(index)}
                key={index}
              >
                <Alert onClose={() => dismissError(index)} severity={message.type === 'error' ? 'error' : 'success'}>
                  {message.type === 'success' ? 'Operação concluída com sucessso' : finalMessage}
                </Alert>
              </Snackbar>
            );
          })
        }
      </>
    );
  }
}

export default Errors;
