import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleUserView, toggleUser } from '../../../../actions/index';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import List from './List';

const mapStateToProps = ({ authentication, users }: IAppState) => ({
  loading: users.loading,
  users: Object.values(users.users),
  view: users.view.userId,
  user: authentication.user || ({} as User),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleUserView: (userId?: number) =>
      dispatch(toggleUserView(userId)),
    toggleUser: (id: number, active: boolean) =>
      dispatch(toggleUser.request({ id, active })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);