import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/authentication';
import {
  fetchUser,
  authenticate,
  recoverPassword,
  resetPassword,
  logout,
} from '../api/authentication';
import User from '../models/User';

export function* fetchUserSaga() {
  try {
    const user: User = yield call(fetchUser);

    yield put(types.fetchUser.success({ user }));
  } catch (error: any) {
    yield put(types.fetchUser.failure({ error }));
  }
}

export function* authenticateSaga(action: ActionType<typeof types.authenticate.request>) {
  const { email, password } = action.payload;

  try {
    yield call(authenticate, email, password);

    yield put(types.authenticate.success());
  } catch (error: any) {
    yield put(types.authenticate.failure({ error }));
  }
}

export function* recoverPasswordSaga(action: ActionType<typeof types.recoverPassword.request>) {
  const { email } = action.payload;

  try {
    yield call(recoverPassword, email);

    yield put(types.recoverPassword.success());
  } catch (error: any) {
    yield put(types.recoverPassword.failure({ error }));
  }
}

export function* resetPasswordSaga(action: ActionType<typeof types.resetPassword.request>) {
  try {
    yield call(resetPassword, action.payload);

    yield put(types.resetPassword.success());
  } catch (error: any) {
    yield put(types.resetPassword.failure({ error }));
  }
}

export function* logoutSaga() {
  try {
    yield call(logout);

    yield put(types.logout.success());
  } catch (error: any) {
    yield put(types.logout.failure({ error }));
  }
}