import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeTableZoneForm,
  CreateTableZone,
  createTableZone,
  EditTableZone,
  editTableZone
} from '../../../../../actions/tables';
import { IAppState } from '../../../../../reducers/index';
import Form from './Form';

interface IProps {
  userid?: number;
}

const mapStateToProps = ({ tables }: IAppState, props: IProps) => ({
  loading: tables.zones.form.loading,
  zone: tables.zones.form.zone,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createTableZone: (properties: CreateTableZone) =>
      dispatch(createTableZone.request(properties)),
    editTableZone: (properties: EditTableZone) =>
      dispatch(editTableZone.request(properties)),
    closeTableZoneForm: () =>
      dispatch(closeTableZoneForm()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
