import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleUserView } from '../../../../actions';
import { IAppState } from '../../../../reducers/index';
import View from './ViewModal';

const mapStateToProps = ({ users }: IAppState) => ({
  user: users.users[users.view.userId || 0] || {},
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleUserView: (userId?: number) =>
      dispatch(toggleUserView(userId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);