import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchSuppliers } from '../../../../actions/suppliers';
import {
  CreateMachine,
  createMachine,
  EditMachine,
  editMachine,
} from '../../../../actions/index';
import { IAppState } from '../../../../reducers/index';
import Form from './Form';

interface IProps {
  machineid?: string;
  isNew?: boolean;
}

const mapStateToProps = ({ machines, suppliers, tables }: IAppState, props: IProps) => ({
  loading: machines.form.loading,
  machine: {
    ...machines.machines[Number(props.machineid || 0)],
    catalogOriginalname: props.isNew ? undefined : (machines.machines[Number(props.machineid || 0)] || {}).catalogOriginalname,
    catalogFilename: props.isNew ? undefined : (machines.machines[Number(props.machineid || 0)] || {}).catalogFilename,
    coverImage: props.isNew ? undefined : (machines.machines[Number(props.machineid || 0)] || {}).coverImage,
    files: props.isNew ? [] : (machines.machines[Number(props.machineid || 0)] || {}).files,
  },
  machines: Object.values(machines.machines),
  redirectReady: machines.form.redirectReady,
  suppliers: Object.values(suppliers.suppliers),
  machineStatus: (tables.dynamicFields.fetch.machineStatus || {}).values || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchSuppliers: () => dispatch(fetchSuppliers.request()),
    createMachine: (properties: CreateMachine) =>
      dispatch(createMachine.request(properties)),
    editMachine: (properties: EditMachine) =>
      dispatch(editMachine.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
