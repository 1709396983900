import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';

import { CreateTableZone, EditTableZone } from '../../../../../actions/index';
import Button from '../../../../common/Button';
import Zone from '../../../../../models/Zone';

interface IProps {
  closeTableZoneForm: () => void;
  createTableZone: (properties: CreateTableZone) => void;
  zone?: Zone;
  editTableZone: (properties: EditTableZone) => void;
  loading: boolean;
}
interface IState {
  id?: number;
  name: string;
  price?: number;
  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      id,
      name,
      price,
    } = props.zone || {};

    this.state = {
      id,
      name: name || '',
      price,
      submitted: false,
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public hasErrors() {
    const { name, price } = this.state;

    if (
      !name.length ||
      (!price && price !== 0)
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createTableZone, editTableZone } = this.props;
    const {
      id,
      name,
      price,
  } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editTableZone({ id, name, price: price || 0 });
    } else {
      createTableZone({ name, price: price || 0 });
    }
  }

  public handleChange(field: string, event: any) {
    const stateUpdate = {
      [field]: event.target.value,
    } as Pick<IState, 'name'>
  
    this.setState({ ...stateUpdate, dirty: true });
  }

  public render() {
    const { closeTableZoneForm, loading } = this.props;
    const {
      id,
      name,
      price,
      submitted,
      dirty,
    } = this.state;

    return (
      <Dialog open onClose={() => closeTableZoneForm()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faGlobe} />
          <span style={{ marginLeft: '8px' }}>{id ? 'Editar Zona' : 'Nova Zona'}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => closeTableZoneForm()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl margin="normal" fullWidth>
            <TextField
              error={submitted && !name.length}
              label="Nome"
              required
              value={name}
              variant="standard"
              onChange={(event: any) => this.handleChange('name', event)}
              helperText={submitted && !name.length ? 'Deve introduzir o nome da zona' : ''}
            />
          </FormControl>
          <FormControl margin="dense" fullWidth>
            <TextField
              error={submitted && !price && price !== 0}
              label="Preço KM"
              value={price || ''}
              variant="standard"
              type="number"
              required
              inputProps={{ step: 0.01, min: 0 }}
              InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
              onChange={(event: any) => this.handleChange('price', event)}
              helperText={submitted && !price ? 'Deve inserir o valor por KM' : ''}
            />
          </FormControl>
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            <Box mr={1}>
              <Button color="default" variant="contained" onClick={() => closeTableZoneForm()}>Cancelar</Button>
            </Box>
            <Button
              disabled={loading || !dirty}
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={this.handleSubmit}
            >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
};

export default Form;
