import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchCustomers } from '../../../../actions/customers';
import { changeMachineCustomer, ChangeMachineCustomer, toggleMachineCustomerForm } from '../../../../actions/machines';
import Machine from '../../../../models/Machine';
import { IAppState } from '../../../../reducers/index';
import CustomerForm from './CustomerForm';

const mapStateToProps = ({ customers, machines }: IAppState) => ({
  loading: machines.customerForm.loading || customers.loading,
  machine: machines.customerForm.machine || ({} as Machine),
  customers: Object.values(customers.customers),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchCustomers: () =>
      dispatch(fetchCustomers.request()),
    toggleMachineCustomerForm: () => dispatch(toggleMachineCustomerForm(undefined)),
    changeMachineCustomer: (properties: ChangeMachineCustomer) =>
      dispatch(changeMachineCustomer.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
