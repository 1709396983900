import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { CreateUser, createUser, EditUser, editUser } from '../../../../actions/users';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import Form from './Form';

interface IProps {
  userid?: string;
}

const mapStateToProps = ({ authentication, users }: IAppState, props: IProps) => ({
  loading: users.form.loading,
  redirectReady: users.form.redirectReady,
  user: users.users[Number(props.userid || 0)],
  auth: authentication.user || {} as User,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createUser: (properties: CreateUser) =>
      dispatch(createUser.request(properties)),
    editUser: (properties: EditUser) =>
      dispatch(editUser.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
