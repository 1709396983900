import {
  CreateCustomer,
  CreateCustomerProposal,
  EditCustomer,
  ToggleCustomer,
  CreateCustomerRep,
  EditCustomerRep,
  DeleteCustomerRep,
  EditCustomerProposal,
} from '../actions/customers';
import Customer from '../models/Customer';
import Proposal from '../models/Proposal';
import axios from './axios';

export const fetchCustomers = async (): Promise<Customer[]> => {
  const { data } = await axios.get('/customers');

  return data;
};

export const createCustomer = async (properties: CreateCustomer) => {
  const { data } = await axios.post('/customers', properties);

  return data;
}

export const editCustomer = async (properties: EditCustomer) => {
  const { data } = await axios.put('/customers', properties);

  return data;
}

export const toggleCustomer = async (properties: ToggleCustomer) => {
  const { data } = await axios.put('/customers/active', properties);

  return data;
}

export const createCustomerRep = async (properties: CreateCustomerRep) => {
  const { data } = await axios.post('/customers/rep', properties);

  return data;
}

export const editCustomerRep = async (properties: EditCustomerRep) => {
  const { data } = await axios.put('/customers/rep', properties);

  return data;
}

export const deleteCustomerRep = async (properties: DeleteCustomerRep) => {
  return await axios.delete(`/customers/rep/id/${properties.id}`);
}

export const fetchCustomerProposals = async (): Promise<Proposal[]> => {
  const { data } = await axios.get('/customers/proposals');

  return data;
};

export const createCustomerProposal = async (properties: CreateCustomerProposal) => {
  const { data } = await axios.post('/customers/proposal', properties);

  return data;
}

export const editCustomerProposal = async (properties: EditCustomerProposal) => {
  const { data } = await axios.put('/customers/proposal', properties);

  return data;
}