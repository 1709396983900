import Axios from 'axios';

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: { 'Cache-Control': 'no-cache' },
  timeout: 120000,
  withCredentials: true,
});

export default axios;
