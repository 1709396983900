import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchMachines, fetchDynamicFieldValuesByForm } from '../../../actions/index';
import { IAppState } from '../../../reducers/index';
import Machines from './Machines';

const mapStateToProps = ({ machines, tables }: IAppState) => ({
  loading: machines.loading || (tables.dynamicFields.fetch.supplierTypes || {}).loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMachines: () =>
      dispatch(fetchMachines.request()),
    fetchMachineStatus: () =>
      dispatch(fetchDynamicFieldValuesByForm.request({ id: 'machineStatus' })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
