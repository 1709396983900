import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleProposalView } from '../../../../../actions/customers';
import { fetchMachines } from '../../../../../actions/machines';
import { fetchUsers } from '../../../../../actions/users';
import { IAppState } from '../../../../../reducers/index';
import View from './ViewModal';

const mapStateToProps = ({ customers, machines, users }: IAppState) => ({
  customers: customers.customers,
  loading: users.loading || machines.loading,
  proposal: customers.proposals.proposals[customers.proposals.view.proposalId || 0] || {},
  machines: machines.machines,
  users: users.users,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleProposalView: (proposalId?: number) =>
      dispatch(toggleProposalView(proposalId)),
    fetchMachines: () => dispatch(fetchMachines.request()),
    fetchUsers: () => dispatch(fetchUsers.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);