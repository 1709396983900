import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchZonesTable, openTableZoneForm, openDeleteTableZone} from '../../../../actions/index';
import { IAppState } from '../../../../reducers/index';
import Zone from '../../../../models/Zone';
import Zones from './Zones';

const mapStateToProps = ({ tables }: IAppState) => ({
  loading: tables.zones.loading,
  zones: Object.values(tables.zones.zones),
  showForm: tables.zones.form.show,
  deleteForm: tables.zones.deleteForm.zone,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchZonesTable: () =>
      dispatch(fetchZonesTable.request()),
    openTableZoneForm: (zone?: Zone) =>
      dispatch(openTableZoneForm(zone)),
    openDeleteTableZone: (zone: Zone) =>
      dispatch(openDeleteTableZone(zone)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Zones);