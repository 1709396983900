import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createMachineTicketTaskExpense,
  CreateMachineTicketTaskExpense,
} from '../../../../../../actions/machines';
import Task from '../../../../../../models/Task';
import User from '../../../../../../models/User';
import { IAppState } from '../../../../../../reducers/index';
import ExpenseModal from './ExpenseModal';

interface IProps {
  task: Task;
}
const mapStateToProps = ({ authentication, machines }: IAppState, props: IProps) => ({
  redirectReady: (machines.tickets.tasksPopupForms[props.task.id] || {}).redirectReady,
  loading: (machines.tickets.tasksPopupForms[props.task.id] || {}).loading,
  auth: authentication.user || {} as User,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createMachineTicketTaskExpense: (properties: CreateMachineTicketTaskExpense) =>
      dispatch(createMachineTicketTaskExpense.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseModal);