import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { logout } from '../../../actions/index';
import { IAppState } from '../../../reducers/index';
import AppBar from './AppBar';

const mapStateToProps = ({ authentication }: IAppState) => ({
  user: authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () =>
      dispatch(logout.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
