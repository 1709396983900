import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';

import { DeleteMachineTicket } from '../../../../../actions/index';
import Button from '../../../../common/Button';

interface IProps {
  id: number;
  deleteMachineTicket: (properties: DeleteMachineTicket) => void;
  handleClose: () => void;
  loading: boolean;
}

class TaskModal extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  public handleDelete() {
    const { deleteMachineTicket, id } = this.props;

    deleteMachineTicket({ id });
  }

  public render() {
    const {
      handleClose,
      loading,
      id,
    } = this.props;

    return (
      <Dialog maxWidth="md" fullWidth open>
        <DialogTitle>
          <span>Apagar SAT #{id}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem a certeza que pretende apagar este SAT?</Typography>
        </DialogContent>
        <DialogActions>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item>
                <Button color="default" variant="contained" onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  startIcon={
                    <FontAwesomeIcon icon={faTrash} />
                  }
                  variant="contained"
                  onClick={this.handleDelete}
                >{loading ? <CircularProgress size={24} /> : 'Apagar'}</Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

export default TaskModal;
