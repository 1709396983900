import { CreateTableZone, EditTableZone, DeleteTableZone, EditDynamicField } from '../actions';
import { DynamicValue } from '../models/DynamicField';
import Zone, { ZoneMeta } from '../models/Zone';
import axios from './axios';

export const fetchZonesTable = async (): Promise<Zone[]> => {
  const { data } = await axios.get('/tables/zones');

  return data;
};

export const createTableZone = async (properties: CreateTableZone) => {
  const { data } = await axios.post('/tables/zones', properties);

  return data;
}

export const editTableZone = async (properties: EditTableZone) => {
  const { data } = await axios.put('/tables/zones', properties);

  return data;
}

export const deleteTableZone = async (properties: DeleteTableZone) => {
  await axios.delete(`/tables/zones/id/${properties.id}`);
}

export const fetchZoneList = async (): Promise<ZoneMeta[]> => {
  const { data } = await axios.get('/tables/zones/list');

  return data;
};

export const fetchDynamicFieldValues = async (id: string): Promise<DynamicValue[]> => {
  const { data } = await axios.get(`/tables/dynamicfields/id/${id}/values`);

  return data;
};

export const editDynamicField = async (properties: EditDynamicField) => {
  const { data } = await axios.put('/tables/dynamicfields', properties);
  
  return data;
}

export const fetchDynamicFieldValuesByForm = async (id: string): Promise<DynamicValue[]> => {
  const { data } = await axios.get(`/tables/dynamicfields/id/${id}/values`);

  return data;
};

