import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { erosionHeadTypes, MachineTypes, sawTypes } from '../../Dashboard/Machines/sources';
import Machine from '../../../models/Machine';

interface IProps {
  machine: Machine;
}

const HerosionHead: FunctionComponent <IProps> = (properties) => {
  const {
    type,
    erosionHeadAxisC360,
    erosionHeadType,
    erosionHeadSubType,
    erosionThreadMin,
    erosionThreadMax,
    erosionThreadingAuto,
    erosionRodMin,
    erosionRodMax,
    erosionRodAuto,
    sawType,
    sawVelocityVariation,
    sawVelocityVariationIMin,
    sawVelocityVariationIMax,
    sawAutoSupply,
    sawCutDimensionsMinDiameter,
    sawCutDimensionsMinRectangle,
    sawCutDimensionsMaxDiameter,
    sawCutDimensionsMaxRectangle,
    sawCutMinLength,
    sawCutMaxLength,
    sawAngleCut,
    sawAngleCutMin,
    sawAngleCutMax,
    sawTapeDimensionsC,
    sawTapeDimensionsL,
    sawTapeDimensionsA,
    sawDimensions,
    sawWeight,
  } = properties.machine;

  return (
    <>
      {type === MachineTypes.EDM && (
        erosionHeadAxisC360 !== null ||
        erosionHeadType ||
        erosionHeadSubType
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Cabeça de erosão</Typography>
              </Box>

              <Grid container spacing={2}>
                {erosionHeadAxisC360 !== null? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tem eixo C 360º</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionHeadAxisC360 ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {erosionHeadType && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de cabeça</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(erosionHeadTypes.find((eh) => eh.id === erosionHeadType) || {}).name}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {erosionHeadType !== 'manual' && erosionHeadSubType ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de sub cabeça</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{
                        erosionHeadSubType === 'manual'
                        ? 'Manual'
                        : erosionHeadSubType === 'auto'
                        ? 'Automática'
                        : ''
                      }</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type === MachineTypes.EDM_WIRE && (
        erosionThreadMin ||
        erosionThreadMax ||
        erosionThreadingAuto !== null
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Fio de erosão</Typography>
              </Box>

              <Grid container spacing={2}>
                {erosionThreadMin && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Diâmetro Min.</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionThreadMin}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {erosionThreadMax && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Diâmetro Máx.</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionThreadMax}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {erosionThreadingAuto !== null ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Enfiamento automático</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionThreadingAuto ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type === MachineTypes.EDM_HOLE && (
        erosionRodMin ||
        erosionRodMax ||
        erosionRodAuto !== null
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Vareta de erosão</Typography>
              </Box>

              <Grid container spacing={2}>
                {erosionRodMin && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Diâmetro Min.</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionRodMin}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {erosionRodMax && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Diâmetro Máx.</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionRodMax}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {erosionRodAuto !== null ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Troca de vareatas automática</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{erosionRodAuto ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type === MachineTypes.BANDSAW && (
        sawType ||
        sawVelocityVariation ||
        sawVelocityVariationIMin ||
        sawVelocityVariationIMax ||
        sawAutoSupply ||
        sawCutDimensionsMinDiameter ||
        sawCutDimensionsMinRectangle ||
        sawCutDimensionsMaxDiameter ||
        sawCutDimensionsMaxRectangle ||
        sawCutMinLength ||
        sawCutMaxLength ||
        sawAngleCut ||
        sawAngleCutMin ||
        sawAngleCutMax ||
        sawTapeDimensionsC ||
        sawTapeDimensionsL ||
        sawTapeDimensionsA ||
        sawDimensions ||
        sawWeight
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Serrote</Typography>
              </Box>
              <Grid container spacing={2}>
                {sawType && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de serrote</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(sawTypes.find((s) => s.id === sawType) || {}).name}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawAutoSupply !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Alimentação automática</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawAutoSupply ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawVelocityVariation !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Variação de velcidade de corte</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawVelocityVariation ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    {sawVelocityVariationIMin !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Interval (Min)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{sawVelocityVariationIMin} m/min</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {sawVelocityVariationIMax !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Interval (Max)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{sawVelocityVariationIMax} m/min</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {sawCutDimensionsMinDiameter && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões Mín de Corte (Diâmetro)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawCutDimensionsMinDiameter} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawCutDimensionsMinRectangle && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões Mín de Corte (Rectangulo)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawCutDimensionsMinRectangle} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawCutDimensionsMaxDiameter && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões Máx de Corte (Diâmetro)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawCutDimensionsMaxDiameter} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawCutDimensionsMaxRectangle && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões Máx de Corte (Rectangulo)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawCutDimensionsMaxRectangle} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawCutMinLength && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Comprimento Mín de Corte</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawCutMinLength} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawCutMaxLength && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Comprimento Máx de Corte</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawCutMaxLength} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawAngleCut !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Corta em ângulo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawAngleCut ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    {sawAngleCutMin && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Angulo Mín.</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{sawAngleCutMin} deg</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {sawAngleCutMax && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Angulo Máx.</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{sawAngleCutMax} deg</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {sawTapeDimensionsC && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões da Fita (C)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawTapeDimensionsC} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawTapeDimensionsL && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões da Fita (L)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawTapeDimensionsL} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawTapeDimensionsA && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões da Fita (A)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawTapeDimensionsA} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawDimensions && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensões do Serrote</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawDimensions} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {sawWeight && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Peso do Serrote</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{sawWeight} Kg</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default HerosionHead;
