import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../common/Button';
import TableButton from '../../../common/TableButton';
import { Column } from '../../../common/types';
import { TableFilter } from '../../../../models/TableFilter';
import Zone from '../../../../models/Zone';
import { tableTranslations } from '../../../common/utils';
import DeleteConfirmation from './DeleteConfirmation';
import Form from './Form';

interface IProps {
  zones: Zone[];
  loading: boolean;
  showForm: boolean;
  deleteForm?: Zone;
  openTableZoneForm: (zone?: Zone) => void;
  openDeleteTableZone: (zone: Zone) => void;
  fetchZonesTable: () => void;
}
interface IState {
  columns: Column[];
  filter: TableFilter;
}

class Zones extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const filter = this.getTableState();
    const columns = this.apendFilterListToColumns([
      { name: 'id', label: 'ID', options: { display: (localStorage.getItem('zones_list_columns_id') || 'true') as Display } },
      { name: 'name', label: 'Nome', options: { display: (localStorage.getItem('zones_list_columns_name') || 'true') as Display } },
      { name: 'price', label: 'Preço KM', options: { display: (localStorage.getItem('zones_list_columns_price') || 'true') as Display } },
      {
        name: 'actions',
        label: 'Acções',
        options: {
          display: (localStorage.getItem('zones_list_columns_actions') || 'true') as Display,
          filter: false,
          customHeadRender: (columnMeta) => (
            <TableCell key="-1">
              <div style={{ width: '90px'}}>{columnMeta.label}</div>
            </TableCell>
          ),
        }
      },
    ], filter.filterList);

    this.state = {
      columns,
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public componentDidMount() {
    const { fetchZonesTable } = this.props;

    fetchZonesTable();
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`zones_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }

  public customRowRender(rowData: any[], dataIndex: number, rowIndex: number) {
    const { columns } = this.state;
    const { openTableZoneForm, openDeleteTableZone, zones } = this.props;

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && <TableCell>{rowData[1]}</TableCell>}
        {columns[2].options.display === 'true' && <TableCell>{rowData[2].toFixed(2)}€</TableCell>}
        {columns[3].options.display === 'true' &&  <TableCell>
          <TableButton onClick={() => openTableZoneForm(zones[dataIndex] || {})}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </TableButton>
          <TableButton onClick={() => openDeleteTableZone(zones[dataIndex] || {})}>
            <FontAwesomeIcon icon={faTrash} />
          </TableButton>
        </TableCell>}
      </TableRow>
    );
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('zones_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('zones_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][]): any[] {
    filterList.forEach((filter, index) => {
      (columns[index] || {}).options = {
        ...((columns[index] || {}).options || {}),
        filterList: filter,
      };
    });

    return columns;
  }

  public render() {
    const { columns, filter } = this.state;
    const { deleteForm, openTableZoneForm, showForm, zones } = this.props;

    return (
      <>
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">
                <Link color="inherit" to="/dashboard/definitions">
                  Definições
                </Link>
              </Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">Zonas</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item sm={6} style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => openTableZoneForm()}
              >Adicionar</Button>
            </Grid>
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <MUIDataTable
                title={''}
                data={zones}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {showForm ? <Form /> : null}
        {deleteForm ? <DeleteConfirmation /> : null}
     </>
    );
  }
};

export default Zones;
