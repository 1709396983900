import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchCustomers } from '../../../actions/customers';
import { IAppState } from '../../../reducers/index';
import Customers from './Customers';

const mapStateToProps = ({ customers }: IAppState) => ({
  loading: customers.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchCustomers: () =>
      dispatch(fetchCustomers.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
