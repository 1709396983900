import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleCustomerView, toggleCustomerRepsView, toggleCustomer, fetchZoneList } from '../../../../actions';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import List from './List';

const mapStateToProps = ({ authentication, customers, tables }: IAppState) => ({
  loading: tables.zones.listLoading || customers.loading,
  customers: Object.values(customers.customers),
  view: customers.view.customerId,
  repsView: customers.repsView.customerId,
  user: authentication.user || ({} as User),
  zones: tables.zones.list || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleView: (customerId?: number) =>
      dispatch(toggleCustomerView(customerId)),
    toggleRepsView: (customerId?: number) =>
      dispatch(toggleCustomerRepsView(customerId)),
    toggleCustomer: (customerId: number, active: boolean) =>
      dispatch(toggleCustomer.request({ customerId, active })),
    fetchZoneList: () =>
      dispatch(fetchZoneList.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
