import { createAction, createAsyncAction } from 'typesafe-actions';

import User from '../models/User';
import { IFailed } from './types';

enum Types {
  FETCH = '@@users/FETCH',
  FETCH_FAILED = '@@users/FETCH_FAILED',
  FETCH_SUCCEEDED = '@@users/FETCH_SUCCEEDED',

  CREATE = '@@users/CREATE',
  CREATE_FAILED = '@@users/CREATE_FAILED',
  CREATE_SUCCEEDED = '@@users/CREATE_SUCCEEDED',

  EDIT = '@@users/EDIT',
  EDIT_FAILED = '@@users/EDIT_FAILED',
  EDIT_SUCCEEDED = '@@users/EDIT_SUCCEEDED',

  TOGGLE_VIEW = '@@users/TOGGLE_VIEW',

  TOGGLE = '@@users/TOGGLE',
  TOGGLE_FAILED = '@@users/TOGGLE_FAILED',
  TOGGLE_SUCCEEDED = '@@users/TOGGLE_SUCCEEDED',
}

interface FetchSuccess {
  users: User[];
}

export const fetchUsers = createAsyncAction(
  Types.FETCH,
  Types.FETCH_SUCCEEDED,
  Types.FETCH_FAILED
)<void, FetchSuccess, IFailed>();

export interface CreateUser {
  name: string;
  email: string;
  phone?: string;
  role: string;
  price?: number;
  password: string;
}

interface UserSuccess {
  user: User;
}

export const createUser = createAsyncAction(
  Types.CREATE,
  Types.CREATE_SUCCEEDED,
  Types.CREATE_FAILED
)<CreateUser, UserSuccess, IFailed>();

export interface EditUser {
  id: number;
  name: string;
  email: string;
  phone?: string;
  role: string;
  price?: number;
  password?: string;
}

export const editUser = createAsyncAction(
  Types.EDIT,
  Types.EDIT_SUCCEEDED,
  Types.EDIT_FAILED
)<EditUser, UserSuccess, IFailed>();

export const toggleUserView = createAction(
  Types.TOGGLE_VIEW,
)<number | undefined>();

export interface ToggleUser {
  id: number;
  active: boolean;
}
export const toggleUser = createAsyncAction(
  Types.TOGGLE,
  Types.TOGGLE_SUCCEEDED,
  Types.TOGGLE_FAILED
)<ToggleUser, ToggleUser, IFailed>();