import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Machine from '../../../models/Machine';

interface IProps {
  machine: Machine;
}

const Optionals: FunctionComponent <IProps> = (properties) => {
  const {
    observations,
    youtubeLink,
    catalogFilename,
    options
  } = properties.machine;

  return observations ||
    youtubeLink ||
    catalogFilename ? (
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Observações Gerais</Typography>
            </Box>
          
            <Grid container spacing={2}>
              {observations ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Observações C</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{observations}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {youtubeLink ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Link Vídeo Youtube</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{youtubeLink}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {catalogFilename ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Catálogo</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      {catalogFilename ? (
                        <Link
                          href={`${process.env.REACT_APP_API_URL}/api/files/machines/${catalogFilename}`}
                          target="_blank"
                        >Ver catalogo</Link>
                      ) : null}
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}

              {(options || []).length > 0 ? (
                <Grid item sm={12}>
                  <Box mt={2}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Opções da máquina</TableCell>
                            <TableCell>Preço</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {options.map((o, i) => (
                            <TableRow key={i}>
                              <TableCell>{o.description}</TableCell>
                              <TableCell>{(o.sellValue || 0).toFixed(2)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    ) : null;
}

export default Optionals;
