import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTag, faSave } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';

import { ChangeMachineCustomer } from '../../../../actions';
import Customer from '../../../../models/Customer';
import Machine from '../../../../models/Machine';

interface IProps {
  customers: Customer[];
  machine: Machine;
  loading: boolean;
  toggleMachineCustomerForm: () => void;
  fetchCustomers: () => void;
  changeMachineCustomer: (properties: ChangeMachineCustomer) => void;
}

interface IState {
  customerId?: number;
  submitted: boolean;
}

class CustomerForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { machine } = this.props;
    const { customerId } = machine;

    this.state = {
      customerId,

      submitted: false,
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchCustomers } = this.props;

    fetchCustomers();
  }

  public handleSelect(field: string, value: any) {
    this.setState({
      [field]: value
    } as Pick<IState, 'customerId'>);
  }

  public hasErrors() {
    const { customerId } = this.state;

    if (!customerId) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const {
      changeMachineCustomer,
      machine
    } = this.props;
    const { customerId } = this.state;
    const { id } = machine;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    changeMachineCustomer({ id, customerId });
  }

  public render() {
    const { customers, loading, machine, toggleMachineCustomerForm } = this.props;
    const { customerId, submitted } = this.state;
    const { serialnumber } = machine;

    return (
      <Dialog open onClose={() => toggleMachineCustomerForm()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faUserTag} />
          <span style={{ marginLeft: '8px' }}>Associar cliente à máquina - #{serialnumber || ''}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleMachineCustomerForm()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Autocomplete
              autoComplete
              clearOnEscape
              openOnFocus
              options={customers.filter((c) => c.active)}
              value={customers.find((c) => c.id === customerId) || null}
              getOptionLabel={(option: Customer) => option.name}
              onChange={(event, value: Customer | null) => this.handleSelect('customerId', value ? value.id : undefined)}
              renderInput={(params: any) =>
                <TextField
                  {...params}
                  error={submitted && !customers.length}
                  label="Seleccione o cliente"
                />
              }
            />
          </FormControl>
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            <Box mt={2}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button color="default" variant="contained" onClick={toggleMachineCustomerForm}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    startIcon={
                      <FontAwesomeIcon icon={faSave} />
                    }
                    variant="contained"
                    onClick={this.handleSubmit}
                  >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
                </Grid>
              </Grid>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default CustomerForm;