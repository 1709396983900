import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

interface IProps {
  generatorHasRefrigerator?: boolean;
  generatorAMP?: number;

  handleChange: (name: string, value: any) => void;
}

const FormGenerator: FunctionComponent <IProps> = (properties) => {
  const {
    generatorHasRefrigerator,
    generatorAMP,

    handleChange,
  } = properties;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Gerador</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <FormControl margin="dense" fullWidth>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={generatorHasRefrigerator || false} />}
                      label="Tem refrigerador"
                      onChange={(event: any) => handleChange('generatorHasRefrigerator', event.target.checked)}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Quantos Amperes"
                    value={generatorAMP || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">amp</InputAdornment> }}
                    onChange={(event: any) => handleChange('generatorAMP', event.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default FormGenerator;