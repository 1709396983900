import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';

import Supplier from '../../../../models/Supplier';
import { MachineTypes, commandTypes } from '../sources';

interface IProps {
  type: string;
  
  description?: string;
  supplierId?: number;
  brand?: string;
  model?: string;
  serialnumber?: string;
  commandType?: string;
  commandBrand?: string;
  commandModel?: string;

  suppliers: Supplier[];
  handleChange: (name: string, value: any) => void;

  submitted: boolean;
}

class FormInfo extends PureComponent<IProps> {
  public render() {
    const {
      type,

      description,
      supplierId,
      brand,
      model,
      serialnumber,
      commandType,
      commandBrand,
      commandModel,

      suppliers,
      handleChange,

      submitted
    } = this.props;

    return (
      <>
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Gerais</Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Serial Number"
                      value={serialnumber || ''}
                      variant="standard"
                      onChange={(event: any) => handleChange('serialnumber', event.target.value)}
                      inputProps={{ maxLength: 45 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon className="form-icon" icon={faBarcode} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth required error={submitted && !supplierId}>
                    <InputLabel id="machine-supplier">Fornecedor</InputLabel>
                    <Select
                      labelId="machine-supplier"
                      value={supplierId || ''}
                      onChange={(event: any) => handleChange('supplierId', event.target.value)}
                    >
                      <MenuItem value="" disabled>Nenhum</MenuItem>
                      {suppliers.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                          {!s.active ? <Typography variant="caption" style={{ color: '#ccc', marginLeft: '7px' }}>(Fornecedor inactivo)</Typography> : null}
                        </MenuItem>
                      ))}
                    </Select>
                    {submitted && !supplierId ? <FormHelperText>Deve seleccionar o fornecedor</FormHelperText> : null}
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Marca"
                      value={brand || ''}
                      variant="standard"
                      inputProps={{ maxLength: 255 }}
                      onChange={(event: any) => handleChange('brand', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Modelo"
                      value={model || ''}
                      variant="standard"
                      inputProps={{ maxLength: 255 }}
                      onChange={(event: any) => handleChange('model', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Descrição"
                      value={description || ''}
                      variant="standard"
                      multiline
                      minRows={3}
                      inputProps={{ maxLength: 3000 }}
                      onChange={(event: any) => handleChange('description', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Comando CNC</Typography>
              </Box>
              <Grid container spacing={2}>
                {type === MachineTypes.FLAT_GRINDING && (
                  <Grid item sm={12}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="machine-commandType">Tipo de Comando</InputLabel>
                      <Select
                        labelId="machine-commandType"
                        value={commandType || ''}
                        onChange={(event: any) => handleChange('commandType', event.target.value)}
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        {commandTypes.map((ct) => (
                          <MenuItem key={ct.id} value={ct.id}>
                            {ct.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {(type !== MachineTypes.FLAT_GRINDING || commandType === 'cnc') && (
                  <>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Marca"
                          value={commandBrand || ''}
                          variant="standard"
                          inputProps={{ maxLength: 255 }}
                          onChange={(event: any) => handleChange('commandBrand', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Modelo"
                          value={commandModel || ''}
                          variant="standard"
                          inputProps={{ maxLength: 255 }}
                          onChange={(event: any) => handleChange('commandModel', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  }
};

export default FormInfo;
