import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { deleteTableZone, closeDeleteTableZone } from '../../../../../actions/index';
import { IAppState } from '../../../../../reducers/index';
import DeleteConfirmation from './DeleteConfirmation';

const mapStateToProps = ({ tables }: IAppState) => ({
  zone: tables.zones.deleteForm.zone!,
  loading: tables.zones.deleteForm.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    closeDeleteTableZone: () => dispatch(closeDeleteTableZone()),
    deleteTableZone: (id: number) =>
      dispatch(deleteTableZone.request({ id })),
  };
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(DeleteConfirmation);
