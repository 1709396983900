import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchDynamicFieldValuesByForm, toggleMachineView } from '../../../../../actions/index';
import {
  CreateCustomerProposal,
  createCustomerProposal,
  EditCustomerProposal,
  editCustomerProposal,
  fetchCustomers,
} from '../../../../../actions/customers';
import { fetchMachines } from '../../../../../actions/machines';
import { fetchUsers } from '../../../../../actions/users';
import { IAppState } from '../../../../../reducers/index';
import Form from './Form';

interface IProps {
  proposalid?: string;
}

const mapStateToProps = ({ customers, machines, tables, users }: IAppState, props: IProps) => ({
  customers: Object.values(customers.customers),
  machines: Object.values(machines.machines),
  loading: customers.loading || machines.loading || customers.proposals.loading || (tables.dynamicFields.fetch.supplierTypes || {}).loading,
  redirectReady: customers.proposals.form.redirectReady,
  users: Object.values(users.users),
  proposal: customers.proposals.proposals[Number(props.proposalid || 0)],
  machineStatus: (tables.dynamicFields.fetch.machineStatus || {}).values || [],
  viewMachine: machines.view.machineId,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createCustomerProposal: (properties: CreateCustomerProposal) =>
      dispatch(createCustomerProposal.request(properties)),
    editCustomerProposal: (properties: EditCustomerProposal) =>
      dispatch(editCustomerProposal.request(properties)),
    fetchCustomers: () => dispatch(fetchCustomers.request()),
    fetchMachines: () => dispatch(fetchMachines.request()),
    fetchMachineStatus: () =>
      dispatch(fetchDynamicFieldValuesByForm.request({ id: 'machineStatus' })),
    fetchUsers: () => dispatch(fetchUsers.request()),
    toggleMachineView: (machineId?: number) =>
      dispatch(toggleMachineView(machineId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
