import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { getType } from 'mime';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactImageMagnify from 'react-image-magnify';
import { Helmet } from 'react-helmet';

import { DynamicValue } from '../../../models/DynamicField';
import APIFile from '../../../models/File';
import Machine from '../../../models/Machine';
import Button from '../../common/Button';
import { steps } from '../../Dashboard/Machines/Form/Form';
import Info from './Info';
import Details from './Details';
import Turreta from './Turreta';
import Fuses from './Fuses';
import BarSupplier from './BarSupplier';
import ErosionHead from './ErosionHead';
import Generator from './Generator';
import InjectionUnit from './InjectionUnit';
import Logistics from './Logistics';
import Optionals from './Optionals';
import { MachineTypes, machineTypes } from '../../Dashboard/Machines/sources';

interface IProps {
  fetchMachineStatus: () => void;
  openForm: (machineId: number) => void;
  loading: boolean;
  history: any;
  machines: Machine[];
  machineStatus: DynamicValue[];
  id: string;
}

interface IState {
  index: number;
  height?: number;
  width?: number;
  smallHeight?: number;
  smallWidth?: number;
}

class MachineComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { index: 0 };

    this.prevImage = this.prevImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.resetRatio = this.resetRatio.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { machines } = this.props;

    if (JSON.stringify(prevProps.machines) !== JSON.stringify(machines)) {
      this.setState({ index: 0 });
    }
  }

  public componentDidMount() {
    const { fetchMachineStatus } = this.props;

    fetchMachineStatus();
    
    window.addEventListener('resize', this.resetRatio);
  }
  
  public componentWillUnmount() {
    window.removeEventListener('resize', this.resetRatio);
  }

  public selectImage(index: number) {
    this.setState({ index, width: undefined, height: undefined });
  }

  public getFiles(files?: APIFile[]): APIFile[] {
    return (files || []).filter((file) => (getType(file.originalname) || 'text').indexOf('image') !== -1);
  }

  public onImgLoad(event: any) {
    const e = (document.getElementsByClassName('image-viewer-container')[0] || {}).getBoundingClientRect();

    let ratio = event.target.offsetWidth / e.width;
    const hRatio = event.target.offsetHeight / e.height;

    if (hRatio > ratio) {
      ratio = hRatio;
    }

    this.setState({
      height: event.target.offsetHeight,
      width: event.target.offsetWidth,
      smallHeight: event.target.offsetHeight / ratio,
      smallWidth: event.target.offsetWidth / ratio,
    });
  }

  public resetRatio() {
    const { width, height } = this.state;

    if (width && height) {
      const e = (document.getElementsByClassName('image-viewer-container')[0] || {}).getBoundingClientRect();

      let ratio = width / e.width;
      const hRatio = height / e.height;

      if (hRatio > ratio) {
        ratio = hRatio;
      }

      this.setState({
        smallHeight: height / ratio,
        smallWidth: width / ratio,
      });
    }
  }

  public prevImage() {
    const { index } = this.state;

    this.setState({ index: index - 1, width: undefined, height: undefined });
  }

  public nextImage() {
    const { index } = this.state;

    this.setState({ index: index + 1, width: undefined, height: undefined });
  }

  public render() {
    const { openForm, loading, machines, machineStatus, id } = this.props;
    const { index, height, width, smallHeight, smallWidth } = this.state;

    const machine = machines.find(
      (m) => m.id === Number(id)
    ) || {} as Machine;
    const images = this.getFiles(machine.files);
    const selectedImage = images[index] || {};

    return loading ? (
      <CircularProgress />
    ) : (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${machine.isMachineNew ? 'Novo' : 'Usado'} ${
            ((machineTypes.find((mt) => mt.id === machine.type) || {}).label || '')}${
            machine.brand ? ` ${machine.brand}` : ''}${machine.model ? ` ${machine.model}` : ''}`}</title>
          <meta name="description" content={`${machine.isMachineNew ? 'Novo' : 'Usado'} ${
            ((machineTypes.find((mt) => mt.id === machine.type) || {}).label || '')}${
            machine.brand ? ` ${machine.brand}` : ''}${machine.model ? ` ${machine.model}` : ''}`} />
        </Helmet>

        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/marketplace">
            <Typography color="secondary"><b>Início</b></Typography>
          </Link>
          <Typography color="textPrimary">{machine.brand} - {machine.model}</Typography>
          <Typography color="textPrimary">Detalhes</Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item sm={8}>
                  <Box mr={2}>
                    <div className="image-viewer noselect">
                      {machine.isMachineNew ? (
                        <Chip color="secondary" className="tag" label="NOVO" />
                      ) : (
                        <Chip color="default" className="tag" label="USADO" />
                      )}
                      {machine.marketplaceEmphasis ? (
                        <div className="emphasis" style={{ top: '350px' }}>CNC em destaque</div>
                      ) : null}

                      <div className="image-viewer-container">
                        {selectedImage.filename ?
                          !width ? (
                            <img
                              alt="Machine"
                              style={{ opacity: 0, position: 'absolute' }}
                              src={`${process.env.REACT_APP_API_URL}/api/files/machines/${selectedImage.filename}`}
                              onLoad={this.onImgLoad}
                            />
                          ) : (
                            <ReactImageMagnify
                              enlargedImagePosition="beside"
                              smallImage={{
                                src: `${process.env.REACT_APP_API_URL}/api/files/machines/${selectedImage.filename}`,
                                width: smallWidth,
                                height: smallHeight,
                                alt: "Machine",
                              }}
                              largeImage={{
                                src: `${process.env.REACT_APP_API_URL}/api/files/machines/${selectedImage.filename}`,
                                height,
                                width,
                              }}
                              isHintEnabled={true}
                              hintTextMouse="Passe o rato para fazer zoom"
                              hintTextTouch="Passe para fazer zoom"
                              enlargedImageContainerStyle={{ zIndex: 999 }}
                            />
                          )
                        : null}
                      </div>
                      <div className="image-viewer-navigator">
                        <IconButton style={{ marginRight: '40px' }} disabled={!index} onClick={this.prevImage}>
                          <ChevronLeft color={!index ? 'disabled' : 'secondary'} fontSize="large" />
                        </IconButton>
                        <IconButton style={{ marginLeft: '40px' }} disabled={!images.length || index === images.length - 1} onClick={this.nextImage}>
                          <ChevronRight color={!images.length || index === images.length - 1 ? 'disabled' : 'secondary'} fontSize="large" />
                        </IconButton>
                      </div>
                    </div>
                    <Box mt={6}>
                      <div className="image-slider noselect">
                        {images.map((image: APIFile, index: number) => (
                          <img
                            key={image.filename}
                            src={`${process.env.REACT_APP_API_URL}/api/files/machines/${image.filename}`}
                            alt="Machine"
                            onClick={() => this.selectImage(index)}
                          />
                        ))}
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={4}>
                  <Grid container spacing={1}>
                    <Grid item sm={6}>
                      <Typography variant="body2" component="span">{machine.brand}</Typography>
                    </Grid>
                    <Grid item sm={6} style={{ textAlign: 'right' }}>
                      <Typography variant="body2" component="b">{machine.model}</Typography>
                    </Grid>
                    <Grid item sm={6} style={{ paddingTop: '10px' }}>
                      <Typography variant="body2" component="span">Ano: {machine.machineYear}</Typography>
                    </Grid>
                    <Grid item sm={6} style={{ textAlign: 'right', paddingTop: '10px' }}>
                      <Typography variant="body2" component="span">Horas de uso: {machine.machineUsageHours || 0}h</Typography>
                    </Grid>
                    <Grid item sm={12} style={{ textAlign: 'right', paddingTop: '10px' }}>
                      <Typography variant="body1" component="span"><b>Preço:</b> <b className="price">{
                        (
                          (
                            machine.marketplacePriceType === 'installPrice'
                            ? machine.installBuyPrice
                            : machine.marketplacePriceType === 'truckPrice'
                            ? machine.truckBuyPrice : 0
                          ) || 0
                        ).toFixed(2)
                      }€</b></Typography>
                    </Grid>
                    <Grid item sm={6} style={{ paddingTop: '10px' }}>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                        href={`${process.env.REACT_APP_API_URL}/api/machines/machine/${machine.id}.pdf`}
                        target="_blank"
                      >
                        <div style={{ width: 'calc(100% - 40px)', float: 'right', textAlign: 'center' }}>F. Técnica</div>
                      </Button>
                    </Grid>
                    <Grid item sm={6} style={{ paddingTop: '10px' }}>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        fullWidth
                        startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                        onClick={() => openForm(machine.id)}
                      >
                        <div style={{ width: 'calc(100% - 40px)', float: 'right', textAlign: 'center' }}>Pedir cotação</div>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {!!steps.find((s) => s.id === 'info' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Info machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'injectionunit' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <InjectionUnit machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'details' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Details machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'turreta' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Turreta machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'fuses' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Fuses machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'barsupplier' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <BarSupplier machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'erosionhead' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <ErosionHead machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'generator' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Generator machine={machine} /> : null}
          {!!steps.find((s) => s.id === 'logistics' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Logistics machine={machine} machineStatus={machineStatus} /> : null}
          {!!steps.find((s) => s.id === 'optionals' && s.present.indexOf(machine.type as MachineTypes) !== -1) ? <Optionals machine={machine} /> : null}
        </Box>
      </>
    );
  }
}

export default MachineComponent;