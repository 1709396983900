import React, { FunctionComponent } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Zones from './Zones';
import DynamicFields from './DynamicFields';

interface IProps {
  history: any;
}

const Tables: FunctionComponent <IProps> = (properties) => {
  const { history } = properties;

  return (
    <div className="dashboard-container">
      <Router history={history}>
        <Switch>
          <Route path="/dashboard/definitions" exact component={List} />
          <Route path="/dashboard/definitions/zones" exact component={Zones} />
          <Route path="/dashboard/definitions/dynamicfields" exact component={DynamicFields} />
          <Redirect to="/dashboard/definitions" />
        </Switch>
      </Router>
    </div>
  );
}

export default Tables