import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../../reducers';

import { dismissError } from '../../../actions';
import Errors from './Errors';

const mapStateToProps = (state: IAppState) => ({
  messages: state.application.messages,
  count: state.application.count,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dismissError: (index: number) =>
      dispatch(dismissError({ index })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
