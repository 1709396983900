import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { parse } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import ContactForm from './ContactForm';
import List from './List';
import Machine from './Machine';

import './Marketplace.css';

interface IProps {
  fetchMachinesForMarketplace: () => void;
  showForm: boolean;
  history: any;
}

interface IState {
}

class Marketplace extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchMachinesForMarketplace } = this.props;

    fetchMachinesForMarketplace();
  }

  public render() {
    const { history, showForm } = this.props;

    return (
      <>
        <AppBar position="static">
          <Link to="/marketplace">
            <img className="logo" alt="GoTec" src="/logo.png" />
          </Link>
        </AppBar>
        <main className="marketplace-container">
          <Container maxWidth="lg">
            <Box mt={4} pb={2}>
              <Router history={history}>
                <Switch>
                  <Route path="/marketplace/:page?" exact render={
                    (props) =>
                      <List
                        history={history}
                        page={props.match.params.page}
                        query={parse(props.location.search, { ignoreQueryPrefix: true })}
                      />
                  } />
                  <Route path="/marketplace/machine/:id/:name" exact render={(props) => <Machine history={history} id={props.match.params.id} />} />
                  <Redirect to="/marketplace" />
                </Switch>
              </Router>
            </Box>
          </Container>
        </main>
        {showForm ? (<ContactForm />) : null}
      </>
    );
  }
}

export default Marketplace;
