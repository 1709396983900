import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchMachinesTickets } from '../../../../actions/machines';
import { IAppState } from '../../../../reducers/index';
import Tickets from './Tickets';

const mapStateToProps = ({ machines }: IAppState) => ({
  loading: machines.tickets.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMachinesTickets: () =>
      dispatch(fetchMachinesTickets.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
