import React, { FunctionComponent } from 'react';
import { useDrop } from 'react-dnd'

import Task, { TaskStatus } from '../../../../../models/Task';
import User from '../../../../../models/User';
import TaskView from './TaskView';

interface IProps {
  tasks: Task[];
  onTaskDrop: (status: TaskStatus, index: number, newStatus: TaskStatus) => void;
  openModal: (task: Task) => void;
  status: TaskStatus;
  users: User[];
  viewOnly: boolean;
}

const TaskContainer: FunctionComponent <IProps> = (properties) => {
  const { tasks, onTaskDrop, openModal, status, users, viewOnly } = properties;

  const [, drop] = useDrop({
    accept: 'task',
    drop: (item) => onTaskDrop((item as any).task.status, (item as any).index, status),
  });

  return (
    <div className="task-container-droparea" ref={drop}>
      {tasks.filter((t) => t.status === status).map((t, index) =>
        <TaskView
          key={index}
          index={index}
          openModal={openModal}
          users={users}
          task={t}
          viewOnly={viewOnly}
        />
      )}
    </div>
  );
}

export default TaskContainer;
