import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

interface IProps {
  barSupplierBrand?: string;
  barSupplierModel?: string;
  barSupplierMaxDiameter?: number;
  barSupplierMaxHeight?: number;
  barSupplierDimentionC?: number;
  barSupplierDimentionL?: number;
  barSupplierDimentionA?: number;
  barSupplierWeight?: number;

  handleChange: (name: string, value: any) => void;
}

const FormContraPoint: FunctionComponent <IProps> = (properties) => {
  const {
    barSupplierBrand,
    barSupplierModel,
    barSupplierMaxDiameter,
    barSupplierMaxHeight,
    barSupplierDimentionC,
    barSupplierDimentionL,
    barSupplierDimentionA,
    barSupplierWeight,

    handleChange,
  } = properties;

  return (
    <Box pt={2}>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography className="subtitle" component="b">Alimentador de barra</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Marca"
                  value={barSupplierBrand || ''}
                  variant="standard"
                  inputProps={{ maxLength: 255 }}
                  onChange={(event: any) => handleChange('barSupplierBrand', event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Modelo"
                  value={barSupplierModel || ''}
                  variant="standard"
                  inputProps={{ maxLength: 255 }}
                  onChange={(event: any) => handleChange('barSupplierModel', event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Diâmetro Máximo"
                  value={barSupplierMaxDiameter || ''}
                  variant="standard"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={(event: any) => handleChange('barSupplierMaxDiameter', event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Comprimento"
                  value={barSupplierMaxHeight || ''}
                  variant="standard"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={(event: any) => handleChange('barSupplierMaxHeight', event.target.value)}
                  helperText="Barra Alimentação"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="C"
                  value={barSupplierDimentionC || ''}
                  variant="standard"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={(event: any) => handleChange('barSupplierDimentionC', event.target.value)}
                  helperText="Dimensão"
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="L"
                  value={barSupplierDimentionL || ''}
                  variant="standard"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={(event: any) => handleChange('barSupplierDimentionL', event.target.value)}
                  helperText="Dimensão"
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="A"
                  value={barSupplierDimentionA || ''}
                  variant="standard"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={(event: any) => handleChange('barSupplierDimentionA', event.target.value)}
                  helperText="Dimensão"
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Peso"
                  value={barSupplierWeight || ''}
                  variant="standard"
                  type="number"
                  inputProps={{ step: 0.01 }}
                  InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                  onChange={(event: any) => handleChange('barSupplierWeight', event.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default FormContraPoint;
