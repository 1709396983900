import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import CardActions from '@material-ui/core/CardActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { CreateMachineTicketTaskSign } from '../../../../../../actions';
import Button from '../../../../../common/Button';
import Machine from '../../../../../../models/Machine';
import Ticket from '../../../../../../models/Ticket';
import User from '../../../../../../models/User';

interface IProps {
  closeSign: () => void;
  createMachineTicketTaskSign: (properties: CreateMachineTicketTaskSign) => void;
  loading: boolean;
  machine: Machine;
  ticketId: number;
  ticket: Ticket;
  taskId?: number;
  user: User;
}
interface IState {
  changed: boolean;
  tasks: Record<number, boolean>;
}

class TaskModal extends Component<IProps, IState> {
  private sigCanvas: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      changed: false,
      tasks: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.handleAllToggle = this.handleAllToggle.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  public handleSubmit() {
    const { ticketId, taskId, createMachineTicketTaskSign } = this.props;
    const { tasks } = this.state;

    const signature = this.sigCanvas.toDataURL();

    createMachineTicketTaskSign({
      ticketId,
      tasks: taskId ? [taskId] : Object.keys(tasks).filter((k: any) => !!tasks[k]).map((k) => Number(k)),
      signature,
    });
  }

  public triggerChange() {
    this.setState({ changed: true });
  }

  public handleAllToggle(value: boolean) {
    const { ticket } = this.props;

    const tasks: Record<number, boolean> = {};
    Object.keys(ticket.tasks).filter((t: any) => ticket.tasks[t].status === 'done').forEach((t: any) => {
      tasks[t] = value;
    });

    this.setState({
      tasks,
      changed: true,
    });
  }

  public handleToggle(id: number) {
    this.setState((curState) => ({
      tasks: {
        ...curState.tasks,
        [id]: !curState.tasks[id],
      },
      changed: true,
    }));
  }

  public render() {
    const {
      closeSign,
      loading,
      machine,
      taskId,
      ticket,
      user,
    } = this.props;
    const { changed, tasks } = this.state;

    return (
      <>
        <Dialog maxWidth="md" fullWidth open>
          <DialogTitle>
            <span>{!taskId ? 'Assinar várias tarefas' : 'Assinatura'}</span>
            <IconButton edge="end" size="small" className="dialog-close-button" onClick={closeSign}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {!taskId ? (
              <>
                <div style={{ padding: '0px 10px 0px 29px', display: 'flex', justifyContent: 'space-between', color: 'grey' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        edge="start"
                        size="small"
                        checked={!Object.values(ticket.tasks).find((t) => !tasks[t.id])}
                        disableRipple
                        onClick={() => this.handleAllToggle(!!Object.values(ticket.tasks).find((t) => !tasks[t.id]))}
                      />
                    }
                    label={<Typography variant="caption">Seleccionar tudo</Typography>}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="caption" color="secondary" style={{ paddingRight: '5px' }}>{Object.values(ticket.tasks).filter((t) => !!tasks[t.id]).length}</Typography>
                    <Typography variant="caption">tarefas seleccionadas</Typography>
                  </div>
                </div>
                <List style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {Object.values(ticket.tasks).filter((t) => t.status === 'done').map((task) => (
                    <ListItem key={task.id} role={undefined} dense button onClick={() => this.handleToggle(task.id)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={tasks[task.id] || false}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={task.title} />
                    </ListItem>
                  ))}
                </List>
              </>
            ) : null}

            <Typography variant="body1" style={{ textAlign: 'center' }}>Confirmo que o técnico {user.name} esteve no dia {format(new Date(), 'yyyy-MM-dd')} a efetuar a intervenção na máquina {machine.id}</Typography>
            <SignatureCanvas
              ref={(ref) => { this.sigCanvas = ref }}
              penColor='black'
              canvasProps={{ width: 900, height: !taskId ? 250 : 150 }}
              onEnd={this.triggerChange}
            />
          </DialogContent>
          <Box p={2} pr={1}>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Box mr={1}>
                <Button color="default" variant="contained" onClick={closeSign}>Cancelar</Button>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                disabled={!changed || (!taskId && !Object.values(tasks).filter((a) => !!a).length)}
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
              </CardActions>
            </Box>
        </Dialog>
      </>
    );
  }
}

export default TaskModal;
