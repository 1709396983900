import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';

import { TaskStatus } from '../../../../../../models/Task';
import User from '../../../../../../models/User';
import { CreateMachineTicketTask, EditMachineTicketTask } from '../../../../../../actions';
import Button from '../../../../../common/Button';
import { checkIfImage } from '../../../../../common/utils';
import File from '../../../../../../models/File';

interface IProps {
  ticketId: number;
  users: User[];
  status: TaskStatus;
  createMachineTicketTask: (properties: CreateMachineTicketTask) => void;
  editMachineTicketTask: (properties: EditMachineTicketTask) => void;
  onCancel: () => void;
  loading: boolean;
  redirectReady: boolean;
}
interface IState {
  id?: number;
  status: TaskStatus;
  title: string;
  technicians: number[];
  fileLoading: boolean;
  files: File[];
  submitted: boolean;
}

class TaskForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { status } = this.props;

    this.state = {
      status,
      title: '',
      technicians: [],
      files: [],
      fileLoading: false,

      submitted: false,
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { onCancel, redirectReady } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      onCancel();
    }
  }

  public handleChange(field: string, event: any) {
    this.setState({
      [field]: event.target.value
    } as Pick<IState, 'title'>);
  }

  public handleSelect(field: string, value: any) {
    this.setState({
      [field]: value
    } as Pick<IState, 'technicians'>);
  }

  public handleFiles(files: any[]) {
    this.setState({ files });
  }

  public hasErrors() {
    const { title, technicians } = this.state;

    if (!title.length || !technicians.length) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const {
      createMachineTicketTask,
      editMachineTicketTask,
      ticketId,
    } = this.props;
    const { id, status, title, technicians, files } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editMachineTicketTask({
        ticketid: ticketId,
        id,
        status,
        title,
        technicians,
        files,
      })
    } else {
      createMachineTicketTask({
        ticketid: ticketId,
        status,
        title,
        technicians,
        files,
      });
    }
  }

  public render() {
    const { onCancel, users, loading } = this.props;
    const { title, technicians, files, fileLoading, submitted } = this.state;

    return (
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid sm={12} item>
            <FormControl fullWidth>
              <TextField
                error={submitted && !title.length}
                placeholder="Insira um título"
                required
                value={title}
                variant="standard"
                onChange={(event: any) => this.handleChange('title', event)}
                helperText={submitted && !title.length ? 'Deve introduzir o título' : ''}
                inputProps={{ maxLength: 255 }}
              />
            </FormControl>
          </Grid>
          <Grid sm={12} item>
            <FormControl fullWidth>
              <Autocomplete
                autoComplete
                clearOnEscape
                openOnFocus
                options={users.filter((u) => u.role === 'admin' || u.role === 'admintech' || u.role === 'tech' || u.role === 'externaltech')}
                value={users.filter((u) => technicians.indexOf(u.id) !== -1)}
                multiple
                getOptionLabel={(option: User) => option.name}
                onChange={(event, value: User[]) => this.handleSelect('technicians', value ? value.map((v) => v.id) : undefined)}
                renderInput={(params: any) =>
                  <TextField
                    {...params}
                    error={submitted && !technicians.length}
                    label="Seleccione técnico(s)"
                    helperText={submitted && !technicians.length ? 'Deve seleccionar pelo menos um técnico' : ''}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid sm={12} item>
            {!fileLoading && (
              <div className="dropzone">
                <DropzoneArea
                  dropzoneText="Arraste e large fotografias aqui"
                  acceptedFiles={['image/*']}
                  initialFiles={files as any}
                  filesLimit={999}
                  getFileLimitExceedMessage={(filesLimit: number) =>
                    `Número máximo de ficheiros ultrapassado. Apena ${filesLimit} é permitido`}
                  getFileAddedMessage={(fileName: string) => `Ficheiro ${fileName} adicionado como sucesso`}
                  getFileRemovedMessage={(fileName: string) => `Ficheiro ${fileName} removido.`}
                  getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                    let message = 'Ficheiro '.concat(rejectedFile.name, ' foi rejeitado. ');
                
                    if (!acceptedFiles.includes(rejectedFile.type)) {
                      message += 'Tipo de ficheiro não suportado. ';
                    }
                
                    if (rejectedFile.size > maxFileSize) {
                      let size = '';

                      if (maxFileSize >= 1048576) {
                        size = maxFileSize / 1048576 + ' megabytes';
                      } else if (maxFileSize >= 1024) {
                        size = maxFileSize / 1024 + ' kilobytes';
                      } else {
                        size = maxFileSize + ' bytes';
                      }
                    
                      message += `O ficheiro é muito grande. Tamanho limite de ${size}.`;
                    }
                
                    return message;
                  }}
                  onChange={this.handleFiles}
                />
              </div>
            )}
          </Grid>
          <Grid item sm={12}>
            {files.length ? (
              <div className="thumbnail-slider">
                {files.map((file: File, index: number) => (
                  <div key={index} className="image">
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="drop"
                      onClick={() => {
                        const doc = document.getElementsByClassName('MuiDropzonePreviewList-imageContainer')[index];
                        if (doc && doc.childNodes[1]) {
                          // @ts-ignore
                          doc.childNodes[1].click();
                        }
                      }}
                    />
                    {checkIfImage(file.name) ? (
                      <img
                        alt=""
                        role="presentation"
                        key={index}
                        src={URL.createObjectURL(file as any)}
                      />
                    ) : (<div>{file.name}</div>)}
                  </div>
                ))}
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item>
              <Button color="default" variant="contained" onClick={onCancel}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                startIcon={
                  <FontAwesomeIcon icon={faSave} />
                }
                variant="contained"
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
};

export default TaskForm;