import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchSuppliers } from '../../../actions/suppliers';
import { IAppState } from '../../../reducers/index';
import Suppliers from './Suppliers';

const mapStateToProps = ({ suppliers }: IAppState) => ({
  loading: suppliers.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchSuppliers: () =>
      dispatch(fetchSuppliers.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
