import { ActionType, getType } from 'typesafe-actions';

import * as tables from '../actions/tables';
import { DynamicValue } from '../models/DynamicField';
import Zone, { ZoneMeta } from '../models/Zone';

type TableAction = ActionType<typeof tables>;

interface IFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly show: boolean;
  readonly zone?: Zone;
}
interface IDeleteFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly zone?: Zone;
}
interface IZonesState {
  error?: object;
  loading: boolean;
  zones: Record<Zone['id'],Zone>;
  list?: ZoneMeta[];
  listLoading: boolean;
  listError?: object;
  form: IFormState;
  deleteForm: IDeleteFormState;
}

interface IFetchState {
  readonly error?: object;
  readonly loading: boolean;
  readonly values?: DynamicValue[];
}
interface IDynamicFieldsState {
  error?: object;
  loading: boolean;
  values?: DynamicValue[];
  meta?: { id: string; name: string };
  fetch: Record<string, IFetchState>;
}
export interface ITablesState {
  readonly zones: IZonesState;
  readonly dynamicFields: IDynamicFieldsState;
}

export const initialState: ITablesState = {
  zones: { 
    loading: false,
    listLoading: false,
    zones: {},
    form: { loading: false, show: false },
    deleteForm: { loading: false },
  },
  dynamicFields: {
    loading: false,
    fetch: {},
  },
};

const reducer = (
  state: ITablesState = initialState,
  action: TableAction
): ITablesState => {
  switch (action.type) {
    case getType(tables.fetchZonesTable.request):
      return {
        ...state,
        zones: {
          ...state.zones,
          loading: true,
        },
      };

    case getType(tables.fetchZonesTable.success): {
      const { zones } = action.payload;

      const zonesObj: Record<Zone['id'], Zone> = {};
      zones.forEach((zone) => {
        zonesObj[zone.id] = zone;
      });

      return {
        ...state,
        zones: {
          ...state.zones,
          zones: zonesObj,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(tables.fetchZonesTable.failure): {
      const { error } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          error,
          loading: false,
        },
      };
    }
    
    case getType(tables.createTableZone.request):
      return {
        ...state,
        zones: {
          ...state.zones,
          form: {
            ...state.zones.form,
            loading: true,
          },
        },
      };

    case getType(tables.createTableZone.success): {
      const { zone } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          zones: {
            ...state.zones.zones,
            [zone.id]: zone,
          },
          form: {
            ...state.zones.form,
            error: undefined,
            loading: false,
            show: false,
          },
        },
      };
    }

    case getType(tables.createTableZone.failure): {
      const { error } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          form: {
            ...state.zones.form,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(tables.editTableZone.request):
      return {
        ...state,
        zones: {
          ...state.zones,
          form: {
            ...state.zones.form,
            loading: true,
          },
        },
      };

    case getType(tables.editTableZone.success): {
      const { zone } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          zones: {
            ...state.zones.zones,
            [zone.id]: {
              ...(state.zones.zones[zone.id] || {}),
              ...zone,
            },
          },
          form: {
            ...state.zones.form,
            error: undefined,
            loading: false,
            show: false,
          },
        },
      };
    }

    case getType(tables.editTableZone.failure): {
      const { error } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          form: {
            ...state.zones.form,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(tables.deleteTableZone.request):
      return {
        ...state,
        zones: {
          ...state.zones,
          deleteForm: {
            ...state.zones.deleteForm,
            loading: true,
          },
        },
      };

    case getType(tables.deleteTableZone.success): {
      const { id } = action.payload;
      const { zones } = state.zones;

      const tempZones = JSON.parse(JSON.stringify(zones));
      delete tempZones[id];

      return {
        ...state,
        zones: {
          ...state.zones,
          zones: tempZones,
          deleteForm: {
            ...state.zones.deleteForm,
            error: undefined,
            loading: false,
            zone: undefined,
          },
        },
      };
    }

    case getType(tables.deleteTableZone.failure): {
      const { error } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          deleteForm: {
            ...state.zones.deleteForm,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(tables.fetchZoneList.request):
      return {
        ...state,
        zones: {
          ...state.zones,
          loading: true,
        },
      };

    case getType(tables.fetchZoneList.success): {
      const { zones } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          list: zones,
          listLoading: false,
          listError: undefined,
        },
      };
    }

    case getType(tables.fetchZoneList.failure): {
      const { error } = action.payload;

      return {
        ...state,
        zones: {
          ...state.zones,
          listError: error,
          listLoading: false,
        },
      };
    }

    case getType(tables.openTableZoneForm): {
      return {
        ...state,
        zones: {
          ...state.zones,
          form: {
            show: true,
            loading: false,
            zone: action.payload,
          },
        },
      };
    }

    case getType(tables.closeTableZoneForm):
      return {
        ...state,
        zones: {
          ...state.zones,
          form: {
            ...state.zones.form,
            show: false,
          },
        },
      };

    case getType(tables.openDeleteTableZone): {
      return {
        ...state,
        zones: {
          ...state.zones,
          deleteForm: {
            loading: false,
            zone: action.payload,
          },
        },
      };
    }

    case getType(tables.closeDeleteTableZone):
      return {
        ...state,
        zones: {
          ...state.zones,
          deleteForm: {
            ...state.zones.deleteForm,
            zone: undefined,
          },
        },
      };
  
    case getType(tables.fetchDynamicFieldValuesByForm.request): {
      const { id } = action.payload;

      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          fetch: {
            ...state.dynamicFields.fetch,
            [id]: {
              ...state.dynamicFields.fetch[id],
              error: undefined,
              loading: true,
            },
          },
        },
      };
    }

    case getType(tables.fetchDynamicFieldValuesByForm.success): {
      const { id, values } = action.payload;

      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          fetch: {
            ...state.dynamicFields.fetch,
            [id]: {
              ...state.dynamicFields.fetch[id],
              values,
              loading: false,
            },
          },
        },
      };
    }

    case getType(tables.fetchDynamicFieldValuesByForm.failure): {
      const { id, error } = action.payload;

      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          fetch: {
            ...state.dynamicFields.fetch,
            [id]: {
              ...state.dynamicFields.fetch[id],
              error,
              loading: false,
            },
          },
        },
      };
    }
  
    case getType(tables.fetchDynamicFieldValues.request):
      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          error: undefined,
          loading: true,
        },
      };

    case getType(tables.fetchDynamicFieldValues.success): {
      const { values } = action.payload;

      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          values,
          loading: false,
        },
      };
    }

    case getType(tables.fetchDynamicFieldValues.failure): {
      const { error } = action.payload;

      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          error,
          loading: false,
        },
      };
    }

    case getType(tables.editDynamicField.request):
      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          error: undefined,
          loading: true,
        },
      };

    case getType(tables.editDynamicField.success):
      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          loading: false,
          meta: undefined,
        },
      };

    case getType(tables.editDynamicField.failure): {
      const { error } = action.payload;

      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          error,
          loading: false,
        },
      };
    }

    case getType(tables.openDynamicFieldForm):
      return {
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          meta: action.payload,
        },
      };

    case getType(tables.closeDynamicFieldForm): {
      return {
        ...state,
        ...state,
        dynamicFields: {
          ...state.dynamicFields,
          meta: undefined,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;