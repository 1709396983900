import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilePdf, faEnvelope, faSearch } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';
import { stringify } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Machine from '../../../models/Machine';
import { machineTypes } from '../../Dashboard/Machines/sources';
import { getCoverImage } from '../../common/utils';
import Button from '../../common/Button';

interface IProps {
  loading: boolean;
  history: any;
  query: any;
  openForm: (machineId: number) => void;
  page?: string;
  machines: Machine[];
}

interface IState {
  filter: boolean;
  search: string;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filter: true,
      search: '',
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
  }

  public componentDidMount() {
    const { query } = this.props;

    this.setState({ search: query.search || '' });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { query } = this.props;

    if (query.search !== prevProps.query.search) {
      this.setState({ search: query.search || '' });
    }
  }

  public getPage() {
    const { page } = this.props;

    return Number(page || 1);
  }

  public handlePageChange(event: any, page: number) {
    const { history, query } = this.props;

    history.push(`/marketplace/${page}?${stringify(query)}`);
  }

  public handleFilterChange(name: any, value: any) {
    const { history, query } = this.props;
    const { search } = this.state;

    query.search = search;
    query[name] = value;

    history.push(`/marketplace/1?${stringify(query)}`);
  }

  public handleChange(event: any) {
    this.setState({ search: event.target.value });
  }

  public handleKeyPress(event: any) {
    const { history, query } = this.props;
    const { search } = this.state;

    query.search = search;

    if (event.keyCode === 9 || event.keyCode === 13) {
      history.push(`/marketplace/1?${stringify(query)}`);
    }
  }

  public toggleFilters() {
    this.setState((prevState) => ({ filter: !prevState.filter }));
  }

  public render() {
    const { loading, machines, openForm, query } = this.props;
    const { filter, search } = this.state;

    const filteredMachines = machines
      .filter((m) =>
        (!query.type || query.type.indexOf(m.type) !== -1) &&
        (!query.brand || query.brand.indexOf(m.brand) !== -1) &&
        (!query.model || query.model.indexOf(m.model) !== -1) &&
        (!query.condition || query.condition.indexOf(String(m.isMachineNew)) !== -1) &&
        (!query.search || query.search.toLowerCase() === (m.description || '').toLowerCase())
      )
      .sort((a, b) => a.marketplaceEmphasis ? -1 : 1);

    const page = this.getPage();
    const pages = Math.ceil(filteredMachines.length / 8);

    const brands: string[] = [];
    machines.forEach((m) => {
      if (m.brand && brands.indexOf(m.brand) === -1) {
        brands.push(m.brand);
      }
    });

    const models: { brand?: string, model: string }[] = [];
    machines.forEach((m) => {
      if (m.model && models.findIndex((f) => f.brand === m.brand && f.model === m.model) === -1) {
        models.push({ brand: m.brand, model: m.model });
      }
    });

    const uniqueModels: Record<string, string> = {}
    models.filter((m) => (query.brand || []).indexOf(m.brand) !== -1 || !(query.brand || []).length).forEach((m) => {
      uniqueModels[m.model] = m.model;
    });

    return loading ? (
      <CircularProgress />
    ) : (
      <>
        <Box pt={1} pb={1}>
          <Button
            size="small"
            color={query.type || query.brand || query.model || query.condition || filter ? 'secondary' : 'default'}
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faFilter} />}
            onClick={this.toggleFilters}
          >
            Filtar
          </Button>
          <FormControl style={{ marginLeft: '10px' }}>
            <TextField
              value={search}
              variant="standard"
              onChange={this.handleChange}
              onKeyUp={this.handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon className="form-icon" icon={faSearch} />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Box>
        <Box pt={1} pb={1}>
          <Grid container spacing={2}>
            {filter ? (
              <>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="marketplace-filters-type">Tipo</InputLabel>
                    <Select
                      labelId="marketplace-filters-type"
                      onChange={(event: any) => this.handleFilterChange('type', event.target.value)}
                      value={query.type ? query.type : []}
                      multiple
                    >
                      {machineTypes.map((mt) => <MenuItem key={mt.id} value={mt.id}>{mt.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="marketplace-filters-brand">Marca</InputLabel>
                    <Select
                      labelId="marketplace-filters-brand"
                      onChange={(event: any) => this.handleFilterChange('brand', event.target.value)}
                      value={query.brand ? query.brand : []}
                      multiple
                    >
                      {brands.map((m) => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="marketplace-filters-model">Modelo</InputLabel>
                    <Select
                      labelId="marketplace-filters-model"
                      onChange={(event: any) => this.handleFilterChange('model', event.target.value)}
                      value={query.model ? query.model : []}
                      multiple
                    >
                      {Object.values(uniqueModels).map((m) => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="marketplace-filters-condition">Condição</InputLabel>
                    <Select
                      labelId="marketplace-filters-condition"
                      onChange={(event: any) => this.handleFilterChange('condition', event.target.value)}
                      value={query.condition ? query.condition : []}
                      multiple
                    >
                      <MenuItem value="true">Nova</MenuItem>
                      <MenuItem value="false">Usada</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
        <Box pt={1} pb={1}>
          <Grid container spacing={2}>
            {filteredMachines
              .slice((page - 1) * 8, (page - 1) * 8 + 8)
              .map((m) => <Grid key={m.id} item sm={3}>
                <Card>
                  <Link to={`/marketplace/machine/${m.id}/${
                    m.isMachineNew ? 'Novo' : 'Usado'
                  }_${encodeURIComponent((machineTypes.find((mt) => mt.id === m.type) || {}).label || '')}${
                    m.brand ? `_${encodeURIComponent(m.brand)}` : ''
                  }${m.model ? `_${encodeURIComponent(m.model)}` : ''}`}>
                    <CardActionArea>
                      {m.isMachineNew ? (
                        <Chip color="secondary" className="tag" label="NOVO" />
                      ) : (
                        <Chip color="default" className="tag" label="USADO" />
                      )}
                      {m.marketplaceEmphasis ? (
                        <div className="emphasis">CNC em destaque</div>
                      ) : null}

                      <CardMedia
                        style={{ height: '250px' }}
                        image={
                          getCoverImage(m.files) !== undefined
                            ? `${process.env.REACT_APP_API_URL}/api/files/machines/${getCoverImage(m.files)!.filename}`
                            : '/placeholder.svg'
                        }
                        title={m.description || ''}
                      />
                      <CardContent style={{ minHeight: '120px' }}>
                        <Grid container spacing={0}>
                          <Grid item sm={6}>
                            <Typography variant="body2" component="span">{m.brand}</Typography>
                          </Grid>
                          <Grid item sm={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body2" component="b">{m.model}</Typography>
                          </Grid>
                          <Grid item sm={6} style={{ paddingTop: '10px' }}>
                            <Typography variant="body2" component="span">Ano: {m.machineYear}</Typography>
                          </Grid>
                          <Grid item sm={12} style={{ paddingTop: '10px' }}>
                            <Typography variant="body2" component="span">Comando CNC: {m.commandBrand || ''} {m.commandModel || ''}</Typography>
                          </Grid>
                          <Grid item sm={12} style={{ textAlign: 'right', paddingTop: '10px' }}>
                            <Typography variant="body1" component="span"><b>Preço:</b> <b className="price">{
                              (
                                (
                                  m.marketplacePriceType === 'installPrice'
                                  ? m.installBuyPrice
                                  : m.marketplacePriceType === 'truckPrice'
                                  ? m.truckBuyPrice : 0
                                ) || 0
                              ).toFixed(2)
                            }€</b></Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                  <CardActions>
                    <Grid container spacing={1}>
                      <Grid item sm={12}>
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          fullWidth
                          startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                          href={`${process.env.REACT_APP_API_URL}/api/machines/machine/${m.id}.pdf`}
                          target="_blank"
                        >
                          F. Técnica
                        </Button>
                      </Grid>
                      <Grid item sm={12}>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          fullWidth
                          startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                          onClick={() => openForm(m.id)}
                        >
                          Pedir cotação
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )}
          </Grid>
          <Box pt={2} pb={2}>
            <Pagination count={pages} page={page} color="secondary" shape="rounded" onChange={this.handlePageChange} />
          </Box>
        </Box>
      </>
    );
  }
}

export default List;
