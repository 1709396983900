import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/suppliers';
import {
  createSupplier,
  editSupplier,
  fetchSuppliers,
  toggleSupplier,
  createSupplierRep,
  editSupplierRep,
  deleteSupplierRep,
} from '../api/suppliers';
import { SupplierRep } from '../models/Rep';
import Supplier from '../models/Supplier';

export function* fetchSuppliersSaga() {
  try {
    const suppliers: Supplier[] = yield call(fetchSuppliers);

    yield put(types.fetchSuppliers.success({ suppliers }));
  } catch (error: any) {
    yield put(types.fetchSuppliers.failure({ error }));
  }
}

export function* createSupplierSaga(action: ActionType<typeof types.createSupplier.request>) {
  try {
    const supplier: Supplier = yield call(createSupplier, action.payload);

    yield put(types.createSupplier.success({ supplier }));
  } catch (error: any) {
    yield put(types.createSupplier.failure({ error }));
  }
}

export function* editSupplierSaga(action: ActionType<typeof types.editSupplier.request>) {
  try {
    const supplier: Supplier = yield call(editSupplier, action.payload);

    yield put(types.editSupplier.success({ supplier }));
  } catch (error: any) {
    yield put(types.editSupplier.failure({ error }));
  }
}

export function* toggleSupplierSaga(action: ActionType<typeof types.toggleSupplier.request>) {
  try {
    yield call(toggleSupplier, action.payload);

    yield put(types.toggleSupplier.success(action.payload));
  } catch (error: any) {
    yield put(types.toggleSupplier.failure({ error }));
  }
}

export function* createSupplierRepSaga(action: ActionType<typeof types.createSupplierRep.request>) {
  try {
    const rep: SupplierRep = yield call(createSupplierRep, action.payload);

    yield put(types.createSupplierRep.success({ rep }));
  } catch (error: any) {
    yield put(types.createSupplierRep.failure({ error }));
  }
}

export function* editSupplierRepSaga(action: ActionType<typeof types.editSupplierRep.request>) {
  try {
    const rep: SupplierRep = yield call(editSupplierRep, action.payload);

    yield put(types.editSupplierRep.success({ rep }));
  } catch (error: any) {
    yield put(types.editSupplierRep.failure({ error }));
  }
}

export function* deleteSupplierRepSaga(action: ActionType<typeof types.deleteSupplierRep.request>) {
  try {
    yield call(deleteSupplierRep, action.payload);

    yield put(types.deleteSupplierRep.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteSupplierRep.failure({ error }));
  }
}