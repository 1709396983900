import { ActionType, getType } from 'typesafe-actions';

import * as application from '../actions/application';
import * as authentication from '../actions/authentication';
import * as dashboard from '../actions/dashboard';
import * as customers from '../actions/customers';
import * as machines from '../actions/machines';
import * as suppliers from '../actions/suppliers';
import * as users from '../actions/users';

type ApplicationAction = ActionType<
  typeof application |
  typeof authentication |
  typeof dashboard |
  typeof customers |
  typeof machines |
  typeof suppliers |
  typeof users
>;

export interface Message {
  type: 'error' | 'success';
  message?: object;
}

export interface IApplicationState {
  readonly messages: Message[];
  readonly count: number;
}

export const initialState: IApplicationState = {
  messages: [],
  count: 0,
};

const reducer = (
  state: IApplicationState = initialState,
  action: ApplicationAction
): IApplicationState => {
  switch (action.type) {
    case getType(application.dismissError): {
      const { index } = action.payload;
      const { messages } = state;

      messages.splice(index, 1);

      return { ...state, messages, count: messages.length };
    }

    case getType(authentication.authenticate.failure):
    case getType(authentication.recoverPassword.failure):
    case getType(authentication.resetPassword.failure):
    case getType(authentication.logout.failure):
    case getType(dashboard.fetchDashboardMetrics.failure):
    case getType(customers.fetchCustomers.failure):
    case getType(customers.createCustomer.failure):
    case getType(customers.editCustomer.failure):
    case getType(customers.createCustomerRep.failure):
    case getType(customers.editCustomerRep.failure):
    case getType(customers.deleteCustomerRep.failure):
    case getType(customers.fetchCustomerProposals.failure):
    case getType(customers.createCustomerProposal.failure):
    case getType(customers.editCustomerProposal.failure):    
    case getType(machines.fetchMachines.failure):
    case getType(machines.createMachine.failure):
    case getType(machines.editMachine.failure):
    case getType(machines.fetchMachinesTickets.failure):
    case getType(machines.createMachineTicket.failure):
    case getType(machines.editMachineTicket.failure):
    case getType(machines.createMachineTicketTask.failure):
    case getType(machines.editMachineTicketTask.failure):
    case getType(machines.createMachineTicketTaskExpense.failure):
    case getType(machines.deleteMachineTicketTaskExpense.failure):
    case getType(machines.createMachineTicketTaskComment.failure):
    case getType(machines.deleteMachineTicketTaskComment.failure):
    case getType(machines.fetchMachinesForMarketplace.failure):
    case getType(machines.sendMachineContact.failure):
    case getType(machines.fetchDashboardTasks.failure):
    case getType(machines.deleteMachine.failure):
    case getType(suppliers.fetchSuppliers.failure):
    case getType(suppliers.createSupplier.failure):
    case getType(suppliers.editSupplier.failure):
    case getType(users.fetchUsers.failure):
    case getType(users.createUser.failure):
    case getType(users.editUser.failure):
    {
      const { messages } = state;
      const { error } = action.payload;

      messages.push({ type: 'error', message: error });

      return { ...state, messages, count: messages.length };
    }

    case getType(customers.createCustomer.success):
    case getType(customers.editCustomer.success):
    case getType(customers.createCustomerRep.success):
    case getType(customers.editCustomerRep.success):
    case getType(customers.deleteCustomerRep.success):
    case getType(customers.createCustomerProposal.success):
    case getType(customers.editCustomerProposal.success):    
    case getType(machines.createMachine.success):
    case getType(machines.editMachine.success):
    case getType(machines.createMachineTicket.success):
    case getType(machines.editMachineTicket.success):
    case getType(machines.createMachineTicketTask.success):
    case getType(machines.editMachineTicketTask.success):
    case getType(machines.createMachineTicketTaskExpense.success):
    case getType(machines.deleteMachineTicketTaskExpense.success):
    case getType(machines.createMachineTicketTaskComment.success):
    case getType(machines.deleteMachineTicketTaskComment.success):
    case getType(machines.sendMachineContact.success):
    case getType(suppliers.createSupplier.success):
    case getType(suppliers.editSupplier.success):
    case getType(users.createUser.success):
    case getType(users.editUser.success):
    {
      const { messages } = state;

      messages.push({ type: 'success' });

      return { ...state, messages, count: messages.length };
    }

    default:
      return state;
  }
};

export default reducer;