import {
  CreateMachine,
  CreateMachineTicket,
  EditMachine,
  EditMachineTicket,
  DeleteMachine,
  ChangeMachineCustomer,
  CreateMachineTicketTask,
  EditMachineTicketTask,
  DeleteMachineTicketTask,
  CreateMachineTicketTaskExpense,
  DeleteMachineTicketTaskExpense,
  CreateMachineTicketTaskComment,
  DeleteMachineTicketTaskComment,
  SendMachineContact,
  CreateMachineTicketTaskSign,
  IFetchDashboardTasks,
  DeleteMachineTicket,
} from '../actions/machines';
import { MachineTypes } from '../App/Dashboard/Machines/sources';
import Machine from '../models/Machine';
import Task, { TaskSign } from '../models/Task';
import axios from './axios';

export const fetchMachines = async (): Promise<Machine[]> => {
  const { data } = await axios.get('/machines');

  return data;
};

interface SubCondition { // Represents a special condition that only applies to a given Machine Type list
  types: MachineTypes[];
  value: boolean | string;
}

interface Rule {
  allowed: MachineTypes[]; // List of Machine Types in which that property is allowed
  conditional?: Record<string, boolean | string | SubCondition> // Exists if aditional conditions for property be allowed are needed
}

const sanitizeMachinePayload = (property: string, properties: any) => {
  const machineType: MachineTypes = properties.type;

  const allowedAll = Object.values(MachineTypes);

  const payloadRules: Record<string, Rule> = {
    id: { allowed: allowedAll },
    type: { allowed: allowedAll },

    // Info Step
    reference: { allowed: allowedAll },
    description: { allowed: allowedAll },
    supplierId: { allowed: allowedAll },
    customerId: { allowed: allowedAll },
    brand: { allowed: allowedAll },
    model: { allowed: allowedAll },
    serialnumber: { allowed: allowedAll },
    commandType: { allowed: [MachineTypes.FLAT_GRINDING] },
    commandBrand: { allowed: allowedAll },
    commandModel: { allowed: allowedAll },
    isMachineNew: { allowed: allowedAll },
    buyPrice: { allowed: allowedAll },
    installBuyPrice: { allowed: allowedAll },
    truckBuyPrice: { allowed: allowedAll },
    machineYear: { allowed: allowedAll, conditional: { isMachineNew: false } },
    machineUsageHours: { allowed: allowedAll, conditional: { isMachineNew: false } },
    status: { allowed: allowedAll },
    costsOthersMaterial: { allowed: allowedAll },
    costsOthersTravels: { allowed: allowedAll },
    costsOthersManHours: { allowed: allowedAll },
    costsOthersOthers: { allowed: allowedAll },

    // Details Step
    courseX: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
        MachineTypes.SWISS_LATHE,
        MachineTypes.FLAT_GRINDING
      ]
    },
    courseY: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
        MachineTypes.SWISS_LATHE,
        MachineTypes.FLAT_GRINDING
      ]
    },
    courseZ: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
        MachineTypes.SWISS_LATHE,
        MachineTypes.FLAT_GRINDING
      ]
    },
    course4Axis: {
      allowed: [
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE
      ]
    },
    course4AxisMM: { allowed: [MachineTypes.BORING] },
    course5Axis: {
      allowed: [MachineTypes.T5X, MachineTypes.BORING, MachineTypes.EDM_WIRE]
    },
    axisType: { allowed: [MachineTypes.T5X, MachineTypes.HORIZONTAL] },
    positioning4Axis: { allowed: [MachineTypes.T5X, MachineTypes.HORIZONTAL], conditional: { axisType: 'positioned' } },
    positioning5Axis: { allowed: [MachineTypes.T5X], conditional: { axisType: 'positioned' } },
    autoPalletExchange: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING] },
    autoPalletExchangeQuantity: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING], conditional: { autoPalletExchange: true } },
    autoPalletExchangeDimension: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING], conditional: { autoPalletExchange: true } },
    tableDimensionX: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
      ],
      conditional: { tableDimensionDiameter: false, autoPalletExchange: { types: [MachineTypes.HORIZONTAL], value: false } }
    },
    tableDimensionY: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
      ],
      conditional: { tableDimensionDiameter: false, autoPalletExchange: { types: [MachineTypes.HORIZONTAL], value: false } }
    },
    tableDimensionDiameter: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
      ],
      conditional: { tableDimensionX: false, tableDimensionY: false }
    },
    spindleTableDistance: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL] },
    spindleTableDistanceMin: { allowed: [MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    spindleTableDistanceMax: { allowed: [MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    spindleTableWeight: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.EDM_HOLE,
      ]
    },
    rotativeTable: { allowed: [MachineTypes.BORING] },
    rotativeTableAngleMinPositioning: { allowed: [MachineTypes.BORING], conditional: { rotativeTable: true } },
    robot: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.PLASTIC_INJECTION] },
    robotNumberOfEletrods: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE], conditional: { robot: true } },
    robotNumberOfPallets: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE], conditional: { robot: true } },
    robotNumberOfAxis: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { robot: true } },
    robotReach: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { robot: true } },
    tankDimensionLength: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    tankDimensionWidth: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    tankDimensionHeight: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    tankSubmersible: { allowed: [MachineTypes.EDM_WIRE] },
    tankSubmersibleAuto: { allowed: [MachineTypes.EDM_WIRE], conditional: { tankSubmersible: true } },
    portableSteeringWheel: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    partMaxHeight: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    dielectricQuantity: { allowed: [MachineTypes.EDM] },
    hasCorePullers: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    corePullersSideMobile: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasCorePullers: true } },
    corePullersSideFixed: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasCorePullers: true } },
    corePullersQuantity: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasCorePullers: true } },
    hasPneumaticValves: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    pneumaticValvesSideMobile: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasPneumaticValves: true } },
    pneumaticValvesSideFixed: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasPneumaticValves: true } },
    pneumaticValvesQuantity: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasPneumaticValves: true } },
    hasControlValveGate: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    controlValveGateQuantity: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasControlValveGate: true } },
    hasMoldTemperatureControl: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    moldTemperatureControlZones: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { hasMoldTemperatureControl: true } },
    maxHydricPression: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    heatingPower: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    oilTankCapacity: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    motorPump: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    motorPower: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    tempControlZones: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    magneticTableDimensionLength: { allowed: [MachineTypes.FLAT_GRINDING] },
    magneticTableDimensionWidth: { allowed: [MachineTypes.FLAT_GRINDING] },
    magneticTableWeight: { allowed: [MachineTypes.FLAT_GRINDING] },
    rapidAxisVelocityX: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.SWISS_LATHE,
        MachineTypes.ROUND_GRINDING,
        MachineTypes.PLASTIC_INJECTION,
        MachineTypes.BANDSAW,
        MachineTypes.TRY_OUT_PRESS,
      ],
    },
    rapidAxisVelocityY: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE
      ],
    },
    rapidAxisVelocityZ: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.EDM,
        MachineTypes.EDM_WIRE,
        MachineTypes.SWISS_LATHE,
        MachineTypes.ROUND_GRINDING,
        MachineTypes.PLASTIC_INJECTION,
        MachineTypes.BANDSAW,
        MachineTypes.TRY_OUT_PRESS,
      ],
    },
    programmableVelocityAxisX: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.EDM] },
    programmableVelocityAxisY: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.EDM] },
    programmableVelocityAxisZ: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.EDM] },
    rapidAxisVelocityC: { allowed: [MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.EDM_WIRE] },
    programmableVelocityAxisC: { allowed: [MachineTypes.T5X, MachineTypes.HORIZONTAL] },
    rapidAxisVelocityB: { allowed: [MachineTypes.T5X, MachineTypes.EDM_WIRE] },
    programmableVelocityAxisB: { allowed: [MachineTypes.T5X] },
    transmissionMotor4Axis: { allowed: [MachineTypes.T5X] },
    transmissionMotor5Axis: { allowed: [MachineTypes.T5X] },
    cutMaxVelocity: { allowed: [MachineTypes.EDM_WIRE] },
    cutMaxAngle: { allowed: [MachineTypes.EDM_WIRE] },
    cutMaxAngleByLength: { allowed: [MachineTypes.EDM_WIRE] },
    turningDiameter: { allowed: [MachineTypes.SWISS_LATHE] },
    turningLength: { allowed: [MachineTypes.SWISS_LATHE] },
    detailsRPM: { allowed: [MachineTypes.TORNOMULTITASKS] },
    detailsPotence: { allowed: [MachineTypes.TORNOMULTITASKS] },
    detailsTorque: { allowed: [MachineTypes.TORNOMULTITASKS] },
    detailsConeType: { allowed: [MachineTypes.TORNOMULTITASKS] },
    headtype: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
      ]
    },
    guidesWidth: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
      ],
      conditional: { headtype: 'guides' }
    },
    RPMAxisC: { allowed: [MachineTypes.EDM_HOLE] },
    maximumFeedrate: { allowed: [MachineTypes.SWISS_LATHE] },
    connectionPower: { allowed: [MachineTypes.SWISS_LATHE] },

    // Fuses Step
    highPressureInteriorRefrigeration: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING, MachineTypes.SWISS_LATHE] },
    highPressureInteriorRefrigerationBar: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING, MachineTypes.SWISS_LATHE], conditional: { highPressureInteriorRefrigeration: true } },
    wasteExtractor: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.BORING,
        MachineTypes.TORNO,
        MachineTypes.SWISS_LATHE,
      ]
    },
    wasteExtractorType: { allowed: [MachineTypes.T3X, MachineTypes.T5X , MachineTypes.HORIZONTAL, MachineTypes.BORING, MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { wasteExtractor: true } },
    toolMeasurer: { allowed: [MachineTypes.T3X, MachineTypes.T5X , MachineTypes.HORIZONTAL, MachineTypes.BORING] },
    toolMeasurerType: { allowed: [MachineTypes.T3X, MachineTypes.T5X , MachineTypes.HORIZONTAL, MachineTypes.BORING], conditional: { toolMeasurer: true } },
    autoCenter: { allowed: [MachineTypes.T3X, MachineTypes.T5X , MachineTypes.HORIZONTAL, MachineTypes.BORING] },
    autoCenterBrand: { allowed: [MachineTypes.T3X, MachineTypes.T5X , MachineTypes.HORIZONTAL, MachineTypes.BORING], conditional: { autoCenter: true } },
    cabinada: { allowed: [MachineTypes.T3X, MachineTypes.T5X , MachineTypes.HORIZONTAL, MachineTypes.BORING] },
    partCatcher: { allowed: [MachineTypes.TORNO] },
    toolMeasurerArm: { allowed: [MachineTypes.TORNO] },
    toolMeasurerArmAutomatic: { allowed: [MachineTypes.TORNO], conditional: { toolMeasurerArm: true } },
    steeringWheelElec: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.BORING,
      ]
    },
    tornoDiameterTurnable: { allowed: [MachineTypes.TORNO] },
    tornoDiameterBush: { allowed: [MachineTypes.TORNO] },
    tornoDiameterCar: { allowed: [MachineTypes.TORNO] },
    tornoDiameterBarPass: { allowed: [MachineTypes.TORNO] },
    spindleRPM: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.SWISS_LATHE,
      ],
    },
    spindleMotorPotence: {
      allowed: [
        MachineTypes.T3X,
        MachineTypes.T5X,
        MachineTypes.HORIZONTAL,
        MachineTypes.TORNO,
        MachineTypes.TORNOMULTITASKS,
        MachineTypes.BORING,
        MachineTypes.SWISS_LATHE,
      ],
    },
    spindleDiameter: { allowed: [MachineTypes.BORING] },
    spindleNoseType: { allowed: [MachineTypes.SWISS_LATHE] },
    spindleLiveTools: { allowed: [MachineTypes.SWISS_LATHE] },
    spindleLiveToolsQuantity: { allowed: [MachineTypes.SWISS_LATHE], conditional: { spindleLiveTools: true } },
    spindleLiveToolsRPM: { allowed: [MachineTypes.SWISS_LATHE], conditional: { spindleLiveTools: true } },
    torque: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING] },
    coneType: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING] },
    spindleType: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING] },
    spindleElectro: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING] },
    spindleElectroBrand: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING], conditional: { spindleElectro: true } },
    spindleElectroCoolerType: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING] },
    spindleInterfaceType: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.TORNOMULTITASKS, MachineTypes.BORING] },
    subSpindle: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    subSpindleDiameterTornable: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { subSpindle: true } },
    subSpindleDiameterBush: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindleDiameterCar: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindleDiameterBar: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindleNoseType: { allowed: [MachineTypes.SWISS_LATHE], conditional: { subSpindle: true } },
    subSpindleLiveTools: { allowed: [MachineTypes.SWISS_LATHE], conditional: { subSpindle: true } },
    subSpindleLiveToolsQuantity: { allowed: [MachineTypes.SWISS_LATHE], conditional: { subSpindleLiveTools: true, subSpindle: true } },
    subSpindleLiveToolsRPM: { allowed: [MachineTypes.SWISS_LATHE], conditional: { subSpindleLiveTools: true, subSpindle: true } },
    subSpindleRPM: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindleMoterPower: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindleInterfaceType: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindlePartCatcher: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: true } },
    subSpindleTravelAxisLength: { allowed: [MachineTypes.SWISS_LATHE], conditional: { subSpindle: true } },
    contraPonto: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: false } },
    contraPontoType: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: false, contraPonto: true } },
    contraPontoPath: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: false, contraPonto: true } },
    contraPontoProgrammablePositioning: { allowed: [MachineTypes.TORNO], conditional: { subSpindle: false, contraPonto: true } },
    grindDimensionX: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    grindDimensionY: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    grindDimensionZ: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    grindTableDistance: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    grindMinVerticalAdd: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    grindRotation: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridPotence: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridRPM: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridMotorPotence: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridRectifierAuto: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridBalanceSystem: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridFilteringSystem: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING] },
    gridFilteringSystemVacuum: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING], conditional: { gridFilteringSystem: true } },
    gridFilteringSystemPaper: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING], conditional: { gridFilteringSystem: true } },
    gridFilteringSystemMagnetic: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING], conditional: { gridFilteringSystem: true } },
    gridFilteringSystemOther: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING], conditional: { gridFilteringSystem: true } },
    gridFilteringSystemOtherValue: { allowed: [MachineTypes.FLAT_GRINDING, MachineTypes.ROUND_GRINDING], conditional: { gridFilteringSystem: true, gridFilteringSystemOther: true } },
    gridRectifierMaxDiameter: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCentersHeight: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCentersInternalRetifierDiameterMin: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCentersInternalRetifierDiameterMax: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridRetifierAngleMin: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridRetifierAngleMax: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridMaxPartWeight: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCarCourse: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridAutoPassing: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCenterHeadType: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCenterContraPontoType: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridCenterContraPontoPath: { allowed: [MachineTypes.ROUND_GRINDING] },
    gridBalancerIncluded: { allowed: [MachineTypes.ROUND_GRINDING] },
    closeUnitPlateDimensionH: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    closeUnitPlateDimensionV: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    closeUnitColumnDistanceH: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitColumnDistanceV: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitMoldTicknessMin: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitMoldTicknessMax: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitMinMoldDimension: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitForce: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    openUnitForce: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitSupPlateCourse: { allowed: [MachineTypes.TRY_OUT_PRESS] },
    closeUnitSupPlateMove: { allowed: [MachineTypes.TRY_OUT_PRESS] },
    closeUnitMobilePlateCourse: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitMaxDistanceBetween: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitExtractorQuantity: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitExtractorForce: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    closeUnitExtractionCourse: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },

    // Turreta Step
    tornTurret: { allowed: [MachineTypes.TORNO, MachineTypes.TORNOMULTITASKS, MachineTypes.SWISS_LATHE] },
    toolQuantity: { allowed: allowedAll, conditional: { tornTurret: { types: [MachineTypes.TORNOMULTITASKS, MachineTypes.SWISS_LATHE], value: true } } },
    warehouseType: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING, MachineTypes.SWISS_LATHE] },
    tollExchangeTime: { allowed: [MachineTypes.T3X, MachineTypes.T5X, MachineTypes.HORIZONTAL, MachineTypes.BORING, MachineTypes.SWISS_LATHE] },
    supportType: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true } },
    staticSupportNumber: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true } },
    motorTool: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true } },
    motorToolRPM: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true } },
    motorToolPotence: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true } },
    motorToolSupports: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true } },
    motorToolNew: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true } },
    motorToolNewSupportName: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true, motorToolNew: true } },
    motorToolNewSupportDescription: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true, motorToolNew: true } },
    motorToolNewSupportBuyPrice: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true, motorToolNew: true } },
    motorToolNewSupportSellPrice: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true, motorToolNew: true } },
    motorToolRadQuantity: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true, motorToolNew: false } },
    motorToolAxiQuantity: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE], conditional: { tornTurret: true, motorTool: true, motorToolNew: false } },
    electrodeWarehouse: { allowed: [MachineTypes.EDM] },
    electrodeWarehouseQuantity: { allowed: [MachineTypes.EDM], conditional: { electrodeWarehouse: true } },
    electrodeMaxHeight: { allowed: [MachineTypes.EDM] },
    electrodeMaxErosionHead: { allowed: [MachineTypes.EDM] },
  
    // Bar supplier Step
    barSupplierBrand: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierModel: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierMaxDiameter: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierMaxHeight: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierDimentionC: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierDimentionL: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierDimentionA: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
    barSupplierWeight: { allowed: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE] },
  
    // Erosion Step
    erosionHeadAxisC360: { allowed: [MachineTypes.EDM] },
    erosionHeadType: { allowed: [MachineTypes.EDM] },
    erosionHeadSubType: { allowed: [MachineTypes.EDM] },
    erosionThreadMin: { allowed: [MachineTypes.EDM_WIRE] },
    erosionThreadMax: { allowed: [MachineTypes.EDM_WIRE] },
    erosionThreadingAuto: { allowed: [MachineTypes.EDM_WIRE] },
    erosionRodMin: { allowed: [MachineTypes.EDM_HOLE] },
    erosionRodMax: { allowed: [MachineTypes.EDM_HOLE] },
    erosionRodAuto: { allowed: [MachineTypes.EDM_HOLE] },
    sawType: { allowed: [MachineTypes.BANDSAW] },
    sawVelocityVariation: { allowed: [MachineTypes.BANDSAW] },
    sawVelocityVariationIMin: { allowed: [MachineTypes.BANDSAW] },
    sawVelocityVariationIMax: { allowed: [MachineTypes.BANDSAW] },
    sawAutoSupply: { allowed: [MachineTypes.BANDSAW] },
    sawCutDimensionsMinDiameter: { allowed: [MachineTypes.BANDSAW] },
    sawCutDimensionsMinRectangle: { allowed: [MachineTypes.BANDSAW] },
    sawCutDimensionsMaxDiameter: { allowed: [MachineTypes.BANDSAW] },
    sawCutDimensionsMaxRectangle: { allowed: [MachineTypes.BANDSAW] },
    sawCutMinLength: { allowed: [MachineTypes.BANDSAW] },
    sawCutMaxLength: { allowed: [MachineTypes.BANDSAW] },
    sawAngleCut: { allowed: [MachineTypes.BANDSAW] },
    sawAngleCutMin: { allowed: [MachineTypes.BANDSAW] },
    sawAngleCutMax: { allowed: [MachineTypes.BANDSAW] },
    sawTapeDimensionsC: { allowed: [MachineTypes.BANDSAW] },
    sawTapeDimensionsL: { allowed: [MachineTypes.BANDSAW] },
    sawTapeDimensionsA: { allowed: [MachineTypes.BANDSAW] },
    sawDimensions: { allowed: [MachineTypes.BANDSAW] },
    sawWeight: { allowed: [MachineTypes.BANDSAW] },
    
    // Generator Step
    generatorHasRefrigerator: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },
    generatorAMP: { allowed: [MachineTypes.EDM, MachineTypes.EDM_WIRE, MachineTypes.EDM_HOLE] },

    // Injection Unit
    injectionUnitFuseDiameter: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionUnitMaxRPMFuse: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionUnitVolume: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionUnitMaxWeight: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionUnitPression: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionUnitRatio: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionCenterRingDiameter: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionScrewStrock: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionRate: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionNozzleContactForce: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionNozzleStroke: { allowed: [MachineTypes.PLASTIC_INJECTION] },
    injectionResineSystem: { allowed: [MachineTypes.TRY_OUT_PRESS] },
    ndInjectionUnit: { allowed: [MachineTypes.PLASTIC_INJECTION, MachineTypes.TRY_OUT_PRESS] },
    ndInjectionUnitFuseDiameter: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionUnitMaxRPMFuse: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionUnitVolume: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionUnitMaxWeight: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionUnitPression: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionUnitRatio: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionCenterRingDiameter: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionScrewStrock: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionRate: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionNozzleContactForce: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionNozzleStroke: { allowed: [MachineTypes.PLASTIC_INJECTION], conditional: { ndInjectionUnit: true } },
    ndInjectionResineSystem: { allowed: [MachineTypes.TRY_OUT_PRESS], conditional: { ndInjectionUnit: true } },
    pressDimensionLength: { allowed: [MachineTypes.TRY_OUT_PRESS] },
    pressDimensionWidth: { allowed: [MachineTypes.TRY_OUT_PRESS] },
    pressDimensionHeight: { allowed: [MachineTypes.TRY_OUT_PRESS] },
    pressWeight: { allowed: [MachineTypes.TRY_OUT_PRESS] },

    // Logistics Step
    dimensionC: { allowed: allowedAll },
    dimensionL: { allowed: allowedAll },
    dimensionA: { allowed: allowedAll },
    weight: { allowed: allowedAll },
    deliveryDate: { allowed: allowedAll },
    warranty: { allowed: allowedAll },
    transportPrice: { allowed: allowedAll },
    paymentConditions: { allowed: allowedAll },
    commands: { allowed: allowedAll },

    // Optionals Step
    observations: { allowed: allowedAll },
    youtubeLink: { allowed: allowedAll },
    catalog: { allowed: allowedAll },
    catalogOriginalname: { allowed: allowedAll },
    catalogFilename: { allowed: allowedAll },
    files: { allowed: allowedAll },
    marketplaceVisibility: { allowed: allowedAll },
    marketplaceEmphasis: { allowed: allowedAll },
    marketplacePriceVisibillity: { allowed: allowedAll },
    marketplacePriceType: { allowed: allowedAll, conditional: { marketplacePriceVisibillity: true } },
    options: { allowed: allowedAll },
  };

  const propertyRule = payloadRules[property] || {};

  return (
    // Check if the property is allowed for the current machine type
    (propertyRule.allowed || []).indexOf(machineType) !== -1 &&
    (
      // Check if there are no aditional conditions
      !propertyRule.conditional ||
      // If adicional conditions check if they match
      Object.keys(propertyRule.conditional).map((condition: string) => {
        if (properties[condition] === undefined || properties[condition] === null) {
          return true;
        }

        const dataType = typeof propertyRule.conditional![condition];
        // Check for adicional condition subtype (specific machine)
        if (dataType === 'object') {
          const subCondition = propertyRule.conditional![condition] as SubCondition;
          return subCondition.types.indexOf(machineType) === -1 || (
            properties[condition] === subCondition.value
          );
        }

        return properties[condition] === propertyRule.conditional![condition]
      }).find(
        // If all conditions are true, property is valid
        (v) => v === false
      ) === undefined
    )
  // Remove empty strings
  ) && properties[property] !== '';
}

const generageFormData = (properties: any) => {
  const formData = new FormData();

  Object.keys(properties)
    .filter((s) => sanitizeMachinePayload(s, properties))
    .forEach((key) => {
      const prop = properties[key];

      if (Array.isArray(prop)) {
        prop.forEach((item) => {
          if (key === 'file' && item.sysfilename) {
            formData.append(key, JSON.stringify({
              originalname: item.name,
              filename: item.sysfilename,
              fullpage: item.fullpage,
              id: item.id,
            }));
          } else if (key === 'files' && item.sysfilename) {
            formData.append(`${key}[]`, JSON.stringify({
              originalname: item.name,
              filename: item.sysfilename,
              fullpage: item.fullpage,
              id: item.id,
            }));
          } else if (key === 'files') {
            formData.append(`${key}[]`, JSON.stringify({
              originalname: item.name,
              fullpage: item.fullpage,
            }));
            formData.append(`${key}_file[]`, item);
          } else if (key === 'options') {
            formData.append(`${key}[]`, JSON.stringify({
              description: item.description || undefined,
              quantity: item.quantity || undefined,
              purchaseValue: item.purchaseValue || undefined,
              sellValue: item.sellValue || undefined,
            }));
          } else {
            formData.append(`${key}[]`, item);
          }
        });
      } else if (prop) {
        formData.append(key, prop);
      }
    }
  );

  return formData;
};

export const createMachine = async (properties: CreateMachine) => {
  const formData = generageFormData(properties);

  const { data } = await axios.post('/machines', formData);

  return data;
}

export const editMachine = async (properties: EditMachine) => {
  const formData = generageFormData(properties);

  const { data } = await axios.put('/machines', formData);

  return data;
}

export const deleteMachine = async (properties: DeleteMachine) => {
  return await axios.delete(`/machines/id/${properties.machineId}`);
}

export const changeMachineCustomer = async (properties: ChangeMachineCustomer) => {
  const { data } = await axios.put('/machines/customer', properties);

  return data;
}

export const fetchMachinesTickets = async (): Promise<any[]> => {
  const { data } = await axios.get('/machines/tickets');

  return data;
};

export const createMachineTicket = async (properties: CreateMachineTicket) => {
  const { data } = await axios.post('/machines/ticket', properties);

  return data;
}

export const editMachineTicket = async (properties: EditMachineTicket) => {
  const { data } = await axios.put('/machines/ticket', properties);

  return data;
}

export const deleteMachineTicket = async (properties: DeleteMachineTicket) => {
  return await axios.delete(`/machines/ticket/id/${properties.id}`);
}

export const createMachineTicketTask = async (properties: CreateMachineTicketTask) => {
  const formData = new FormData();

  Object.keys(properties).forEach((key) => {
    // @ts-ignore
    const prop = properties[key];

    if (Array.isArray(prop)) {
      prop.forEach((item) => {
        if (key === 'file' && item.sysfilename) {
          formData.append(key, JSON.stringify({
            originalname: item.name,
            filename: item.sysfilename,
            id: item.id,
          }));
        } else if (key === 'files' && item.sysfilename) {
          formData.append(`${key}[]`, JSON.stringify({
            originalname: item.name,
            filename: item.sysfilename,
            id: item.id,
          }));
        } else {
          formData.append(`${key}[]`, item);
        }
      });
    } else if (prop) {
      formData.append(key, prop);
    }
  });

  const { data } = await axios.post('/machines/ticket/task', formData);

  return data;
}

export const editMachineTicketTask = async (properties: EditMachineTicketTask) => {
  const formData = new FormData();

  Object.keys(properties).forEach((key) => {
    // @ts-ignore
    const prop = properties[key];

    if (Array.isArray(prop)) {
      prop.forEach((item) => formData.append(`${key}[]`, item));
    } else if (key === 'file' && prop && prop.sysfilename) {
      formData.append(key, JSON.stringify({
        originalname: prop.name,
        filename: prop.sysfilename,
      }));
    } else if (prop) {
      formData.append(key, prop);
    }
  });

  const { data } = await axios.put('/machines/ticket/task', formData);

  return data;
}

export const deleteMachineTicketTask = async (properties: DeleteMachineTicketTask) => {
  await axios.delete(`/machines/ticket/task/ticketid/${properties.ticketid}/id/${properties.id}`);
}

export const createMachineTicketTaskExpense = async (properties: CreateMachineTicketTaskExpense) => {
  const { data } = await axios.post('/machines/ticket/task/expense', properties);

  return data;
}

export const deleteMachineTicketTaskExpense = async (properties: DeleteMachineTicketTaskExpense) => {
  return await axios.delete(`/machines/ticket/task/expense/ticketid/${properties.ticketid}/taskid/${properties.taskid}/id/${properties.id}`);
}

export const createMachineTicketTaskComment = async (properties: CreateMachineTicketTaskComment) => {
  const { data } = await axios.post('/machines/ticket/task/comment', properties);

  return data;
}

export const deleteMachineTicketTaskComment = async (properties: DeleteMachineTicketTaskComment) => {
  return await axios.delete(`/machines/ticket/task/comment/ticketid/${properties.ticketid}/taskid/${properties.taskid}/id/${properties.id}`);
}

export const createMachineTicketTaskSign = async (properties: CreateMachineTicketTaskSign): Promise<TaskSign> => {
  const { data } = await axios.post('/machines/ticket/task/sign', properties);

  return data;
}

export const fetchMachinesForMarketplace = async (): Promise<Machine[]> => {
  const { data } = await axios.get('/machines/marketplace');

  return data;
};

export const sendMachineContact = async (properties: SendMachineContact): Promise<void> => {
  const { data } = await axios.post('/machines/contact', properties);

  return data;
};

export const fetchDashboardTasks = async (properties: IFetchDashboardTasks): Promise<Task[]> => {
  const { data } = await axios.get(
    '/machines/tickets/tasks'
    + (properties.technicianId ? '/technicianid/' + properties.technicianId : '')
  );

  return data;
};