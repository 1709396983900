import { ActionType, getType } from 'typesafe-actions';

import * as authentication from '../actions/authentication';
import User from '../models/User';

type AuthenticationAction = ActionType<typeof authentication>;

export interface ILoginState {
  readonly error?: object;
  readonly loading: boolean;
}

interface IFormState {
  readonly redirectReady: boolean;
  readonly error?: object;
  readonly loading: boolean;
}

export interface IAuthenticationState {
  readonly authenticated: boolean;
  readonly checked: boolean;
  readonly user?: User;
  readonly error?: object;
  readonly loading: boolean;
  readonly login: ILoginState;
  readonly passwordRecoveryForm: IFormState;
  readonly passwordResetForm: IFormState;
}

export const initialState: IAuthenticationState = {
  authenticated: false,
  checked: false,
  loading: false,
  login: {
    loading: false,
  },
  passwordRecoveryForm: {
    redirectReady: false,
    loading: false,
  },
  passwordResetForm: {
    redirectReady: false,
    loading: false,
  },
};

const reducer = (
  state: IAuthenticationState = initialState,
  action: AuthenticationAction
): IAuthenticationState => {
  switch (action.type) {
    case getType(authentication.fetchUser.request):
      return {
        ...state,
        loading: true,
      };

    case getType(authentication.fetchUser.success): {
      const { user } = action.payload;

      return {
        ...state,
        user,
        authenticated: true,
        error: undefined,
        checked: true,
        loading: false,
      };
    }

    case getType(authentication.fetchUser.failure): {
      const { error } = action.payload;

      return {
        ...state,
        user: undefined,
        error,
        checked: true,
        loading: false,
      };
    }

    case getType(authentication.authenticate.request):
      return {
        ...state,
        login: {
          loading: true,
        },
      };

    case getType(authentication.authenticate.success):
      return {
        ...state,
        authenticated: true,
        login: {
          loading: false,
        },
      };

    case getType(authentication.authenticate.failure): {
      const { error } = action.payload;

      return {
        ...state,
        login: {
          error,
          loading: false,
        },
      };
    }

    case getType(authentication.recoverPassword.request):
      return {
        ...state,
        passwordRecoveryForm: {
          ...state.passwordRecoveryForm,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(authentication.recoverPassword.success):
      return {
        ...state,
        passwordRecoveryForm: {
          ...state.passwordRecoveryForm,
          redirectReady: true,
          loading: false,
        },
      };

    case getType(authentication.recoverPassword.failure): {
      const { error } = action.payload;

      return {
        ...state,
        passwordRecoveryForm: {
          ...state.passwordRecoveryForm,
          error,
          loading: false,
        },
      };
    }

    case getType(authentication.resetPassword.request):
      return {
        ...state,
        passwordResetForm: {
          ...state.passwordResetForm,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(authentication.resetPassword.success):
      return {
        ...state,
        passwordResetForm: {
          ...state.passwordResetForm,
          redirectReady: true,
          loading: false,
        },
      };

    case getType(authentication.resetPassword.failure): {
      const { error } = action.payload;

      return {
        ...state,
        passwordResetForm: {
          ...state.passwordResetForm,
          error,
          loading: false,
        },
      };
    }

    case getType(authentication.logout.request):
      return {
        ...state,
        loading: true,
      };

    case getType(authentication.logout.success): {
      return {
        ...state,
        user: undefined,
        authenticated: false,
        loading: false,
      };
    }

    case getType(authentication.logout.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(authentication.clear): {
      return {
        ...state,
        user: undefined,
        authenticated: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;