import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEuroSign,
  faExclamation,
  faQuestion,
  faPercentage,
  faPlay,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { CategoryScale } from 'chart.js'; 
import Chart from 'chart.js/auto';

import { IFetchDashboardMetrics, IFetchDashboardTasks } from '../../../actions/index';
import ViewMachineModal from '../Machines/ViewModal';
import DashboardMetrics from '../../../models/DashboardMetrics';
import Task, { TaskStatus } from '../../../models/Task';
import Ticket, { TicketStatus } from '../../../models/Ticket';
import User from '../../../models/User';
import Zone from '../../../models/Zone';
import Button from '../../common/Button';
import TaskModal from '../Machines/Tickets/Form/TaskModal';
import SignModal from '../Machines/Tickets/Form/SignModal';
import DashboardTaskView from './DashboardTaskView';

interface IProps {
  fetchUsers: () => void;
  fetchDashboardTasks: (properties:  IFetchDashboardTasks) => void;
  fetchMachines: () => void;
  fetchMachinesTickets: () => void;
  fetchDashboardMetrics: (properties:  IFetchDashboardMetrics) => void;
  fetchZonesTable: () => void;
  history: any;
  loading: boolean;
  metrics?: DashboardMetrics;
  openMachineTicketTask: (task: Task) => void;
  taskModal?: Task;
  tasks: Task[];
  tickets: Ticket[];
  users: User[];
  activeUser: User;
  openSignModal: boolean;
  toggleMachineView: (machineId?: number) => void;
  viewMachine?: number;
  zones: Zone[];
}
interface IState {
  year: number;
  years: number[];
  selection: 'crm' | 'tickets';
  technicianId?: number;
  commercialId?: number;
}

class DashboardContent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    Chart.register(CategoryScale);

    const years = [];
    const year = new Date().getFullYear();
    for (let y = 2020; y <= year; y += 1) {
      years.push(y);
    }

    this.state = {
      year,
      years,
      selection: 'crm',
    };

    this.toggleView = this.toggleView.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleTechChange = this.handleTechChange.bind(this);
    this.handleCommChange = this.handleCommChange.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  public componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers();
    this.fetchData();
  }

  public handleYearChange(event: any) {
    this.setState({ year: event.target.value }, () => {
      this.fetchData();
    });
  }

  public handleTechChange(event: any) {
    this.setState({ technicianId: event.target.value || undefined }, () => {
      this.fetchData();
    });
  }

  public handleCommChange(event: any) {
    this.setState({ commercialId: event.target.value || undefined }, () => {
      this.fetchData();
    });
  }

  public toggleView(selection: 'crm' | 'tickets') {
    this.setState({ selection });
  }

  public fetchData() {
    const {
      activeUser,
      fetchDashboardTasks,
      fetchMachines,
      fetchMachinesTickets,
      fetchDashboardMetrics,
      fetchZonesTable,
      history
    } = this.props;
    const { year, technicianId, commercialId } = this.state;

    if (activeUser.role === 'externaltech') {
      return history.push('/dashboard/machines/tickets/list');
    }

    if (activeUser.role === 'administrative') {
      return;
    }

    if (activeUser.role !== 'admincommercial' && activeUser.role !== 'commercial') {
      fetchDashboardTasks({ technicianId });
      fetchMachines();
      fetchMachinesTickets();
      fetchZonesTable();
    }
    if (activeUser.role !== 'admintech' && activeUser.role !== 'tech') {
      fetchDashboardMetrics({ year, commercialId, technicianId });
    }

    return;
  }

  public openModal(task: Task) {
    const { openMachineTicketTask } = this.props;

    openMachineTicketTask(task);
  }

  public render() {
    const { year, years, selection, commercialId, technicianId } = this.state;
    const {
      activeUser,
      history,
      loading,
      openSignModal,
      viewMachine,
      tickets,
      taskModal,
      tasks,
      toggleMachineView,
      users,
      metrics,
      zones
    } = this.props;

    const viewOnly = activeUser.role !== 'admin' && activeUser.role !== 'admintech';

    return (
      <div className="dashboard-container">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h5" component="h5" style={{ marginTop: '15px' }}>Dashboard</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
              {activeUser.role === 'admin' ? (
                <Paper className="dashboard-toggler">
                  <Button
                    color={selection === 'crm' ? 'secondary' : 'default'}
                    variant={selection === 'crm' ? 'contained' : 'outlined'}
                    style={{ backgroundColor: selection !== 'crm' ? 'transparent' : undefined }}
                    onClick={() => this.toggleView('crm')}
                  >
                    <span style={{ color: selection !== 'crm' ? '#72808c' : undefined }}>CRM</span>
                  </Button>
                  <Button
                    color={selection === 'tickets' ? 'secondary' : 'default'}
                    variant={selection === 'tickets' ? 'contained' : 'outlined'}
                    style={{ backgroundColor: selection !== 'tickets' ? 'transparent' : undefined }}
                    onClick={() => this.toggleView('tickets')}
                  >
                    <span style={{ color: selection !== 'tickets' ? '#72808c' : undefined }}>SATs</span>
                  </Button>
                </Paper>
              ) : null}
            </Grid>
            {!loading ? (
              <>
                <Grid item xs={12} sm={6} md={2}>
                  {(activeUser.role === 'admin' && selection === 'crm') || activeUser.role === 'admincommercial' ? (
                    <FormControl fullWidth>
                      <InputLabel id="dashboard-commercials">Comerciais</InputLabel>
                      <Select
                        labelId="dashboard-commercials"
                        value={commercialId || ''}
                        onChange={this.handleCommChange}
                      >
                        {!users.filter((u) => u.role === 'commercial' ||  u.role === 'admincommercial' || u.id === activeUser.id).length
                          ? <MenuItem disabled>Não existem comerciais disponíveis</MenuItem>
                          : <MenuItem style={{ color: '#e3e3e3', fontStyle: 'italic' }}>Nenhum</MenuItem>
                        }
                        {users.filter((u) => u.role === 'commercial' ||  u.role === 'admincommercial' || u.id === activeUser.id).map((u) =>
                          <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  ) : null}
                  {(activeUser.role === 'admin' && selection === 'tickets') || activeUser.role === 'admintech' ? (
                    <FormControl fullWidth>
                      <InputLabel id="dashboard-technicians">Técnicos</InputLabel>
                      <Select
                        labelId="dashboard-technicians"
                        value={technicianId || ''}
                        onChange={this.handleTechChange}
                      >
                        {!users.filter((u) => u.role === 'tech' ||  u.role === 'admintech' || u.id === activeUser.id).length
                          ? <MenuItem disabled>Não existem técnicos disponíveis</MenuItem>
                          : <MenuItem style={{ color: '#e3e3e3', fontStyle: 'italic' }}>Nenhum</MenuItem>}
                        {users.filter((u) => u.role === 'tech' ||  u.role === 'admintech' || u.id === activeUser.id).map((u) =>
                          <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="dashboard-year">Ano</InputLabel>
                    <Select
                      labelId="dashboard-year"
                      value={year}
                      onChange={this.handleYearChange}
                    >
                      {years.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
        
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {(selection === 'crm' && activeUser.role === 'admin')
            || activeUser.role === 'admincommercial'
            || activeUser.role === 'commercial'
            ? (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="green-avatar">
                            <FontAwesomeIcon icon={faCheck} />
                          </Avatar>
                        }
                        title={<b>Propostas adjudicadas</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={'/dashboard/customers/proposals/list?status=awarded&year=' + year}>
                          <Typography variant="h3">{(metrics || {}).awarded_count || 0}</Typography>
                        </Link>
                        <Typography variant="body2">{((metrics || {}).awarded_total || 0).toFixed(2)}€</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="blue-avatar">
                            <FontAwesomeIcon icon={faQuestion} />
                          </Avatar>
                        }
                        title={<b>Propostas pendentes</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={'/dashboard/customers/proposals/list?status=pending&year=' + year}>
                          <Typography variant="h3">{(metrics || {}).pending_count || 0}</Typography>
                        </Link>
                        <Typography variant="body2">{((metrics || {}).pending_total || 0).toFixed(2)}€</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="red-avatar">
                            <FontAwesomeIcon icon={faTimes} />
                          </Avatar>
                        }
                        title={<b>Propostas não adjudicadas</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={'/dashboard/customers/proposals/list?status=notawarded&year=' + year}>
                          <Typography variant="h3">{(metrics || {}).notawarded_count || 0}</Typography>
                        </Link>
                        <Typography variant="body2">{((metrics || {}).notawarded_total || 0).toFixed(2)}€</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="yellow-avatar">
                            <FontAwesomeIcon icon={faExclamation} />
                          </Avatar>
                        }
                        title={<b>Oportunidades sem proposta</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}> 
                        <Link to={'/dashboard/customers/proposals/list?status=-&year=' + year}>
                          <Typography variant="h3">{(metrics || {}).without_proposal || 0}</Typography>
                        </Link>
                        <Typography variant="body2">
                          <span>Há mais de 5 dias: </span>
                          <b>{((metrics || {}).without_proposal_plus5days || 0)}</b>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="green-avatar">
                            <FontAwesomeIcon icon={faEuroSign} />
                          </Avatar>
                        }
                        title={<b>Ganhos (margem)</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={'/dashboard/customers/proposals/list?status=awarded&year=' + year}>
                          <Typography variant="h3">{(metrics || {}).awarded_margin || 0}</Typography>
                        </Link>
                        <Typography variant="body2">&nbsp;</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="blue-avatar">
                            <FontAwesomeIcon icon={faPercentage} />
                          </Avatar>
                        }
                        title={<b>Concretização</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={'/dashboard/customers/proposals/list?status=awarded&year=' + year}>
                          <Typography variant="h3">{
                            ((metrics || {}).awarded_count || 0) > 0
                            ? (
                              ((((metrics || {}).awarded_count || 0) / (
                                ((metrics || {}).awarded_count || 0) +
                                ((metrics || {}).pending_count || 0) +
                                ((metrics || {}).notawarded_count || 0)
                              )) * 100).toFixed(2)
                            ) : 0
                          }</Typography>
                        </Link>
                        <Typography variant="body2">&nbsp;</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={12}>
                    <Card>
                      <CardContent>
                        <Line
                          data={{
                            labels: [
                              'Jan',
                              'Fev',
                              'Mar',
                              'Abr',
                              'Mai',
                              'Jun',
                              'Jul',
                              'Ago',
                              'Set',
                              'Out',
                              'Nov',
                              'Dez',
                            ],
                            datasets: [
                              {
                                backgroundColor: "#7eca8f",
                                borderColor: "#28a745",
                                pointBorderColor: "#fff",
                                pointBackgroundColor: "#28a745",
                                pointHoverBackgroundColor: "#28a745",
                                pointHoverBorderColor: "#7eca8f",
                                data: (metrics || {}).details,
                              }
                            ]
                          }}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false
                              }
                            },
                            scales: {
                              y: {
                                beginAtZero: true
                              },
                            },
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {(selection === 'tickets' && activeUser.role === 'admin') ||
            activeUser.role === 'tech' ||
            activeUser.role === 'admintech'
            ? (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar>
                            <FontAwesomeIcon icon={faExclamation} />
                          </Avatar>
                        }
                        title={<b>SATs em espera</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={`/dashboard/machines/tickets/list?tickets=${tickets.filter((t) => t.status === TicketStatus.PENDING && new Date(t.created).getFullYear() === year).map((t) => t.id)}&year=${year}`}>
                          <Typography variant="h3">{tickets.filter((t) => t.status === TicketStatus.PENDING && new Date(t.created).getFullYear() === year).length}</Typography>
                        </Link>
                        <Typography variant="body2">
                          <span>Há mais de 5 dias: </span>
                          <b>{tickets.filter((t) => t.status === TicketStatus.PENDING && new Date(t.created).getFullYear() === year && new Date(t.created).getTime() > 5 * 24 * 60 * 60 * 1000).length}</b>
                        </Typography>
                     </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="blue-avatar">
                            <FontAwesomeIcon icon={faPlay} />
                          </Avatar>
                        }
                        title={<b>SATs em execução</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={`/dashboard/machines/tickets/list?tickets=${tickets.filter((t) => t.status === TicketStatus.EXECUTION && new Date(t.created).getFullYear() === year).map((t) => t.id)}&year=${year}`}>
                          <Typography variant="h3">{tickets.filter((t) => t.status === TicketStatus.EXECUTION && new Date(t.created).getFullYear() === year).length}</Typography>
                        </Link>
                        <Typography variant="body2">&nbsp;</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar className="green-avatar">
                            <FontAwesomeIcon icon={faCheck} />
                          </Avatar>
                        }
                        title={<b>SATs concluídos</b>}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Link to={`/dashboard/machines/tickets/list?tickets=${tickets.filter((t) => t.status === TicketStatus.DONE && new Date(t.created).getFullYear() === year).map((t) => t.id)}&year=${year}`}>
                          <Typography variant="h3">{tickets.filter((t) => t.status === TicketStatus.DONE && new Date(t.created).getFullYear() === year).length}</Typography>
                        </Link>
                        <Typography variant="body2">
                          <span>Tempo médio de conclusão: </span>
                          <b>{(((metrics || {}).task_completion_avg || 0) / 1000 / 60 / 60 / 24).toFixed(1)}</b>
                          <span> dias</span>
                        </Typography>
                     </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={12}>
                    <Card>
                      <CardHeader title="As minhas tarefas"></CardHeader>
                      <CardContent>
                        <Grid container spacing={4}>
                          <Grid item sm={6} md={4}>
                            <Paper className="task-header pending">
                              <Box pl={2}>
                                <Typography variant="body1" component="span">Em espera</Typography>
                              </Box>
                            </Paper>
                            <Box p={2} className="task-container">
                              {tasks.filter((t) => t.status === TaskStatus.PENDING).map((task) =>
                                <DashboardTaskView
                                  key={task.id}
                                  history={history}
                                  openModal={this.openModal}
                                  toggleMachineView={toggleMachineView}
                                  ticket={tickets.find((t) => t.id === task.ticketid) || {} as Ticket}
                                  task={task}
                                  users={users}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid item sm={6} md={4}>
                            <Paper className="task-header execution">
                              <Box pl={2}>
                                <Typography variant="body1" component="span">Em execução</Typography>
                              </Box>
                            </Paper>
                            <Box p={2} className="task-container">
                              {tasks.filter((t) => t.status === TaskStatus.EXECUTION).map((task) =>
                                <DashboardTaskView
                                  key={task.id}
                                  history={history}
                                  openModal={this.openModal}
                                  toggleMachineView={toggleMachineView}
                                  ticket={tickets.find((t) => t.id === task.ticketid) || {} as Ticket}
                                  task={task}
                                  users={users}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid item sm={6} md={4}>
                            <Paper className="task-header done">
                              <Box pl={2}>
                                <Typography variant="body1" component="span">Concluídas</Typography>
                              </Box>
                            </Paper>
                            <Box p={2} className="task-container">
                              {tasks.filter((t) => t.status === TaskStatus.DONE).map((task) =>
                                <DashboardTaskView
                                  key={task.id}
                                  history={history}
                                  openModal={this.openModal}
                                  toggleMachineView={toggleMachineView}
                                  task={task}
                                  ticket={tickets.find((t) => t.id === task.ticketid) || {} as Ticket}
                                  users={users}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </>
        )}
        {!openSignModal && taskModal ? (
          <TaskModal
            taskid={taskModal.id}
            ticketid={taskModal.ticketid}
            users={users}
            zoneId={(tickets.find((t) => t.id === taskModal.ticketid) || {}).zoneId}
            zones={zones}
            viewOnly={viewOnly && activeUser.role !== 'tech'}
          />
        ): null}
        {openSignModal ? <SignModal /> : null}
        {viewMachine ? <ViewMachineModal /> : null}
      </div>
    );
  }
}

export default DashboardContent;
