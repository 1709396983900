import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeDynamicFieldForm,
  EditDynamicField,
  editDynamicField,
  fetchDynamicFieldValues,
} from '../../../../../actions/tables';
import { IAppState } from '../../../../../reducers/index';
import Form from './Form';

interface IProps {
  userid?: number;
}

const mapStateToProps = ({ tables }: IAppState, props: IProps) => ({
  meta: tables.dynamicFields.meta || {} as { id: string; name: string; },
  loading: tables.dynamicFields.loading,
  values: tables.dynamicFields.values || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDynamicFieldMeta: (id: string) =>
      dispatch(fetchDynamicFieldValues.request({ id })),
    editDynamicField: (properties: EditDynamicField) =>
      dispatch(editDynamicField.request(properties)),
    closeDynamicFieldForm: () =>
      dispatch(closeDynamicFieldForm()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
