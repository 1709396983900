import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { coneTypes, MachineTypes, motorTransmissionTypes } from '../../Dashboard/Machines/sources';
import Machine from '../../../models/Machine';

interface IProps {
  machine: Machine;
}

const Details: FunctionComponent <IProps> = (properties) => {
  const {
    type,
    
    courseX,
    courseY,
    courseZ,
    course4Axis,
    course4AxisMM,
    course5Axis,
    axisType,
    positioning4Axis,
    positioning5Axis,
    autoPalletExchange,
    autoPalletExchangeQuantity,
    autoPalletExchangeDimension,
    tableDimensionX,
    tableDimensionY,
    tableDimensionDiameter,
    spindleTableDistance,
    spindleTableDistanceMin,
    spindleTableDistanceMax,
    spindleTableWeight,
    rotativeTable,
    rotativeTableAngleMinPositioning,
    robot,
    robotNumberOfEletrods,
    robotNumberOfPallets,
    robotNumberOfAxis,
    robotReach,
    tankDimensionLength,
    tankDimensionWidth,
    tankDimensionHeight,
    tankSubmersible,
    tankSubmersibleAuto,
    portableSteeringWheel,
    partMaxHeight,
    dielectricQuantity,
    hasCorePullers,
    corePullersSideMobile,
    corePullersSideFixed,
    corePullersQuantity,
    hasPneumaticValves,
    pneumaticValvesSideMobile,
    pneumaticValvesSideFixed,
    pneumaticValvesQuantity,
    hasControlValveGate,
    controlValveGateQuantity,
    hasMoldTemperatureControl,
    moldTemperatureControlZones,
    maxHydricPression,
    heatingPower,
    oilTankCapacity,
    motorPump,
    motorPower,
    tempControlZones,
    magneticTableDimensionLength,
    magneticTableDimensionWidth,
    magneticTableWeight,
    rapidAxisVelocityX,
    rapidAxisVelocityY,
    rapidAxisVelocityZ,
    programmableVelocityAxisX,
    programmableVelocityAxisY,
    programmableVelocityAxisZ,
    rapidAxisVelocityC,
    programmableVelocityAxisC,
    rapidAxisVelocityB,
    programmableVelocityAxisB,
    transmissionMotor4Axis,
    transmissionMotor5Axis,
    cutMaxVelocity,
    cutMaxAngle,
    cutMaxAngleByLength,
    turningDiameter,
    turningLength,
    detailsRPM,
    detailsPotence,
    detailsTorque,
    detailsConeType,
    headtype,
    guidesWidth,
    RPMAxisC,
    maximumFeedrate,
    connectionPower,
  } = properties.machine;

  return (
    <>
      {(type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.TORNO ||
        type === MachineTypes.TORNOMULTITASKS ||
        type === MachineTypes.BORING ||
        type === MachineTypes.EDM ||
        type === MachineTypes.EDM_WIRE ||
        type === MachineTypes.EDM_HOLE ||
        type === MachineTypes.SWISS_LATHE ||
        type === MachineTypes.FLAT_GRINDING
      ) && (
        courseX || 
        courseY ||
        courseZ ||
        course4Axis ||
        course4AxisMM ||
        course5Axis ||
        axisType ||
        positioning4Axis ||
        positioning5Axis
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Curso</Typography>
              </Box>

              <Grid container spacing={2}>
                {courseX ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Curso X</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{courseX} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {courseY && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Curso Y</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{courseY} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {courseZ ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Curso Z</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{courseZ} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {course4Axis && (
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM_WIRE ||
                  type === MachineTypes.EDM_HOLE
                ) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>4º Eixo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{course4Axis} graus</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {course4AxisMM && type === MachineTypes.BORING && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>4º Eixo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{course4AxisMM} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {course5Axis && (
                  type === MachineTypes.T5X ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM_WIRE
                ) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>5º Eixo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{course5Axis} graus</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {axisType && (type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de Eixo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{
                        axisType === 'positioned'
                        ? 'Posicionado'
                        : axisType === 'continuous'
                          ? 'Contínuo'
                          : ''
                      }</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {positioning4Axis && (type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL) && axisType === 'positioned' && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Resolução Posicionamento 4º Eixo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{positioning4Axis}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    {positioning5Axis && type === MachineTypes.T5X && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Resolução Posicionamento 5º Eixo</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{positioning5Axis}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type !== MachineTypes.EDM_HOLE && type !== MachineTypes.SWISS_LATHE && type !== MachineTypes.FLAT_GRINDING && (
        rapidAxisVelocityX ||
        rapidAxisVelocityY ||
        rapidAxisVelocityZ
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Velocidade Rápido</Typography>
              </Box>

              <Grid container spacing={2}>
                {rapidAxisVelocityX ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Eixo X</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{rapidAxisVelocityX} m/min</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {rapidAxisVelocityY && (
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM ||
                  type === MachineTypes.EDM_WIRE
                ) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Eixo Y</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{rapidAxisVelocityY} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {rapidAxisVelocityZ ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Eixo Z</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{rapidAxisVelocityZ} m/min</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.BORING ||
        type === MachineTypes.EDM ||
        type === MachineTypes.EDM_WIRE
      ) && (
        programmableVelocityAxisX ||
        programmableVelocityAxisY ||
        programmableVelocityAxisZ ||
        rapidAxisVelocityC ||
        programmableVelocityAxisC ||
        rapidAxisVelocityB ||
        programmableVelocityAxisB ||
        transmissionMotor4Axis ||
        transmissionMotor5Axis ||
        cutMaxVelocity ||
        cutMaxAngle ||
        cutMaxAngleByLength
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Velocidade Programável</Typography>
              </Box>

              <Grid container spacing={2}>
                {type !== MachineTypes.EDM_WIRE && (
                  <>
                    {programmableVelocityAxisX ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Eixo X</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{programmableVelocityAxisX} m/min</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {programmableVelocityAxisY ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Eixo Y</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{programmableVelocityAxisY} m/min</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {programmableVelocityAxisZ ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Eixo Z</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{programmableVelocityAxisZ} m/min</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
                {(
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM_WIRE
                ) && (
                  <>
                    {rapidAxisVelocityC ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Velocidade Rápido - Eixo C</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{rapidAxisVelocityC} graus/s</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {type !== MachineTypes.EDM_WIRE && programmableVelocityAxisC ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Velocidade Programável - Eixo C</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{programmableVelocityAxisC} graus/s</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {(
                      type === MachineTypes.T5X ||
                      type === MachineTypes.BORING ||
                      type === MachineTypes.EDM_WIRE
                    ) && (
                      <>
                        {rapidAxisVelocityB ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Velocidade Rápido - Eixo B</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{rapidAxisVelocityB} graus/s</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {type !== MachineTypes.EDM_WIRE && programmableVelocityAxisB ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Velocidade Programável - Eixo B</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{programmableVelocityAxisB} graus/s</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {type === MachineTypes.T5X && transmissionMotor4Axis ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo Transmissão Motor - 4º eixo</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{(motorTransmissionTypes.find((m) => m.id === transmissionMotor4Axis) || {}).name}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {type === MachineTypes.T5X && transmissionMotor5Axis ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo Transmissão Motor - 5º eixo</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{(motorTransmissionTypes.find((m) => m.id === transmissionMotor5Axis) || {}).name}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                {type === MachineTypes.EDM_WIRE && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Velocidade Corte Máx.</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{cutMaxVelocity} mm/Min</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Angulo Máx. de Corte</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{cutMaxAngle} deg</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Angulo Máx. de Corte em quantos mm</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{cutMaxAngleByLength} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type !== MachineTypes.FLAT_GRINDING && (
        turningDiameter ||
        turningLength ||
        detailsRPM ||
        detailsPotence ||
        detailsTorque ||
        detailsConeType ||
        autoPalletExchange ||
        autoPalletExchangeQuantity ||
        autoPalletExchangeDimension ||
        headtype ||
        guidesWidth ||
        RPMAxisC ||
        maximumFeedrate ||
        connectionPower ||
        robot !== null ||
        robotNumberOfEletrods ||
        robotNumberOfPallets ||
        robotNumberOfAxis ||
        robotReach ||
        tankDimensionLength ||
        tankDimensionWidth ||
        tankDimensionHeight ||
        tankSubmersible ||
        tankSubmersibleAuto ||
        portableSteeringWheel !== null ||
        partMaxHeight ||
        dielectricQuantity ||
        hasCorePullers ||
        corePullersSideMobile !== null ||
        corePullersSideFixed !== null ||
        corePullersQuantity ||
        hasPneumaticValves ||
        pneumaticValvesSideMobile !== null ||
        pneumaticValvesSideFixed !== null ||
        pneumaticValvesQuantity ||
        hasControlValveGate ||
        controlValveGateQuantity ||
        hasMoldTemperatureControl ||
        moldTemperatureControlZones ||
        maxHydricPression ||
        heatingPower ||
        oilTankCapacity ||
        motorPump ||
        motorPower ||
        tempControlZones
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Máquina</Typography>
              </Box>

              <Grid container spacing={2}>
                {type === MachineTypes.SWISS_LATHE && (
                  <>
                    {turningDiameter && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Diâmetro de Giro</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{turningDiameter} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {turningDiameter && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Comprimento de Giro</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{turningLength} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {type === MachineTypes.TORNOMULTITASKS && (
                  <>
                    {detailsRPM ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>RPM</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{detailsRPM}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {detailsPotence ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Pontência</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{detailsPotence}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {detailsTorque ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Torque</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{detailsTorque}Nm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {detailsConeType ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Cone</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{(coneTypes.find((c) => c.id === detailsConeType) || {}).name}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
                {(type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.TORNO ||
                  type === MachineTypes.TORNOMULTITASKS
                ) && (
                  <>
                    {headtype ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Cabeça</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{
                            headtype === 'bars'
                            ? 'Barramentos'
                            : headtype === 'guides'
                              ? 'Guias Lineares'
                              : ''
                          }</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {guidesWidth && headtype === 'guides' && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Largura das Guias</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{guidesWidth}mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {RPMAxisC && type === MachineTypes.EDM_HOLE && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>RPM Eixo C</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{RPMAxisC}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {(type === MachineTypes.T3X || type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL || type === MachineTypes.BORING) && (
                  <>
                    {autoPalletExchange !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Troca Automática de Palete</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{autoPalletExchange ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {autoPalletExchange && (
                      <>
                        {autoPalletExchangeQuantity ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantidade</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{autoPalletExchangeQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {autoPalletExchangeDimension ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Dimensão</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{autoPalletExchangeDimension}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                {maximumFeedrate && type === MachineTypes.SWISS_LATHE && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Velocidade de Alimentação</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{maximumFeedrate} mm/Min</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {connectionPower && type === MachineTypes.SWISS_LATHE && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Potência de Ligação</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{connectionPower} Kw</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {(type === MachineTypes.EDM || type === MachineTypes.EDM_WIRE || type === MachineTypes.PLASTIC_INJECTION) && (
                  <>
                    {robot !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem robot</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{robot ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {robot && type !== MachineTypes.PLASTIC_INJECTION && (
                      <>
                        {robotNumberOfEletrods && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantos eletrodos</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{robotNumberOfEletrods}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {robotNumberOfPallets && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantas paletes</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{robotNumberOfPallets}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {robot && type === MachineTypes.PLASTIC_INJECTION && (
                      <>
                        {robotNumberOfAxis && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantos eixos</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{robotNumberOfAxis}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {robotReach && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Alcance</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{robotReach} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {(type === MachineTypes.EDM || type === MachineTypes.EDM_WIRE || type === MachineTypes.EDM_HOLE) && (
                  <>
                    {tankDimensionLength && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Dimensão do tanque (Comprimento)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tankDimensionLength} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {tankDimensionWidth && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Dimensão do tanque (largura)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tankDimensionWidth} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {tankDimensionHeight && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Dimensão do tanque (Altura)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tankDimensionHeight} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {tankSubmersible !== null && type === MachineTypes.EDM_WIRE && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tanque submersível</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tankSubmersible ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {tankSubmersibleAuto !== null && type === MachineTypes.EDM_WIRE && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tanque submersível automático</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tankSubmersibleAuto ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {portableSteeringWheel !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem volante portátil</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{portableSteeringWheel ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {partMaxHeight !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Altura máxima da peça</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{partMaxHeight} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {dielectricQuantity !== null && type === MachineTypes.EDM && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Quantidade de dieletrico</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{dielectricQuantity} l</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {type === MachineTypes.PLASTIC_INJECTION && (
                  <>
                    {hasCorePullers !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem core pullers</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{hasCorePullers ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                        {corePullersSideMobile !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Lado Móvel</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{corePullersSideMobile ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {corePullersSideFixed !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Lado Fixo</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{corePullersSideFixed ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {corePullersQuantity && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantos</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{corePullersQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {hasPneumaticValves !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem valvulas pneumáticas</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{hasPneumaticValves ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                        {pneumaticValvesSideMobile !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Lado Móvel</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{pneumaticValvesSideMobile ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {pneumaticValvesSideFixed !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Lado Fixo</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{pneumaticValvesSideFixed ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {pneumaticValvesQuantity && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantos</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{pneumaticValvesQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {hasControlValveGate !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem control valve gate</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{hasControlValveGate ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                        {controlValveGateQuantity && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantos</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{controlValveGateQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {hasMoldTemperatureControl !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem controlo de temperatura do molde</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{hasMoldTemperatureControl ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                        {hasMoldTemperatureControl && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantas Zonas</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{moldTemperatureControlZones}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {maxHydricPression ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Pressão Máxima do Grupo Hidráulico</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{maxHydricPression} bar</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {heatingPower ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Potência de Aquecimento</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{heatingPower} Kw</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {oilTankCapacity ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Capacidade do tanque de óleo</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{oilTankCapacity} l</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                                        {motorPump ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Motor Pump</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{motorPump} Kw</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {motorPower ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Potência motor</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{motorPower} Kw</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tempControlZones ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Quantidade zonas controlo temperatura</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tempControlZones}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        (type === MachineTypes.HORIZONTAL && !autoPalletExchange) ||
        type === MachineTypes.BORING ||
        type === MachineTypes.EDM ||
        type === MachineTypes.EDM_WIRE ||
        type === MachineTypes.EDM_HOLE
      ) && (
        tableDimensionX ||
        tableDimensionY ||
        tableDimensionDiameter ||
        spindleTableDistance ||
        spindleTableWeight ||
        spindleTableDistanceMin ||
        spindleTableDistanceMax ||
        rotativeTable !== null ||
        rotativeTableAngleMinPositioning
     ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Dimensão da Mesa</Typography>
              </Box>

              <Grid container spacing={2}>
                {(
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  (type === MachineTypes.HORIZONTAL && !autoPalletExchange) ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM ||
                  type === MachineTypes.EDM_WIRE ||
                  type === MachineTypes.EDM_HOLE
                ) && (
                  <>
                    {tableDimensionX ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>X</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tableDimensionX} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tableDimensionY ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Y</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tableDimensionY} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tableDimensionDiameter && type !== MachineTypes.HORIZONTAL && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Diâmetro</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tableDimensionDiameter} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {spindleTableDistance && (type === MachineTypes.T3X || type === MachineTypes.T5X || type === MachineTypes.HORIZONTAL) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Distância Spindle à Mesa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleTableDistance} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {spindleTableDistanceMin && (type === MachineTypes.EDM_WIRE || type === MachineTypes.EDM_HOLE) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Distância Min. Spindle à Mesa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleTableDistanceMin} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {spindleTableDistanceMax && (type === MachineTypes.EDM_WIRE || type === MachineTypes.EDM_HOLE) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Distância Máx. Spindle à Mesa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleTableDistanceMax} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {spindleTableWeight && (
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.EDM ||
                  type === MachineTypes.EDM_WIRE ||
                  type === MachineTypes.EDM_HOLE
                ) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Peso Máx Sobre a Mesa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleTableWeight} Kg</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {type === MachineTypes.BORING && rotativeTable !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Mesa rotativa 360º</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{rotativeTable ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    {rotativeTableAngleMinPositioning && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Ângulo mínimo de posicionamento</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{rotativeTableAngleMinPositioning} deg</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type === MachineTypes.FLAT_GRINDING && (
        magneticTableDimensionLength ||
        magneticTableDimensionWidth ||
        magneticTableWeight
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Dimensão da Mesa Magnética</Typography>
              </Box>

              <Grid container spacing={2}>
                {magneticTableDimensionLength ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>C</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{magneticTableDimensionLength} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {magneticTableDimensionWidth ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>L</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{magneticTableDimensionWidth} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {magneticTableWeight ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Peso Máx Sobre a Mesa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{magneticTableWeight} Kg</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default Details;
