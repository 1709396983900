import { InputAdornment, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { erosionHeadTypes, sawTypes, MachineTypes } from '../sources';

interface IProps {
  type: string;

  erosionHeadAxisC360?: boolean;
  erosionHeadType?: string;
  erosionHeadSubType?: string;
  erosionThreadMin?: number;
  erosionThreadMax?: number;
  erosionThreadingAuto?: boolean;
  erosionRodMin?: number;
  erosionRodMax?: number;
  erosionRodAuto?: boolean;
  sawType?: string;
  sawVelocityVariation?: boolean;
  sawVelocityVariationIMin?: number;
  sawVelocityVariationIMax?: number;
  sawAutoSupply?: boolean;
  sawCutDimensionsMinDiameter?: number;
  sawCutDimensionsMinRectangle?: number;
  sawCutDimensionsMaxDiameter?: number;
  sawCutDimensionsMaxRectangle?: number;
  sawCutMinLength?: number;
  sawCutMaxLength?: number;
  sawAngleCut?: boolean;
  sawAngleCutMin?: number;
  sawAngleCutMax?: number;
  sawTapeDimensionsC?: number;
  sawTapeDimensionsL?: number;
  sawTapeDimensionsA?: number;
  sawDimensions?: number;
  sawWeight?: number;

  handleChange: (name: string, value: any) => void;
}

const FormErosionHead: FunctionComponent <IProps> = (properties) => {
  const {
    type,
  
    erosionHeadAxisC360,
    erosionHeadType,
    erosionHeadSubType,
    erosionThreadMin,
    erosionThreadMax,
    erosionThreadingAuto,
    erosionRodMin,
    erosionRodMax,
    erosionRodAuto,
    sawType,
    sawVelocityVariation,
    sawVelocityVariationIMin,
    sawVelocityVariationIMax,
    sawAutoSupply,
    sawCutDimensionsMinDiameter,
    sawCutDimensionsMinRectangle,
    sawCutDimensionsMaxDiameter,
    sawCutDimensionsMaxRectangle,
    sawCutMinLength,
    sawCutMaxLength,
    sawAngleCut,
    sawAngleCutMin,
    sawAngleCutMax,
    sawTapeDimensionsC,
    sawTapeDimensionsL,
    sawTapeDimensionsA,
    sawDimensions,
    sawWeight,

    handleChange,
  } = properties;

  return (
    <>
      {type === MachineTypes.EDM && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Cabeça de Erosão</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={erosionHeadAxisC360 || false} />}
                        label="Tem eixo C 360º"
                        onChange={(event: any) => handleChange('erosionHeadAxisC360', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="machine-erosionheadtype">Tipo de cabeça</InputLabel>
                    <Select
                      labelId="machine-erosionheadtype"
                      value={erosionHeadType || ''}
                      onChange={(event: any) => handleChange('erosionHeadType', event.target.value)}
                    >
                      <MenuItem value="">Nenhum</MenuItem>
                      {erosionHeadTypes.map((eh) => (<MenuItem value={eh.id}>{eh.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                {erosionHeadSubType && erosionHeadSubType !== 'manual' && (
                  <Grid item sm={6}>
                    <FormControl margin="dense" fullWidth>
                      <InputLabel id="machine-erosionheadsubtype">Tipo de sub cabeça</InputLabel>
                      <Select
                        labelId="machine-erosionheadsubtype"
                        value={erosionHeadSubType || ''}
                        onChange={(event: any) => handleChange('erosionHeadSubType', event.target.value)}
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        <MenuItem value="manual">Manual</MenuItem>
                        <MenuItem value="auto">Automático</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      {type === MachineTypes.EDM_WIRE && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Fio de Erosão</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <FormControl fullWidth margin="dense">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={erosionThreadingAuto || false} />}
                        label="Enfiamento automático"
                        onChange={(event: any) => handleChange('erosionThreadingAuto', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Diâmetro Min."
                      value={erosionThreadMin || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(event: any) => handleChange('erosionThreadMin', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Diâmetro Máx."
                      value={erosionThreadMax || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(event: any) => handleChange('erosionThreadMax', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      {type === MachineTypes.EDM_HOLE && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Vareta de Erosão</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <FormControl fullWidth margin="dense">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={erosionRodAuto || false} />}
                        label="Troca de vareatas automática"
                        onChange={(event: any) => handleChange('erosionRodAuto', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Diâmetro Min."
                      value={erosionRodMin || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(event: any) => handleChange('erosionRodMin', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Diâmetro Máx."
                      value={erosionRodMax || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(event: any) => handleChange('erosionRodMax', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      {type === MachineTypes.BANDSAW && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Serrote</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="machine-sawtype">Tipo de serrote</InputLabel>
                    <Select
                      labelId="machine-sawtype"
                      value={sawType || ''}
                      onChange={(event: any) => handleChange('sawType', event.target.value)}
                    >
                      <MenuItem value="">Nenhum</MenuItem>
                      {sawTypes.map((s) => (<MenuItem value={s.id}>{s.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth margin="dense">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={sawAutoSupply || false} />}
                        label="Alimentação automática"
                        onChange={(event: any) => handleChange('sawAutoSupply', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid container />
                <Grid item sm={4}>
                  <FormControl fullWidth margin="dense">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={sawVelocityVariation || false} />}
                        label="Variação de velcidade de corte"
                        onChange={(event: any) => handleChange('sawVelocityVariation', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {sawVelocityVariation && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Min"
                          value={sawVelocityVariationIMin || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                          onChange={(event: any) => handleChange('sawVelocityVariationIMin', event.target.value)}
                          helperText="Interval"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Min"
                          value={sawVelocityVariationIMax || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">m/min</InputAdornment> }}
                          onChange={(event: any) => handleChange('sawVelocityVariationIMax', event.target.value)}
                          helperText="Interval"
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid container />
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Diâmetro"
                      value={sawCutDimensionsMinDiameter || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawCutDimensionsMinDiameter', event.target.value)}
                      helperText="Dimensões Mín de Corte"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Rectangulo"
                      value={sawCutDimensionsMinRectangle || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawCutDimensionsMinRectangle', event.target.value)}
                      helperText="Dimensões Mín de Corte"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Diâmetro"
                      value={sawCutDimensionsMaxDiameter || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawCutDimensionsMaxDiameter', event.target.value)}
                      helperText="Dimensões Máx de Corte"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Rectangulo"
                      value={sawCutDimensionsMaxRectangle || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawCutDimensionsMaxRectangle', event.target.value)}
                      helperText="Dimensões Máx de Corte"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Comprimento Mín de Corte"
                      value={sawCutMinLength || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawCutMinLength', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Comprimento Máx de Corte"
                      value={sawCutMaxLength || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawCutMaxLength', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid container />
                <Grid item sm={4}>
                  <FormControl fullWidth margin="dense">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={sawAngleCut || false} />}
                        label="Corta em ângulo"
                        onChange={(event: any) => handleChange('sawAngleCut', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {sawAngleCut && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Angulo Mín."
                          value={sawAngleCutMin || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                          onChange={(event: any) => handleChange('sawAngleCutMin', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Angulo Máx."
                          value={sawAngleCutMax || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                          onChange={(event: any) => handleChange('sawAngleCutMax', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid container />
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="C"
                      value={sawTapeDimensionsC || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawTapeDimensionsC', event.target.value)}
                      helperText="Dimensões da Fita"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="L"
                      value={sawTapeDimensionsL || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawTapeDimensionsL', event.target.value)}
                      helperText="Dimensões da Fita"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="A"
                      value={sawTapeDimensionsA || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawTapeDimensionsA', event.target.value)}
                      helperText="Dimensões da Fita"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Dimensões do Serrote"
                      value={sawDimensions || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawDimensions', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Peso do Serrote"
                      value={sawWeight || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                      onChange={(event: any) => handleChange('sawWeight', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default FormErosionHead;
