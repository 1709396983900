import {
  CreateSupplier,
  EditSupplier,
  ToggleSupplier,
  CreateSupplierRep,
  EditSupplierRep,
  DeleteSupplierRep
} from '../actions/suppliers';
import Supplier from '../models/Supplier';
import axios from './axios';

export const fetchSuppliers = async (): Promise<Supplier[]> => {
  const { data } = await axios.get('/suppliers');

  return data;
};

export const createSupplier = async (properties: CreateSupplier) => {
  const { data } = await axios.post('/suppliers', properties);

  return data;
}

export const editSupplier = async (properties: EditSupplier) => {
  const { data } = await axios.put('/suppliers', properties);

  return data;
}

export const toggleSupplier = async (properties: ToggleSupplier) => {
  const { data } = await axios.put('/suppliers/active', properties);

  return data;
}

export const createSupplierRep = async (properties: CreateSupplierRep) => {
  const { data } = await axios.post('/suppliers/rep', properties);

  return data;
}

export const editSupplierRep = async (properties: EditSupplierRep) => {
  const { data } = await axios.put('/suppliers/rep', properties);

  return data;
}

export const deleteSupplierRep = async (properties: DeleteSupplierRep) => {
  return await axios.delete(`/suppliers/rep/id/${properties.id}`);
}