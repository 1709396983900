import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createMachineTicketTaskSign, CreateMachineTicketTaskSign, closeSign } from '../../../../../../actions/machines';
import Machine from '../../../../../../models/Machine';
import User from '../../../../../../models/User';
import { IAppState } from '../../../../../../reducers/index';
import SignModal from './SignModal';

const mapStateToProps = ({ authentication, machines }: IAppState) => ({
  ticketId: machines.tickets.signForm.ticketId || 0,
  ticket: machines.tickets.tickets[machines.tickets.signForm.ticketId || 0],
  taskId: machines.tickets.signForm.taskId,
  loading: machines.tickets.signForm.loading,
  machine: machines.machines[(machines.tickets.tickets[machines.tickets.signForm.ticketId || 0] || {}).machineId || 0] || ({} as Machine),
  user: authentication.user || ({} as User),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    closeSign: () => dispatch(closeSign()),
    createMachineTicketTaskSign: (properties: CreateMachineTicketTaskSign) =>
      dispatch(createMachineTicketTaskSign.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignModal);