import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Machine from '../../../models/Machine';
import { commandTypes, MachineTypes } from '../../Dashboard/Machines/sources';

interface IProps {
  machine: Machine;
}

const Info: FunctionComponent <IProps> = (properties) => {
  const { machine } = properties;
  const {
    type,

    description,
    brand,
    model,
    serialnumber,
    commandType,
    commandBrand,
    commandModel,
  } = machine;

  return (
    <>
      {description || brand || model || serialnumber ? (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Informação Geral</Typography>
              </Box>

              <Grid container spacing={2}>
                {type === MachineTypes.FLAT_GRINDING && commandType ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de Comando</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(commandTypes.find((ct) => ct.id === commandType) || {}).name}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {(type !== MachineTypes.FLAT_GRINDING || commandType === 'cnc') && (
                  <>
                    {brand && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Comando (Marca)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{brand}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {model && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Comando (Modelo)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{model}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {serialnumber && (
                  <>
                    <Grid item sm={6}>
                      <Grid container spacing={2}>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Serial Number</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{serialnumber}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
               {description && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Descrição</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{description}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      {commandType || commandBrand || commandModel ? (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Comando CNC</Typography>
              </Box>

              <Grid container spacing={2}>
                {type === MachineTypes.FLAT_GRINDING && commandType ? (
                  <Grid item sm={12}>
                    <Grid container spacing={2}>
                      <Grid item sm={6}>
                        <Typography variant="body2">
                          <b>Tipo de Comando</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography variant="body2">{(commandTypes.find((ct) => ct.id === commandType) || {}).name}</Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {type !== MachineTypes.FLAT_GRINDING || commandType === 'cnc' ? (
                  <>
                    <Grid item sm={6}>
                      <Grid container spacing={2}>
                        {commandBrand ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Comando CNC - Marca</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{commandBrand}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item sm={6}>
                      <Grid container spacing={2}>
                        {commandModel ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Comando CNC - Modelo</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{commandModel}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </>
  );
}

export default Info;
