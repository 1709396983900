
import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { FunctionComponent } from 'react';

interface IProps extends ButtonProps {
  component?: any;
  target?: string;
  to?: string;
  center?: boolean;
}

const CustomButton: FunctionComponent<IProps> = (properties) => {
  const { children, center, color } = properties;

  return (
    <Button
      {...properties}
      style={{
        backgroundColor: color === 'default' ? '#b6c3ce' : undefined,
        color: color === 'default' ? '#ffffff' : undefined,
        justifyContent: !center ? 'flex-start' : undefined,
        ...properties.style,
      }}
    >{children}</Button>
  );
};

export default CustomButton;