import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { openMarketplaceForm, fetchDynamicFieldValuesByForm } from '../../../actions/index';
import { IAppState } from '../../../reducers/index';
import Machine from './Machine';

const mapStateToProps = ({ machines, tables }: IAppState) => ({
  error: machines.marketplace.error,
  machines: machines.marketplace.machines || [],
  loading: machines.marketplace.loading || (tables.dynamicFields.fetch.machineStatus || {}).loading,
  machineStatus: (tables.dynamicFields.fetch.machineStatus || {}).values || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMachineStatus: () =>
      dispatch(fetchDynamicFieldValuesByForm.request({ id: 'machineStatus' })),
    openForm: (machineId: number) => dispatch(openMarketplaceForm({ machineId })),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Machine);