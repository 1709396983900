import { ActionType, getType } from 'typesafe-actions';

import * as customers from '../actions/customers';
import Customer from '../models/Customer';
import Proposal from '../models/Proposal';

type CustomerAction = ActionType<typeof customers>;

export interface IFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectReady: boolean;
  readonly redirectCustomerId?: number;
}

export interface IProposalFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectReady: boolean;
}

interface IProposalViewState {
  proposalId?: number;
}
interface ICustomersProposalsState {
  readonly proposals: Record<Proposal['id'], Proposal>;
  readonly form: IProposalFormState;
  readonly error?: object;
  readonly loading: boolean;
  readonly view: IProposalViewState;
}

interface IViewState {
  customerId?: number;
}
interface IRepsState {
  customerId?: number;
  loading: boolean;
  error?: object;
  redirectReady: boolean;
}

export interface ICustomersState {
  readonly proposals: ICustomersProposalsState;
  readonly customers: Record<Customer['id'], Customer>;
  readonly form: IFormState;
  readonly error?: object;
  readonly loading: boolean;
  readonly view: IViewState;
  readonly repsView: IRepsState;
}


export const initialState: ICustomersState = {
  loading: false,
  customers:{},
  form: { loading: false, redirectReady: false },
  view: {},
  repsView: {
    loading: false,
    redirectReady: false,
  },
  proposals: {
    proposals: {},
    loading: false,
    form: { loading: false, redirectReady: false },
    view: {},
  }
};

const reducer = (
  state: ICustomersState = initialState,
  action: CustomerAction
): ICustomersState => {
  switch (action.type) {
    case getType(customers.fetchCustomers.request):
      return {
        ...state,
        loading: true,
      };

    case getType(customers.fetchCustomers.success): {
      const { customers } = action.payload;

      const customersObj: Record<Customer['id'], Customer> = {};
      customers.forEach((customer) => {
        customersObj[customer.id] = customer;
      });

      return {
        ...state,
        customers: customersObj,
        error: undefined,
        loading: false,
      };
    }

    case getType(customers.fetchCustomers.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(customers.createCustomer.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(customers.createCustomer.success): {
      const { customer } = action.payload;

      return {
        ...state,
        customers: {
          ...state.customers,
          [customer.id]: customer,
        },
        form: {
          ...state.form,
          redirectReady: true,
          redirectCustomerId: customer.id,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(customers.createCustomer.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(customers.editCustomer.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          loading: true,
        },
      };

    case getType(customers.editCustomer.success): {
      const { customer } = action.payload;

      return {
        ...state,
        customers: {
          ...state.customers,
          [customer.id]: {
            ...(state.customers[customer.id] || {}),
            ...customer,
          },
        },
        form: {
          ...state.form,
          redirectReady: true,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(customers.editCustomer.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(customers.toggleCustomer.request):
      return {
        ...state,
        loading: true,
      };

    case getType(customers.toggleCustomer.success): {
      const { customerId, active } = action.payload;

      return {
        ...state,
        customers: {
          ...state.customers,
          [customerId]: {
            ...(state.customers[customerId] || {}),
            active,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(customers.toggleCustomer.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(customers.toggleCustomerView):
      return {
        ...state,
        view: {
          customerId: action.payload,
        },
      };

    case getType(customers.toggleCustomerRepsView):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          customerId: action.payload,
        },
      };

    case getType(customers.createCustomerRep.request):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          loading: true,
          redirectReady: false,
        }
      };

    case getType(customers.createCustomerRep.success): {
      const { rep } = action.payload;
      const customer = state.customers[rep.customerId] || {};
      const { reps = [] } = customer;

      reps.push(rep);

      return {
        ...state,
        customers: {
          ...state.customers,
          [rep.customerId]: {
            ...customer,
            reps,
          }
        },
        repsView: {
          ...state.repsView,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(customers.createCustomerRep.failure): {
      const { error } = action.payload;

      return {
        ...state,
        repsView: {
          ...state.repsView,
          error,
          loading: false,
        },
      };
    }
  
    case getType(customers.editCustomerRep.request):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          loading: true,
          redirectReady: false,
        }
      };

    case getType(customers.editCustomerRep.success): {
      const { rep } = action.payload;
      const customer = state.customers[rep.customerId] || {};
      const { reps = [] } = customer;

      const index = reps.findIndex((r) => r.id === rep.id);
      reps[index] = rep;

      return {
        ...state,
        customers: {
          ...state.customers,
          [rep.customerId]: {
            ...customer,
            reps,
          }
        },
        repsView: {
          ...state.repsView,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(customers.editCustomerRep.failure): {
      const { error } = action.payload;

      return {
        ...state,
        repsView: {
          ...state.repsView,
          error,
          loading: false,
        },
      };
    }
  
    case getType(customers.deleteCustomerRep.request):
      return {
        ...state,
        repsView: {
          ...state.repsView,
          loading: true,
        }
      };

    case getType(customers.deleteCustomerRep.success): {
      const { id, customerId } = action.payload;
      const customer = state.customers[customerId] || {};
      const { reps = [] } = customer;

      const index = reps.findIndex((r) => r.id === id);
      reps.splice(index, 1);

      return {
        ...state,
        customers: {
          ...state.customers,
          [customerId]: {
            ...customer,
            reps,
          }
        },
        repsView: {
          ...state.repsView,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(customers.deleteCustomerRep.failure): {
      const { error } = action.payload;

      return {
        ...state,
        repsView: {
          ...state.repsView,
          error,
          loading: false,
        },
      };
    }
  
    case getType(customers.fetchCustomerProposals.request):
      return {
        ...state,
        proposals: {
          ...state.proposals,
          loading: true,
        }
      };

    case getType(customers.fetchCustomerProposals.success): {
      const { proposals } = action.payload;

      const proposalsObj: Record<Proposal['id'], Proposal> = {};
      proposals.forEach((proposal) => {
        proposalsObj[proposal.id] = proposal;
      });

      return {
        ...state,
        proposals: {
          ...state.proposals,
          proposals: proposalsObj,
          error: undefined,
          loading: false,
        }
      };
    }

    case getType(customers.fetchCustomerProposals.failure): {
      const { error } = action.payload;

      return {
        ...state,
        proposals: {
          ...state.proposals,
          error,
          loading: false,
        }
      };
    }

    case getType(customers.createCustomerProposal.request):
      return {
        ...state,
        proposals: {
          ...state.proposals,
          form: {
            ...state.proposals.form,
            redirectReady: false,
            loading: true,
          },
        },
      };

    case getType(customers.createCustomerProposal.success): {
      const { proposal } = action.payload;

      return {
        ...state,
        proposals: {
          ...state.proposals,
          proposals: {
            ...state.proposals.proposals,
            [proposal.id]: proposal,
          },
          form: {
            ...state.proposals.form,
            redirectReady: true,
            error: undefined,
            loading: false,
          },  
        },
      };
    }

    case getType(customers.createCustomerProposal.failure): {
      const { error } = action.payload;

      return {
        ...state,
        proposals: {
          ...state.proposals,
          form: {
            ...state.proposals.form,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(customers.editCustomerProposal.request):
      return {
        ...state,
        proposals: {
          ...state.proposals,
          form: {
            ...state.proposals.form,
            redirectReady: false,
            loading: true,
          },
        },
      };

    case getType(customers.editCustomerProposal.success): {
      const { proposal } = action.payload;

      return {
        ...state,
        proposals: {
          ...state.proposals,
          proposals: {
            ...state.proposals.proposals,
            [proposal.id]: {
              ...(state.proposals.proposals[proposal.id] || {}),
              ...proposal,
            },
          },
          form: {
            ...state.proposals.form,
            redirectReady: true,
            error: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(customers.editCustomerProposal.failure): {
      const { error } = action.payload;

      return {
        ...state,
        proposals: {
          ...state.proposals,
          form: {
            ...state.proposals.form,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(customers.toggleProposalView):
      return {
        ...state,
        proposals: {
          ...state.proposals,
          view: {
            proposalId: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;