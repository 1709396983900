import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '../common/Button';
import BlackCard from '../common/BlackCard';
import './../Auth/Auth.css';

interface IProps {
  loading: boolean;
  recoverPassword: (email: string) => void;
  redirectReady: boolean;
  error?: object;
}

interface IState {
  email: string;
  submitted: boolean;
  success: boolean;
}

class PasswordRecovery extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      submitted: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissSuccess = this.dismissSuccess.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { redirectReady } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ success: true, submitted: false, email: '' });
    }
  }

  private handleKeyPress(e: any) {
    if (e.which === 13) {
      this.handleSubmit();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'email'>);
    };
  }

  public hasErrors() {
    const { email } = this.state;

    if (!email.length) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { email } = this.state;
    const { recoverPassword } = this.props;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    recoverPassword(email);
  }

  public dismissSuccess() {
    this.setState({ success: false });
  }

  public render() {
    const { email, submitted, success } = this.state;
    const { loading } = this.props;

    return (
      <>
        <Box mt={10}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item sm={3}>
              <Box className="auth-logo" mb={4}>
                <Link to="/authenticate">
                  <img alt="GoTec" src="/logo.png" />
                </Link>
              </Box>
              <BlackCard>
                <CardHeader title={
                  <Typography variant="h6" component="h5">Recuperar palavra-passe</Typography>
                } />
                <CardContent>
                  <FormControl
                    required
                    fullWidth
                    margin="normal"
                  >
                    
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      onChange={this.handleChange('email')}
                      onKeyPress={this.handleKeyPress}
                      value={email}
                      variant="standard"
                      required
                      helperText={submitted && !email.length ? 'Deve introduzir o email' : ''}
                    />
                  </FormControl>

                  <FormControl
                    required
                    fullWidth
                    margin="normal"
                  >
                    <Box mt={4}>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="secondary"
                        component="button"
                        fullWidth
                        center
                      >{loading ? <CircularProgress size={24} /> : 'Recuperar Password'}</Button>
                    </Box>
                  </FormControl>
                </CardContent>
              </BlackCard>
            </Grid>
          </Grid>
        </Box>
        {success === true ? <Snackbar open={true} onClose={this.dismissSuccess}>
          <Alert onClose={this.dismissSuccess} severity="success">Verifique o seu email.</Alert>
        </Snackbar> : null}
      </>
    );
  }
}

export default PasswordRecovery;
