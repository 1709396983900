import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleSupplierView, toggleSupplierRepsView, toggleSupplier } from '../../../../actions';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import List from './List';

const mapStateToProps = ({ authentication, suppliers }: IAppState) => ({
  loading: suppliers.loading,
  suppliers: Object.values(suppliers.suppliers),
  view: suppliers.view.supplierId,
  repsView: suppliers.repsView.supplierId,
  user: authentication.user || ({} as User),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleView: (supplierId?: number) =>
      dispatch(toggleSupplierView(supplierId)),
    toggleSupplier: (supplierId: number, active: boolean) =>
      dispatch(toggleSupplier.request({ supplierId, active })),
    toggleRepsView: (customerId?: number) =>
      dispatch(toggleSupplierRepsView(customerId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
