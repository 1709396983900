import { createAction } from 'typesafe-actions';

enum Types {
  DISMISS_ERROR = '@@application/DISMISS_ERROR',
}

interface IDismissError {
  index: number;
}

export const dismissError = createAction(
  Types.DISMISS_ERROR
)<IDismissError>();