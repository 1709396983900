import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchUsers,
  fetchDashboardTasks,
  fetchDashboardMetrics,
  fetchMachines,
  fetchMachinesTickets,
  fetchZonesTable,
  IFetchDashboardMetrics,
  IFetchDashboardTasks,
  openMachineTicketTask,
  toggleMachineView,
} from '../../../actions';
import Task from '../../../models/Task';
import User from '../../../models/User';
import { IAppState } from '../../../reducers/index';
import DashboardContent from './DashboardContent';

const mapStateToProps = ({ authentication, users, machines, dashboard, tables }: IAppState) => ({
  users: Object.values(users.users),
  loading: users.loading || machines.dashboard.loading || machines.tickets.loading || dashboard.loading || tables.zones.loading,
  tickets: Object.values(machines.tickets.tickets),
  zones: Object.values(tables.zones.zones),
  taskModal: machines.tickets.taskModal,
  tasks: machines.dashboard.tasks || [],
  metrics: dashboard.metrics,
  activeUser: authentication.user || ({} as User),
  openSignModal: machines.tickets.signForm.open,
  viewMachine: machines.view.machineId,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchUsers: () =>
      dispatch(fetchUsers.request()),
    fetchZonesTable: () => dispatch(fetchZonesTable.request()),
    fetchDashboardTasks: (properties: IFetchDashboardTasks) =>
      dispatch(fetchDashboardTasks.request(properties)),
    fetchMachines: () =>
      dispatch(fetchMachines.request()),
    fetchMachinesTickets: () =>
      dispatch(fetchMachinesTickets.request()),
    fetchDashboardMetrics: (properties: IFetchDashboardMetrics) =>
      dispatch(fetchDashboardMetrics.request(properties)),
    openMachineTicketTask: (task: Task) => dispatch(openMachineTicketTask(task)),
    toggleMachineView: (machineId?: number) =>
      dispatch(toggleMachineView(machineId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContent);
