import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Machine from '../../../../models/Machine';

interface IProps {
  machine: Machine;
}

const Generator: FunctionComponent <IProps> = (properties) => {
  const {
    generatorHasRefrigerator,
    generatorAMP,
  } = properties.machine;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Gerador</Typography>
            </Box>

            <Grid container spacing={2}>
              {generatorHasRefrigerator !== null? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Tem refrigerador</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{generatorHasRefrigerator ? 'Sim' : 'Não'}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {generatorAMP && (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Quantos Amperes</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{generatorAMP} AMP</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default Generator;
