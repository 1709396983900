import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const TableButton = withStyles({
  root: {
    borderRadius: '0',
    backgroundColor: '#eef1f5',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '2px',
    '& svg': {
      fontSize: 12
    },
    minWidth: '36px',
    ':disabled': {
      color: '#b6c3ce',
    },
    '&:hover': {
      backgroundColor: '#c7cbd1',
    }
  },
})(IconButton);

export default TableButton;