import { IFetchDashboardMetrics } from '../actions';
import DashboardMetrics from '../models/DashboardMetrics';
import axios from './axios';

export const fetchDashboardMetrics = async (properties: IFetchDashboardMetrics): Promise<DashboardMetrics> => {
  const { data } = await axios.get(
    `/dashboard/metrics/${properties.year}`
    + (properties.technicianId ? '/technicianid/' + properties.technicianId : '')
    + (properties.commercialId ? '/commercialid/' + properties.commercialId : '')
  );

  return data;
};