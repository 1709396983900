import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchDynamicFieldValuesByForm, toggleSupplierView } from '../../../../actions';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import View from './ViewModal';

const mapStateToProps = ({ authentication, suppliers, tables }: IAppState) => ({
  loading: (tables.dynamicFields.fetch.supplierTypes || {}).loading,
  supplier: suppliers.suppliers[suppliers.view.supplierId || 0] || {},
  user: authentication.user || ({} as User),
  supplierTypes: (tables.dynamicFields.fetch.supplierTypes || {}).values || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleSupplierView: (supplierId?: number) =>
      dispatch(toggleSupplierView(supplierId)),
    fetchSupplierTypes: () =>
      dispatch(fetchDynamicFieldValuesByForm.request({ id: 'supplierTypes' })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);