import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { coneTypes, coolingType, MachineTypes, spindleNoseTypes, spindleTypes } from '../sources';
import Machine from '../../../../models/Machine';

interface IProps {
  machine: Machine;
}

const Fuses: FunctionComponent <IProps> = (properties) => {
  const {
    type,

    highPressureInteriorRefrigeration,
    highPressureInteriorRefrigerationBar,
    wasteExtractor,
    wasteExtractorType,
    toolMeasurer,
    toolMeasurerType,
    autoCenter,
    autoCenterBrand,
    cabinada,
    partCatcher,
    toolMeasurerArm,
    toolMeasurerArmAutomatic,
    steeringWheelElec,
    tornoDiameterTurnable,
    tornoDiameterBush,
    tornoDiameterCar,
    tornoDiameterBarPass,
    spindleRPM,
    spindleMotorPotence,
    spindleDiameter,
    spindleNoseType,
    spindleLiveTools,
    spindleLiveToolsQuantity,
    spindleLiveToolsRPM,
    torque,
    coneType,
    spindleType,
    spindleElectro,
    spindleElectroBrand,
    spindleElectroCoolerType,
    spindleInterfaceType,
    subSpindle,
    subSpindleDiameterTornable,
    subSpindleDiameterBush,
    subSpindleDiameterCar,
    subSpindleDiameterBar,
    subSpindleNoseType,
    subSpindleLiveTools,
    subSpindleLiveToolsQuantity,
    subSpindleLiveToolsRPM,
    subSpindleRPM,
    subSpindleMoterPower,
    subSpindleInterfaceType,
    subSpindlePartCatcher,
    subSpindleTravelAxisLength,
    contraPonto,
    contraPontoType,
    contraPontoPath,
    contraPontoProgrammablePositioning,
    grindDimensionX,
    grindDimensionY,
    grindDimensionZ,
    grindTableDistance,
    grindMinVerticalAdd,
    grindRotation,
    gridPotence,
    gridRPM,
    gridMotorPotence,
    gridRectifierAuto,
    gridBalanceSystem,
    gridFilteringSystem,
    gridFilteringSystemVacuum,
    gridFilteringSystemPaper,
    gridFilteringSystemMagnetic,
    gridFilteringSystemOther,
    gridFilteringSystemOtherValue,
    gridRectifierMaxDiameter,
    gridCentersHeight,
    gridCentersInternalRetifierDiameterMin,
    gridCentersInternalRetifierDiameterMax,
    gridRetifierAngleMin,
    gridRetifierAngleMax,
    gridMaxPartWeight,
    gridCarCourse,
    gridAutoPassing,
    gridCenterHeadType,
    gridCenterContraPontoType,
    gridCenterContraPontoPath,
    gridBalancerIncluded,

    closeUnitPlateDimensionH,
    closeUnitPlateDimensionV,
    closeUnitColumnDistanceH,
    closeUnitColumnDistanceV,
    closeUnitMoldTicknessMin,
    closeUnitMoldTicknessMax,
    closeUnitMinMoldDimension,
    closeUnitForce,
    openUnitForce,
    closeUnitSupPlateCourse,
    closeUnitSupPlateMove,
    closeUnitMobilePlateCourse,
    closeUnitMaxDistanceBetween,
    closeUnitExtractorQuantity,
    closeUnitExtractorForce,
    closeUnitExtractionCourse,
  } = properties.machine;

  return (
    <>
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.BORING ||
        type === MachineTypes.TORNO ||
        type === MachineTypes.SWISS_LATHE
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Características</Typography>
              </Box>

              <Grid container spacing={2}>
              {highPressureInteriorRefrigeration !== null && (
                type === MachineTypes.T3X ||
                type === MachineTypes.T5X ||
                type === MachineTypes.HORIZONTAL ||
                type === MachineTypes.BORING ||
                type === MachineTypes.SWISS_LATHE
              ) && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Refrigeração Interior de Alta Pressão</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{highPressureInteriorRefrigeration ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    {highPressureInteriorRefrigerationBar && highPressureInteriorRefrigeration && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Bar</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{highPressureInteriorRefrigerationBar}m</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {wasteExtractor ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Extrator de Limalha</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{wasteExtractor ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {(
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.SWISS_LATHE
                ) && (
                  <>
                    {wasteExtractorType && wasteExtractor && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Extrator</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{
                            wasteExtractorType === 'carpet'
                            ? 'Tapete'
                            : wasteExtractorType === 'spindle'
                              ? 'Fuso'
                              : ''
                          }</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {(
                      type === MachineTypes.T3X ||
                      type === MachineTypes.T5X ||
                      type === MachineTypes.HORIZONTAL ||
                      type === MachineTypes.BORING
                    ) && (
                      <>
                        {toolMeasurer != null ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Medidor de Ferramenta</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{toolMeasurer ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {toolMeasurerType && toolMeasurer && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo de Medidor</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{
                                toolMeasurerType === 'contact'
                                  ? 'Contacto'
                                  : toolMeasurerType === 'laser'
                                    ? 'Laser'
                                    : ''
                              }</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {autoCenter !== null ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Palpador de Centragem Automática</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{autoCenter ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {autoCenterBrand && autoCenter && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Marca</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{autoCenterBrand}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {cabinada !== null ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Cabinada Completa</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{cabinada ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                {type === MachineTypes.TORNO && (
                  <>
                    {partCatcher !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Apanhador de peças</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{partCatcher ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {toolMeasurerArm !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Braço medidor de ferramenta</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{toolMeasurerArm ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {toolMeasurerArmAutomatic !== null && toolMeasurerArm && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Medidor</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{
                            toolMeasurerArmAutomatic === 'automatic'
                            ? 'Automático'
                            : toolMeasurerArmAutomatic === 'semi-automatic'
                            ? 'Semi-Automático'
                            : toolMeasurerArmAutomatic === 'manual'
                            ? 'Manual' : toolMeasurerArmAutomatic
                          }</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {(type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.TORNO ||
                  type === MachineTypes.BORING) && steeringWheelElec !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Volante Electrónico</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{steeringWheelElec ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {type === MachineTypes.TORNO && (
                  <>
                    {tornoDiameterTurnable ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Diâmetro Torneável</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tornoDiameterTurnable} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tornoDiameterBush ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Diâmetro Bucha</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tornoDiameterBush} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tornoDiameterCar ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Diâmetro Sobre o Carro</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tornoDiameterCar} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {tornoDiameterBarPass ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Passagem de Barra</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{tornoDiameterBarPass} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.TORNO ||
        type === MachineTypes.TORNOMULTITASKS ||
        type === MachineTypes.BORING ||
        type === MachineTypes.SWISS_LATHE
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Spindle</Typography>
              </Box>

              <Grid container spacing={2}>
                {spindleRPM ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>RPM</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleRPM}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {spindleMotorPotence ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Potência de Motor</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleMotorPotence} kw</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {spindleDiameter && (type === MachineTypes.BORING || type === MachineTypes.SWISS_LATHE) ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Diâmetro do spindle</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{spindleDiameter} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {type === MachineTypes.SWISS_LATHE && (
                  <>
                    {spindleNoseType && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Spindle Nose</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{(spindleNoseTypes.find((s) => s.id === spindleNoseType) || {}).name}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {spindleLiveTools !== null && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Spindle Live tools</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{spindleLiveTools ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {spindleLiveTools && spindleLiveToolsQuantity && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Quantidade</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{spindleLiveToolsQuantity}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {spindleLiveTools && spindleLiveToolsRPM && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>RPM</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{spindleLiveToolsRPM}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {(
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.TORNOMULTITASKS ||
                  type === MachineTypes.BORING
                ) && (
                  <>
                    {torque ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Torque</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{torque} Nm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {coneType ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Cone</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{(coneTypes.find((c) => c.id === coneType) || {}).name}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                    </>
                    ) : null}
                    {spindleType ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Spindle</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{(spindleTypes.find((c) => c.id === spindleType) || {}).name}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {spindleElectro !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Spindle Electromandrino</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{spindleElectro ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {spindleElectroBrand && spindleElectro && (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Marca</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{spindleElectroBrand}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {spindleElectroCoolerType ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Refrigerador Spindle</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{(coolingType.find((c) => c.id === spindleElectroCoolerType) || {}).name}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {spindleInterfaceType ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tipo de Interface</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{spindleInterfaceType}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
                {(type === MachineTypes.TORNO || type === MachineTypes.SWISS_LATHE) && (
                  <>
                    {subSpindle !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Sub Spindle</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{subSpindle ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {subSpindle ? (
                      <>
                        {subSpindleDiameterTornable ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Diâmetro Torneável</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleDiameterTornable} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleDiameterBush ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Diâmetro da Bucha</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleDiameterBush} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleDiameterCar ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Diâmetro Sobre o Carro</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleDiameterCar} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleDiameterBar ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Passagem de Barra</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleDiameterBar} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleNoseType && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo de Sub Spindle Nose</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{(spindleNoseTypes.find((s) => s.id === subSpindleNoseType) || {}).name}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {subSpindleLiveTools !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Sub Spindle Live Tools</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleLiveTools ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {subSpindleLiveTools && subSpindleLiveToolsQuantity && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Quantidade</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleLiveToolsQuantity}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {subSpindleLiveTools && subSpindleLiveToolsRPM && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>RPM</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleLiveToolsRPM}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {subSpindleRPM ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>RPM</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleRPM}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleMoterPower ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Potência Motor</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleMoterPower} kw</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleInterfaceType ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo de Interface</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleInterfaceType}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindlePartCatcher !== null ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Apanhador de Peças no Sub Spindle</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindlePartCatcher ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {subSpindleTravelAxisLength ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Sub Spindle Comprimento do Eixo de Viagem</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{subSpindleTravelAxisLength} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {contraPonto !== null ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Contra Ponto</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{contraPonto ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {contraPonto && (
                          <>
                            {contraPontoType ? (
                              <>
                                <Grid item sm={6}>
                                  <Typography variant="body2">
                                    <b>Tipo de Contraponto</b>
                                  </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                  <Typography variant="body2">{contraPontoType}</Typography>
                                </Grid>
                                <Grid item sm={12}>
                                  <Divider />
                                </Grid>
                              </>
                            ) : null}
                            {contraPontoPath ? (
                              <>
                                <Grid item sm={6}>
                                  <Typography variant="body2">
                                    <b>Curso do Contraponto</b>
                                  </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                  <Typography variant="body2">{contraPontoPath}</Typography>
                                </Grid>
                                <Grid item sm={12}>
                                  <Divider />
                                </Grid>
                              </>
                            ) : null}
                            {contraPontoProgrammablePositioning !== null ? (
                              <>
                                <Grid item sm={6}>
                                  <Typography variant="body2">
                                    <b>Posicionamento Programável</b>
                                  </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                  <Typography variant="body2">{contraPontoProgrammablePositioning ? 'Sim' : 'Não'}</Typography>
                                </Grid>
                                <Grid item sm={12}>
                                  <Divider />
                                </Grid>
                              </>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(type === MachineTypes.FLAT_GRINDING || type === MachineTypes.ROUND_GRINDING) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Mó</Typography>
              </Box>

              <Grid container spacing={2}>
                {grindDimensionX && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão da Mó (X)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{grindDimensionX} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {grindDimensionY && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão da Mó (Y)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{grindDimensionY} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {grindDimensionZ && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão da Mó (Z)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{grindDimensionZ} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {grindTableDistance && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Distância da Mó à Mesa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{grindTableDistance} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {grindMinVerticalAdd && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Incremento Mínimo Vertical</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{grindMinVerticalAdd} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {grindRotation && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Rotação da Mó</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{grindRotation} deg</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {gridPotence && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Potência da Mó</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{gridPotence} Kw</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {gridRPM && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>RMP da Mó</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{gridRPM}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {gridMotorPotence && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Potência do Motor da Mó</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{gridMotorPotence} Kw</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {gridRectifierAuto !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tem retificador de mó automático</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{gridRectifierAuto ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {gridBalanceSystem !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tem sistema de balanceador de mó</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{gridBalanceSystem ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {gridFilteringSystem !== null && (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tem sistema de filtragem</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{gridFilteringSystem ? 'Sim' : 'Não'}</Typography>
                    </Grid>
                    {(
                      gridFilteringSystemVacuum ||
                      gridFilteringSystemPaper ||
                      gridFilteringSystemMagnetic ||
                      gridFilteringSystemOther ||
                      gridFilteringSystemOtherValue
                    ) && (
                      <>
                        <Grid item sm={6} />
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Sistemas:</b>
                          </Typography>
                          <Typography variant="body2">{gridFilteringSystemVacuum ? 'Aspiração' : ''}</Typography>
                          <Typography variant="body2">{gridFilteringSystemPaper ? 'Filtro de Papel' : ''}</Typography>
                          <Typography variant="body2">{gridFilteringSystemMagnetic ? 'Magnético' : ''}</Typography>
                          <Typography variant="body2">{gridFilteringSystemOther ? 'Outro: ' + (gridFilteringSystemOtherValue || '') : ''}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}
                    {type === MachineTypes.ROUND_GRINDING && (
                     <>
                        {gridRectifierMaxDiameter && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Diâmetro máximo de retificação</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridRectifierMaxDiameter} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridCentersHeight && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Altura de Centros</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridCentersHeight} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridCentersInternalRetifierDiameterMin && (
                          <>
                            <Grid item sm={3}>
                              <Typography variant="body2">
                                <b>Diâmetro de Retificação Interna (Min)</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography variant="body2">{gridCentersInternalRetifierDiameterMin} mm</Typography>
                            </Grid>
                          </>
                        )}
                        {gridCentersInternalRetifierDiameterMax && (
                          <>
                            <Grid item sm={3}>
                              <Typography variant="body2">
                                <b>Diâmetro de Retificação Interna (Max)</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography variant="body2">{gridCentersInternalRetifierDiameterMax} mm</Typography>
                            </Grid>
                          </>
                        )}
                        {(gridCentersInternalRetifierDiameterMin || gridCentersInternalRetifierDiameterMax) && (
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        )}
                        {gridRetifierAngleMin && (
                          <>
                            <Grid item sm={3}>
                              <Typography variant="body2">
                                <b>Ângulo de Retificação (Min)</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography variant="body2">{gridRetifierAngleMin} deg</Typography>
                            </Grid>
                          </>
                        )}
                        {gridRetifierAngleMax && (
                          <>
                            <Grid item sm={3}>
                              <Typography variant="body2">
                                <b>Ângulo de Retificação (Max)</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography variant="body2">{gridRetifierAngleMax} deg</Typography>
                            </Grid>
                          </>
                        )}
                        {(gridRetifierAngleMin || gridRetifierAngleMax) && (
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        )}
                        {gridMaxPartWeight && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Peso Máximo da Peça</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridMaxPartWeight} Kg</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridCarCourse && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Curso carro da mó</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridCarCourse} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridAutoPassing !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Passagem automática</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridAutoPassing ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridCenterHeadType && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo de Centro Cabeça</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridCenterHeadType}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridCenterContraPontoType && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Tipo de Centro Contra Ponto</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridCenterContraPontoType}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridCenterContraPontoPath && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Curso do Centro Contra Ponto</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridCenterContraPontoPath} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {gridBalancerIncluded !== null && (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Incluí sistema de balanceamento da mó</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{gridBalancerIncluded ? 'Sim' : 'Não'}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.TRY_OUT_PRESS) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Unidade de Fecho</Typography>
              </Box>

              <Grid container spacing={2}>
                {type === MachineTypes.PLASTIC_INJECTION && (
                  <>
                    {closeUnitPlateDimensionH ? (
                      <>
                        <Grid item sm={3}>
                          <Typography variant="body2">
                            <b>Dimensão dos pratos (Horizontal)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <Typography variant="body2">{closeUnitPlateDimensionH} mm</Typography>
                        </Grid>
                      </>
                    ) : null}
                    {closeUnitPlateDimensionV ? (
                      <>
                        <Grid item sm={3}>
                          <Typography variant="body2">
                            <b>Dimensão dos pratos (Vertical)</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <Typography variant="body2">{closeUnitPlateDimensionV} mm</Typography>
                        </Grid>
                      </>
                    ) : null}
                    {(closeUnitPlateDimensionH || closeUnitPlateDimensionV) && (
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                    )}
                  </>
                )}
                {closeUnitColumnDistanceH ? (
                  <>
                    <Grid item sm={3}>
                      <Typography variant="body2">
                        <b>Distância entre colunas (Horizontal)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2">{closeUnitColumnDistanceH} mm</Typography>
                    </Grid>
                  </>
                ) : null}
                {closeUnitColumnDistanceV ? (
                  <>
                    <Grid item sm={3}>
                      <Typography variant="body2">
                        <b>Distância entre colunas (Vertical)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2">{closeUnitColumnDistanceV} mm</Typography>
                    </Grid>
                  </>
                ) : null}
                {(closeUnitColumnDistanceH || closeUnitColumnDistanceV) && (
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                )}
                {closeUnitMoldTicknessMin ? (
                  <>
                    <Grid item sm={3}>
                      <Typography variant="body2">
                        <b>Espessura do Molde (Min)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2">{closeUnitMoldTicknessMin} mm</Typography>
                    </Grid>
                  </>
                ) : null}
                {closeUnitMoldTicknessMax ? (
                  <>
                    <Grid item sm={3}>
                      <Typography variant="body2">
                        <b>Espessura do Molde (Max)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2">{closeUnitMoldTicknessMax} mm</Typography>
                    </Grid>
                  </>
                ) : null}
                {(closeUnitMoldTicknessMin || closeUnitMoldTicknessMax) && (
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                )}
                {closeUnitMinMoldDimension ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão Mínima do Molde</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{closeUnitMinMoldDimension}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {closeUnitForce ? (
                  <>
                    <Grid item sm={3}>
                      <Typography variant="body2">
                        <b>Força de Fecho</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2">{closeUnitForce} ton</Typography>
                    </Grid>
                  </>
                ) : null}
                {openUnitForce ? (
                  <>
                    <Grid item sm={3}>
                      <Typography variant="body2">
                        <b>Força de Abertura</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2">{openUnitForce} ton</Typography>
                    </Grid>
                  </>
                ) : null}
                {(closeUnitForce || openUnitForce) && (
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                )}
                {type === MachineTypes.TRY_OUT_PRESS && (
                  <>
                    {closeUnitSupPlateCourse ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Curso de abertura do prato superior</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{closeUnitSupPlateCourse} mm</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                    {closeUnitSupPlateMove !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Prato superior inclina</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{closeUnitSupPlateMove ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
                {closeUnitMobilePlateCourse ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Curso de abertura do prato móvel</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{closeUnitMobilePlateCourse} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {closeUnitMaxDistanceBetween ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Distância máxima entre prato móvel e fixo</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{closeUnitMaxDistanceBetween} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {closeUnitExtractorQuantity ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Quantidade de Extratores</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{closeUnitExtractorQuantity}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {closeUnitExtractorForce ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Força do extractor</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{closeUnitExtractorForce} kN</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {closeUnitExtractionCourse ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Curso de extração</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{closeUnitExtractionCourse} mm</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default Fuses;
