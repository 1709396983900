import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/machines';
import {
  createMachine,
  createMachineTicket,
  editMachine,
  deleteMachine,
  changeMachineCustomer,
  editMachineTicket,
  deleteMachineTicket,
  fetchMachines,
  fetchMachinesTickets,
  createMachineTicketTask,
  deleteMachineTicketTask,
  editMachineTicketTask,
  createMachineTicketTaskExpense,
  deleteMachineTicketTaskExpense,
  createMachineTicketTaskComment,
  deleteMachineTicketTaskComment,
  createMachineTicketTaskSign,
  fetchMachinesForMarketplace,
  sendMachineContact,
  fetchDashboardTasks,
} from '../api/machines';
import Machine, { MachineWithProposal } from '../models/Machine';
import Task, { TaskComment, TaskExpense, TaskSign } from '../models/Task';
import Ticket from '../models/Ticket';

export function* fetchMachinesSaga() {
  try {
    const machines: MachineWithProposal[] = yield call(fetchMachines);

    yield put(types.fetchMachines.success({ machines }));
  } catch (error: any) {
    yield put(types.fetchMachines.failure({ error }));
  }
}

export function* createMachineSaga(action: ActionType<typeof types.createMachine.request>) {
  try {
    const machine: Machine = yield call(createMachine, action.payload);

    yield put(types.createMachine.success({ machine }));
  } catch (error: any) {
    yield put(types.createMachine.failure({ error }));
  }
}

export function* editMachineSaga(action: ActionType<typeof types.editMachine.request>) {
  try {
    const machine: Machine = yield call(editMachine, action.payload);

    yield put(types.editMachine.success({ machine }));
  } catch (error: any) {
    yield put(types.editMachine.failure({ error }));
  }
}

export function* deleteMachineSaga(action: ActionType<typeof types.deleteMachine.request>) {
  try {
    yield call(deleteMachine, action.payload);

    yield put(types.deleteMachine.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteMachine.failure({ error }));
  }
}

export function* changeMachineCustomerSaga(action: ActionType<typeof types.changeMachineCustomer.request>) {
  try {
    yield call(changeMachineCustomer, action.payload);

    yield put(types.changeMachineCustomer.success(action.payload));
  } catch (error: any) {
    yield put(types.changeMachineCustomer.failure({ error }));
  }
}

export function* fetchMachinesTicketsSaga() {
  try {
    const tickets: Ticket[] = yield call(fetchMachinesTickets);

    yield put(types.fetchMachinesTickets.success({ tickets }));
  } catch (error: any) {
    yield put(types.fetchMachinesTickets.failure({ error }));
  }
}

export function* createMachineTicketSaga(action: ActionType<typeof types.createMachineTicket.request>) {
  try {
    const ticket: Ticket = yield call(createMachineTicket, action.payload);

    yield put(types.createMachineTicket.success({ ticket }));
  } catch (error: any) {
    yield put(types.createMachineTicket.failure({ error }));
  }
}

export function* editMachineTicketSaga(action: ActionType<typeof types.editMachineTicket.request>) {
  try {
    const ticket: Ticket = yield call(editMachineTicket, action.payload);

    yield put(types.editMachineTicket.success({ ticket }));
  } catch (error: any) {
    yield put(types.editMachineTicket.failure({ error }));
  }
}

export function* deleteMachineTicketSaga(action: ActionType<typeof types.deleteMachineTicket.request>) {
  try {
    yield call(deleteMachineTicket, action.payload);

    yield put(types.deleteMachineTicket.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteMachineTicket.failure({ error }));
  }
}

export function* deleteMachineTicketTaskSaga(action: ActionType<typeof types.deleteMachineTicketTask.request>) {
  try {
    yield call(deleteMachineTicketTask, action.payload);

    yield put(types.deleteMachineTicketTask.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteMachineTicketTask.failure({ error }));
  }
}

export function* createMachineTicketTaskSaga(action: ActionType<typeof types.createMachineTicketTask.request>) {
  const { status } = action.payload;

  try {
    const task: Task = yield call(createMachineTicketTask, action.payload);

    yield put(types.createMachineTicketTask.success({ task }));
  } catch (error: any) {
    yield put(types.createMachineTicketTask.failure({ error, status }));
  }
}

export function* editMachineTicketTaskSaga(action: ActionType<typeof types.editMachineTicketTask.request>) {
  const { id, status } = action.payload;

  try {
    const task: Task = yield call(editMachineTicketTask, action.payload);

    yield put(types.editMachineTicketTask.success({ task }));
  } catch (error: any) {
    yield put(types.editMachineTicketTask.failure({ error, id, status }));
  }
}

export function* createMachineTicketTaskExpenseSaga(action: ActionType<typeof types.createMachineTicketTaskExpense.request>) {
  const { taskid } = action.payload;

  try {
    const expense: TaskExpense = yield call(createMachineTicketTaskExpense, action.payload);

    yield put(types.createMachineTicketTaskExpense.success({ expense }));
  } catch (error: any) {
    yield put(types.createMachineTicketTaskExpense.failure({ error, taskid }));
  }
}

export function* deleteMachineTicketTaskExpenseSaga(action: ActionType<typeof types.deleteMachineTicketTaskExpense.request>) {
  const { taskid } = action.payload;

  try {
    yield call(deleteMachineTicketTaskExpense, action.payload);

    yield put(types.deleteMachineTicketTaskExpense.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteMachineTicketTaskExpense.failure({ error, taskid }));
  }
}

export function* createMachineTicketTaskCommentSaga(action: ActionType<typeof types.createMachineTicketTaskComment.request>) {
  const { taskid } = action.payload;

  try {
    const comment: TaskComment = yield call(createMachineTicketTaskComment, action.payload);

    yield put(types.createMachineTicketTaskComment.success({ comment }));
  } catch (error: any) {
    yield put(types.createMachineTicketTaskComment.failure({ error, taskid }));
  }
}

export function* deleteMachineTicketTaskCommentSaga(action: ActionType<typeof types.deleteMachineTicketTaskComment.request>) {
  const { taskid } = action.payload;

  try {
    yield call(deleteMachineTicketTaskComment, action.payload);

    yield put(types.deleteMachineTicketTaskComment.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteMachineTicketTaskComment.failure({ error, taskid }));
  }
}

export function* createMachineTicketTaskSignSaga(action: ActionType<typeof types.createMachineTicketTaskSign.request>) {
  try {
    const { ticketId, tasks } = action.payload;

    const taskSign: TaskSign = yield call(createMachineTicketTaskSign, action.payload);

    yield put(types.createMachineTicketTaskSign.success({ ...taskSign, ticketId, tasks }));

  } catch (error: any) {
    yield put(types.createMachineTicketTaskSign.failure({ error }));
  }
}

export function* fetchMachinesForMarketplaceSaga() {
  try {
    const machines: Machine[] = yield call(fetchMachinesForMarketplace);

    yield put(types.fetchMachinesForMarketplace.success({ machines }));
  } catch (error: any) {
    yield put(types.fetchMachinesForMarketplace.failure({ error }));
  }
}

export function* sendMachineContactSaga(action: ActionType<typeof types.sendMachineContact.request>) {
  try {
    yield call(sendMachineContact, action.payload);

    yield put(types.sendMachineContact.success());
  } catch (error: any) {
    yield put(types.sendMachineContact.failure({ error }));
  }
}

export function* fetchDashboardTasksSaga(action: ActionType<typeof types.fetchDashboardTasks.request>) {
  try {
    const tasks: Task[] = yield call(fetchDashboardTasks, action.payload);

    yield put(types.fetchDashboardTasks.success({ tasks }));
  } catch (error: any) {
    yield put(types.fetchDashboardTasks.failure({ error }));
  }
}