import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

interface IProps {
  dimensionC?: number;
  dimensionL?: number;
  dimensionA?: number;
  weight?: number;
  deliveryDate?: number;
  warranty?: string;
  transportPrice?: number;
  paymentConditions?: string;
  commands?: string;

  handleChange: (name: string, value: any) => void;
}

const FormLogistics: FunctionComponent <IProps> = (properties) => {
  const {
    dimensionC,
    dimensionL,
    dimensionA,
    weight,
    deliveryDate,
    warranty,
    transportPrice,
    paymentConditions,
    commands,

    handleChange,
  } = properties;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Dimensão e Peso</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={3}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="C"
                    value={dimensionC || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                    onChange={(event: any) => handleChange('dimensionC', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="L"
                    value={dimensionL || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                    onChange={(event: any) => handleChange('dimensionL', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="A"
                    value={dimensionA || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                    onChange={(event: any) => handleChange('dimensionA', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Peso"
                    value={weight || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                    onChange={(event: any) => handleChange('weight', event.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Condições gerais de entrega e pagamento</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Prazo Entrega (dias)"
                    value={deliveryDate || ''}
                    variant="standard"
                    type="number"
                    onChange={(event: any) => handleChange('deliveryDate', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Garantia"
                    value={warranty || ''}
                    variant="standard"
                    multiline
                    minRows={1}
                    inputProps={{ maxLength: 3000 }}
                    onChange={(event: any) => handleChange('warranty', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Preço de Transporte"
                    value={transportPrice || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                    onChange={(event: any) => handleChange('transportPrice', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Condições de Pagamento"
                    value={paymentConditions || ''}
                    multiline
                    variant="standard"
                    inputProps={{ maxLength: 255 }}
                    onChange={(event: any) => handleChange('paymentConditions', event.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Funções do comando</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={12}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Funções do comando"
                    value={commands || ''}
                    variant="standard"
                    multiline
                    minRows={3}
                    inputProps={{ maxLength: 3000 }}
                    onChange={(event: any) => handleChange('commands', event.target.value)}
                    helperText="Funções Específicas"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default FormLogistics;
