import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Form from './Form';

interface IProps {
  fetchMachinesTickets: () => void;
  history: any;
  loading: boolean;
}
interface IState {}

class Tickets extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchMachinesTickets } = this.props;

    fetchMachinesTickets();
  }

  public render() {
    const { history } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route path="/dashboard/machines/tickets/list" exact component={List} />
          <Route path="/dashboard/machines/tickets/new" exact render={() => <Form history={history} />} />
          <Route path="/dashboard/machines/tickets/ticketid/:ticketid/edit" exact render={(props) => <Form history={history} ticketid={props.match.params.ticketid} />} />
          <Redirect to="/authenticate" />
        </Switch>
      </Router>
    );
  }
};

export default Tickets;