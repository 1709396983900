import { createAction, createAsyncAction } from 'typesafe-actions';

import { SupplierRep } from '../models/Rep';
import Supplier from '../models/Supplier';
import { IFailed } from './types';

enum Types {
  FETCH = '@@suppliers/FETCH',
  FETCH_FAILED = '@@suppliers/FETCH_FAILED',
  FETCH_SUCCEEDED = '@@suppliers/FETCH_SUCCEEDED',

  CREATE = '@@suppliers/CREATE',
  CREATE_FAILED = '@@suppliers/CREATE_FAILED',
  CREATE_SUCCEEDED = '@@suppliers/CREATE_SUCCEEDED',

  EDIT = '@@suppliers/EDIT',
  EDIT_FAILED = '@@suppliers/EDIT_FAILED',
  EDIT_SUCCEEDED = '@@suppliers/EDIT_SUCCEEDED',

  TOGGLE_SUPPLIER = '@@suppliers/TOGGLE_SUPPLIER',
  TOGGLE_SUPPLIER_FAILED = '@@suppliers/TOGGLE_SUPPLIER_FAILED',
  TOGGLE_SUPPLIER_SUCCEEDED = '@@suppliers/TOGGLE_SUPPLIER_SUCCEEDED',

  TOGGLE_VIEW = '@@suppliers/TOGGLE_VIEW',

  TOGGLE_REPS_VIEW = '@@suppliers/TOGGLE_REPS_VIEW',

  CREATE_REP = '@@suppliers/CREATE_REP',
  CREATE_REP_FAILED = '@@suppliers/CREATE_REP_FAILED',
  CREATE_REP_SUCCEEDED = '@@suppliers/CREATE_REP_SUCCEEDED',

  EDIT_REP = '@@suppliers/EDIT_REP',
  EDIT_REP_FAILED = '@@suppliers/EDIT_REP_FAILED',
  EDIT_REP_SUCCEEDED = '@@suppliers/EDIT_REP_SUCCEEDED',

  DELETE_REP = '@@suppliers/DELETE_REP',
  DELETE_REP_FAILED = '@@suppliers/DELETE_REP_FAILED',
  DELETE_REP_SUCCEEDED = '@@suppliers/DELETE_REP_SUCCEEDED',
}

interface FetchSuccess {
  suppliers: Supplier[];
}

export const fetchSuppliers = createAsyncAction(
  Types.FETCH,
  Types.FETCH_SUCCEEDED,
  Types.FETCH_FAILED
)<void, FetchSuccess, IFailed>();

export interface CreateSupplier {
  name: string,
  email?: string,
  telephone?: string,
  supplierTaxNumber?: string,
  supplierType?: number,
  companyName?: string,
  address?: string,
  city?: string,
  country?: string,
}

interface SupplierSuccess {
  supplier: Supplier;
}

export const createSupplier = createAsyncAction(
  Types.CREATE,
  Types.CREATE_SUCCEEDED,
  Types.CREATE_FAILED
)<CreateSupplier, SupplierSuccess, IFailed>();

export interface EditSupplier extends CreateSupplier {
  id: number,
}

export const editSupplier = createAsyncAction(
  Types.EDIT,
  Types.EDIT_SUCCEEDED,
  Types.EDIT_FAILED
)<EditSupplier, SupplierSuccess, IFailed>();

export interface ToggleSupplier {
  supplierId: number;
  active: boolean;
}

export const toggleSupplier = createAsyncAction(
  Types.TOGGLE_SUPPLIER,
  Types.TOGGLE_SUPPLIER_SUCCEEDED,
  Types.TOGGLE_SUPPLIER_FAILED
)<ToggleSupplier, ToggleSupplier, IFailed>();

export const toggleSupplierView = createAction(
  Types.TOGGLE_VIEW,
)<number | undefined>();

export const toggleSupplierRepsView = createAction(
  Types.TOGGLE_REPS_VIEW,
)<number | undefined>();

interface SupplierRepSuccess {
  rep: SupplierRep;
}

export interface CreateSupplierRep {
  supplierId: number;
  name: string;
  email: string;
  telephone: string;
  personFunctionId?: number;
}
export const createSupplierRep = createAsyncAction(
  Types.CREATE_REP,
  Types.CREATE_REP_SUCCEEDED,
  Types.CREATE_REP_FAILED
)<CreateSupplierRep, SupplierRepSuccess, IFailed>();

export interface EditSupplierRep extends CreateSupplierRep {
  id: number,
}

export const editSupplierRep = createAsyncAction(
  Types.EDIT_REP,
  Types.EDIT_REP_SUCCEEDED,
  Types.EDIT_REP_FAILED
)<EditSupplierRep, SupplierRepSuccess, IFailed>();

export interface DeleteSupplierRep {
  id: number;
  supplierId: number;
}
export const deleteSupplierRep = createAsyncAction(
  Types.DELETE_REP,
  Types.DELETE_REP_SUCCEEDED,
  Types.DELETE_REP_FAILED
)<DeleteSupplierRep, DeleteSupplierRep, IFailed>();