import File from './File';

export enum TaskStatus {
  PENDING = 'pending',
  EXECUTION = 'execution',
  DONE = 'done',
}

export enum TaskExpenseType {
  MATERIAL = 'material',
  TRAVELS = 'travels',
  MANHOURS = 'manHours',
  OTHERS = 'others',
}

export interface TaskExpense {
  ticketid: number;
  taskid: number;
  id: number;
  type: TaskExpenseType;
  description?: string;
  regUserId?: number;
  quantity: number;
  price: number;
  date: Date;
  datetime: Date;
  userid: number;
}

export interface TaskComment {
  ticketid: number;
  taskid: number;
  id: number;
  comment: string;
  datetime: Date;
  userid: number;
}

export interface TaskSign {
  signature: string;
  datetime: Date;
}

class Task {
  public id!: number;
  
  public ticketid!: number;

  public status!: TaskStatus;

  public title!: string;

  public technicians!: number[];

  public files!: File[];

  public originalname?: string;

  public expenses!: Record<TaskExpense['id'], TaskExpense>;

  public comments!: Record<TaskComment['id'], TaskComment>;

  public signs!: TaskSign[];

  public datetime!: Date;
}

export default Task;