import { ActionType, getType } from 'typesafe-actions';

import * as dashboard from '../actions/dashboard';
import DashboardMetrics from '../models/DashboardMetrics';

type DashboardAction = ActionType<typeof dashboard>;

export interface IDashboardState {
  readonly error?: object;
  readonly loading: boolean;
  readonly metrics?: DashboardMetrics;
}

export const initialState: IDashboardState = {
  loading: false,
};

const reducer = (
  state: IDashboardState = initialState,
  action: DashboardAction
): IDashboardState => {
  switch (action.type) {
    case getType(dashboard.fetchDashboardMetrics.request):
      return {
        ...state,
        loading: true,
      };

    case getType(dashboard.fetchDashboardMetrics.success):
      return {
        ...state,
        metrics: action.payload,
        error: undefined,
        loading: false,
      };

    case getType(dashboard.fetchDashboardMetrics.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;