import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchCustomers } from '../../../../../actions/customers';
import {
  createMachineTicket,
  CreateMachineTicket,
  editMachineTicket,
  EditMachineTicket,
  fetchMachines,
  fetchMachinesTickets,
  fetchZonesTable,
  editMachineTicketTask,
  EditMachineTicketTask,
  openMachineTicketTask,
  openSign,
} from '../../../../../actions/index';
import { fetchUsers } from '../../../../../actions/users';
import Task from '../../../../../models/Task';
import User from '../../../../../models/User';
import { IAppState } from '../../../../../reducers/index';
import Form from './Form';

interface IProps {
  ticketid?: string;
}

const mapStateToProps = ({ authentication, customers, machines, tables, users }: IAppState, props: IProps) => ({
  customers: Object.values(customers.customers),
  machines: Object.values(machines.machines),
  users: Object.values(users.users),
  zones: Object.values(tables.zones.zones),
  ticket: machines.tickets.tickets[Number(props.ticketid || 0)] || {},
  loading: customers.loading || machines.loading || users.loading || machines.tickets.loading,
  loadingZones: tables.zones.loading,
  taskModal: machines.tickets.taskModal,
  openSignModal: machines.tickets.signForm.open,
  redirectReady: machines.tickets.form.redirectReady,
  redirectReadyId: machines.tickets.form.redirectReadyId,
  user: authentication.user || ({} as User),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createMachineTicket: (properties: CreateMachineTicket) =>
      dispatch(createMachineTicket.request(properties)),
    editMachineTicket: (properties: EditMachineTicket) =>
      dispatch(editMachineTicket.request(properties)),
    editMachineTicketTask: (properties: EditMachineTicketTask) =>
      dispatch(editMachineTicketTask.request(properties)),
    fetchCustomers: () =>
      dispatch(fetchCustomers.request()),
    fetchMachines: () =>
      dispatch(fetchMachines.request()),
    fetchMachinesTickets: () =>
      dispatch(fetchMachinesTickets.request()),
    fetchUsers: () =>
      dispatch(fetchUsers.request()),
    fetchZonesTable: () => dispatch(fetchZonesTable.request()),
    openMachineTicketTask: (task: Task) =>
      dispatch(openMachineTicketTask(task)),
    openSign: (ticketId: number) =>
      dispatch(openSign({ ticketId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
