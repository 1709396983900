import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchZoneList, toggleCustomerView } from '../../../../actions';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import View from './ViewModal';

const mapStateToProps = ({ authentication, customers, tables }: IAppState) => ({
  customer: customers.customers[customers.view.customerId || 0] || {},
  user: authentication.user || ({} as User),
  loading: tables.zones.listLoading,
  zones: tables.zones.list || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchZoneList: () => dispatch(fetchZoneList.request()),
    toggleCustomerView: (customerId?: number) =>
      dispatch(toggleCustomerView(customerId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);