import { connect } from 'react-redux';
import { IAppState } from '../reducers';

import App from './App';

const mapStateToProps = (state: IAppState) => ({
  authenticated: state.authentication.authenticated,
  checked: state.authentication.checked,
  loading: state.authentication.loading,
});

export default connect(mapStateToProps)(App);
