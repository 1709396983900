import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/dashboard';
import { fetchDashboardMetrics } from '../api/dashboard';
import DashboardMetrics from '../models/DashboardMetrics';

export function* fetchDashboardMetricsSaga(action: ActionType<typeof types.fetchDashboardMetrics.request>) {
  try {
    const metrics: DashboardMetrics = yield call(fetchDashboardMetrics, action.payload);

    yield put(types.fetchDashboardMetrics.success(metrics));
  } catch (error: any) {
    yield put(types.fetchDashboardMetrics.failure({ error }));
  }
}