import { getType } from 'mime';

import APIFile from '../../models/File';

export const getCoverImage = (files: APIFile[], coverImage?: string): APIFile | undefined => {
  if (files) {
    for (let file of files) {
      if ((getType(file.originalname) || 'text').indexOf('image') !== -1) {
        return file;
      }
    }
  }

  return undefined;
}

export const tableTranslations = {
  body: {
    noMatch: 'Sem dados para mostrar',
    toolTip: 'Ordem',
  },
  filter: {
    all: 'Tudo',
    reset: 'Limpar',
    title: 'Filtros',
  },
  pagination: {
    displayRows: 'de',
    next: 'Próx. página',
    previous: 'Página ant.',
    rowsPerPage: 'Linhas por página',
  },
  selectedRows: {
    delete: 'Apagar',
    deleteAria: 'Apagar linhas seleccionadas',
    text: 'linha(s) seleccionadas',
  },
  toolbar: {
    downloadCsv: 'Descarregar CSV',
    filterTable: 'Filtrar tabela',
    print: 'Imprimir',
    search: 'Pesquisa',
    viewColumns: 'Ver colunas',
  },
  viewColumns: {
    title: 'Mostrar colunas',
    titleAria: 'Mostrar/esconder colunas da tabela',
  },
};

export const checkIfImage = (filename: string) => {
  return /\.(jpg|jpeg|gif|png|gif)$/.test(filename.toLowerCase());
};
