import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { storageType, MachineTypes } from '../sources';

interface IProps {
  type: string;

  tornTurret?: boolean;
  toolQuantity?: number;
  turningDiameter?: number;
  turningLength?: number;
  warehouseType?: number;
  tollExchangeTime?: number;
  supportType?: string;
  staticSupportNumber?: number;
  motorTool?: boolean;
  motorToolRPM?: number;
  motorToolPotence?: number;
  motorToolSupports?: string;
  motorToolNew?: boolean;
  motorToolNewSupportName?: string;
  motorToolNewSupportDescription?: string;
  motorToolNewSupportBuyPrice?: number;
  motorToolNewSupportSellPrice?: number;
  motorToolRadQuantity?: number;
  motorToolAxiQuantity?: number;
  electrodeWarehouse?: boolean;
  electrodeWarehouseQuantity?: number;
  electrodeMaxHeight?: number;
  electrodeMaxErosionHead?: number;

  handleChange: (name: string, value: any) => void;
}

const FormOptionals: FunctionComponent <IProps> = (properties) => {
  const {
    type,

    tornTurret,
    toolQuantity,
    warehouseType,
    tollExchangeTime,
    supportType,
    staticSupportNumber,
    motorTool,
    motorToolRPM,
    motorToolPotence,
    motorToolSupports,
    motorToolNew,
    motorToolNewSupportName,
    motorToolNewSupportDescription,
    motorToolNewSupportBuyPrice,
    motorToolNewSupportSellPrice,
    motorToolRadQuantity,
    motorToolAxiQuantity,
    electrodeWarehouse,
    electrodeWarehouseQuantity,
    electrodeMaxHeight,
    electrodeMaxErosionHead,

    handleChange,
  } = properties;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Gerais</Typography>
            </Box>

            <Grid container spacing={2}>
              {(type === MachineTypes.TORNO || type === MachineTypes.TORNOMULTITASKS || type === MachineTypes.SWISS_LATHE) && (
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={tornTurret || false} />}
                        label="Turreta de Ferramentas"
                        onChange={(event: any) => handleChange('tornTurret', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
              {(
                type === MachineTypes.T3X ||
                type === MachineTypes.T5X ||
                type === MachineTypes.HORIZONTAL ||
                type === MachineTypes.TORNO ||
                type === MachineTypes.BORING ||
                type === MachineTypes.SWISS_LATHE ||
                tornTurret
              ) && (
                <>
                  <Grid item sm={3}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Quantidade de Ferramentas"
                        value={toolQuantity || ''}
                        variant="standard"
                        type="number"
                        onChange={(event: any) => handleChange('toolQuantity', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  {type !== MachineTypes.TORNO && (
                    <>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <InputLabel id="marchine-warehousetype">Tipo de Armazém</InputLabel>
                          <Select
                            labelId="marchine-warehousetype"
                            value={warehouseType || ''}
                            onChange={(event: any) => handleChange('warehouseType', event.target.value)}
                          >
                            <MenuItem value="">Nenhum</MenuItem>
                            {storageType.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Tempo de Troca Tool to Tool"
                            value={tollExchangeTime || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">s</InputAdornment> }}
                            onChange={(event: any) => handleChange('tollExchangeTime', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {(type === MachineTypes.TORNO || type === MachineTypes.SWISS_LATHE) && tornTurret && (
                <>
                  <Grid item sm={3}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Tipo de Suporte"
                        value={supportType || ''}
                        variant="standard"
                        inputProps={{ maxLength: 255 }}
                        onChange={(event: any) => handleChange('supportType', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={3}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Nº de Suportes Estáticos"
                        value={staticSupportNumber || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        onChange={(event: any) => handleChange('staticSupportNumber', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container />
                  <Grid item sm={3}>
                    <FormControl margin="dense" fullWidth>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={motorTool || false} />}
                          label="Ferramenta Motorizada"
                          onChange={(event: any) => handleChange('motorTool', event.target.checked)}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {motorTool && (
                    <>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="RPM"
                            value={motorToolRPM || ''}
                            variant="standard"
                            type="number"
                            onChange={(event: any) => handleChange('motorToolRPM', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Potência Spindle Fresagem"
                            value={motorToolPotence || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            onChange={(event: any) => handleChange('motorToolPotence', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Suportes Ferramenta Motorizada"
                            value={motorToolSupports || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('motorToolSupports', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={12}>
                        <FormControl margin="dense" fullWidth>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={motorToolNew || false} />}
                              label="Novo"
                              onChange={(event: any) => handleChange('motorToolNew', event.target.checked)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      {motorToolNew ? (
                        <>
                          <Grid item sm={3}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Nome do Suporte"
                                value={motorToolNewSupportName || ''}
                                variant="standard"
                                inputProps={{ maxLength: 255 }}
                                onChange={(event: any) => handleChange('motorToolNewSupportName', event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={3}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Descrição do Suporte"
                                value={motorToolNewSupportDescription || ''}
                                variant="standard"
                                inputProps={{ maxLength: 255 }}
                                onChange={(event: any) => handleChange('motorToolNewSupportDescription', event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={3}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Preço Compra"
                                value={motorToolNewSupportBuyPrice || ''}
                                variant="standard"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                                onChange={(event: any) => handleChange('motorToolNewSupportBuyPrice', event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={3}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Preço Venda"
                                value={motorToolNewSupportSellPrice || ''}
                                variant="standard"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                                onChange={(event: any) => handleChange('motorToolNewSupportSellPrice', event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item sm={6}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Qts. Radiais"
                                value={motorToolRadQuantity || ''}
                                variant="standard"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                onChange={(event: any) => handleChange('motorToolRadQuantity', event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item sm={6}>
                            <FormControl margin="dense" fullWidth>
                              <TextField
                                label="Qts. Axiais"
                                value={motorToolAxiQuantity || ''}
                                variant="standard"
                                type="number"
                                inputProps={{ step: 0.01 }}
                                onChange={(event: any) => handleChange('motorToolAxiQuantity', event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              {type === MachineTypes.EDM && (
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={electrodeWarehouse || false} />}
                        label="Tem armazém de eletrodos?"
                        onChange={(event: any) => handleChange('electrodeWarehouse', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
              {electrodeWarehouse && (
                <>
                  <Grid item sm={6}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Quantidade de eletrodos"
                        value={electrodeWarehouseQuantity || ''}
                        variant="standard"
                        type="number"
                        onChange={(event: any) => handleChange('electrodeWarehouseQuantity', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid container />
              {type === MachineTypes.EDM && (
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Altura máxima dos eletrodos"
                      value={electrodeMaxHeight || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('electrodeMaxHeight', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              )}
              {type === MachineTypes.EDM && (
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Peso máximo do eletrodo na cabeça de erosão"
                      value={electrodeMaxErosionHead || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                      onChange={(event: any) => handleChange('electrodeMaxErosionHead', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default FormOptionals;
