import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/tables';
import {
  fetchZonesTable,
  createTableZone,
  editTableZone,
  deleteTableZone,
  fetchZoneList,
  fetchDynamicFieldValues,
  editDynamicField,
} from '../api/tables';
import { DynamicValue } from '../models/DynamicField';
import Zone, { ZoneMeta } from '../models/Zone';

export function* fetchZonesTableSaga() {
  try {
    const zones: Zone[] = yield call(fetchZonesTable);

    yield put(types.fetchZonesTable.success({ zones }));
  } catch (error: any) {
    yield put(types.fetchZonesTable.failure({ error }));
  }
}

export function* createTableZoneSaga(action: ActionType<typeof types.createTableZone.request>) {
  try {
    const zone: Zone = yield call(createTableZone, action.payload);

    yield put(types.createTableZone.success({ zone }));
  } catch (error: any) {
    yield put(types.createTableZone.failure({ error }));
  }
}

export function* editTableZoneSaga(action: ActionType<typeof types.editTableZone.request>) {
  try {
    const zone: Zone = yield call(editTableZone, action.payload);

    yield put(types.editTableZone.success({ zone }));
  } catch (error: any) {
    yield put(types.editTableZone.failure({ error }));
  }
}

export function* deleteTableZoneSaga(action: ActionType<typeof types.deleteTableZone.request>) {
  try {
    yield call(deleteTableZone, action.payload);

    yield put(types.deleteTableZone.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteTableZone.failure({ error }));
  }
}

export function* fetchZoneListSaga() {
  try {
    const zones: ZoneMeta[] = yield call(fetchZoneList);

    yield put(types.fetchZoneList.success({ zones }));
  } catch (error: any) {
    yield put(types.fetchZoneList.failure({ error }));
  }
}

export function* fetchDynamicFieldValuesSaga(action: ActionType<typeof types.fetchDynamicFieldValues.request>) {
  try {
    const values: DynamicValue[] = yield call(fetchDynamicFieldValues, action.payload.id);

    yield put(types.fetchDynamicFieldValues.success({ values }));
  } catch (error: any) {
    yield put(types.fetchDynamicFieldValues.failure({ error }));
  }
}

export function* editDynamicFieldSaga(action: ActionType<typeof types.editDynamicField.request>) {
  try {
    yield call(editDynamicField, action.payload);

    yield put(types.editDynamicField.success());
  } catch (error: any) {
    yield put(types.editDynamicField.failure({ error }));
  }
}

export function* fetchDynamicFieldValuesByFormSaga(action: ActionType<typeof types.fetchDynamicFieldValuesByForm.request>) {
  const { id } = action.payload;

  try {
    const values: DynamicValue[] = yield call(fetchDynamicFieldValues, id);

    yield put(types.fetchDynamicFieldValuesByForm.success({ id, values }));
  } catch (error: any) {
    yield put(types.fetchDynamicFieldValuesByForm.failure({ id, error }));
  }
}