import { createAsyncAction } from 'typesafe-actions';

import DashboardMetrics from '../models/DashboardMetrics';

import { IFailed } from './types';

enum Types {
  FETCH_DASHBOARD_METRICS = '@@dashboard/FETCH_DASHBOARD_METRICS',
  FETCH_DASHBOARD_METRICS_FAILED = '@@authentication/FETCH_DASHBOARD_METRICS_FAILED',
  FETCH_DASHBOARD_METRICS_SUCCEEDED = '@@authentication/FETCH_DASHBOARD_METRICS_SUCCEEDED',
}

export interface IFetchDashboardMetrics {
  year: number;
  commercialId?: number;
  technicianId?: number;
}

export const fetchDashboardMetrics = createAsyncAction(
  Types.FETCH_DASHBOARD_METRICS,
  Types.FETCH_DASHBOARD_METRICS_SUCCEEDED,
  Types.FETCH_DASHBOARD_METRICS_FAILED
)<IFetchDashboardMetrics, DashboardMetrics, IFailed>();