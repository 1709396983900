import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import React, { FunctionComponent } from 'react';

import Zone from '../../../../../models/Zone';

interface IProps {
  zone: Zone;
  deleteTableZone: (id: number) => void;
  loading: boolean;
  closeDeleteTableZone: () => void;
}

const DeleteConfirmation: FunctionComponent<IProps> = (properties) => {
  const { closeDeleteTableZone, deleteTableZone, loading, zone } = properties;

  return (
    <Dialog open onClose={() => closeDeleteTableZone()}>
      <DialogTitle>
        <FontAwesomeIcon className="header-icon" icon={faTrash} />
        <span style={{ marginLeft: '8px' }}>Apagar Zona - {zone.name || ''}</span>
        <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => closeDeleteTableZone()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>Tem certeza que quer apagar a zona #{zone.name || ''}?</DialogContent>
      <Box p={2} pt={0}>
        <DialogActions>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item>
                <Button color="default" variant="contained" onClick={closeDeleteTableZone}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  startIcon={
                    <FontAwesomeIcon icon={faTrash} />
                  }
                  variant="contained"
                  onClick={() => deleteTableZone(zone.id)}
                >{loading ? <CircularProgress size={24} /> : 'Apagar'}</Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default DeleteConfirmation;