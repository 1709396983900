import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  CreateCustomer,
  createCustomer,
  EditCustomer,
  editCustomer,
  fetchUsers,
  fetchZoneList
} from '../../../../actions/index';
import { IAppState } from '../../../../reducers/index';
import Form from './Form';

interface IProps {
  customerid?: string;
}

const mapStateToProps = ({ customers, tables, users }: IAppState, props: IProps) => ({
  loading: tables.zones.listLoading || customers.form.loading,
  redirectReady: customers.form.redirectReady,
  redirectCustomerId: customers.form.redirectCustomerId,
  customer: customers.customers[Number(props.customerid || 0)],
  users: Object.values(users.users),
  zones: tables.zones.list || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createCustomer: (properties: CreateCustomer) =>
      dispatch(createCustomer.request(properties)),
    editCustomer: (properties: EditCustomer) =>
      dispatch(editCustomer.request(properties)),
    fetchUsers: () => dispatch(fetchUsers.request()),
    fetchZoneList: () =>
      dispatch(fetchZoneList.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
