import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';

import { DynamicValue } from '../../../../models/DynamicField';

interface IProps {
  isMachineNew?: boolean;
  buyPrice?: number;
  installBuyPrice?: number;
  truckBuyPrice?: number;
  machineYear?: number;
  machineUsageHours?: number
  status?: number;

  machineStatus: DynamicValue[];
  handleChange: (name: string, value: any) => void;

  submitted: boolean;
}

class FormInfoPrices extends PureComponent<IProps> {
  public render() {
    const {
      isMachineNew,
      buyPrice,
      installBuyPrice,
      truckBuyPrice,
      machineYear,
      machineUsageHours,
      status,

      machineStatus,
      handleChange,

      submitted
    } = this.props;

    return (
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Preços e condição da máquina</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Preço de Compra"
                    value={buyPrice || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                    onChange={(event: any) => handleChange('buyPrice', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Preço com instalação"
                    value={installBuyPrice || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                    onChange={(event: any) => handleChange('installBuyPrice', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    label="Preço em cima do camião"
                    value={truckBuyPrice || ''}
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                    onChange={(event: any) => handleChange('truckBuyPrice', event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl component="fieldset" fullWidth margin="dense">
                  <FormLabel component="legend">A máquina é nova?</FormLabel>
                  <RadioGroup
                    value={isMachineNew !== undefined && isMachineNew !== null ? String(isMachineNew || false) : null}
                    onChange={(event: any) => handleChange('isMachineNew', event.target.value === 'true')}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Sim" />
                    <FormControlLabel value="false" control={<Radio />} label="Nao" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {isMachineNew === false && (
                <>
                  <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="machine-machineYear">Ano</InputLabel>
                      <Select
                        labelId="machine-machineYear"
                        value={machineYear || ''}
                        onChange={(event: any) => handleChange('machineYear', event.target.value)}
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        {new Array(new Date().getFullYear() + 1 - 1980).fill(undefined).map((u, y) => <MenuItem key={y} value={y + 1980}>{y + 1980}</MenuItem>).reverse()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        label="Horas de Uso"
                        value={machineUsageHours || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        onChange={(event: any) => handleChange('machineUsageHours', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item sm={12}>
                <FormControl margin="normal" required fullWidth error={submitted && !status}>
                  <InputLabel id="marchine-status">Estado</InputLabel>
                  <Select
                    labelId="marchine-status"
                    value={status || ''}
                    onChange={(event: any) => handleChange('status', event.target.value)}
                  >
                    {machineStatus.map((s) => (<MenuItem key={s.id} value={s.id}>{s.value}</MenuItem>))}
                  </Select>
                  {submitted && !status ? <FormHelperText>Deve seleccionar o estado da máquina</FormHelperText> : null}
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    );
  }
};

export default FormInfoPrices;
