import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { deleteMachine, closeDeleteMachine } from '../../../../actions/index';
import { IAppState } from '../../../../reducers/index';
import DeleteConfirmation from './DeleteConfirmation';

const mapStateToProps = ({ machines }: IAppState) => ({
  machine: machines.machines[machines.deleteForm.machineId || 0],
  loading: machines.deleteForm.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    closeDeleteMachine: () => dispatch(closeDeleteMachine()),
    deleteMachine: (machineId: number) =>
      dispatch(deleteMachine.request({ machineId })),
  };
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(DeleteConfirmation);
