import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useDrag } from 'react-dnd'

import Task from '../../../../../models/Task';
import User from '../../../../../models/User';

interface IProps {
  index: number;
  openModal: (task: Task) => void;
  task: Task;
  users: User[];
  viewOnly: boolean;
}

const TaskComponent: FunctionComponent <IProps> = (properties) => {
  const { index, openModal, task, users, viewOnly } = properties;

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'task', task, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div ref={!viewOnly ? drag : undefined}>
      <Box pb={2}>
        <Card style={{ cursor: 'pointer', opacity: isDragging ? 0.5 : 1 }} onClick={() => openModal(task)}>
          <CardContent>
            <Box pb={2}>
              <Typography variant="body1" component="span">{task.title}</Typography>

              {(task.signs || []).length
                ? <Chip
                  label="Assinada"
                  variant="outlined"
                  size="small"
                  style={{ float: 'right' }}
                /> : null}
            </Box>
            <Typography variant="body2"><FontAwesomeIcon className="form-icon" icon={faUserCog} /> {(task.technicians || []).map((t) => (users.find((u) => u.id === t) || {}).name).join(', ')}</Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default TaskComponent;
