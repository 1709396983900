import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Proposal from '../../../../../models/Proposal';
import Button from '../../../../common/Button';
import { getCoverImage } from '../../../../common/utils';
import Customer from '../../../../../models/Customer';
import Machine from '../../../../../models/Machine';
import User from '../../../../../models/User';

interface IProps {
  customers: Record<Customer['id'], Customer>;
  proposal: Proposal;
  fetchUsers: () => void;
  fetchMachines: () => void;
  loading: boolean;
  machines: Record<Machine['id'], Machine>;
  toggleProposalView: (customerId?: number) => void;
  users: Record<User['id'], User>;
}

interface IState {
  tab: number;
}

class ViewModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { tab: 0 };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  public componentDidMount() {
    const { fetchUsers, fetchMachines } = this.props;

    fetchUsers();
    fetchMachines();
  }

  public handleTabChange(event: any, tab: number) {
    this.setState({ tab });
  }

  public render() {
    const { customers, loading, proposal, machines, toggleProposalView, users } = this.props;
    const {
      id,
      commercialUserId,
      date,
      machineId,
      priceSelection,
      discount,
      specialPrice,
      total,
      customerId,
      opportunityObservations,
      status,
      deliveryDays,
      proposalObservations,
      options,
    } = proposal;
    const { tab } = this.state;

    const machine = machines[machineId] || ({} as Machine);

    return (
      <Dialog fullWidth maxWidth="lg" open onClose={() => toggleProposalView()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faUserTag} />
          <span style={{ marginLeft: '8px' }}>Detalhes da proposta</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleProposalView()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? <CircularProgress size={24} /> : (
            <>
              <AppBar position="static">
                <Tabs variant="fullWidth" value={tab} onChange={this.handleTabChange}>
                  <Tab label="Oportunidade" />
                  <Tab label="Proposta" disabled={!status} />
                </Tabs>
              </AppBar>
              {tab === 0 ? (
                <Box mt={2}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Comercial responsável</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(users[commercialUserId] || {}).name}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Data da oportunidade</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{format(new Date(date), 'yyyy-MM-dd')}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Máquina</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">#{(machines[machineId] || {}).id} {(machines[machineId] || {}).description}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Cliente</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(customers[customerId] || {}).name}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Observações</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{opportunityObservations}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box mt={2}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Estado</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{
                        status === 'oportunity' ? 'Oportunidade'
                        : status === 'pending' ? 'Pendente'
                        : status === 'awarded' ? 'Adjudicada'
                        : status === 'notawarded' ? 'Não Ajudicada'
                        : ''
                      }</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Prazo de entrega</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{deliveryDays || ''}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={12}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Fotografia</TableCell>
                              <TableCell>Ref. Máquina</TableCell>
                              <TableCell>Modelo</TableCell>
                              <TableCell>Preço Máq.</TableCell>
                              <TableCell>Preço total</TableCell>
                              <TableCell>Desconto</TableCell>
                              <TableCell>Preço Especial</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {machines[machineId] ? (
                              <TableRow>
                                <TableCell>
                                  {getCoverImage(machine.files) !== undefined ? (
                                    <img
                                      alt={machine.description || ''}
                                      src={`${process.env.REACT_APP_API_URL}/api/files/machines/${getCoverImage(machine.files)!.filename}`}
                                      style={{ maxWidth: '96px', maxHeight: '72px'}}
                                    />
                                  ) : null}
                                </TableCell>
                                <TableCell>{machine.id || ''}</TableCell>
                                <TableCell>{machine.model}</TableCell>
                                <TableCell>{
                                  priceSelection === 'installBuyPrice'
                                  ? 'Preço com instalação'
                                  : priceSelection === 'truckBuyPrice'
                                  ? 'Preço em cima do camião'
                                  : ''
                                }</TableCell>
                                <TableCell>{total.toFixed(2)}</TableCell>
                                <TableCell>{discount.toFixed(2)}</TableCell>
                                <TableCell>{specialPrice ? specialPrice.toFixed(2) : undefined}</TableCell>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    {(options || []).filter((o) => o.active).length > 0 ? (
                      <Grid item sm={12}>
                        <Box mt={2}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Opções da máquina</TableCell>
                                  <TableCell>Quantidade</TableCell>
                                  <TableCell>Preço</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {options.filter((o) => o.active).map((o, i) => (
                                  <TableRow key={i}>
                                    <TableCell>{o.description}</TableCell>
                                    <TableCell>{o.quantity}</TableCell>
                                    <TableCell>{(o.sellValue || 0).toFixed(2)}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Grid>
                    ) : null}
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Observações</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{proposalObservations}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            <Box mr={1}>
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
                component={Link}
                to={`/dashboard/customers/proposals/proposalid/${id}/edit`}
              >Editar</Button>
            </Box>
            <Button color="default" variant="contained" onClick={() => toggleProposalView()}>Fechar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default ViewModal;