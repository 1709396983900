import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchSuppliers, toggleMachineView } from '../../../../actions';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import View from './ViewModal';

const mapStateToProps = ({ authentication, machines, suppliers, tables }: IAppState) => ({
  loading: suppliers.loading,
  machine: machines.machines[machines.view.machineId || 0] || {},
  suppliers: Object.values(suppliers.suppliers),
  machineStatus: (tables.dynamicFields.fetch.machineStatus || {}).values || [],
  user: authentication.user || ({} as User),
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchSuppliers: () => dispatch(fetchSuppliers.request()),
    toggleMachineView: (machineId?: number) =>
      dispatch(toggleMachineView(machineId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);