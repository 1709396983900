import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Proposals from './Proposals';
import Form from './Form';

interface IProps {
  fetchCustomers: () => void;
  history: any;
  loading: boolean;
}
interface IState {}

class Customers extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchCustomers } = this.props;

    fetchCustomers();
  }

  public render() {
    const { history } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route path="/dashboard/customers/list" exact component={List} />
          <Route path="/dashboard/customers/proposals" render={(props) => <Proposals history={history} location={props.location} />} />
          <Route path="/dashboard/customers/new" exact render={(props) => <Form history={history} location={props.location} />} />
          <Route path="/dashboard/customers/customerid/:customerid/edit" exact render={(props) => <Form history={history} location={props.location} customerid={props.match.params.customerid} />} />
          <Redirect to="/authenticate" />
        </Switch>
      </Router>
    );
  }
};

export default Customers;