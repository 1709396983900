import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronRight, faPencilAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import MUILink from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import { parse } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import Customer from '../../../../../models/Customer';
import Proposal from '../../../../../models/Proposal';
import { TableFilter } from '../../../../../models/TableFilter';
import Button from '../../../../common/Button';
import TableButton from '../../../../common/TableButton';
import { Column } from '../../../../common/types';
import { tableTranslations } from '../../../../common/utils';
import ViewModal from '../ViewModal';
import ViewMachineModal from '../../../Machines/ViewModal';

import './List.css';

interface IProps {
  history: any;
  location: any;
  match: any;
  proposals: Proposal[];
  customers: Record<string, Customer>;
  fetchCustomers: () => void;
  fetchMachines: () => void;
  loading: boolean;
  toggleProposalView: (proposalId?: number) => void;
  toggleMachineView: (machineId?: number) => void;
  view?: number;
  viewMachine?: number;
}
interface IState {
  columns: Column[];
  filter: TableFilter;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { id, year, status } = parse(props.location.search, { ignoreQueryPrefix: true });

    const filter = this.getTableState();
    const columns = this.apendFilterListToColumns([
      { name: 'id', label: 'ID', options: { display: (localStorage.getItem('proposals_list_columns_id') || 'true') as Display, filterList: id ? [String(id)] : [] } },
      { name: 'date', label: 'Data oportunidade', options: { display: (localStorage.getItem('proposals_list_columns_date') || 'true') as Display } },
      { name: 'customerName', label: 'Cliente', options: { display: (localStorage.getItem('proposals_list_columns_customerName') || 'true') as Display } },
      { name: 'observations', label: 'Observações', options: { display: (localStorage.getItem('proposals_list_columns_observations') || 'true') as Display } },
      { name: 'deliveryDays', label: 'Prazo de entrega', options: { display: (localStorage.getItem('proposals_list_columns_deliveryDays') || 'true') as Display } },
      {
        name: 'statusText',
        label: 'Estado',
        options: {
          display: (localStorage.getItem('proposals_list_columns_statusText') || 'true') as Display,
          filterList: status ? [
            status === 'pending'
            ? 'Pendente'
            : status === 'awarded'
            ? 'Adjudicada'
            : status === 'notawarded'
            ? 'Não Ajudicada'
            : '-'
          ] : [],
        },
      },
      { name: 'machineLabel', label: 'Máquina', options: { display: (localStorage.getItem('proposals_list_columns_machineLabel') || 'true') as Display } },
      { name: 'total', label: 'Valor Total', options: { display: (localStorage.getItem('proposals_list_columns_total') || 'true') as Display } },
      {
        name: 'year',
        label: 'Ano',
        options: {
          display: (localStorage.getItem('proposals_list_columns_year') || 'false') as Display,
          filterList: year ? [String(year)] : [],
        }
      },
      { name: 'commercialName', label: 'Comercial', options: { display: (localStorage.getItem('proposals_list_columns_commercialName') || 'true') as Display } },
      {
        name: 'actions',
        label: 'Acções',
        options: {
          display: (localStorage.getItem('proposals_list_columns_actions') || 'true') as Display,
          filter: false,
          customHeadRender: (columnMeta) => (
            <TableCell key="-1">
              <div style={{ width: '130px'}}>{columnMeta.label}</div>
            </TableCell>
          ),
        },
      },
    ], filter.filterList, false);

    this.state = {
      columns,
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public componentDidMount() {
    const {
      fetchCustomers,
      fetchMachines
    } = this.props;

    fetchCustomers();
    fetchMachines();
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`proposals_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }

  public customRowRender(rowData: any[], dataIndex: number, rowIndex: number) {
    const { columns } = this.state;
    const { proposals, toggleProposalView, toggleMachineView } = this.props;

    const proposal = (proposals[dataIndex] || {});

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && <TableCell>{format(new Date(rowData[1]), 'yyyy-MM-dd')}</TableCell>}
        {columns[2].options.display === 'true' && <TableCell>{rowData[2]}</TableCell>}
        {columns[3].options.display === 'true' && <TableCell>{rowData[3] || <b>_</b>}</TableCell>}
        {columns[4].options.display === 'true' && <TableCell>{rowData[4] ? rowData[4] : <b>_</b>}</TableCell>}
        {columns[5].options.display === 'true' && (
          <TableCell>
            {rowData[5] ? (
              <>
                <Chip
                  style={{
                    height: 'auto',
                    padding: '3px',
                    backgroundColor:
                      rowData[5] === 'Oportunidade'
                      ? '#ff9100'
                      : rowData[5] === 'Pendente'
                      ? '#007bff'
                      : rowData[5] === 'Adjudicada'
                      ? '#28a745'
                      : rowData[5] === 'Não Ajudicada'
                      ? '#dc3545'
                      : '',
                    color: '#ffffff',
                  }}
                  label={rowData[5]}
                />
                {proposal.deliveryDays ? <small style={{ paddingLeft: '5px' }}>({proposal.deliveryDays} dias)</small> : null}
              </>
            ) : (<b>_</b>)}
          </TableCell>
        )}
        {columns[6].options.display === 'true' && <TableCell>
          <Button color="primary" onClick={() => toggleMachineView(rowData[6].split(' ')[0])} variant="outlined" size="small">#{rowData[6]}</Button>
        </TableCell>}
        {columns[7].options.display === 'true' && <TableCell>
          <div style={{ textAlign: 'right' }}>{rowData[7].toFixed(2)}€</div>
        </TableCell>}
        {columns[8].options.display === 'true' && <TableCell>{rowData[8]}</TableCell>}
        {columns[9].options.display === 'true' && <TableCell>{rowData[9]}</TableCell>}
        {columns[10].options.display === 'true' &&  <TableCell>
          {!rowData[5] ? (
            <Tooltip title="Sem dados de proposta" aria-label="Sem dados de proposta">
              <span>
                <TableButton disabled>
                  <FontAwesomeIcon icon={faFileAlt} />
                </TableButton>
              </span>
            </Tooltip>
          ) : (
            <MUILink href={`${process.env.REACT_APP_API_URL}/api/customers/proposals/proposalid/${rowData[0]}.pdf`} target="_blank">
              <TableButton>
                <FontAwesomeIcon icon={faFileAlt} />
              </TableButton>
            </MUILink>
          )}
          <TableButton onClick={() => toggleProposalView(rowData[0])}>
            <FontAwesomeIcon icon={faEye} />
          </TableButton>
          <Link to={`/dashboard/customers/proposals/proposalid/${rowData[0]}/edit`}>
            <TableButton>
              <FontAwesomeIcon icon={faPencilAlt} />
            </TableButton>
          </Link>
        </TableCell>}
      </TableRow>
    );
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('proposals_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('proposals_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][], all: boolean): any[] {
    const { location } = this.props;
    const { id, year, status } = parse(location.search, { ignoreQueryPrefix: true });

    filterList.forEach((filter, index) => {
      if (
        (
          (index !== 0 || !id) &&
          (index !== 5 || !year) &&
          (index !== 8 || !status)
        ) || all
      ) {
        (columns[index] || {}).options = {
          ...((columns[index] || {}).options || {}),
          filterList: filter,
        };
      }
    });

    return columns;
  }

  public render() {
    const { columns, filter } = this.state;
    const { customers, proposals, view, viewMachine } = this.props;

    return (
      <div className="dashboard-container">
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">Clientes</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">Propostas</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item sm={6} style={{ textAlign: 'right' }}>
              <Link to="/dashboard/customers/proposals/new">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                >Adicionar</Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <MUIDataTable
                title={''}
                data={proposals.map((p) => ({
                  ...p,
                  machineLabel: p.machineId ? `${p.machineId}${p.machineBrand ? ' ' + p.machineBrand : ''}${p.machineModel ? ' ' + p.machineModel : ''}` : undefined,
                  customerName: (customers[p.customerId] || {}).name,
                  observations: p.proposalObservations || p.opportunityObservations,
                  statusText: p.status === 'oportunity'
                    ? 'Oportunidade'
                    : p.status === 'pending'
                    ? 'Pendente'
                    : p.status === 'awarded'
                    ? 'Adjudicada'
                    : p.status === 'notawarded'
                    ? 'Não Ajudicada'
                    : '-',
                  year: new Date(p.date).getFullYear(),
                  total: p.total * (1 - (p.discount || 0) * 0.01),
                }))}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList, true),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {view ? <ViewModal /> : null}
        {viewMachine ? <ViewMachineModal /> : null}
      </div>
    );
  }
};

export default withRouter(List);