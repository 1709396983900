import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  DeleteMachineTicket,
  deleteMachineTicket,
} from '../../../../../actions/machines';
import { IAppState } from '../../../../../reducers/index';
import DeleteModal from './DeleteModal';

const mapStateToProps = ({ machines }: IAppState) => ({
  loading: machines.tickets.ticketDeletePopup.loading, 
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteMachineTicket: (properties: DeleteMachineTicket) =>
      dispatch(deleteMachineTicket.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);