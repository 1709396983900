import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeMarketplaceForm,
  SendMachineContact,
  sendMachineContact
} from '../../../actions/index';
import { IAppState } from '../../../reducers';
import ContactForm from './ContactForm';

const mapStateToProps = ({ machines }: IAppState) => ({
  loading: machines.marketplace.contactForm.loading,
  machineId: machines.marketplace.contactForm.machineId,
  machines: machines.marketplace.machines || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    handleClose: () => dispatch(closeMarketplaceForm()),
    sendMachineContact: (properties: SendMachineContact) =>
      dispatch(sendMachineContact.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);