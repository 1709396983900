import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChevronRight, faEnvelope, faPhone, faMapMarkedAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateSupplier, EditSupplier } from '../../../../actions/suppliers';
import { countries, validateEmail } from '../../../../data';
import { DynamicValue } from '../../../../models/DynamicField';
import Supplier from '../../../../models/Supplier';
import Button from '../../../common/Button';

interface IProps {
  createSupplier: (properties: CreateSupplier) => void;
  supplier?: Supplier;
  editSupplier: (properties: EditSupplier) => void;
  fetchSupplierTypes: () => void;
  history: any;
  loading: boolean;
  supplierTypes: DynamicValue[];
  redirectReady: boolean;
}
interface IState {
  id?: number;
  name: string;
  email: string;
  telephone: string;
  supplierTaxNumber: string;
  supplierType?: number;
  companyName: string;
  address: string;
  city: string;
  country: string;
  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      id,
      name,
      email,
      telephone,
      supplierTaxNumber,
      supplierType,
      companyName,
      address,
      city,
      country
    } = props.supplier || {};

    this.state = {
      id,
      name: name || '',
      email: email || '',
      telephone: telephone || '',
      supplierTaxNumber: supplierTaxNumber || '',
      supplierType: supplierType || undefined,
      companyName: companyName || '',
      address: address || '',
      city: city || '',
      country: country || '',
      submitted: false,
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const { fetchSupplierTypes } = this.props;

    fetchSupplierTypes();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { history, supplier, redirectReady } = this.props;

    if (JSON.stringify(prevProps.supplier) !== JSON.stringify(supplier)) {
      const {
        id,
        name,
        email,
        telephone,
        supplierTaxNumber,
        supplierType,
        companyName,
        address,
        city,
        country
      } = supplier || {};
  
      this.setState({
        id,
        name: name || '',
        email: email || '',
        telephone: telephone || '',
        supplierTaxNumber: supplierTaxNumber || '',
        supplierType: supplierType || undefined,
        companyName: companyName || '',
        address: address || '',
        city: city || '',
        country: country || '',
        submitted: false,
      });
    }
    
    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        history.push('/dashboard/suppliers/list');
      });
    }
  }

  public hasErrors() {
    const { email, name, supplierTaxNumber } = this.state;

    if (
      !name.length ||
      !supplierTaxNumber.length ||
      (!!email && !validateEmail(email))
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createSupplier, editSupplier } = this.props;
    const {
      id,
      address,
      city,
      companyName,
      country,
      supplierTaxNumber,
      supplierType,
      email,
      name,
      telephone,
    } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editSupplier({
        id,
        address: address || undefined,
        city: city || undefined,
        companyName: companyName || undefined,
        country: country || undefined,
        supplierTaxNumber,
        supplierType: supplierType || undefined,
        email: email || undefined,
        name,
        telephone: telephone || undefined,
      });
    } else {
      createSupplier({
        address: address || undefined,
        city: city || undefined,
        companyName: companyName || undefined,
        country: country || undefined,
        supplierTaxNumber,
        supplierType: supplierType || undefined,
        email: email || undefined,
        name,
        telephone: telephone || undefined,
      });
    }
  }

  public handleChange(field: string, event: any) {
    this.setState({
      [field]: event.target.value,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public render() {
    const { loading, supplierTypes } = this.props;
    const {
      id,
      address,
      city,
      companyName,
      country,
      supplierTaxNumber,
      supplierType,
      email,
      name,
      telephone,
      submitted,
      dirty,
    } = this.state;

    return (
      <>
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />
        
        <Box>
          <Typography variant="h6" component="h2">Fornecedores</Typography>
          <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard/suppliers/list">
              Lista de Fornecedores
            </Link>
            <Typography color="textPrimary">{id ? `Editar ${name}` : 'Adicionar'}</Typography>
          </Breadcrumbs>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/suppliers/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Informações</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !name.length}
                            label="Nome"
                            required
                            value={name}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('name', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faUserTag} />
                                </InputAdornment>
                              )
                            }}
                            helperText={submitted && !name.length ? 'Deve introduzir o nome do cliente' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            label="Telefone"
                            value={telephone}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('telephone', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faPhone} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            label="Empresa"
                            value={companyName}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('companyName', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment className="form-icon" position="start">
                                  <FontAwesomeIcon icon={faBuilding} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid sm={6} item>
                    <Grid container spacing={4}>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !!email && !validateEmail(email)}
                            label="Email"
                            value={email}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('email', event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faEnvelope} />
                                </InputAdornment>
                              )
                            }}
                            helperText={submitted && !!email && !validateEmail(email) ? 'Deve introduzir um email válido' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            error={submitted && !supplierTaxNumber.length}
                            label="Número de contribuinte"
                            required
                            value={supplierTaxNumber}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('supplierTaxNumber', event)}
                            helperText={submitted && !supplierTaxNumber.length ? 'Deve introduzir o número de contribuinte' : ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sm={12} item>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            label="Tipo de Fornecedor"
                            select
                            value={supplierType || ''}
                            variant="standard"
                            onChange={(event: any) => this.handleChange('supplierType', event)}
                          >
                            {supplierTypes.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Localização</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={12} item>
                    <FormControl fullWidth>
                      <TextField
                        label="Morada"
                        fullWidth
                        value={address}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('address', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faMapMarkedAlt} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        label="Cidade"
                        value={city}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('city', event)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        label="País"
                        select
                        value={country}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('country', event)}
                      >
                        {countries.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box pb={2}>
          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/suppliers/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
};

export default Form;
