import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchUsers } from '../../../actions/users';
import { IAppState } from '../../../reducers/index';
import Users from './Users';

const mapStateToProps = ({ users }: IAppState) => ({
  loading: users.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchUsers: () =>
      dispatch(fetchUsers.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
