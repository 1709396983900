import { createAction, createAsyncAction } from 'typesafe-actions';
import { DynamicValue } from '../models/DynamicField';

import Zone, { ZoneMeta } from '../models/Zone';
import { IFailed } from './types';

enum Types {
  FETCH_ZONES_TABLE = '@@tables/FETCH_ZONES_TABLE',
  FETCH_ZONES_TABLE_FAILED = '@@users/FETCH_ZONES_TABLE_FAILED',
  FETCH_ZONES_TABLE_SUCCEEDED = '@@users/FETCH_ZONES_TABLE_SUCCEEDED',

  CREATE_TABLE_ZONE = '@@tables/CREATE_TABLE_ZONE',
  CREATE_TABLE_ZONE_FAILED = '@@tables/CREATE_TABLE_ZONE_FAILED',
  CREATE_TABLE_ZONE_SUCCEEDED = '@@tables/CREATE_TABLE_ZONE_SUCCEEDED',

  EDIT_TABLE_ZONE = '@@tables/EDIT_TABLE_ZONE',
  EDIT_TABLE_ZONE_FAILED = '@@tables/EDIT_TABLE_ZONE_FAILED',
  EDIT_TABLE_ZONE_SUCCEEDED = '@@tables/EDIT_TABLE_ZONE_SUCCEEDED',

  DELETE_TABLE_ZONE = '@@tables/DELETE_TABLE_ZONE',
  DELETE_TABLE_ZONE_FAILED = '@@tables/DELETE_TABLE_ZONE_FAILED',
  DELETE_TABLE_ZONE_SUCCEEDED = '@@tables/DELETE_TABLE_ZONE_SUCCEEDED',

  OPEN_TABLE_ZONE_FORM = '@@tables/OPEN_TABLE_ZONE_FORM',
  CLOSE_TABLE_ZONE_FORM = '@@tables/CLOSE_TABLE_ZONE_FORM',
  OPEN_DELETE_TABLE_ZONE_FORM = '@@tables/OPEN_DELETE_TABLE_ZONE_FORM',
  CLOSE_DELETE_TABLE_ZONE_FORM = '@@tables/CLOSE_DELETE_TABLE_ZONE_FORM',

  FETCH_ZONE_LIST = '@@tables/FETCH_ZONE_LIST',
  FETCH_ZONE_LIST_FAILED = '@@users/FETCH_ZONE_LIST_FAILED',
  FETCH_ZONE_LIST_SUCCEEDED = '@@users/FETCH_ZONE_LIST_SUCCEEDED',

  FETCH_DYNAMICFIELD_VALUES = '@@tables/FETCH_DYNAMICFIELD_VALUES',
  FETCH_DYNAMICFIELD_VALUES_FAILED = '@@users/FETCH_DYNAMICFIELD_VALUES_FAILED',
  FETCH_DYNAMICFIELD_VALUES_SUCCEEDED = '@@users/FETCH_DYNAMICFIELD_VALUES_SUCCEEDED',
  
  EDIT_DYNAMICFIELD = '@@tables/EDIT_DYNAMICFIELD',
  EDIT_DYNAMICFIELD_FAILED = '@@tables/EDIT_DYNAMICFIELD_FAILED',
  EDIT_DYNAMICFIELD_SUCCEEDED = '@@tables/EDIT_DYNAMICFIELD_SUCCEEDED',
  
  OPEN_DYNAMICFIELD_FORM = '@@tables/OPEN_DYNAMICFIELD_FORM',
  CLOSE_DYNAMICFIELD_FORM = '@@tables/CLOSE_DYNAMICFIELD_FORM',

  FETCH_DYNAMICFIELD_VALUES_BYFORM = '@@tables/FETCH_DYNAMICFIELD_VALUES_BYFORM',
  FETCH_DYNAMICFIELD_VALUES_BYFORM_FAILED = '@@users/FETCH_DYNAMICFIELD_VALUES_BYFORM_FAILED',
  FETCH_DYNAMICFIELD_VALUES_BYFORM_SUCCEEDED = '@@users/FETCH_DYNAMICFIELD_VALUES_BYFORM_SUCCEEDED',
}

interface FetchSuccess {
  zones: Zone[];
}

export const fetchZonesTable = createAsyncAction(
  Types.FETCH_ZONES_TABLE,
  Types.FETCH_ZONES_TABLE_SUCCEEDED,
  Types.FETCH_ZONES_TABLE_FAILED
)<void, FetchSuccess, IFailed>();

export interface CreateTableZone {
  name: string;
  price: number;
}

interface ZoneTableSuccess {
  zone: Zone;
}

export const createTableZone = createAsyncAction(
  Types.CREATE_TABLE_ZONE,
  Types.CREATE_TABLE_ZONE_SUCCEEDED,
  Types.CREATE_TABLE_ZONE_FAILED
)<CreateTableZone, ZoneTableSuccess, IFailed>();

export interface EditTableZone {
  id: number;
  name: string;
  price: number;
}

export const editTableZone = createAsyncAction(
  Types.EDIT_TABLE_ZONE,
  Types.EDIT_TABLE_ZONE_SUCCEEDED,
  Types.EDIT_TABLE_ZONE_FAILED
)<EditTableZone, ZoneTableSuccess, IFailed>();

export interface DeleteTableZone {
  id: number;
}

export const deleteTableZone = createAsyncAction(
  Types.DELETE_TABLE_ZONE,
  Types.DELETE_TABLE_ZONE_SUCCEEDED,
  Types.DELETE_TABLE_ZONE_FAILED
)<DeleteTableZone, DeleteTableZone, IFailed>();

export const openTableZoneForm = createAction(Types.OPEN_TABLE_ZONE_FORM)<Zone | undefined>();
export const closeTableZoneForm = createAction(Types.CLOSE_TABLE_ZONE_FORM)<void>();
export const openDeleteTableZone = createAction(Types.OPEN_DELETE_TABLE_ZONE_FORM)<Zone>();
export const closeDeleteTableZone = createAction(Types.CLOSE_DELETE_TABLE_ZONE_FORM)<void>();

export const fetchZoneList = createAsyncAction(
  Types.FETCH_ZONE_LIST,
  Types.FETCH_ZONE_LIST_SUCCEEDED,
  Types.FETCH_ZONE_LIST_FAILED
)<void, { zones: ZoneMeta[] }, IFailed>();


interface FetchDymicFieldValuesSuccess {
  values: {
    id: number;
    value: string;
  }[];
}
export const fetchDynamicFieldValues = createAsyncAction(
  Types.FETCH_DYNAMICFIELD_VALUES,
  Types.FETCH_DYNAMICFIELD_VALUES_SUCCEEDED,
  Types.FETCH_DYNAMICFIELD_VALUES_FAILED
)<{ id: string }, FetchDymicFieldValuesSuccess, IFailed>();

export interface EditDynamicField {
  id: string;
  values: {
    id?: number;
    value: string;
  }[]
}

export const editDynamicField = createAsyncAction(
  Types.EDIT_DYNAMICFIELD,
  Types.EDIT_DYNAMICFIELD_SUCCEEDED,
  Types.EDIT_DYNAMICFIELD_FAILED
)<EditDynamicField, void, IFailed>();

export const openDynamicFieldForm = createAction(Types.OPEN_DYNAMICFIELD_FORM)<{ id: string; name: string }>();
export const closeDynamicFieldForm = createAction(Types.CLOSE_DYNAMICFIELD_FORM)<void>();

interface FetchDymicFieldValuesByFormError extends IFailed {
  id: string;
}
interface FetchDymicFieldValuesByFormSuccess {
  id: string;
  values: DynamicValue[];
}
export const fetchDynamicFieldValuesByForm = createAsyncAction(
  Types.FETCH_DYNAMICFIELD_VALUES_BYFORM,
  Types.FETCH_DYNAMICFIELD_VALUES_BYFORM_SUCCEEDED,
  Types.FETCH_DYNAMICFIELD_VALUES_BYFORM_FAILED
)<{ id: string }, FetchDymicFieldValuesByFormSuccess, FetchDymicFieldValuesByFormError>();
