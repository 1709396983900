import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IAppState } from '../../../../../reducers/index';
import {
  fetchCustomers,
  fetchUsers,
  openSign,
  showDeleteMachineTicket,
  toggleMachineView,
  toggleMachineTicketTechsView,
} from '../../../../../actions/index';
import User from '../../../../../models/User';
import List from './List';

const mapStateToProps = ({ authentication, customers, machines, users }: IAppState) => ({
  customers: customers.customers,
  machines: machines.machines,
  loading: customers.loading || machines.tickets.loading || machines.loading || users.loading,
  deleteMachineTicket: machines.tickets.ticketDeletePopup.id,
  openSignModal: machines.tickets.signForm.open,
  tickets: Object.values(machines.tickets.tickets),
  users: users.users,
  user: authentication.user || ({} as User),
  viewTechs: machines.tickets.viewTechs.ticketId,
  viewMachine: machines.view.machineId,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchCustomers: () => dispatch(fetchCustomers.request()),
    fetchUsers: () => dispatch(fetchUsers.request()),
    openSign: (ticketId: number, taskId: number) =>
      dispatch(openSign({ ticketId, taskId })),
    showDeleteMachineTicket: (id?: number) =>
      dispatch(showDeleteMachineTicket(id)),
    toggleMachineView: (machineId?: number) =>
      dispatch(toggleMachineView(machineId)),
    toggleMachineTicketTechsView: (ticketId?: number) =>
      dispatch(toggleMachineTicketTechsView(ticketId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
