import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { authenticate } from '../../actions/index';
import { IAppState } from '../../reducers/index';
import Auth from './Auth';

const mapStateToProps = ({ authentication }: IAppState) => ({
  error: authentication.login.error,
  loading: authentication.login.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authentication: (email: string, password: string) =>
      dispatch(authenticate.request({ email, password })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
