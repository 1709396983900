import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Button from '../common/Button';
import BlackCard from '../common/BlackCard';
import './Auth.css';

interface IProps {
  loading: boolean;
  authentication: (email: string, password: string) => void;
  error?: object;
}

interface IState {
  email: string;
  password: string;
  submitted: boolean;
}

class Auth extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private handleKeyPress(e: any) {
    if (e.which === 13) {
      this.handleSubmit();
    }
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'email'>);
    };
  }

  public hasErrors() {
    const { email, password } = this.state;

    if (!email.length || !password.length) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { email, password } = this.state;
    const { authentication } = this.props;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    authentication(email, password);
  }

  public render() {
    const { email, password, submitted } = this.state;
    const { loading } = this.props;

    return (
      <Box mt={10}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item sm={3}>
            <Box className="auth-logo" mb={4}>
              <img alt="GoTec" src="/logo.png" />
            </Box>
            <BlackCard>
              <CardHeader title={
                <Typography variant="h6" component="h5">Bem vindo(a).</Typography>
              } />
              <CardContent>
                <FormControl
                  required
                  fullWidth
                  margin="normal"
                >
                  
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    onChange={this.handleChange('email')}
                    onKeyPress={this.handleKeyPress}
                    value={email}
                    variant="standard"
                    required
                    helperText={submitted && !email.length ? 'Deve introduzir o email' : ''}
                  />
                </FormControl>

                <FormControl
                  required
                  fullWidth
                  margin="normal"
                >
                  <TextField
                    id="password"
                    type="password"
                    name="password"
                    label="Password"
                    onChange={this.handleChange('password')}
                    onKeyPress={this.handleKeyPress}
                    value={password}
                    variant="standard"
                    required
                    helperText={submitted && !password.length ? 'Deve introduzir a password' : ''}
                  />
                </FormControl>
                <Link to="/password-recovery">
                  <Typography color="secondary" variant="body2">Recuperar Palavra-Passe</Typography>
                </Link>
                <FormControl
                  required
                  fullWidth
                  margin="normal"
                >
                  <Box mt={4}>
                    <Button
                      onClick={this.handleSubmit}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      center
                    >{loading ? <CircularProgress size={24} /> : 'Iniciar Sessão'}</Button>
                  </Box>
                </FormControl>
              </CardContent>
            </BlackCard>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Auth;
