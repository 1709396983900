import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  CreateSupplier,
  createSupplier,
  EditSupplier,
  editSupplier,
  fetchDynamicFieldValuesByForm,
} from '../../../../actions/index';
import { IAppState } from '../../../../reducers/index';
import Form from './Form';

interface IProps {
  supplierid?: string;
}

const mapStateToProps = ({ suppliers, tables }: IAppState, props: IProps) => ({
  loading: suppliers.form.loading || (tables.dynamicFields.fetch.supplierTypes || {}).loading,
  redirectReady: suppliers.form.redirectReady,
  supplier: suppliers.suppliers[Number(props.supplierid || 0)],
  supplierTypes: (tables.dynamicFields.fetch.supplierTypes || {}).values || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createSupplier: (properties: CreateSupplier) =>
      dispatch(createSupplier.request(properties)),
    editSupplier: (properties: EditSupplier) =>
      dispatch(editSupplier.request(properties)),
    fetchSupplierTypes: () =>
      dispatch(fetchDynamicFieldValuesByForm.request({ id: 'supplierTypes' })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
