import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../common/Button';
import User from '../../../models/User';

const StyledToolbar = withStyles({
  root: {
    justifyContent: 'space-between',
  },
})(Toolbar);


interface IProps {
  user?: User;
  logout: () => void;
}

interface IState {
  anchorEl?: any;
  isMenuOpen: boolean;
}

class Header extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
  }

  public handleLogout() {
    const { logout } = this.props;

    this.setState({ isMenuOpen: false }, logout);
  }

  public handleMenuClose() {
    this.setState({ isMenuOpen: false });
  }

  public handleMenu(event: any) {
    this.setState({ anchorEl: event.currentTarget, isMenuOpen: true });
  }

  public render() {
    const { anchorEl, isMenuOpen } = this.state;
    const { user } = this.props;

    return (
      <AppBar position="static">
        <StyledToolbar>
          <Link to="/">
            <img className="logo" alt="GoTec" src="/logo.png" />
          </Link>
          <Button
            aria-label={(user || {}).name}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
          >
            {(user || {}).name}
            <ExpandMore />
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
          >
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
          </Menu>
        </StyledToolbar>
      </AppBar>
    );
  }
}

export default Header;
