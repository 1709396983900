import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  editMachineTicketTask,
  EditMachineTicketTask,
} from '../../../../../../actions/machines';
import Task from '../../../../../../models/Task';
import { IAppState } from '../../../../../../reducers/index';
import MoveModal from './MoveModal';

interface IProps {
  task: Task;
}
const mapStateToProps = ({ machines }: IAppState, props: IProps) => ({
  redirectReady: (machines.tickets.tasksPopupForms[props.task.id] || {}).redirectReady,
  loading: (machines.tickets.tasksPopupForms[props.task.id] || {}).loading, 
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    editMachineTicketTask: (properties: EditMachineTicketTask) =>
      dispatch(editMachineTicketTask.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveModal);
