import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { ActionType } from 'typesafe-actions';

import { clear } from './actions/authentication';
import axios from './api/axios';
import appReducer, { IAppState } from './reducers/index';
import sagas from './sagas/index';

const rootReducer = (state: any, action: ActionType<any>): IAppState => {
  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

let middleware = applyMiddleware(sagaMiddleware);
middleware = composeWithDevTools(
  applyMiddleware(sagaMiddleware),
  applyMiddleware(createLogger())
);

const store = createStore(rootReducer, middleware);
sagaMiddleware.run(sagas);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(clear());
    }

    return Promise.reject(error);
  }
);

export default store;
