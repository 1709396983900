import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { openDynamicFieldForm } from '../../../../actions/index';
import { IAppState } from '../../../../reducers/index';
import DynamicFields from './DynamicFields';

const mapStateToProps = ({ tables }: IAppState) => ({
  showForm: !!tables.dynamicFields.meta,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    openDynamicFieldForm: (meta: { id: string; name: string }) =>
      dispatch(openDynamicFieldForm(meta)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFields);