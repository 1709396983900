import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleMachineTicketTechsView } from '../../../../../actions';
import { IAppState } from '../../../../../reducers/index';
import View from './ViewTechsModal';

const mapStateToProps = ({ machines, users }: IAppState) => ({
  ticket: machines.tickets.tickets[machines.tickets.viewTechs.ticketId || 0] || {},
  users: users.users,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleMachineTicketTechsView: (ticketId?: number) =>
      dispatch(toggleMachineTicketTechsView(ticketId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);