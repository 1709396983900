import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronRight, faPencilAlt, faCopy, faUserTag, faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Machine, { MachineWithProposal } from '../../../../models/Machine';
import User from '../../../../models/User';
import Customer from '../../../../models/Customer';
import { DynamicValue } from '../../../../models/DynamicField';
import { TableFilter } from '../../../../models/TableFilter';
import Button from '../../../common/Button';
import TableButton from '../../../common/TableButton';
import { Column } from '../../../common/types';
import { tableTranslations } from '../../../common/utils';
import { machineTypes } from '../sources';
import CustomerForm from '../CustomerForm';
import ViewModal from '../ViewModal';
import DeleteConfirmation from '../DeleteConfirmation';

interface IProps {
  fetchCustomers: () => void;
  fetchCustomerProposals: () => void;
  machines: MachineWithProposal[];
  loading: boolean;
  toggleMachineView: (machineId?: number) => void;
  toggleMachineCustomerForm: (machine: Machine) => void;
  openDeleteMachine: (machineId: number) => void;
  user: User;
  view?: number;
  customerForm: Machine | undefined;
  deleteForm?: number;
  customers: Record<Customer['id'], Customer>;
  machineStatus: DynamicValue[];
}
interface IState {
  columns: Column[],
  filter: TableFilter;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    
    const filter = this.getTableState();
    const columns = this.apendFilterListToColumns([
      { name: 'id', label: 'ID', options: { display: (localStorage.getItem('machines_list_columns_id') || 'true') as Display, filter: false, } },
      { name: 'brand', label: 'Marca', options: { display: (localStorage.getItem('machines_list_columns_brand') || 'true') as Display } },
      { name: 'model', label: 'Model', options: { display: (localStorage.getItem('machines_list_columns_model') || 'true') as Display } },
      { name: 'machineYear', label: 'Ano', options: { display: (localStorage.getItem('machines_list_columns_machineYear') || 'true') as Display } },
      { name: 'type', label: 'Tipo', options: { display: (localStorage.getItem('machines_list_columns_type') || 'true') as Display } },
      { name: 'description', label: 'Descrição', options: { display: (localStorage.getItem('machines_list_columns_description') || 'true') as Display, filter: false, } },
      { name: 'serialnumber', label: 'SN', options: { display: (localStorage.getItem('machines_list_columns_serialnumber') || 'false') as Display } },
      { name: 'isMachineNew', label: 'Condição', options: { display: (localStorage.getItem('machines_list_columns_isMachineNew') || 'false') as Display } },
      {
        name: 'marketplaceVisibility',
        label: 'Visibilidade Marketplace',
        options: {
          display: (localStorage.getItem('machines_list_columns_marketplaceVisibility') || 'true') as Display,
          customHeadRender: (columnMeta) => (
            <TableCell key="marketplaceVisibility">
              <div style={{ width: '90px' }}>{columnMeta.label}</div>
            </TableCell>
          ),
        }
      },
      { name: 'statusText', label: 'Estado', options: { display: (localStorage.getItem('machines_list_columns_statusText') || 'true') as Display } },
      { name: 'customerName', label: 'Cliente', options: { display: (localStorage.getItem('machines_list_columns_customerName') || 'true') as Display } },
      {
        name: 'actions',
        label: 'Acções',
        options: {
          display: (localStorage.getItem('machines_list_columns_actions') || 'true') as Display,
          filter: false,
          customHeadRender: (columnMeta) => (
            <TableCell key="-1">
              <div style={{ width: '170px' }}>{columnMeta.label}</div>
            </TableCell>
          ),
        },
      },
    ], filter.filterList);

    this.state = {
      columns,
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.deleteMachine = this.deleteMachine.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public componentDidMount() {
    const { fetchCustomers } = this.props;

    fetchCustomers();
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`machines_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }

  public customRowRender(rowData: any[], dataIndex: number, rowIndex: number) {
    const { columns } = this.state;
    const { user, toggleMachineView, toggleMachineCustomerForm, machines } = this.props;

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && <TableCell>{rowData[1]}</TableCell>}
        {columns[2].options.display === 'true' && <TableCell>{rowData[2]}</TableCell>}
        {columns[3].options.display === 'true' && <TableCell>{rowData[3]}</TableCell>}
        {columns[4].options.display === 'true' && <TableCell>{rowData[4]}</TableCell>}
        {columns[5].options.display === 'true' && <TableCell>{rowData[5]}</TableCell>}
        {columns[6].options.display === 'true' && <TableCell>{rowData[6]}</TableCell>}
        {columns[7].options.display === 'true' && <TableCell>{rowData[7]}</TableCell>}
        {columns[8].options.display === 'true' && <TableCell style={{ textAlign: 'center' }}>{
          rowData[8] === true ? (
            <CheckCircleIcon fontSize="large" style={{ color: 'green' }} />
          ) : (
            <CancelIcon fontSize="large" style={{ color: '#cb0000' }} />
          )
        }</TableCell>}
        {columns[9].options.display === 'true' && <TableCell>{rowData[9]}</TableCell>}
        {columns[10].options.display === 'true' && <TableCell>{rowData[10]}</TableCell>}
        {columns[11].options.display === 'true' &&  <TableCell>
          <TableButton onClick={() => toggleMachineView(rowData[0])}>
            <FontAwesomeIcon icon={faEye} />
          </TableButton>
          {user.role !== 'admintech' && user.role !== 'tech' && user.role !== 'externaltech' && user.role !== 'commercial' ? (
            <>
              <Link to={`/dashboard/machines/machineid/${rowData[0]}/edit`}>
                <TableButton>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </TableButton>
              </Link>
              <Link to={`/dashboard/machines/machineid/${rowData[0]}/new`}>
                <TableButton>
                  <FontAwesomeIcon icon={faCopy} />
                </TableButton>
              </Link>
              <TableButton
                onClick={() => toggleMachineCustomerForm(machines[dataIndex])}
                style={{ color: (machines[dataIndex] || {}).customerId ? '#cb0000' : ''}}
              >
                <FontAwesomeIcon icon={faUserTag} />
              </TableButton>
              {(machines[dataIndex] || {}).nproposals === 0 && (
                <TableButton
                  onClick={() => this.deleteMachine(rowData[0])}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </TableButton>
              )}
            </>
        ) : null}
        </TableCell>}
      </TableRow>
    );
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('machines_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('machines_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][]): any[] {
    filterList.forEach((filter, index) => {
      (columns[index] || {}).options = {
        ...((columns[index] || {}).options || {}),
        filterList: filter,
      };
    });

    return columns;
  }

  public deleteMachine(machineId: number) {
    const { openDeleteMachine } = this.props;
    
    openDeleteMachine(machineId);
  }

  public render() {
    const { columns, filter } = this.state;
    const { customerForm, customers, deleteForm, machines, machineStatus, view, user } = this.props;

    return (
      <>
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">Máquinas</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">Lista de Máquinas</Typography>
              </Breadcrumbs>
            </Grid>

            {user.role !== 'admintech' && user.role !== 'tech' ? (
              <Grid item sm={6} style={{ textAlign: 'right' }}>
                <Link to="/dashboard/machines/new">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                  >Adicionar</Button>
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <MUIDataTable
                title={''}
                data={machines.map((m) => ({
                  ...m,
                  type: (machineTypes.find((mt) => mt.id === m.type) || {}).label,
                  isMachineNew: m.isMachineNew ? 'Novo' : 'Usado',
                  statusText: (machineStatus.find((s) => s.id === m.status) || {}).value,
                  customerName: (customers[m.customerId || 0] || {}).name,
                }))}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {view ? <ViewModal /> : null}
        {customerForm ? <CustomerForm /> : null}
        {deleteForm ? <DeleteConfirmation /> : null}
      </>
    );
  }
};

export default List;