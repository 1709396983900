import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Machine from '../../../../models/Machine';

interface IProps {
  machine: Machine;
}

const Logistics: FunctionComponent <IProps> = (properties) => {
  const {
    dimensionC,
    dimensionL,
    dimensionA,
    weight,
    deliveryDate,
    warranty,
    transportPrice,
    paymentConditions,
    commands,
  } = properties.machine;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Dimensão e Peso</Typography>
            </Box>

            <Grid container spacing={2}>
              {dimensionC ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Dimensão C</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{dimensionC} m</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {dimensionL ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Dimensão L</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{dimensionL} m</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {dimensionA ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Dimensão A</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{dimensionA} m</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {weight ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Peso</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{weight} Kg</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Condições gerais de entrega e pagamento</Typography>
            </Box>

            <Grid container spacing={2}>
              {deliveryDate ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Prazo Entrega</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{deliveryDate}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {warranty ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Garantia</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{warranty}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {transportPrice ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Preço de Transporte</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{transportPrice}€</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {paymentConditions ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Condições de Pagamento</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{paymentConditions}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Funções do comando</Typography>
            </Box>

            <Grid container spacing={2}>
              {commands ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Funções Específicas - Funções do comando</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{commands}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default Logistics;
