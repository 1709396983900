import { ActionType, getType } from 'typesafe-actions';

import * as machines from '../actions/machines';
import Machine, { MachineWithProposal } from '../models/Machine';
import Task, { TaskComment, TaskExpense, TaskStatus } from '../models/Task';
import Ticket from '../models/Ticket';

type MachineAction = ActionType<typeof machines>;

const normalizeTicket = (ticket: any) => {
  const tasks: Record<Task['id'], Task> = {};

  const tasksStatus: Record<string, number> = {};
  Object.values(ticket.tasks || []).forEach((task: any) => {
    const expenses: Record<TaskExpense['id'], TaskExpense> = {};
    const comments: Record<TaskComment['id'], TaskComment> = {};

    Object.values(task.expenses || []).forEach((expense: any) => {
      expenses[expense.id] = expense;
    });
    Object.values(task.comments || []).forEach((comment: any) => {
      comments[comment.id] = comment;
    });

    if (!tasksStatus[task.status]) {
      tasksStatus[task.status] = 0;
    }
    tasksStatus[task.status] += 1;

    tasks[task.id] = {
      ...task,
      expenses,
      comments,
    };
  });

  /*
  Em espera se não tivesse tarefas ou se todas as tarefas estivessem em espera
  Em progresso se tivesse tarefas noutros estado que não em espera e sem todas estarem concluídas
  Concluído com todas as tarefas tivessem o estado concluído
  */
  let status = TaskStatus.PENDING;
  if (tasksStatus[TaskStatus.EXECUTION] > 0) {
    status = TaskStatus.EXECUTION;
  } else if (
    tasksStatus[TaskStatus.DONE] > 0 &&
    !tasksStatus[TaskStatus.PENDING] &&
    Object.values(ticket.tasks || []).length
  ) {
    status = TaskStatus.DONE;
  }

  return { ...ticket, tasks, status };
};

interface IContactForm {
  readonly open: boolean;
  readonly error?: object;
  readonly loading: boolean;
  readonly machineId: number;
}

export interface IFormState {
  readonly error?: object;
  readonly loading: boolean;
  readonly redirectReady: boolean;
  readonly redirectReadyId?: number;
}

interface ISignState {
  readonly ticketId?: number;
  readonly taskId?: number;
  readonly open: boolean;
  readonly loading: boolean;
  readonly error?: object;
}

export interface IDeletePopupState {
  readonly error?: object;
  readonly loading: boolean;
}

export interface ITicketDeletePopupState {
  readonly error?: object;
  readonly id?: number;
  readonly loading: boolean;
}

export interface IViewTicketState {
  ticketId?: number;
}

interface ITicketsState {
  readonly tickets: Record<Ticket['id'], Ticket>;
  readonly tasksForms: Record<string, IFormState>;
  readonly tasksPopupForms: Record<string, IFormState>;
  readonly tasksPopup: Record<string, IFormState>;
  readonly taskModal?: Task;
  readonly signForm: ISignState;
  readonly form: IFormState;
  readonly error?: object;
  readonly loading: boolean;
  readonly viewTechs: IViewTicketState;
  readonly deletePopup: IDeletePopupState;
  readonly ticketDeletePopup: ITicketDeletePopupState;
}

interface IMarketplaceStatus {
  readonly machines?: Machine[];
  readonly error?: object;
  readonly loading: boolean;
  readonly contactForm: IContactForm;
}

interface IDashboardTasks {
  readonly tasks?: Task[];
  readonly error?: object;
  readonly loading: boolean;
}

export interface IViewState {
  machineId?: number;
}

interface ICustomerFormState {
  machine?: Machine;
  error?: object;
  loading: boolean;
}

interface IDeleteFormState {
  machineId?: number;
  error?: object;
  loading: boolean;
}

export interface IMachinesState {
  readonly machines: Record<Machine['id'], MachineWithProposal>;
  readonly form: IFormState;
  readonly error?: object;
  readonly loading: boolean;
  readonly tickets: ITicketsState;
  readonly marketplace: IMarketplaceStatus;
  readonly dashboard: IDashboardTasks;
  readonly view: IViewState;
  readonly customerForm: ICustomerFormState;
  readonly deleteForm: IDeleteFormState;
}

export const initialState: IMachinesState = {
  loading: false,
  machines: {},
  form: { loading: false, redirectReady: false },
  customerForm: { loading: false },
  deleteForm: { loading: false },
  tickets: {
    tickets: {},
    tasksForms: {},
    tasksPopup: {},
    tasksPopupForms: {},
    loading: false,
    form: { loading: false, redirectReady: false },
    signForm: {
      open: false,
      loading: false,
    },
    viewTechs: {},
    deletePopup: { loading: false },
    ticketDeletePopup: { loading: false },
  },
  marketplace: {
    loading: false,
    contactForm: {
      open: false,
      loading: false,
      machineId: 0,
    },
  },
  dashboard: {
    loading: false,
  },
  view: {},
};

const reducer = (
  state: IMachinesState = initialState,
  action: MachineAction
): IMachinesState => {
  switch (action.type) {
    case getType(machines.fetchMachines.request):
      return {
        ...state,
        loading: true,
      };

    case getType(machines.fetchMachines.success): {
      const { machines } = action.payload;

      const machinesObj: Record<Machine['id'], MachineWithProposal> = {};
      machines.forEach((machine) => {
        machinesObj[machine.id] = machine;
      });

      return {
        ...state,
        machines: machinesObj,
        error: undefined,
        loading: false,
      };
    }

    case getType(machines.fetchMachines.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(machines.createMachine.request):
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
          redirectReady: false,
        },
      };

    case getType(machines.createMachine.success): {
      const { machine } = action.payload;

      return {
        ...state,
        machines: {
          ...state.machines,
          [machine.id]: {
            ...machine,
            nproposals: 0,
          },
        },
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(machines.createMachine.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(machines.editMachine.request):
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
          redirectReady: false,
        },
      };

    case getType(machines.editMachine.success): {
      const { machine } = action.payload;

      return {
        ...state,
        machines: {
          ...state.machines,
          [machine.id]: {
            nproposals: (state.machines[machine.id] || {}).nproposals,
            ...machine,
          },
        },
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirectReady: true,
        },
      };
    }

    case getType(machines.editMachine.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(machines.openDeleteMachine): {
      return {
        ...state,
        deleteForm: {
          machineId: action.payload,
          loading: false,
        },
      };
    }

    case getType(machines.closeDeleteMachine): {
      return {
        ...state,
        deleteForm: {
          ...state.deleteForm,
          machineId: undefined,
        },
      };
    }

    case getType(machines.deleteMachine.request):
      return {
        ...state,
        deleteForm: {
          ...state.deleteForm,
          loading: true,
          error: undefined,
        },
      };

    case getType(machines.deleteMachine.success): {
      const { machineId } = action.payload;
      const { machines } = state;

      delete machines[machineId];

      return {
        ...state,
        machines,
        deleteForm: {
          machineId: undefined,
          loading: false,
          error: undefined,
        },
      };
    }

    case getType(machines.deleteMachine.failure): {
      const { error } = action.payload;

      return {
        ...state,
        deleteForm: {
          ...state.deleteForm,
          loading: false,
          error,
        },
      };
    }

    case getType(machines.changeMachineCustomer.request):
      return {
        ...state,
        customerForm: {
          ...state.customerForm,
          loading: true,
        },
      };

    case getType(machines.changeMachineCustomer.success): {
      const { id, customerId } = action.payload;

      return {
        ...state,
        machines: {
          ...state.machines,
          [id]: {
            ...(state.machines[id] || {}),
            customerId,
          },
        },
        customerForm: {
          ...state.customerForm,
          error: undefined,
          loading: false,
          machine: undefined,
        },
      };
    }

    case getType(machines.changeMachineCustomer.failure): {
      const { error } = action.payload;

      return {
        ...state,
        customerForm: {
          ...state.customerForm,
          error,
          loading: false,
        },
      };
    }
    
    case getType(machines.toggleMachineView): {
      return {
        ...state,
        view: {
          machineId: action.payload,
        },
      };
    }
    
    case getType(machines.toggleMachineCustomerForm): {
      return {
        ...state,
        customerForm: {
          ...state.customerForm,
          machine: action.payload,
        },
      };
    }

    case getType(machines.fetchMachinesTickets.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          loading: true,
        },
      };

    case getType(machines.fetchMachinesTickets.success): {
      const { tickets } = action.payload;

      const ticketsObj: Record<Ticket['id'], Ticket> = {};
      tickets.forEach((ticket) => {
        ticketsObj[ticket.id] = normalizeTicket(ticket);
      });

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: ticketsObj,
          error: undefined,
          loading: false,
        }
      };
    }

    case getType(machines.fetchMachinesTickets.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          error,
          loading: false,
        },
      };
    }

    case getType(machines.createMachineTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          form: {
            ...state.tickets.form,
            loading: true,
            redirectReady: false,
            redirectReadyId: undefined,
          },
        },
      };

    case getType(machines.createMachineTicket.success): {
      const { ticket } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticket.id]: {
              ...normalizeTicket(ticket),
              tasks: [],
            },
          },
          form: {
            ...state.tickets.form,
            error: undefined,
            loading: false,
            redirectReady: true,
            redirectReadyId: ticket.id,
          },
        }
      };
    }

    case getType(machines.createMachineTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          form: {
            ...state.tickets.form,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(machines.editMachineTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          form: {
            ...state.tickets.form,
            loading: true,
            redirectReady: false,
            redirectReadyId: undefined,
          },
        },
      };

    case getType(machines.editMachineTicket.success): {
      const { ticket } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticket.id]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[ticket.id] || {}),
                ...ticket,
              }),
            },
          },
          form: {
            ...state.tickets.form,
            error: undefined,
            loading: false,
            redirectReady: true,
          },
        },
      };
    }

    case getType(machines.editMachineTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          form: {
            ...state.tickets.form,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(machines.showDeleteMachineTicket):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketDeletePopup: {
            id: action.payload,
            loading: false,
          },
        },
      };

    case getType(machines.deleteMachineTicket.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketDeletePopup: {
            ...state.tickets.ticketDeletePopup,
            loading: true,
          },
        },
      };

    case getType(machines.deleteMachineTicket.success): {
      const { id } = action.payload;

      const tickets = JSON.parse(JSON.stringify(state.tickets.tickets));
      delete tickets[id];

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets,
          ticketDeletePopup: {
            ...state.tickets.ticketDeletePopup,
            error: undefined,
            id: undefined,
            loading: false,
          },
        },
      };
    }

    case getType(machines.deleteMachineTicket.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketDeletePopup: {
            ...state.tickets.ticketDeletePopup,
            error,
            loading: false,
          },
        },
      };
    }

    case getType(machines.openMachineTicketTask):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          taskModal: action.payload,
        },
      };
    
    case getType(machines.closeMachineTicketTask):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          taskModal: undefined,
        },
      };

    case getType(machines.createMachineTicketTask.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksForms: {
            ...state.tickets.tasksForms,
            [action.payload.status]: {
              loading: true,
              redirectReady: false,
            }
          },
        },
      };

    case getType(machines.createMachineTicketTask.success): {
      const { task } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [task.ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[task.ticketid] || {}),
                tasks: {
                  ...(state.tickets.tickets[task.ticketid] || {}).tasks,
                  [task.id]: task,
                }
              }),
            },
          },
          tasksForms: {
            ...state.tickets.tasksForms,
            [task.status]: {
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
        }
      };
    }

    case getType(machines.createMachineTicketTask.failure): {
      const { error, status } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksForms: {
            ...state.tickets.tasksForms,
            [status]: {
              ...(state.tickets.tasksForms[status] || {}),
              loading: false,
              error,
            }
          },
        },
      };
    }

    case getType(machines.editMachineTicketTask.request): {
      const { id, status } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksForms: {
            ...state.tickets.tasksForms,
            [status]: {
              loading: true,
              redirectReady: false,
            }
          },
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [id]: {
              loading: true,
              redirectReady: false,
            }
          },
        },
      };
    }

    case getType(machines.editMachineTicketTask.success): {
      const { task } = action.payload;
      const { dashboard } = state;
      
      const taskDashboardId = (dashboard.tasks || []).findIndex((t) =>
        t.ticketid === task.ticketid && t.id === task.id
      );

      let newDaskboard = { ...dashboard };
      if (taskDashboardId !== -1) {
        (dashboard.tasks || [])[taskDashboardId] = {
          ...(dashboard.tasks || [])[taskDashboardId],
          ...task,
        };
      }

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [task.ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[task.ticketid] || {}),
                tasks: {
                  ...(state.tickets.tickets[task.ticketid] || {}).tasks,
                  [task.id]: {
                    ...(state.tickets.tickets[task.ticketid] || {}).tasks[task.id],
                    ...task,
                  },
                }
              }),
            },
          },
          tasksForms: {
            ...state.tickets.tasksForms,
            [task.status]: {
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [task.id]: {
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
        },
      };
    }

    case getType(machines.editMachineTicketTask.failure): {
      const { error, id, status } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksForms: {
            ...state.tickets.tasksForms,
            [status]: {
              ...(state.tickets.tasksForms[status] || {}),
              loading: false,
              error,
            }
          },
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [id]: {
              ...(state.tickets.tasksPopupForms[id] || {}),
              loading: false,
              error,
            }
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTask.request): {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          deletePopup: {
            ...state.tickets.deletePopup,
            loading: true,
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTask.success): {
      const { ticketid, id } = action.payload;
      const { dashboard } = state;
      
      const taskDashboardId = JSON.parse(JSON.stringify(
        (dashboard.tasks || []).findIndex((t) =>
          t.ticketid === ticketid && t.id === id
        )
      ));

      let newDaskboard = { ...dashboard };
      if (taskDashboardId !== -1) {
        dashboard.tasks!.splice(taskDashboardId, 1);
      }

      const tasks = JSON.parse(JSON.stringify((state.tickets.tickets[ticketid] || {}).tasks || {}));
      delete tasks[id];

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[ticketid] || {}),
                tasks,
              }),
            },
          },
          deletePopup: {
            ...state.tickets.deletePopup,
            loading: false,
          },
          taskModal: undefined,
        },
      };
    }

    case getType(machines.deleteMachineTicketTask.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          deletePopup: {
            ...state.tickets.deletePopup,
            loading: false,
            error,
          },
        },
      };
    }

    case getType(machines.createMachineTicketTaskExpense.request): {
      const { taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [taskid]: {
              loading: true,
              redirectReady: false,
            }
          },
        },
      };
    }

    case getType(machines.createMachineTicketTaskExpense.success): {
      const { expense } = action.payload;
      const { dashboard } = state;
      
      const taskDashboardId = (dashboard.tasks || []).findIndex((t) =>
        t.ticketid === expense.ticketid && t.id === expense.taskid
      );

      let newDaskboard = { ...dashboard };
      if (taskDashboardId !== -1) {
        (dashboard.tasks || [])[taskDashboardId] = {
          ...(dashboard.tasks || [])[taskDashboardId],
          expenses: {
            ...((((state.tickets.tickets[expense.ticketid] || {}).tasks || {})[expense.taskid] || {}).expenses),
            [expense.id]: expense,
          }
        };
      }

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [expense.ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[expense.ticketid] || {}),
                tasks: {
                  ...((state.tickets.tickets[expense.ticketid] || {}).tasks || {}),
                  [expense.taskid]: {
                    ...(((state.tickets.tickets[expense.ticketid] || {}).tasks || {})[expense.taskid] || {}),
                    expenses: {
                      ...((((state.tickets.tickets[expense.ticketid] || {}).tasks || {})[expense.taskid] || {}).expenses),
                      [expense.id]: expense,
                    }
                  }
                },
              }),
            },
          },
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [expense.taskid]: {
              ...state.tickets.tasksPopupForms[expense.taskid],
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
        },
      };
    }

    case getType(machines.createMachineTicketTaskExpense.failure): {
      const { error, taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [taskid]: {
              ...state.tickets.tasksPopupForms[taskid],
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTaskExpense.request): {
      const { taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [taskid]: {
              loading: true,
              redirectReady: false,
            }
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTaskExpense.success): {
      const { ticketid, taskid, id } = action.payload;
      const { dashboard } = state;
      
      const taskDashboardId = (dashboard.tasks || []).findIndex((t) =>
        t.ticketid === ticketid && t.id === taskid
      );

      const expenses = (((state.tickets.tickets[ticketid] || {}).tasks || {})[taskid] || {}).expenses || {};
      delete expenses[id];

      let newDaskboard = { ...dashboard };
      if (taskDashboardId !== -1) {
        (dashboard.tasks || [])[taskDashboardId] = {
          ...(dashboard.tasks || [])[taskDashboardId],
          expenses,
        };
      }

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[ticketid] || {}),
                tasks: {
                  ...((state.tickets.tickets[ticketid] || {}).tasks || {}),
                  [taskid]: {
                    ...(((state.tickets.tickets[ticketid] || {}).tasks || {})[taskid] || {}),
                    expenses,
                  }
                },
              }),
            },
          },
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [taskid]: {
              ...state.tickets.tasksPopupForms[taskid],
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTaskExpense.failure): {
      const { error, taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopupForms: {
            ...state.tickets.tasksPopupForms,
            [taskid]: {
              ...state.tickets.tasksPopupForms[taskid],
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(machines.createMachineTicketTaskComment.request): {
      const { taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopup: {
            ...state.tickets.tasksPopup,
            [taskid]: {
              loading: true,
              redirectReady: false,
            }
          },
        },
      };
    }

    case getType(machines.createMachineTicketTaskComment.success): {
      const { comment } = action.payload;
      const { dashboard } = state;
      
      const taskDashboardId = (dashboard.tasks || []).findIndex((t) =>
        t.ticketid === comment.ticketid && t.id === comment.taskid
      );

      let newDaskboard = { ...dashboard };
      if (taskDashboardId !== -1) {
        (dashboard.tasks || [])[taskDashboardId] = {
          ...(dashboard.tasks || [])[taskDashboardId],
          comments: {
            ...((((state.tickets.tickets[comment.ticketid] || {}).tasks || {})[comment.taskid] || {}).comments),
            [comment.id]: comment,
          }
        };
      }

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [comment.ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[comment.ticketid] || {}),
                tasks: {
                  ...((state.tickets.tickets[comment.ticketid] || {}).tasks || {}),
                  [comment.taskid]: {
                    ...(((state.tickets.tickets[comment.ticketid] || {}).tasks || {})[comment.taskid] || {}),
                    comments: {
                      ...((((state.tickets.tickets[comment.ticketid] || {}).tasks || {})[comment.taskid] || {}).comments),
                      [comment.id]: comment,
                    }
                  }
                },
              }),
            },
          },
          tasksPopup: {
            ...state.tickets.tasksPopup,
            [comment.taskid]: {
              ...state.tickets.tasksPopup[comment.taskid],
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
        },
      };
    }

    case getType(machines.createMachineTicketTaskComment.failure): {
      const { error, taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopup: {
            ...state.tickets.tasksPopup,
            [taskid]: {
              ...state.tickets.tasksPopup[taskid],
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTaskComment.request): {
      const { taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopup: {
            ...state.tickets.tasksPopup,
            [taskid]: {
              loading: true,
              redirectReady: false,
            }
          },
        },
      };
    }

    case getType(machines.deleteMachineTicketTaskComment.success): {
      const { ticketid, taskid, id } = action.payload;
      const { dashboard } = state;
      
      const taskDashboardId = (dashboard.tasks || []).findIndex((t) =>
        t.ticketid === ticketid && t.id === taskid
      );

      const comments = (((state.tickets.tickets[ticketid] || {}).tasks || {})[taskid] || {}).comments || {};
      delete comments[id];

      let newDaskboard = { ...dashboard };
      if (taskDashboardId !== -1) {
        (dashboard.tasks || [])[taskDashboardId] = {
          ...(dashboard.tasks || [])[taskDashboardId],
          comments,
        };
      }

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketid]: {
              ...normalizeTicket({
                ...(state.tickets.tickets[ticketid] || {}),
                tasks: {
                  ...((state.tickets.tickets[ticketid] || {}).tasks || {}),
                  [taskid]: {
                    ...(((state.tickets.tickets[ticketid] || {}).tasks || {})[taskid] || {}),
                    comments,
                  }
                },
              }),
            },
          },
          tasksPopup: {
            ...state.tickets.tasksPopup,
            [taskid]: {
              ...state.tickets.tasksPopup[taskid],
              error: undefined,
              loading: false,
              redirectReady: true,
            }
          },
        },
      };
    }
  
    case getType(machines.deleteMachineTicketTaskComment.failure): {
      const { error, taskid } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          tasksPopup: {
            ...state.tickets.tasksPopup,
            [taskid]: {
              ...state.tickets.tasksPopup[taskid],
              error,
              loading: false,
            },
          },
        },
      };
    }

    case getType(machines.toggleMachineTicketTechsView):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          viewTechs: {
            ...state.tickets.viewTechs,
            ticketId: action.payload,
          },
        },
      };


    case getType(machines.createMachineTicketTaskSign.request):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          signForm: {
            ...state.tickets.signForm,
            loading: true,
          },
        },
      };

    case getType(machines.createMachineTicketTaskSign.success): {
      const { ticketId, tasks, signature, datetime } = action.payload;
      const { dashboard } = state;

      let newDaskboard = { ...dashboard };
      const ticket = state.tickets.tickets[ticketId] || {};
      tasks.forEach((taskId) => {
        if (!ticket.tasks[taskId].signs) {
          ticket.tasks[taskId].signs = [];
        }
        ticket.tasks[taskId].signs.push({ signature, datetime });

        const taskDashboardId = (dashboard.tasks || []).findIndex((t) =>
          t.ticketid === ticketId && t.id === taskId
        );
        if (taskDashboardId !== -1) {
          (dashboard.tasks || [])[taskDashboardId] = {
            ...(dashboard.tasks || [])[taskDashboardId],
            signs: ticket.tasks[taskId].signs,
          };
        }
      });

      return {
        ...state,
        dashboard: newDaskboard,
        tickets: {
          ...state.tickets,
          tickets: {
            ...state.tickets.tickets,
            [ticketId]: { ...normalizeTicket(ticket)  },
          },
          signForm: {
            ...state.tickets.signForm,
            error: undefined,
            loading: false,
            open: false,
          },
        },
      };
    }
  
    case getType(machines.createMachineTicketTaskSign.failure): {
      const { error } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          signForm: {
            ...state.tickets.signForm,
            error,
            loading: false,
          },
        },
      };
    }
  
    case getType(machines.openSign): {
      const { ticketId, taskId } = action.payload;

      return {
        ...state,
        tickets: {
          ...state.tickets,
          signForm: {
            ...state.tickets.signForm,
            ticketId,
            taskId,
            open: true,
          },
        },
      };
    }
  
    case getType(machines.closeSign):
      return {
        ...state,
        tickets: {
          ...state.tickets,
          signForm: {
            ...state.tickets.signForm,
            open: false,
          },
        },
      };
  
    case getType(machines.fetchMachinesForMarketplace.request):
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          loading: true,
        },
      };

    case getType(machines.fetchMachinesForMarketplace.success): {
      const { machines } = action.payload;

      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          machines,
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(machines.fetchMachinesForMarketplace.failure): {
      const { error } = action.payload;

      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          error,
          loading: false,
        },
      };
    }

    case getType(machines.openMarketplaceForm): {
      const { machineId } = action.payload;

      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          contactForm: {
            ...state.marketplace.contactForm,
            open: true,
            machineId,
          },
        },
      };
    }

    case getType(machines.closeMarketplaceForm): {
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          contactForm: {
            ...state.marketplace.contactForm,
            open: false,
          },
        },
      };
    }
  
    case getType(machines.sendMachineContact.request): {
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          contactForm: {
            ...state.marketplace.contactForm,
            error: undefined,
            loading: true,
          }
        },
      };
    }

    case getType(machines.sendMachineContact.success): {
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          contactForm: {
            ...state.marketplace.contactForm,
            loading: false,
            open: false,
          },
        },
      };
    }

    case getType(machines.sendMachineContact.failure): {
      const { error } = action.payload;

      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          contactForm: {
            ...state.marketplace.contactForm,
            error,
            loading: false,
          },
        },
      };
    }
  
    case getType(machines.fetchDashboardTasks.request): {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error: undefined,
          loading: true,
        },
      };
    }

    case getType(machines.fetchDashboardTasks.success): {
      const { tasks } = action.payload;

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          tasks,
          loading: false,
        },
      };
    }

    case getType(machines.fetchDashboardTasks.failure): {
      const { error } = action.payload;

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          error,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;