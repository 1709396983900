import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import Auth from './Auth';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import Dashboard from './Dashboard';
import Marketplace from './Marketplace';

import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#cb0000',
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#cb0000',
        fontWeight: 700,
        '&$error': {
          color: '#cb0000',
          fontWeight: 700
        },
      }
    },
  }
});

const history = createBrowserHistory();

interface IProps {
  authenticated: boolean;
  checked: boolean;
  loading: boolean;
}

const App: FunctionComponent <IProps> = (properties) => {
  const { authenticated, checked, loading } = properties;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          {
          loading && !checked ? (
            <LinearProgress />
          ) : (
            authenticated ? (
              <Switch>
                <Route path="/dashboard" render={() => <Dashboard history={history} />} />
                <Route path="/marketplace" render={() => <Marketplace history={history} />} />
                <Redirect to="/dashboard" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/authenticate" exact component={Auth} />
                <Route path="/password-recovery" exact component={PasswordRecovery} />
                <Route path="/password-reset/:hash" exact render={(props) => <PasswordReset history={history} hash={props.match.params.hash} />} />
                <Route path="/marketplace" render={() => <Marketplace history={history} />} />
                <Redirect to="/authenticate" />
              </Switch>
            )
          )}
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
