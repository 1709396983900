import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { SendMachineContact } from '../../../actions/index';
import Machine from '../../../models/Machine';
import Button from '../../common/Button';
import { getCoverImage } from '../../common/utils';
import { validateEmail } from '../../../data';

interface IProps {
  handleClose: () => void;
  sendMachineContact: (properties: SendMachineContact) => void;
  loading: boolean;
  machineId: number;
  machines: Machine[];
}

interface IState {
  name: string;
  email: string;
  telephone: string;
  companyName: string;
  message: string;
  captcha?: string | null;

  submitted: boolean;
}

class ContactForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: '',
      email: '',
      telephone: '',
      companyName: '',
      message: '',

      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(name: string, event: any) {
    this.setState({ [name]: event.target.value } as Pick<IState, 'name'>);
  }

  public handleCaptchaChange(captcha: string | null) {
    this.setState({ captcha });
  }

  public hasError() {
    const { name, email, captcha } = this.state;

    return name.length === 0 || email.length === 0 || !validateEmail(email) || !captcha;
  }

  public handleSubmit() {
    if (this.hasError()) {
      return this.setState({ submitted: true });
    }

    const { machineId, sendMachineContact } = this.props;
    const { name, email, telephone, companyName, message, captcha } = this.state;

    sendMachineContact({
      id: machineId,
      name,
      email,
      telephone: telephone || undefined,
      companyName: companyName || undefined,
      message: message || undefined,
      captcha: captcha || '',
    })
  }

  public render() {
    const { handleClose, loading, machineId, machines } = this.props;
    const { captcha, name, email, telephone, companyName, message, submitted } = this.state;

    const machine = machines.find((m) => m.id === machineId) || {} as Machine;

    return (
      <>
        <Dialog maxWidth="md" fullWidth open>
          <DialogTitle>Pedir cotação</DialogTitle>
          <DialogContent>
            <Box pb={2}>
              <Grid container spacing={2}>
                <Grid item sm={6} style={{ textAlign: 'center' }}>
                  {getCoverImage(machine.files) !== undefined ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/api/files/machines/${getCoverImage(machine.files)!.filename}`}
                      alt={machine.description || ''}
                      style={{ maxWidth: '100%', maxHeight: '140px' }}
                    />
                  ) : null}
                </Grid>
                <Grid item sm={6}>
                  <Box pb={2}>
                    <Typography variant="body2" component="p"><b>{machine.brand}</b></Typography>
                    <Typography variant="body2" component="p">{machine.model}</Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography variant="body2" component="p">Ano: {machine.machineYear}</Typography>
                    <Typography variant="body2" component="p">Horas de uso: {machine.machineUsageHours || 0}h</Typography>
                  </Box>
                  <Typography variant="body1" component="p"><b>Preço:</b> <b className="price">{
                    (
                      (
                        machine.marketplacePriceType === 'installPrice'
                        ? machine.installBuyPrice
                        : machine.marketplacePriceType === 'truckPrice'
                        ? machine.truckBuyPrice : 0
                      ) || 0
                    ).toFixed(2)
                  }€</b></Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider light />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <TextField
                    error={submitted && !name.length}
                    label="Nome"
                    fullWidth
                    required
                    value={name}
                    variant="standard"
                    onChange={(event: any) => this.handleChange('name', event)}
                    helperText={submitted && !name.length ? 'Deve inserir um nome' : ''}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <TextField
                    error={submitted && (!email.length || !validateEmail(email))}
                    label="Email"
                    fullWidth
                    required
                    value={email}
                    variant="standard"
                    onChange={(event: any) => this.handleChange('email', event)}
                    helperText={submitted && (!email.length || !validateEmail(email)) ? 'Deve inserir um email válido' : ''}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Telefone"
                    fullWidth
                    value={telephone}
                    variant="standard"
                    onChange={(event: any) => this.handleChange('telephone', event)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Empresa"
                    fullWidth
                    value={companyName}
                    variant="standard"
                    onChange={(event: any) => this.handleChange('companyName', event)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Mensagem"
                    fullWidth
                    value={message}
                    variant="standard"
                    multiline
                    minRows={3}
                    onChange={(event: any) => this.handleChange('message', event)}
                  />
                </FormControl>
              </Grid>
              <Grid>
                <FormControl fullWidth error={submitted && !captcha}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                    onChange={this.handleCaptchaChange}
                  />
                  {submitted && !captcha ? <FormHelperText>Deve preencher a verificação</FormHelperText> : null}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" variant="contained" onClick={handleClose}>Cancelar</Button>
            <Button
              disabled={loading}
              variant="contained"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              onClick={this.handleSubmit}
            >{loading ? <CircularProgress size={24} /> : 'Enviar'}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default ContactForm;
