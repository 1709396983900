import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import React, { FunctionComponent } from 'react';

import Ticket, { TicketStatus } from '../../../../../models/Ticket';
import { TaskStatus } from '../../../../../models/Task';
import User from '../../../../../models/User';
import Button from '../../../../common/Button';

interface IProps {
  ticket: Ticket;
  users: Record<User['id'], User>;
  toggleMachineTicketTechsView: (ticketId?: number) => void;
}
const ViewTechsModal: FunctionComponent <IProps> = (properties) => {
  const { toggleMachineTicketTechsView, ticket, users } = properties;

  const technicians: number[] = [];
  Object.values(ticket.tasks || {}).filter((task) =>
    ticket.status === TicketStatus.DONE ||
    task.status !== TaskStatus.DONE
  ).forEach((task) => {
    (task.technicians || []).forEach((id: number) => {
      if (technicians.indexOf(id) === -1) {
        technicians.push(id);
      }
    })
  });

  return (
    <Dialog maxWidth="md" fullWidth open onClose={() => toggleMachineTicketTechsView()}>
      <DialogTitle>
        <FontAwesomeIcon className="header-icon" icon={faPlug} />
        <span style={{ marginLeft: '8px' }}>Técnicos no ticket #{ticket.id}</span>
        <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleMachineTicketTechsView()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {technicians.map((id) => (
              <TableRow key={id}>
                <TableCell>{(users[id] || {}).name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <Box p={2} pt={0}>
        <DialogActions>
          <Button color="default" variant="contained" onClick={() => toggleMachineTicketTechsView()}>Fechar</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ViewTechsModal;