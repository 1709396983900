export enum MachineTypes {
  'T3X' = '3x',
  'T5X' = '5x',
  'HORIZONTAL' = 'horizontal',
  'TORNO' = 'torno',
  'TORNOMULTITASKS' = 'torno_multitasks',
  'BORING' = 'boring',
  'EDM' = 'edm',
  'EDM_WIRE' = 'edm_wire',
  'EDM_HOLE' = 'edm_hole',
  'SWISS_LATHE' = 'swiss_lathe',
  'FLAT_GRINDING' = 'flat_grinding',
  'ROUND_GRINDING' = 'round_grinding',
  'PLASTIC_INJECTION' = 'plastic_injection',
  'BANDSAW' = 'bandsaw',
  'TRY_OUT_PRESS' = 'try_out_press',
};

export const coneTypes = [
  { id: 1, name: 'BT30' },
  { id: 2, name: 'BT40' },
  { id: 3, name: 'BT50' },
  { id: 4, name: 'HSK40' },
  { id: 5, name: 'HSK50' },
  { id: 6, name: 'HSK63' },
  { id: 7, name: 'HSK80' },
  { id: 8, name: 'HSK100' },
  { id: 9, name: 'SK40' },
  { id: 10, name: 'SK50' },
  { id: 11, name: 'BBT40 - Big Plus' },
  { id: 12, name: 'HSK100T' },
  { id: 13, name: 'BBT50 - Big Plus' },
];

export const spindleTypes = [
  { id: 'direct_motor', name: 'Motor Direto' },
  { id: 'transmission_belts', name: 'Transmissão correias' },
  { id: 'velocity_box', name: 'Caixa de Velocidades' },
  { id: 'electromandrine', name: 'Eletromandrino' },
  { id: 'capto_c5', name: 'Capto C5', type: MachineTypes.TORNOMULTITASKS },
  { id: 'capto_c6', name: 'Capto C6', type: MachineTypes.TORNOMULTITASKS },
];

export const spindleNoseTypes = [
  { id: 'a-4', name: 'A-4' },
  { id: 'a-5', name: 'A-5' },
  { id: 'a-6', name: 'A-6' },
  { id: 'a-8', name: 'A-8' },
  { id: 'a-11', name: 'A-11' },
  { id: 'a-15', name: 'A-15' },
  { id: 'a-20', name: 'A-20' },
];

export const coolingType = [
  { id: 1, name: 'Óleo' },
  { id: 2, name: 'Anti-Congelante' },
];

export const storageType = [
  { id: 1, name: 'Tipo Braço' },
  { id: 2, name: 'Tipo Disco' },
  { id: 3, name: 'Tipo Robot' },
  { id: 4, name: 'Tipo Turreta' },
];

export const motorTransmissionTypes = [
  { id: 'direct_drive', name: 'Direct Drive' },
  { id: 'transmission_eng', name: 'Transmissão engrenagem' },
];

export const commandTypes = [
  { id: 'cnc', name: 'CNC' },
  { id: 'auto', name: 'Auto' },
  { id: 'semi_auto', name: 'Semi Auto' },
  { id: 'manual', name: 'Manual' },
];

export const erosionHeadTypes = [
  { id: 'erowa', name: 'EROWA' },
  { id: '3r', name: '3R' },
  { id: 'hirschman', name: 'HIRSCHMAN' },
  { id: 'manual', name: 'Manual' },
];

export const sawTypes = [
  { id: 'hztape', name: 'Fita Horizontal' },
  { id: 'vttape', name: 'Fita Vertical' },
  { id: 'alternativesaw', name: 'Serrote Alternativo' },
  { id: 'disc', name: 'Disco' },
];

export const machineTypes = [
  { id: MachineTypes.T3X, label: 'Centro Maquinação 3 Eixos' },
  { id: MachineTypes.T5X, label: 'Centro Maquinação 5 eixos' },
  { id: MachineTypes.HORIZONTAL, label: 'Centro Maquinação Horizontal' },
  { id: MachineTypes.TORNO, label: 'Torno CNC' },
  { id: MachineTypes.TORNOMULTITASKS, label: 'Torno CNC Multitasking' },
  { id: MachineTypes.BORING, label: 'Mandriladora' },
  { id: MachineTypes.EDM, label: 'EDM' },
  { id: MachineTypes.EDM_WIRE, label: 'WIRE EDM' },
  { id: MachineTypes.EDM_HOLE, label: 'EDM HOLE Drilling' },
  { id: MachineTypes.SWISS_LATHE, label: 'CNC Swiss Type Lathe' },
  { id: MachineTypes.FLAT_GRINDING, label: 'Flat Grinding' },
  { id: MachineTypes.ROUND_GRINDING, label: 'Round Grinding' },
  { id: MachineTypes.PLASTIC_INJECTION, label: 'Injecção de Plástico' },
  { id: MachineTypes.BANDSAW, label: 'Bandsaw' },
  { id: MachineTypes.TRY_OUT_PRESS, label: 'Try out press' },
];