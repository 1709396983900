import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/users';
import { createUser, editUser, fetchUsers, toggleUser } from '../api/users';
import User from '../models/User';

export function* fetchUsersSaga() {
  try {
    const users: User[] = yield call(fetchUsers);

    yield put(types.fetchUsers.success({ users }));
  } catch (error: any) {
    yield put(types.fetchUsers.failure({ error }));
  }
}

export function* createUserSaga(action: ActionType<typeof types.createUser.request>) {
  try {
    const user: User = yield call(createUser, action.payload);

    yield put(types.createUser.success({ user }));
  } catch (error: any) {
    yield put(types.createUser.failure({ error }));
  }
}

export function* editUserSaga(action: ActionType<typeof types.editUser.request>) {
  try {
    const user: User = yield call(editUser, action.payload);

    yield put(types.editUser.success({ user }));
  } catch (error: any) {
    yield put(types.editUser.failure({ error }));
  }
}

export function* toggleUserSaga(action: ActionType<typeof types.toggleUser.request>) {
  try {
    yield call(toggleUser, action.payload);

    yield put(types.toggleUser.success(action.payload));
  } catch (error: any) {
    yield put(types.toggleUser.failure({ error }));
  }
}