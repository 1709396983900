import File from './File';

export interface IMachineLine {
  id: number;
  reference: string;
  description?: string;
  model?: string;
  transportPrice?: number;
  margin?: number;
  discount?: number;
  total?: number;
  files: File[];
}

export enum ProposalStatus {
  OPORTUNITY = 'oportunity',
  PENDING = 'pending',
  AWARDED = 'awarded',
  NOTAWARDED = 'notawarded',
}

export default class Proposal {
  public id!: number;

  public commercialUserId!: number;

  public date!: Date;

  public customerId!: number;
  
  public machineId!: number;

  public machineBrand!: string;

  public machineModel!: string;
  
  public priceSelection!: string;
  
  public discount!: number;

  public specialPrice?: number;

  public total!: number;

  public opportunityObservations?: string;
  
  public reference?: string;

  public deliveryDays?: number;

  public status?: ProposalStatus;

  public proposalObservations?: string;

  public commercialName!: string;

  public datetime!: Date;

  public options!: ProposalOption[];
}

export interface ProposalOption {
  active: boolean;
  description?: string;
  quantity?: number;
  sellValue?: number;
}