import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEnvelope, faPhone, faUnlock, faUserTag } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateUser, EditUser } from '../../../../actions/users';
import { validateEmail } from '../../../../data';
import User from '../../../../models/User';
import Button from '../../../common/Button';

interface IProps {
  auth: User;
  createUser: (properties: CreateUser) => void;
  user?: User;
  editUser: (properties: EditUser) => void;
  history: any;
  loading: boolean;
  redirectReady: boolean;
}
interface IState {
  id?: number;
  name: string;
  email: string;
  phone?: string;
  role: string;
  price?: number;
  password: string;
  repassword: string;
  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      id,
      name,
      email,
      phone,
      role,
      price,
    } = props.user || {};

    this.state = {
      id,
      name: name || '',
      email: email || '',
      phone: phone || '',
      role: role || '',
      price,
      password: '',
      repassword: '',
      submitted: false,
      dirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { user, history, redirectReady } = this.props;

    if (JSON.stringify(prevProps.user) !== JSON.stringify(user)) {
      const {
        id,
        name,
        email,
        phone,
        role,
        price,
      } = user || {};
  
      this.setState({
        id,
        name: name || '',
        email: email || '',
        phone: phone || '',
        role: role || '',
        price,
        password: '',
        repassword: '',
        submitted: false,
        dirty: false,
      });
    }

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        history.push('/dashboard/users/list');
      });
    }
  }

  public hasErrors() {
    const { id, name, email, role, price, password, repassword } = this.state;

    if (
      !name.length ||
      !email.length ||
      !validateEmail(email) ||
      !role.length ||
      (
        (!id || !!password.length || !!repassword.length) && (
          !password.length ||
          !repassword.length
        )
      ) ||
      (!price && price !== 0) ||
      password !== repassword
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createUser, editUser } = this.props;
    const {
      id,
      name,
      email,
      phone,
      role,
      price,
      password,
  } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      return editUser({ id, name, email, phone: phone || undefined, role, price, password: password || undefined });
    }

    return createUser({ name, email, phone: phone || undefined, role, price, password });
  }

  public handleChange(field: string, event: any) {
    const stateUpdate = {
      [field]: event.target.value,
    } as Pick<IState, 'name'>
  
    this.setState({ ...stateUpdate, dirty: true });
  }

  public render() {
    const { auth, loading } = this.props;
    const {
      id,
      name,
      email,
      phone,
      role,
      price,
      password,
      repassword,
      submitted,
      dirty,
    } = this.state;

    return (
      <>
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />

        <Box>
          <Typography variant="h6" component="h2">Utilizadores</Typography>
          <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard/users/list">
              Lista de Utilizadores
            </Link>
            <Typography color="textPrimary">{id ? `Editar ${name}` : 'Adicionar'}</Typography>
          </Breadcrumbs>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/users/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading || !dirty}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Dados gerais</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        error={submitted && !name.length}
                        disabled={(auth || {}).role !== 'admin'}
                        label="Nome"
                        required
                        value={name}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('name', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faUserTag} />
                            </InputAdornment>
                          )
                        }}
                        helperText={submitted && !name.length ? 'Deve introduzir o nome do utilizador' : ''}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        error={submitted && (!email.length || !validateEmail(email))}
                        disabled={(auth || {}).role !== 'admin'}
                        label="Email"
                        required
                        value={email}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('email', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faEnvelope} />
                            </InputAdornment>
                          )
                        }}
                        helperText={submitted && (!email.length || !validateEmail(email)) ? 'Deve introduzir um email de utilizador válido' : ''}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        label="Telefone"
                        required
                        value={phone}
                        variant="standard"
                        onChange={(event: any) => this.handleChange('phone', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faPhone} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                    <FormControl
                      error={submitted && !role.length}
                      disabled={(auth || {}).role !== 'admin'}
                      required
                      margin="normal"
                      fullWidth
                    >
                      <InputLabel id="user-role">Perfil</InputLabel>
                      <Select
                        labelId="user-role"
                        value={role || ''}
                        onChange={(event: any) => this.handleChange('role', event)}
                      >
                        <MenuItem value="" disabled>Nenhum</MenuItem>
                        <MenuItem value="admin">Admininstrador</MenuItem>
                        <MenuItem value="admincommercial">Administrador Comercial</MenuItem>
                        <MenuItem value="commercial">Comercial</MenuItem>
                        <MenuItem value="admintech">Administrador Técnico</MenuItem>
                        <MenuItem value="tech">Técnico</MenuItem>
                        <MenuItem value="administrative">Administrativo</MenuItem>
                        <MenuItem value="externaltech">Técnico Externo</MenuItem>
                      </Select>
                      {submitted && !role.length ? <FormHelperText>Deve seleccionar o perfil</FormHelperText> : null}
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        error={submitted && !price && price !== 0}
                        disabled={(auth || {}).role !== 'admin'}
                        label="Preço por hora"
                        value={price || ''}
                        variant="standard"
                        type="number"
                        required
                        inputProps={{ step: 0.01, min: 0 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
                        onChange={(event: any) => this.handleChange('price', event)}
                        helperText={submitted && !price ? 'Deve inserir o valor por hora' : ''}
                      />
                    </FormControl>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        error={submitted && !id && !password.length}
                        label="Palavra-passe"
                        required={!id}
                        value={password}
                        variant="standard"
                        type="password"
                        onChange={(event: any) => this.handleChange('password', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faUnlock} />
                            </InputAdornment>
                          )
                        }}
                        helperText={submitted && !id && !password.length ? 'Deve introduzir a palavra-passe' : ''}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        error={submitted && (!id || password.length > 0 || repassword.length > 0) && (!repassword.length || password !== repassword)}
                        label="Repita a Palavra-passe"
                        required={!id}
                        value={repassword}
                        variant="standard"
                        type="password"
                        onChange={(event: any) => this.handleChange('repassword', event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon className="form-icon" icon={faUnlock} />
                            </InputAdornment>
                          )
                        }}
                        helperText={
                          submitted && (!id || password.length > 0 || repassword.length > 0) ?
                            !repassword.length ? 'Deve repetir a palavra-passe'
                            : password !== repassword ? 'As palavras-passe são direferentes'
                            : ''
                          : ''}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box pb={2}>
          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/users/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading || !dirty}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
};

export default Form;
