import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BlackCard from '../common/BlackCard';
import Button from '../common/Button';
import './../Auth/Auth.css';

interface IProps {
  loading: boolean;
  resetPassword: (password: string, hash: string) => void;
  hash: string;
  history: any;
  redirectReady: boolean;
  error?: object;
}

interface IState {
  password: string;
  repassword: string;
  submitted: boolean;
}

class PasswordReset extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      password: '',
      repassword: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { history, redirectReady } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      history.push('/authentication');
    }
  }

  private handleKeyPress(e: any) {
    if (e.which === 13) {
      this.handleSubmit();
    }
  }

  public handleChange(key: keyof IState, e: any) {
    const { value } = e.target;

    this.setState({ [key]: value } as Pick<IState, 'password'>);
  }

  public hasErrors() {
    const { password, repassword } = this.state;

    if (
      !password.length ||
      !repassword.length ||
      password !== repassword
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { password } = this.state;
    const { hash, resetPassword } = this.props;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    resetPassword(password, hash);
  }

  public render() {
    const { password, repassword, submitted } = this.state;
    const { loading } = this.props;

    return (
      <>
        <Box mt={10}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item sm={3}>
              <Box className="auth-logo" mb={4}>
                <Link to="/authenticate">
                  <img alt="GoTec" src="/logo.png" />
                </Link>
              </Box>
              <BlackCard>
                <CardHeader title={
                  <Typography variant="h6" component="h5">Redefinir palavra-passe</Typography>
                } />
                <CardContent>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      error={submitted && !password.length}
                      label="Palavra-passe"
                      required
                      value={password}
                      variant="standard"
                      type="password"
                      onChange={(event: any) => this.handleChange('password', event)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon className="form-icon" icon={faUnlock} />
                          </InputAdornment>
                        )
                      }}
                      helperText={submitted && !password.length ? 'Deve introduzir a palavra-passe' : ''}
                    />
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <TextField
                      error={submitted && (!repassword.length || password !== repassword)}
                      label="Repita a Palavra-passe"
                      required
                      value={repassword}
                      variant="standard"
                      type="password"
                      onChange={(event: any) => this.handleChange('repassword', event)}
                      helperText={
                        submitted ?
                          !repassword.length ? 'Deve repetir a palavra-passe'
                          : password !== repassword ? 'As palavras-passe são direferentes'
                          : ''
                        : ''}
                    />
                  </FormControl>

                  <FormControl
                    required
                    fullWidth
                    margin="normal"
                  >
                    <Box mt={4}>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="secondary"
                        component="button"
                        fullWidth
                        center
                      >{loading ? <CircularProgress size={24} /> : 'Redefinir Password'}</Button>
                    </Box>
                  </FormControl>
                </CardContent>
              </BlackCard>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default PasswordReset;
