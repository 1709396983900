import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Task from '../../../models/Task';
import User from '../../../models/User';
import Ticket from '../../../models/Ticket';

interface IProps {
  openModal: (task: Task) => void;
  history: any;
  task: Task;
  ticket: Ticket;
  users: User[];
  toggleMachineView: (machineId?: number) => void;
}

const TaskComponent: FunctionComponent <IProps> = (properties) => {
  const { history, openModal, task, ticket, toggleMachineView, users } = properties;

  const goToTicket = (event: any) => {
    event.stopPropagation();

    history.push(`/dashboard/machines/tickets/ticketid/${task.ticketid}/edit`);
  };
  const openMachine = (event: any) => {
    event.stopPropagation();

    toggleMachineView(ticket.machineId);
  };

  return (
    <Box pb={2}>
      <Card style={{ cursor: 'pointer' }} onClick={() => openModal(task)}>
        <CardContent>
          <Box pb={1}>
            <Typography variant="body1" component="span">{task.title}</Typography>

            {(task.signs || []).length
              ? <Chip
                label="Assinada"
                variant="outlined"
                size="small"
                style={{ float: 'right' }}
              /> : null}
          </Box>
          <Box pb={1}>
            <Typography variant="body1" component="span">SAT <b onClick={goToTicket}>#{task.ticketid}</b></Typography>
          </Box>
          {ticket.machineId ? <Box pb={1}>
            <Typography variant="body1" component="span">Máquina <b onClick={openMachine}>#{ticket.machineId}</b></Typography>
          </Box> : null}
          <Typography variant="body2">
            <FontAwesomeIcon
              className="form-icon"
              icon={faUserCog}
            /> {(task.technicians || []).map((t: number) => (users.find((u) => u.id === t) || {}).name).join(', ')}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default TaskComponent;
