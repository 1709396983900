import { IResetRequest } from '../actions';
import User from '../models/User';
import axios from './axios';

export async function fetchUser(): Promise<User> {
  const { data } = await axios.get('/users/account', {
    headers: { Pragma: 'no-cache' },
  });

  return data;
}

export const authenticate = async (
  email: string,
  password: string,
) =>
  axios.post('/users/authentication', {
    email,
    password,
  });

export const recoverPassword = async (email: string) =>
  axios.post('/users/password-recovery', { email });

export const resetPassword = async (properties: IResetRequest) =>
  axios.post('/users/password-reset', properties);

export const logout = async () =>
  axios.post('/users/authentication/logout');