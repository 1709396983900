import Task from './Task';

export enum TicketStatus {
  PENDING = 'pending',
  EXECUTION = 'execution',
  DONE = 'done',
}

export class TicketMeta {
  public id!: number;
  
  public title!: string;
  
  public machineId?: number;

  public machineBrand?: string;

  public machineModel?: string;

  public customerId!: number;

  public observations?: string;

  public limitDate?: Date;

  public responsibleUserId!: number;

  public zoneId!: number;

  public costCenter?: string;

  public invoiceno?: string;

  public created!: Date;

  public status!: TicketStatus;
}

export default interface Ticket extends TicketMeta {
  tasks: Record<Task['id'], Task>;
}