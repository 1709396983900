import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';

import Task, { TaskStatus } from '../../../../../../models/Task';
import { EditMachineTicketTask } from '../../../../../../actions';
import Button from '../../../../../common/Button';

interface IProps {
  anchorEl: Element;
  editMachineTicketTask: (properties: EditMachineTicketTask) => void;
  handleClose: () => void;
  task: Task;
  redirectReady: boolean;
  loading: boolean;
}

interface IState {
  status: TaskStatus;
}

class MoveModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { task } = props;

    this.state = { status: task.status };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { handleClose, redirectReady } = this.props;

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      handleClose();
    }
  }

  public handleChange(event: any) {
    this.setState({ status: event.target.value });
  }

  public handleSubmit() {
    const { status } = this.state;
    const { editMachineTicketTask, task } = this.props;

    editMachineTicketTask({
      id: task.id,
      ticketid: task.ticketid,
      title: task.title,
      technicians: task.technicians,
      status,
      files: task.files,
    });
  }

  public render() {
    const { anchorEl, handleClose, loading } = this.props;
    const { status } = this.state;

    return (
      <Popover
        open={true}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card style={{ width: '320px' }}>
          <CardHeader title="Mover Para" action={
            <IconButton edge="end" size="small" className="dialog-close-button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }></CardHeader>
          <CardContent>
            <FormControl margin="dense" fullWidth>
              <Select
                labelId="movemodal-status"
                value={status || ''}
                onChange={this.handleChange}
              >
                {Object.values(TaskStatus).map((status) => <MenuItem key={status} value={status}>{
                  status === 'pending' ? 'Pendente' : status === 'execution' ? 'Em Execução' : 'Concluído'
                }</MenuItem>)}
              </Select>
            </FormControl>
          </CardContent>
          <Box p={2} pr={1}>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Box mr={1}>
                <Button color="default" variant="contained" onClick={handleClose}>Cancelar</Button>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </CardActions>
          </Box>
        </Card>
      </Popover>
    );
  }
}

export default MoveModal;
