import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  closeMachineTicketTask,
  createMachineTicketTaskComment,
  CreateMachineTicketTaskComment,
  DeleteMachineTicketTaskComment,
  deleteMachineTicketTaskComment,
  DeleteMachineTicketTaskExpense,
  deleteMachineTicketTaskExpense,
  EditMachineTicketTask,
  editMachineTicketTask,
} from '../../../../../../actions/machines';
import { openSign } from '../../../../../../actions/machines';
import User from '../../../../../../models/User';
import { IAppState } from '../../../../../../reducers/index';
import TaskModal from './TaskModal';

interface IProps {
  ticketid: number;
  taskid: number;
}
const mapStateToProps = ({ authentication, machines }: IAppState, props: IProps) => {
  const task = ((machines.tickets.tickets[props.ticketid] || {}).tasks || {})[props.taskid] || {};

  return {
    task,
    user: authentication.user || ({} as User),
    redirectReady: (machines.tickets.tasksPopup[props.taskid] || {}).redirectReady,
    redirectReadyTask: (machines.tickets.tasksForms[task.status] || {}).redirectReady,
    loading: (machines.tickets.tasksPopup[props.taskid] || {}).loading, 
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createMachineTicketTaskComment: (properties: CreateMachineTicketTaskComment) =>
      dispatch(createMachineTicketTaskComment.request(properties)),
    deleteMachineTicketTaskComment: (properties: DeleteMachineTicketTaskComment) =>
      dispatch(deleteMachineTicketTaskComment.request(properties)),
    deleteMachineTicketTaskExpense: (properties: DeleteMachineTicketTaskExpense) =>
      dispatch(deleteMachineTicketTaskExpense.request(properties)),
    editMachineTicketTask: (properties: EditMachineTicketTask) =>
      dispatch(editMachineTicketTask.request(properties)),
    openSign: (ticketId: number, taskId: number) =>
      dispatch(openSign({ ticketId, taskId })),
    closeMachineTicketTask: () => dispatch(closeMachineTicketTask()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal);