import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  createMachineTicketTask,
  CreateMachineTicketTask,
  editMachineTicketTask,
  EditMachineTicketTask,
} from '../../../../../../actions/machines';
import { TaskStatus } from '../../../../../../models/Task';
import { IAppState } from '../../../../../../reducers/index';
import TaskForm from './TaskForm';

interface IProps {
  status: TaskStatus;
}

const mapStateToProps = ({ machines }: IAppState, props: IProps) => ({
  redirectReady: (machines.tickets.tasksForms[props.status] || {}).redirectReady,
  loading: (machines.tickets.tasksForms[props.status] || {}).loading, 
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createMachineTicketTask: (properties: CreateMachineTicketTask) =>
      dispatch(createMachineTicketTask.request(properties)),
    editMachineTicketTask: (properties: EditMachineTicketTask) =>
      dispatch(editMachineTicketTask.request(properties)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
