import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  fetchCustomers,
  openDeleteMachine,
  toggleMachineView,
  toggleMachineCustomerForm,
} from '../../../../actions/index';
import Machine from '../../../../models/Machine';
import User from '../../../../models/User';
import { IAppState } from '../../../../reducers/index';
import List from './List';

const mapStateToProps = ({ authentication, customers, machines, tables }: IAppState) => ({
  loading: machines.loading || customers.loading,
  machines: Object.values(machines.machines),
  view: machines.view.machineId,
  user: authentication.user || ({} as User),
  customerForm: machines.customerForm.machine,
  customers: customers.customers,
  deleteForm: machines.deleteForm.machineId,
  machineStatus: (tables.dynamicFields.fetch.machineStatus || {}).values || [],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    openDeleteMachine: (machineId: number) =>
      dispatch(openDeleteMachine(machineId)),
    fetchCustomers: () => dispatch(fetchCustomers.request()),
    toggleMachineView: (machineId?: number) =>
      dispatch(toggleMachineView(machineId)),
    toggleMachineCustomerForm: (machine: Machine) =>
      dispatch(toggleMachineCustomerForm(machine)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
