import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Form from './Form';
import Tickets from './Tickets';

interface IProps {
  fetchMachines: () => void;
  fetchMachineStatus: () => void;
  history: any;
  loading: boolean;
}
interface IState {}

class Machines extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchMachines, fetchMachineStatus } = this.props;

    fetchMachines();
    fetchMachineStatus();
  }

  public render() {
    const { history } = this.props;

    return (
      <div className="dashboard-container">
        <Router history={history}>
          <Switch>
            <Route path="/dashboard/machines" exact component={List} />
            <Route path="/dashboard/machines/list" exact component={List} />
            <Route path="/dashboard/machines/tickets" render={() => <Tickets history={history} />} />
            <Route path="/dashboard/machines/new" exact render={() => <Form history={history} />} />
            <Route path="/dashboard/machines/machineid/:machineid/edit" exact render={(props) => <Form history={history} machineid={props.match.params.machineid} />} />
            <Route path="/dashboard/machines/machineid/:machineid/new" exact render={(props) => <Form history={history} machineid={props.match.params.machineid} isNew={true} />} />
            <Redirect to="/authenticate" />
          </Switch>
        </Router>
      </div>
    );
  }
};

export default Machines;