import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchCustomerProposals } from '../../../../actions/customers';
import { IAppState } from '../../../../reducers/index';
import Proposals from './Proposals';

const mapStateToProps = ({ customers }: IAppState) => ({
  loading: customers.proposals.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchCustomerProposals: () =>
      dispatch(fetchCustomerProposals.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Proposals);
